.product-listing {
    position: relative;
    margin-top: 80px;
    @include media-breakpoint-up(sm) {
        margin-top: 85px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 35px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 40px;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: 50px;
    }
    @media screen and (max-width: 360px) {
        width: 100%;
    }
    .product-grid {
        .filter-bar {
            .filters {
                .button-filter {
                    margin-bottom: 10px;
                }
            }
        }
        .product-grid-header {
            @include media-breakpoint-down(sm) {
                gap: 20px 0;
                padding-bottom: 0px;
            }

            .pl-heading {
                @include media-breakpoint-down(md) {
                    margin-top: -8px;
                }
                &.idea-starter-cat{
                    margin-bottom: 0px;
                }
            }
            h1 {
                margin-bottom: 20px;
                @include media-breakpoint-down(sm) {
                    word-break: break-all;
                }
            }
            .surcharge-banner {
                margin-bottom: 20px;
            }
            
        }

        .col-6 {
            padding: 0 10px;
            gap: 10px 0;
        }
        .small-plp-image-tile {
            // @include media-breakpoint-up(xxl) {
            //     width: 20%;
            // }
            @include media-breakpoint-up(lg) {
                width: 16.66667%;
            }
            // .image-container {
            //     img {
            //         object-fit: cover;   
            //         @include media-breakpoint-up(md) {
            //             height: 238px;
            //         }
            //         @include media-breakpoint-up(lg) {
            //             height: 278px;
            //         }
            //         @include media-breakpoint-up(xl) {
            //             height: 332px;
            //         }
            //         @include media-breakpoint-up(xxl) {
            //             height: 370px;
            //         }
            //     }
            // }
            .image-container {
                min-height: 200px;
                @include media-breakpoint-up(sm) {
                    min-height: 190px;
                }
                @include media-breakpoint-up(md) {
                    min-height: 188px;
                }
                @include media-breakpoint-up(lg) {
                    min-height: 228px;
                }
                @include media-breakpoint-up(xl) {
                    min-height: 282px;
                }
                @include media-breakpoint-up(xxl) {
                    min-height: 320px;
                }
                img {
                    object-fit: cover;   
                    @include media-breakpoint-up(sm) {
                        height: 190px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 188px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 228px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 282px;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 320px;
                    }
                }
            }
        }
        .large-plp-image-tile {
            // @include media-breakpoint-up(xxl) {
            //     width: 40%;
            // }
            @include media-breakpoint-up(lg) {
                width: 33.33333%;
            }
            // .image-container {
            //     img {
            //         object-fit: cover;    
            //         @include media-breakpoint-up(md) {
            //             height: 238px;
            //         }
            //         @include media-breakpoint-up(lg) {
            //             height: 278px;
            //         }
            //         @include media-breakpoint-up(xl) {
            //             height: 332px;
            //         }
            //         @include media-breakpoint-up(xxl) {
            //             height: 370px;
            //         }
            //     }
            // }
            .image-container {
                min-height: 200px;
                @include media-breakpoint-up(sm) {
                    min-height: 190px;
                }
                @include media-breakpoint-up(md) {
                    min-height: 188px;
                }
                @include media-breakpoint-up(lg) {
                    min-height: 228px;
                }
                @include media-breakpoint-up(xl) {
                    min-height: 282px;
                }
                @include media-breakpoint-up(xxl) {
                    min-height: 320px;
                }
                img {
                    object-fit: cover;    
                    @include media-breakpoint-up(sm) {
                        height: 190px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 188px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 228px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 282px;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 320px;
                    }
                }
            }
        }
        .sticky-filter{
            &.applied-filter-sticky{  
                .applied-filters{
                    .applied-filter-list{
                        margin-bottom: 5px;
                    }
                    .iconBar{
                        margin-bottom: 10px;
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .sticky-prod-coparison-bar {
        background-color: $sugarcane;
        padding: 17px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 17px 12px;
        }

        .left-area-bar {
            p {
                font-size: 13px;
                font-weight: 600;
                text-transform: uppercase;
                color: $brand-primary;
            }

            .added-products {
                list-style: none;
                display: flex;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                    gap: 10px;
                }

                li {
                    span {
                        margin-left: 7px;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 13px;
                    }

                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                }
            }
        }

        .right-area-bar {
            .button {
                border: 1px solid $brand-primary;
                border-radius: 50px;

                &:hover {
                    background-color: $brand-primary;
                    color: $white;
                }
            }
        }
    }
    .idea-started-product-list, .download-product-list {
        margin-top: 20px;
        align-items: end;
    
        @include media-breakpoint-down(xl) { 
            align-items: start;
        }
        @include media-breakpoint-down(lg) { 
            align-items: end;
        }
        li {
            padding-right: 0;
        }
    }
    
}

.plp-no-result-found {
    @include top-bottom-padding;
    text-align: center;
    @include header(1);
}
.iconBar {
    display: inline-flex;
    width: 30%;
    justify-content: flex-end;
    margin-left: auto;
    margin-bottom: 30px;
    align-self: flex-start;
    
    @include media-breakpoint-only(sm) {
        width: 26%;
    }
    @media screen and (max-width: 374px) {
        margin-left: 0;
        width: auto;
    }
    @include media-breakpoint-down(sm) {
        align-items: center;
    }
    @include media-breakpoint-up(md) {
        width: 20%;
    }
    @include media-breakpoint-up(xl) {
        width: 15%;
    }
    button {
        background: none;
        box-shadow: none;
        border: none;
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
        &.active, &:hover{
            img{
                filter: invert(46%) sepia(24%) saturate(1009%) hue-rotate(350deg) brightness(88%) contrast(87%);
            } 
        }
    }
    [data-title]:hover:after {
        opacity: 1;
    }
    [data-title]:after {
        content: attr(data-title);
        color: #956839;
        font-size: 9px;
        position: absolute;
        top: 30px;
        left: -50%;
        white-space: nowrap;
        opacity: 0;
    }
    [data-title] {
        position: relative;
    }
    .sort-by {
        font-size: 13px;
        line-height: 28px;
        letter-spacing: 0.65px;
        margin-right: 21px;
        text-transform: uppercase;
        font-weight: 600;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
            line-height: 19px;
            margin-right: 8px;
        }
    }
} 
.downloadsList {
    .table {
        .gridCintainer {
            display: grid;
            grid-template-columns: 1fr repeat(2, 239px) 110px;
            @include media-breakpoint-down(lg) {
                grid-template-columns: 1fr repeat(2, 170px) 110px;
            }
            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr repeat(3, 110px);
            }
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-wrap: wrap;
            }
            .gridList {
                font-size: 14px;
                font-family: $font-family-PlusJakartaSans !important;
                color: $brand-primary;
                border: none;
                padding: 21px 10px;
                .link-icon-btn{
                margin-top: 0;
                } 
            }
        }
        .headingContainer {
            .gridList {
                font-weight: $font-weight-semibold;
                background: none;
                padding-inline:0;
                @include media-breakpoint-down(sm) {
                display: none;
                &:first-child{
                display: block;
                width: 100%;
                }
                }
                
                button {
                    background: none;
                    box-shadow: none;
                    border: none;
                    margin-left: 5px;
                    display: inline-block;
                    padding: 0;
                }
            }
            .item3 {
                // grid-area: 1/3/2/4;
            }
        } 
        .detailContainer {
            @include media-breakpoint-down(sm) {
                padding-block: 21px;
            }
            .gridList {
                padding-inline:0 10px;
                line-height: 26px;
                font-weight: $default-font-weight;
                @include media-breakpoint-down(sm) {
                    width: auto;
                    padding: 0 10px;
                }
                &:first-child {
                    padding-left:10px;
                    @include media-breakpoint-down(md) {
                        white-space: nowrap;
                        width:17ch;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    @include media-breakpoint-down(sm) {
                        white-space: inherit; 
                        overflow:inherit;
                        width: 100%;
                        margin-bottom:5px;
                    }
                }
                &:last-child {
                    text-align: right;
                    @include media-breakpoint-down(sm) {
                    text-align: left;
                    width: 100%;
                    margin-top:5px;
                    }
                }
                a {
                    display: block;
                    padding: 0;
                    font-size: 14px;
                    font-weight: $default-font-weight;
                    &::before{
                    display: none; 
                    }
                    .download-icon {
                        margin-left: 10px;
                    }
                } 
            }
            .gridList.item2 {
                @include media-breakpoint-down(sm) {
                    position: relative;
                    &::after {
                        content: "/";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                } 
            }
            &:nth-child(odd) {
                background-color: $cultured;
            }
        }
    }
}

.count-filter-seperation {
    margin: 0 5px;
    word-break: break-word;
    @include media-breakpoint-down(sm) {
        white-space: pre-line;
    }
}

.product-PLP {
    .product {
        .product-tile {
            .tile-body {
                padding: 11px 0;
                @include media-breakpoint-only(xl) {
                    padding-top: 19px;
                    padding-bottom: 10px;
                }
                @include media-breakpoint-between(md,lg) {
                    padding: 13px 0 10px;
                }
                .pdp-link {
                    .link {
                        margin-bottom: 11px;
                        font-size: 16px;
                        @include media-breakpoint-between(md,lg) {
                            margin-bottom: 10px;
                        }
                        sup {
                            font-size: .45em;
                            top: -8px;
                        }
                        span {
                            // font-weight: 600;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            letter-spacing: .65px;
                            text-transform: uppercase;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .tile-footer {
                margin-bottom: 0;
                display: none;
                .pdp-link-btn {
                    width: 32px;
                    height: 32px;
                    img {
                        width: 17px;
                    }
                }
            }
        }
    }
}
.product-listing {
    .product-grid {
        .product-PLP {
            .large-plp-image-tile, .small-plp-image-tile {
                .product {
                    // padding-bottom: 40px;
                    padding-bottom: 20px;
                    .article-block.image-with-text {
                        padding-top: 0;
                        padding-bottom: 0;
                        img {
                            object-fit: cover;
                            @include media-breakpoint-up(sm) {
                                height: 190px;
                            }
                            @include media-breakpoint-up(md) {
                                height: 188px;
                            }
                            @include media-breakpoint-up(lg) {
                                height: 228px;
                            }
                            @include media-breakpoint-up(xl) {
                                height: 282px;
                            }
                            @include media-breakpoint-up(xxl) {
                                height: 320px;
                            }
                        }
                    }
                }
            }
            .applied-filters {
                .applied-filter-list {
                    margin-bottom: 71px;
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 49px;
                    }
                }
            }
            .pl-heading {
                .h1 {
                    @include media-breakpoint-between(md,lg) {
                        font-size: 60px;
                        line-height: 82px;
                        letter-spacing: 0.6px;
                    }
                }
            }

            
        }
    }
}