@import "~base/components/header";

header {
    position: relative;
    // background-color: #E0E7E7;
    @include media-breakpoint-down(lg) {
        padding: 15px 0;
    }
    @include media-breakpoint-up(lg) {
        z-index: 10;
    }

    &:not(.stickyHeader) {
        .navbar-header {
            .pull-left > .container {
                padding: 0;
            }
        }
    }
    .top-header {
        padding: 15px 0;
        position: relative;

        @include media-breakpoint-down(lg) {
            padding: 10px 0;
        }

        .navbar-header {
            position: relative;
            overflow: hidden;

            .pull-left {
                .search {
                    display: none;
                    position: fixed;
                    top: 0;
                    transform: none;
                    left: auto;
                    z-index: 10;
                    margin-left: 20px;
                    width: 100%;
                    // padding: 12px 0;
                    padding: 6px 0;
                    margin-top: 0;
                    .site-search {
                        .form-control {
                            border-radius: 0;
                            border: none;
                            text-transform: uppercase;
                            font-size: 13px;
                            letter-spacing: 0.65px;
                            font-weight: 600;
                            padding: 20px 16px 16px 16px;
                            color: $brand-primary;
                            @include media-breakpoint-up(lg) {
                                background-color: transparent;
                                line-height: 13px;
                                padding: 10px 16px;
                            }
                            &.search-field-1 {
                                position: absolute;
                                top: 14px;
                                left: 0;
                                color: rgba(0, 0, 0, 0.45);
                                z-index: -1;
                                text-align: left;
                                @include media-breakpoint-down(lg) {
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }

            .pull-right {
                .user {
                    a {
                        text-decoration: none;
                    }
                }

                .minicart {
                    .hide-no-link {
                        display: none;
                    }

                    .minicart-quantity {
                        background-color: $brand-secondary;
                        color: $white;
                    }

                    .popover {
                        border-color: transparent;
                    }
                }
            }
        }
    }

    .top-header-icons {
        margin: 0;

        @include media-breakpoint-up(lg) {
            // position: fixed;
            // top: 21px;
            // z-index: 2;
        }
        li {
            &:not(:last-child) {
                margin-right: 20px;
                @include media-breakpoint-down(lg) {
                    margin-right: 30px;
                }
            }

            a {
                display: flex;
                cursor: pointer;
            }

            .search-icon {
                img {
                    @include media-breakpoint-down(lg) {
                        width: 23px;
                    }
                }
            }
            .profile-icon {
                img {
                    @include media-breakpoint-down(lg) {
                        width: 21px;
                    }
                }
            }

            .chat-icon {
                img {
                    @include media-breakpoint-down(lg) {
                        width: 24px;
                    }
                }
            }
            &.active {
                z-index: 4;
            }

        }
    }

    .mobile-category-bar {
        width: 100%;
    }

    .top-header-nav-right {
        a {
            font-size: 13px;
            text-transform: uppercase;
            color: $brand-primary;

            @include media-breakpoint-down(lg) {
                font-size: $h2-font-size;
                text-transform: capitalize;
                font-family: $PlusJakartaSans;
                font-weight: 300;
            }

            // span {
            //     margin-right: 5px;
            // }
            
            img {
                margin: 4px 0 0 14px;
            }
        }
    }

    .acount-bar {
        margin: 0;
        background-color: $white;
        position: absolute;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 250px);
        margin: 0;
        z-index: 1;
        padding: 8px 7px;
        display: none;

        @include media-breakpoint-down(lg) {
            left: 0;
            top: 100%;
            transform: inherit;
            width: 100%;
            padding: 0;
        }

        .user {
            margin: 0;
            display: flex;
            line-height: initial;

            &:not(:last-child) {
                margin-right: 15px;
            }

            a {
                display: flex;
                color: $brand-primary;
                font-size: 13px;
                font-family: $PlusJakartaSans;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.65px;

                @include media-breakpoint-down(lg) {
                    width: 100%;
                    font-size: 23px;
                    padding: 16px 0;
                    border-top: 1px solid $brand-primary;
                    border-bottom: 1px solid $brand-primary;
                    text-transform: capitalize;
                    font-weight: 300;
                }

                i {
                    display: none;
                }
            }
        }
    }

    .search {
        position: absolute;
        left: 20px;
        top: -3px;
        width: calc(100% - 250px);
        background-color: $white;
        z-index: 2;

        @include media-breakpoint-down(lg) {
            width: 100%;
            // top: 0;
            left: 25px;
            top: 16px;
        }

        .form-control {
            border: none;
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 0.65px;
            font-weight: 600;
            padding: 20px 16px 16px 16px;
            color: $brand-primary;
            @include media-breakpoint-down(lg) {
                padding: 5px 16px 5px 16px;
            }
            &::placeholder {
                /* Most modern browsers support this now. */
                color: #959595;
            }

            &:focus {
                box-shadow: none;
            }
        }

        button[type="submit"] {
            visibility: hidden;
            display: none;
        }
    }

    .header-spacer {
        margin: 1px 0 0;
        opacity: 1;
        background-color: $brand-primary;
        height: 1px;
    }

    .main-header {
        .navbar-toggler {
            margin-right: auto;
        }

        .brand {
            position: initial;
            padding: 0;
            margin: 0;

            @include media-breakpoint-down(lg) {
                width: auto;
                margin-right: auto;
            }

            img {
                width: auto;
                &.d-lg-none {
                    max-height: 30px;
                }
            }
        }

        .main-menu {
            background-color: $white;
            // z-index: 4;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                background-color: transparent;
            }

            &.menu-toggleable-left {
                .close-menu {
                    position: fixed;
                    top: 0;
                    left: -100%;
                    width: 60px;
                    height: 60px;
                    background-color: #E0E7E7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                }

                &.in {
                    .close-menu {
                        // left: 0;
                        transition: 0.4s;
                    }

                    .scroll-arrow {
                        opacity: 1;
                    }
                }

                // .container {
                //     padding: 0;
                // }
            }

            .navbar {
                position: static;

                @include media-breakpoint-down(lg) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 20px;
                    height: 100%;
                }

                .mobile-top-header {
                    padding: 16px 0;
                    border-bottom: 1px solid $brand-primary;
                    width: 100%;
                    position: relative;

                    .search {
                        display: none;
                    }

                    .acount-bar {
                        display: none;
                    }
                }

                .menu-group {
                    position: static;

                    @include media-breakpoint-down(lg) {
                        width: 100%;    
                        padding: 0px;
                        flex: auto;
                        position: relative;
                        overflow-y: auto;
                        height: calc(100vh - 150px);
                    }

                    .nav {
                        .nav-item {
                            position: static;

                            @include media-breakpoint-down(lg) {
                                border-bottom: 1px solid $brand-primary;
                            }
                              &:focus {
                                  outline: none;
                                  background-color: transparent;

                              }

                            .nav-link {
                                color: $brand-primary;
                                background-color: transparent;
                                padding: 30px 15px;
                                font-size: $nav-link-font-size;
                                line-height: $nav-link-font-size;

                                @include media-breakpoint-down(lg) {
                                    padding: 16px 0;
                                }

                                // &::after {
                                //     display: none;
                                // }
                            }

                            &:last-child {
                                .nav-link {
                                    padding-right: 0;
                                }
                            }

                            &.dropdown {
                                .dropdown-menu {
                                    @include media-breakpoint-down(lg) {
                                        left: inherit;
                                        right: -100%;
                                    }

                                    .nav-menu {
                                        display: flex;
                                        width: 18px;
                                        margin-right: 11px;

                                        .close-menu {
                                            position: initial;
                                            background-color: transparent;
                                            border: none;
                                            width: auto;
                                            height: auto;

                                            // .close-button {
                                            //     display: none;
                                            // }
                                        }
                                    }
                                }

                                &.show {
                                    .dropdown-menu {
                                        display: flex;
                                        flex-direction: column;
                                        /*--new change--*/
                                        @include media-breakpoint-down(lg) {
                                            position: absolute;
                                            flex-direction: row;
                                            flex-wrap: wrap;
                                            opacity: 1;
                                            transition: 0.4s;
                                            right: 0;
                                            z-index: 1;
                                            top: 0;
                                            height: 100%;
                                        }
                                        @include media-breakpoint-down(lg) {
                                            left: 0;
                                            // transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
                                            flex-direction: column;
                                        }

                                        .top-category {
                                            @include media-breakpoint-down(lg) {
                                                padding: 0;
                                                background: transparent;
                                            }

                                        }

                                        .cat-nav {
                                            @include media-breakpoint-down(lg) {
                                                display: none;
                                            }
                                        }

                                        .cat-nav-items {
                                            @include media-breakpoint-down(lg) {
                                                flex-direction: column;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            & > .container {
                padding: 0;
            }
            .custom-drop {
                .container {
                    // padding: 0 50px;
                    // @include media-breakpoint-down(xl) {
                    //     padding: 0 44px;
                    // }
                    // @include media-breakpoint-down(md) {
                    //     padding: 0 40px;
                    // }
                }
            }
        }

        .menu-toggleable-left {
            &.navbar-toggleable-lg {
                &.in {
                    left: 110px;
                    margin-right: 0;

                    @include media-breakpoint-down(sm) {
                        left: 74px;
                    }

                    @include media-breakpoint-down(xs) {
                        left: 60px;
                    }
                }
            }
        }
    }
}

.dropdown-item {
    &:hover {
        background-color: transparent;
    }
}