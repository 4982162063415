.filter-bar { 
    // position: absolute;
    // top: -50px;
    padding: 5px 0px;
    background-color: #ffffff;
    width: 100%;
    @include media-breakpoint-down(xxl) {
        top: -47px;
        padding: 10px 0;
    }
    @include media-breakpoint-down(xl) {
        // top: -40px;
        padding: 6px 0;
    }
    // @include media-breakpoint-down(lg) {
    //     // top: -15px;
    //     padding: 6px 0;
    // }
    // @include media-breakpoint-down(md) {
    //     // top: -10px;
    // }
    // @include media-breakpoint-down(sm) {
    //     // top: -15px;
    //     padding: 6px 0;
    // }
    &.filter-shadow{
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
    }
     &.filter-sticky {
        position: fixed;
        padding: 15px 0px 5px;
        width: 100%;
        background: $white;
        top: 60px;
        z-index: 10;
        right: 0;
        // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
        // @include media-breakpoint-up(lg) {
        //     z-index: 3;
        // }
        @include media-breakpoint-down(xxl) {
            padding: 15px 40px 5px;
        }
        @include media-breakpoint-down(lg) {
            padding: 15px 30px 5px;
        }
        @include media-breakpoint-down(md) {
            padding: 15px 25px 5px;
        }
        @include media-breakpoint-down(sm) {
            padding: 15px 20px 5px;
        }
        .search-field {
            top: 23px;
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
        @include media-breakpoint-down(md) {
            // margin-top: 0;
            top: 23px;
        }
        }
    }
   
   
    .filters {
        flex-wrap: nowrap;
        max-width: 1540px;
        margin: auto;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }

        .button-filter {
            position: relative ;
            z-index: 3;
            margin-bottom: 10px;
            margin-right: 10px;

            &:hover {
                background-color: $brand-secondary;
                color: $white;
            }
        }

        .show-filter-bar {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $white;
            padding: 76px 0px 50px;
            z-index: 2;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
            @include media-breakpoint-down(lg) {
                padding: 60px 0 40px;
            }
            @include media-breakpoint-down(md) {
                padding: 50px 0 20px;
            }

            .nav-pills {
                .nav-link {
                    color: $brand-primary;
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 400;
                    padding: 0;
                    text-align: left;

                    @include media-breakpoint-up(md) {
                        margin-inline-end: auto;
                    }

                    &:hover {
                        color: $brand-secondary;
                    }

                    &.active {
                        background-color: $white;
                        color: $brand-secondary;
                    }
                }
            }

            .tab-content {
                .tab-pane {
                    // display: grid;
                    display: inline-grid;
                    grid-auto-flow: column;
                    grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
                    overflow: auto;
                    column-gap: 40px;

                    @include media-breakpoint-down(sm) {
                        display: block;
                    }

                    @include media-breakpoint-only(sm) {
                        display: block;
                        column-count: 2;
                    }

                    @include media-breakpoint-down(lg) {
                        column-gap: 20px;
                    }
                    @include media-breakpoint-down(md) {
                        column-gap: 10px;
                    }

                    .filter-item,
                    .custom-filter-item {
                        width: 100%;

                        @include media-breakpoint-up(lg) {
                            width: 50%;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 20%;
                        }

                        .link {
                            font-size: 13px;
                            line-height: 26px;
                            color: $brand-primary;
                            border: none;
                            background-color: $white;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            margin-left: 15px;

                            span {
                                white-space: normal;
                                text-align: left;

                                &:hover {
                                    color: $brand-secondary;
                                }

                                &.selected {
                                    color: $brand-secondary;
                                    // margin-left: 7px;
                                }
                            }
                        }
                    }

                    .filter-item {
                        @include media-breakpoint-up(lg) {
                            width: auto;
                        }
                    }

                    &:not(.active) {
                        height: 0;
                        display: none;
                    }
                }

                .custom-filter-item,
                .filter-item {
                    position: relative;

                    img {
                        position: absolute;
                        left: 0;
                        height: 8px;
                        top: 8px;
                    }
                }
            }

            .nav {
                button {
                    position: relative;

                    &.unchecked {
                        color: $brand-primary;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            height: 19px;
                            width: 19px;
                            border: 1px solid $brand-primary;
                            border-radius: 50px;
                            transform: translate(0, -50%);
                        }

                        &.nav-link {
                            padding-left: 31px;
                        }
                    }

                    &.checked {
                        color: $brand-secondary;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            height: 19px;
                            width: 19px;
                            border: none;
                            background-image: url(../images/products/checked-icon.svg);
                            transform: translate(0, -50%);

                        }

                        &.nav-link {
                            padding-left: 31px;
                        }
                    }
                }

                .custom-filters {
                    margin-top: 10px;

                    .filter-value {
                        margin-top: 8px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }

            .col-6 {
                // padding: 0 10px;
                padding: 0;
                gap: 10px 0;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        order: 1;
    }
   
}


.sticky-filter{
    &.applied-filter-sticky{  
        position: fixed;
        right: 0;
        top: 118px;
        background: white;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
        padding: 5px 0px 5px;
        width: 100%;
        z-index: 9;
        .applied-filters{
            @extend .container;
            .applied-filter-list{
                margin-bottom: 5px;
            }
        }
    }
}
.applied-filters {
    width: 100%;
    display: flex;
    margin: auto;
    // align-items: center;
    // margin-top: 20px;
    position: relative;
    z-index: 1;
    gap: 0 30px;
    @include media-breakpoint-down(sm) {
        gap: 0 10px;
    }
    @media screen and (max-width: 360px) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    //  @include media-breakpoint-down(xxl) {
    //     display: block;
    //  }

    p {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        align-self: flex-start;
        letter-spacing: 0.65px;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
            // white-space: pre-line;
            word-break: break-word;
            max-width: 220px;
        }
    }

    .applied-filter-list {
        padding: 0;
        display: flex;
        align-items: center;
        gap: 11px 30px;
        flex-wrap: wrap;
        max-width: calc(100% - 46px);
        overflow-x: auto;
        cursor: auto;
        margin-bottom: 30px;
        margin-left: 4px;
        width: 80%;
        @include media-breakpoint-up(xxl) {
            margin-left: 8px;
        }
        @include media-breakpoint-only(sm) {
            width: 74%;
        }
        @include media-breakpoint-down(sm) {
            width: 99%;
            gap: 10px 20px;
            max-width: 100%;
        }
        @media screen and (max-width: 360px) {  
            width: 100%;
        }
        @include media-breakpoint-up(md) {
            width: 80%;
        }
        @include media-breakpoint-up(xl) {
            width: 85%;
        }


        // @include media-breakpoint-down(xxl) {
        //     margin-bottom: 20px;
        // }

        // @include media-breakpoint-down(xl) {
        //     margin-bottom: 15px;
        // }

        // @include media-breakpoint-down(lg) {
        //     margin-bottom: 10px;
        // }

        @include media-breakpoint-down(md) {
            // margin-bottom: 5px;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            gap: 10px 20px;
            max-width: 100%;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        .filter-value,
        .image-video {
            // margin: 0 0 10px;
            float: inherit;
            display: flex;
            // align-items: center;
            gap: 0 7px;
            white-space: pre-wrap;

            button {
                margin: 0;
                // padding: 5px 0 0;
                display: flex;
            }

            span {
                color: $brand-secondary;
                font-size: 13px;
                // line-height: $default-text-line-height;
            }
        }

        .custom-filter-reset,
        .custom-filter-clearfilter {
            border: none;
            padding: 0;
            display: flex;
            align-items: center;
            gap: 0 5px;
            white-space: nowrap;
            span {
                color: $harvest-haze;
                text-transform: capitalize;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        order: 3;
    }
}

.filter-library {
    margin: 30px 0 40px 0;
    .filter-image-list {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        gap: 20px;
        @include media-breakpoint-down(xl) {
            margin-bottom: 40px;
            gap: 67px;
        }
        @include media-breakpoint-down(lg) {
            gap: 20px;
        }

        @include media-breakpoint-down(md) {
            display: block;
            margin-bottom: 20px;
        }

        ul {
            display: grid;
            gap: 20px;
            grid-template-columns: auto auto auto auto auto auto auto auto auto auto;

            @include media-breakpoint-down(xxl) {
                display: flex;
                flex-wrap: wrap;
                // grid-template-columns: auto auto auto auto auto;
            }

            @include media-breakpoint-down(lg) {
                gap: 16px 20px;
            }

            @include media-breakpoint-down(md) {
                overflow-x: auto;
                flex-wrap: nowrap;
                -ms-overflow-style: none;
                scrollbar-width: none;
                gap: 10px;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .filter-text {
            flex: 0 0 7.1333%;

            @include media-breakpoint-down(xxl) {
                flex: 0 0 6.7111%;
            }

            @include media-breakpoint-down(xl) {
                flex: 0 0 9.66%;
            }

            @include media-breakpoint-down(lg) {
                flex: 0 0 14.4%;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
        }

        .product-details {
            display: block;
            width: 110px;
            overflow-wrap: break-word;
            background: none;
            border: none;
            text-align: left;
            line-height: 18px;

            @include media-breakpoint-down(xxl) {
                width: 84px;
            }

            @include media-breakpoint-down(xl) {
                width: 137px;
            }

            @include media-breakpoint-down(lg) {
                width: 101px;
            }

            @include media-breakpoint-down(md) {
                width: 162px;
            }

            @include media-breakpoint-down(sm) {
                width: 98px;
            }
            &:hover {
                span {
                    color: $brand-secondary;
                }
            }
            &.active-filter {
                .product-filter-name {
                   color: $brand-secondary;
                }
            }
        }
        .product-filter-name     {
            font-size: 13px;
            line-height: 18px;
        }
        .narrow-image {
            height: 100%;
            width: 100%;
            max-height: 38px;
            margin-bottom: 5px;
            @include media-breakpoint-down(xxl) {
                max-height: 30px;
            }

            @include media-breakpoint-down(xl) {
                max-height: 47px;
            }

            @include media-breakpoint-down(lg) {
                max-height: 35px;
            }

            @include media-breakpoint-down(md) {
                max-height: 56px;
            }

            @include media-breakpoint-down(sm) {
                max-height: 34px;
            }
        }

        p {
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.65px;
            line-height: 18px;
            text-transform: uppercase;
            white-space: break-spaces;
            @include media-breakpoint-only(xl) {
                letter-spacing: 0px;
                font-size: 12px;
            }
           
        }
    }
}

.common-listing-header {
    // padding: 50px 0 0;

    .search-products {
        .search-product {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: $font-weight-semibold;
        }
    }

    .pl-heading {
        margin: 20px 0;
    }


}

.spacer-line {
    background-color: $brand-primary;
    margin: 0;
    border: 0;
    height: 1px;
    position: relative;
    z-index: -1;

}

.square-product-grid {
    .product {
        .image-container {
            margin: 0 0 10px;

            img {
                width: 100%;
            }
        }

        .title-container {
            padding: 0;
            margin-bottom: 40px;

            .is-Title {
                font-size: 18px;
                line-height: 25px;
                font-weight: $font-weight-semibold;
                color: $brand-primary;
            }

            .price-config {
                margin: 10px 0 0;
                display: flex;
                flex-direction: column;

                span {
                    @include plp-subHeading;
                }
            }
        }
    }
}

.material-list {
    .material-list-item {
        .material-list-item-title {
            border-top: 1px solid $brand-primary;
            border-bottom: 1px solid $brand-primary;

            h4 {
                font-size: 25px;
                padding: 20px 0;
                line-height: 22px;
                font-weight: $font-weight-semibold;
            }
        }

        .material-list-item-subtitle {
            h6 {
                font-size: 14px;
                padding: 20px 0 13px;
                font-weight: $font-weight-semibold;
                line-height: 22px;
            }
        }

        .material-list-items {
            .mli-title {
                text-transform: uppercase;
                font-size: 14px;
                margin-bottom: 10px;
                display: inline-block;
                font-weight: $font-weight-semibold;
                line-height: 22px;
                letter-spacing: 0.65px;
            }

            .product {
                .product-tile {
                    .tile-body {
                        display: flex;
                        flex-direction: column;

                        span {
                            font-size: 18px;
                            line-height: 22px;
                           
                        }
                    }
                }
            }
        }
        &:last-child {
            .spacer-line {
                display: none;
            }
        }
    }
}

.filters {
    .product-search {
        // position: relative;
        margin-left: 10px;
        z-index: 2;
       
        .suggestions-wrapper {
            position: absolute;
            top: 65px;
            z-index: 1;
            display: none !important;

            // @include media-breakpoint-down(md) {
            //     left: 100px;
            //     top: 115px;
            // }

            @include media-breakpoint-only(md) {
                left: 100px;
            }

            .suggestions {
                padding-top: 10px;

                @include media-breakpoint-down(lg) {
                    padding: 10px;
                    border: 0;
                }
            }
        }
        @media screen and (max-width: 360px) {
            width: 100%;
        }
    }

    .search-products {
        .search-product {
            display: flex;

            img {
                margin-right: 10px;
            }
        }

        span {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 28px;
        }
    }

    .search-field {
        position: absolute;
        left: auto;
        border: none;
        padding: 0;
        // top: 28px;
        top: 13px;
        text-transform: uppercase;
        transform: translate(0px, -36%);
        line-height: 43px;
        font-weight: 600;
        font-size: 13px;
        padding: 0px 20px 0 0;
        margin-left: 27px;
        width: 200px;
        @include media-breakpoint-down(xxl) {
            top: 18px;
        }
        @include media-breakpoint-down(xl) {
            top: 14px;
        }
        @include media-breakpoint-down(lg) {
            // top: 29px;
            width: auto;
        }

        @include media-breakpoint-down(sm) {
            top: auto;
            margin-top:-20px;
            width: auto;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .search-material-lib {
        width: 250px;
        @include media-breakpoint-down(lg) {
            // top: 29px;
            width: 250px;
        }
        @media only screen and (max-width: 312px) {
            margin-top: -35px;
            height: 50px;
        }
    }

    .search-field-1 {
        position: absolute;
        z-index: -1;
        display: none;
        border: 0;
        width: auto;

        &:focus {
            box-shadow: none;
        }
    }

    .site-search {
        button {
            display: none;
        }
    }
}

.load-more-div {
    .load-more {
        @include bottom-margin;
        .plp-result-count-text {
            font-size: 13px;
            line-height: 26px;
            letter-spacing: 0px;
            font-weight: normal;
        }
        .more {
            // margin: 50px auto 15px;
            margin-top: 20px;
        }
    }
}

// .plp-video {
//     @include media-breakpoint-down(lg) {
//         width: 47%;
//         margin-left: 1%;
//         &:nth-child(2n+1) {
//             margin-left: 0;
//         }
//     }
//     @include media-breakpoint-down(sm) {
//         width: 100%;
//     }
//     .plp-video-payer {
//         width: 400px;
//         @include media-breakpoint-down(lg) {
//             width: 100%;
//         }
//     }
// }
.material-grid-header {
    .applied-filters {
        .applied-filter-list {
            margin-left: 0;
        }
    }
}


