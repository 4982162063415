@import "~base/components/footer";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');
// Footer
footer {
    padding: 0;
    background-color: transparent;
    .footer-upper {
        padding: 50px 0 0;
        @include media-breakpoint-down(xxl) {
            padding: 40px 0 0;
        }
        @include media-breakpoint-down(xl) {
            padding: 35px 0 0;
        }
        @include media-breakpoint-down(lg) {
            padding: 29px 0 0;
        }
        @include media-breakpoint-down(md) {
            padding: 25px 0 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 20px 0 0;
        }
        .footer-item {
            padding-bottom: 50px;
            padding-top: 11px;
            &:first-child{
                // padding-top: 0;
                @include media-breakpoint-only(md) {
                    margin-bottom: 0;
                }
                @include media-breakpoint-down(lg) {
                    padding-bottom: 0;
                }
                @include media-breakpoint-only(xs) {
                    align-items: center;
                }
            }
            &:nth-child(2){
                // padding-top: 3px;
                @include media-breakpoint-only(md) { 
                    margin-top: -23px;
                }
            }
            &:nth-child(3){
                // padding-top: 3px;
                @include media-breakpoint-only(md) { 
                    margin-top: -23px;
                }
            }
            @include media-breakpoint-down(xl) {
                padding-bottom: 30px;
                margin-bottom: 30px;
            }
            @include media-breakpoint-down(sm) {
                padding-bottom: 0;
            }
            &:last-child {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                }
            }
            h4 {
                font-size: 13px;
                text-transform: uppercase;
                line-height: 26px;
                font-weight: 600;
                letter-spacing: 0.65px;
            }

            p {
                font-size: 13px;
                line-height: 25px;
                margin-bottom: 0;
                font-weight: $default-font-weight;
            }
            address {
                font-size: 13px;
                line-height: 25px;
                margin-top: 25px;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    display: flex;
                    a {
                        font-size: 13px;
                        line-height: 25px;
                        text-decoration: none;
                        color: $brand-primary;
                        display: flex;
                        &.cookies-setting-btn {
                            font-size: 13px !important;
                            line-height: 25px !important;
                            color: $brand-primary !important;
                            background-color: transparent !important;
                        }
                    }
                }
            }
            .footer-social-media{
                margin-top: 6px; 
            }
            // .sub-btn {
            //     padding: 7px;
            //     &:hover {
            //         color: $white;
            //     }
            // }
            .footer-item-content {
                a {
                    color: $brand-primary;
                }
            }
            .footer-logo {
                img {
                    @include media-breakpoint-down(md) {
                       width: 100%;
                    }
                }
            }
        }
    }

    .footer-bottom {
        padding: 15px 0;
        // button {
        //     border: none;
        //     background: none;
        // }
    }
}
