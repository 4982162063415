@import "~base/components/formFields";

.form-group {
    &.required .form-control-label::before {
        content: "*";
        color: $danger;
    }
}

.form {
    .form-select {
        // height: 50px;
    }
    .form-label.required:after { 
        content:"*";
        color:red;
     }

    .form-control {
        color: $brand-primary;
        border: 1px solid $gray58;
        border-radius: 40px;
        background-color: $cultured;
        font-size: $default-text-font-size;
        padding: 10px 25px;

        &:focus {
            box-shadow: none;
            border-color: $gray58;
        }
    }

    &.needs-validation {
        .select-field {
            position: relative;
            .is-invalid {
                margin-top: 35px;
            }

            .invalid-feedback {
                position: absolute;
                top: -30px;
                font-size: 13px;
                color: $aethon-red;
            }
        }
    }

}

.kii-table {
    th, td {
        box-shadow: none;
        border-bottom: 1px solid #000;
        padding: 20px 5px;
        background-color: transparent;
    }
    tbody {
        tr {
            &:hover {
                background-color: $cultured;
            }
        }
    }
}