
// apply header styles, usage: @include header(2) // h2 styles
@mixin header($headerIndex) {
    $value: nth($headers, $headerIndex);

    font-family: nth($value, 2);
    font-size: nth($value, 3);
    line-height: nth($value, 4);
    letter-spacing: nth($value, 5);
    font-style: nth($value, 6);
    font-weight: nth($value, 7);

    @include media-breakpoint-up(md) {
        font-size: nth($value, 8);
        line-height: nth($value, 9);
        letter-spacing: nth($value, 10);
    }

    @include media-breakpoint-up(lg) {
        font-size: nth($value, 11);
        line-height: nth($value, 12);
        letter-spacing: nth($value, 13);
    }

    @include media-breakpoint-up(xxl) {
        font-size: nth($value, 14);
        line-height: nth($value, 15);
        letter-spacing: nth($value, 16);
    }
}



// generate color classnames, e.g. ".text--red, .text--lg--red"
@mixin color-classes($attribute) {
    @each $name,
    $hex in $colors {
        &--#{"" + $name} {
            #{$attribute}: $hex;
        }
    }

    &--md {
        @include media-breakpoint-up(md) {

            @each $name,
            $hex in $colors {
                &--#{"" + $name} {
                    #{$attribute}: $hex;
                }
            }
        }
    }

    &--lg {
        @include media-breakpoint-up(lg) {

            @each $name,
            $hex in $colors {
                &--#{"" + $name} {
                    #{$attribute}: $hex;
                }
            }
        }
    }
}

// generate size classnames, e.g. ".font-size--15, .font-size--lg--30"
@mixin text-size-classes($attribute, $from, $to) {
    @for $i from $from to $to {
        &--#{$i} {
            #{$attribute}: #{$i}px !important;
        }
        &--sm {
            @include media-breakpoint-up(sm) {
                &--#{$i} {
                    #{$attribute}: #{$i}px !important;
                }
            }
        }
        &--md {
            @include media-breakpoint-up(md) {
                &--#{$i} {
                    #{$attribute}: #{$i}px !important;
                }
            }
        }
        &--lg {
            @include media-breakpoint-up(lg) {
                &--#{$i} {
                    #{$attribute}: #{$i}px !important;
                }
            }
        }
        &--xxl {
            @include media-breakpoint-up(xxl) {
                &--#{$i} {
                    #{$attribute}: #{$i}px !important;
                }
            }
        }
    }
}

@mixin button($style: "") {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: $PlusJakartaSans;
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: $brand-primary;
    background-color: transparent;
    margin-top: 15px;

    &:hover {
        // background-color: transparent;
        color: inherit;
    }
    &:focus {
        box-shadow: none;
        // background-color: transparent;
        color: inherit;
    }

    @if $style ==primary {
        position: relative;
        top: -5px;
        transition: 0.4s;
        padding: 10px 0 0;
        letter-spacing: .65px;
        line-height: 20px;
        &:after {
            content: '';
            position: absolute;
            top: calc(100% + 3px);
            left: 0;
            width: 100%;
            border-bottom: 1px solid $brand-primary;
            transition: 0.4s;
        }
        &:hover {
            color: $brand-primary;
            top: 0;

            &:after {
                top: 0;
            }
        }
        &:focus {
            color: $brand-primary;
        }

        &:active {
            background-color: transparent;
            color: inherit;
        }
    } 
    @if $style ==secondary {
        padding: 10px 0 0;
        letter-spacing: .65px;
        line-height: 20px;
        margin-top: 0;
        font-size: 15px;
        &:hover {
            color: $brand-secondary;
            border-bottom: 1px solid $brand-secondary;
        }
        &:focus {
            color: $brand-secondary;
            font-weight: 600;
            border-bottom: 1px solid $brand-secondary;
        }

        &:active, &.active {
            background-color: transparent;
            color: $brand-secondary;
            font-weight: 600;
            border-bottom: 1px solid $brand-secondary;
        }
    }
    @if $style ==outline {
        border-radius: 50px;
        border: 1px solid $brand-primary;
        padding: 6px 11px;
        margin-top: 0;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.65px;
        &:hover {
            background-color: $brand-primary;
            color: $white;
        }
        &[class*="text--"] {
            color: inherit;
        }
        &[class*="border--"] {
            border-color: inherit;
        }
    }

    @if $style == tertiary {
        border-radius: 50px;
        border: 1px solid transparent;
        padding: 7px 10px 5px;
        margin-top: 0;
        &:hover {
            background-color: $brand-primary;
            color: $white;
        }
    }
    @if $style ==filter {
        border-radius: 50px;
        background-color: $white-smoke;
        padding: 7px 10px 5px;
        margin-top: 0;
        color: $brand-primary;

        &:hover {
            background-color: $brand-secondary;
            color: $white;
        }
    }

    @if $style ==arrow {
        // background: url(../../images/plp-arrow-btn.svg);
        border: 1px solid $brand-secondary;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
            border-color: $brand-secondary;
        }
        img {
            max-width: 15px;
        }
    }
    @if $style ==document {
        padding: 3px 6px 3px 19px;
        position: relative;
        display: inline-block;
        margin: 0;
        color: $brand-secondary;
        text-transform: capitalize;
        font-weight: 300;
        font-size: 16px;
        &:before, 
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            width: 11px;
            height: 14px;
            display: block;
            
        }
        &:before {
            border: 1px solid $brand-secondary;
        }
        &:after {
            width: 7.8px;
            top: 8px;
            left: 4px;
            transform: rotate(45deg);
            border-top: 1px solid $brand-secondary;
            height: 0;
        }
    }
    @if $style ==configure {
        padding: 6px 11px 6px 29px;
        position: relative;
        display: inline-block;
        font-size: 13px;
        border: 1px solid $brand-primary;
        border-radius: 50px;
        background: url(../../../../images/hero-banner/config-icon.svg) no-repeat 5px 5px;
        &:hover {
            border-color: $brand-primary;
            color: #fff;
            background-color: $brand-primary;
        }
    }
}


// apply spacer
@mixin spacer($spacerIndex) {
    $value: nth($spacers, $spacerIndex);
    height: nth($value, 2);

    @include media-breakpoint-up(sm) {
        height: nth($value, 3);
    }
    @include media-breakpoint-up(md) {
        height: nth($value, 4);
    }
    @include media-breakpoint-up(lg) {
        height: nth($value, 5);
    }
    @include media-breakpoint-up(xl) {
        height: nth($value, 6);
    }
    @include media-breakpoint-up(xxl) {
        height: nth($value, 7);
    }
}

@mixin link-icon-btn {
    font-size: $default-text-font-size;
    padding: 3px 6px 3px 24px;
    position: relative;
    margin-top: 30px;
    display: inline-block;
    color: $brand-primary;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        // top: 55%;
        // transform: translateY(-50%);
        width: 11px;
        height: 14px;
        background-image: url('../images/download-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}

@mixin back-button {
    font-size: $font-size-13;
    font-weight: $font-weight-semibold;
    line-height: 16px;
    letter-spacing: 0.65px;
    color: $brand-primary;
    position: relative;
    padding: 0 0 0 31px;
    text-transform: uppercase;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -9px;
        width: 19px;
        height: 19px;
        background-image: url('../images/back-btn.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
    &:hover {
        color: $brand-primary;
    }
}

@mixin fav-button {
    font-size: $font-size-13;
    font-weight: $font-weight-semibold;
    line-height: 16px;
    letter-spacing: 0.65px;
    color: $brand-primary;
    position: relative;
    padding: 0 0 0 32px;
    text-transform: uppercase;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -9px;
        width: 20px;
        height: 19px;
        background-image: url('../images/fav-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}

@mixin plp-subHeading {
    font-size: $font-size-13;
    line-height: 16px;
    letter-spacing: 0.65px;
    font-weight: $font-weight-semibold;
    color: $brand-primary;
}

// apply padding classnames, e.g. ".padding-top-bottom-50 "
@mixin top-bottom-padding {
    padding-top: 20px;
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 29px;
        padding-bottom: 29px;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @include media-breakpoint-up(xxl) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@mixin top-bottom-margin {
    margin-top: 20px;
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 29px;
        margin-bottom: 29px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 35px;
        margin-bottom: 35px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

// apply margin classnames, e.g. ".margin-top-50"
@mixin top-margin {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
        margin-top: 25px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 29px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 35px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 40px;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: 50px;
    }
}

// apply margin classnames, e.g. ".padding-50"
@mixin bottom-margin {
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 25px;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 29px;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: 50px;
    }
}

// apply padding classnames, e.g. ".padding-top-50"
@mixin top-padding {
    padding-top: 20px;
    @include media-breakpoint-up(sm) {
        padding-top: 25px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 29px;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 35px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 40px;
    }
    @include media-breakpoint-up(xxl) {
        padding-top: 50px;
    }
}
// apply padding classnames, e.g. ".padding-bottom-50"
@mixin bottom-padding {
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
        padding-bottom: 25px;
    }
    @include media-breakpoint-up(md) {
        padding-bottom: 29px;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 35px;
    }
    @include media-breakpoint-up(xl) {
        padding-bottom: 40px;
    }
    @include media-breakpoint-up(xxl) {
        padding-bottom: 50px;
    }
}