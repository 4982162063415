// @import "bootstrap/scss/functions";
@import "~base/variables";
@import "bootstrap/scss/mixins/breakpoints";
// @import "~base/variables";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts" !default;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;


/*
* COLORS
*/
$brand-primary: #000000;
$brand-secondary: #956839;
$cultured: #f6f8f8;
$light-blue: #f0f7f7;
$neon-white: #f5f7f7;
$celeste: #cdcdc1;
$sugarcane: #f0f0ec;
$green: #73630D;
$seagreen: #c8d6d0;
$azure: #eef3f1;
$teal: #1C4844;
$blush: #d8ada5;
$light-pink: #f3e6e4;
$brick: #A24F44;
$brown: #a2765d;
$ligh-brown: #e3d6ce;
$maroon: #653734;
$smoke-white: #f7f7f7;
$gray: #767676;
$gray58: #949494;
$white: #ffffff;
$peach: #E3D5CE;
$dark-brown: #8C602F;
$default-bg: #e0e7e7;
$white-smoke: #ededed;
$mcKenzie: #946938;
$gainsboro: #e8e8e8;
$platinum: #e6e6df;
$aethon-red: #C71717;
$main-red: #ff0000;
$Gray44: #707070;
// $harvest-haze:#DE652F;
$harvest-haze: #ce470d;
$charcoal: #324048;
$light-grayish-blue : #e9ecef;
$pink:#FF00CE;
$kh-highlight: #323E48;
$kh-tint: #EFEFF0;
$kh-default: #D6D8DA;

$colors: (
    brand-primary: $brand-primary,
    brand-secondary: $brand-secondary,
    cultured : $cultured,
    light-blue: $light-blue,
    neon-white: $neon-white,
    celeste: $celeste,
    sugarcane: $sugarcane,
    green: $green,
    seagreen: $seagreen,
    azure: $azure,
    teal: $teal,
    blush: $blush,
    light-pink: $light-pink,
    brick: $brick,
    brown: $brown,
    ligh-brown: $ligh-brown,
    maroon: $maroon,
    smoke-white: $smoke-white,
    gray: $gray,
    gray58: $gray58,
    white: $white,
    peach: $peach,
    dark-brown: $dark-brown,
    default-bg: $default-bg,
    white-smoke: $white-smoke,
    platinum: $platinum,
    aethon-red:$aethon-red,
    Gray44: $Gray44, 
    harvest-haze: $harvest-haze,
    charcoal: $charcoal,
    light-grayish-blue: $light-grayish-blue,
    pink:$pink,
    kh-highlight: $kh-highlight,
    kh-tint: $kh-tint,
    kh-default: $kh-default
);

:root{
    --scrollbar-color: "#000000";
}

/*
* GRID SYSTEM
*/
// These values should match the Breakpoints values in scripts/constants/SiteConstants.js
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1640px
);


.container {
    padding: 0 20px;
    max-width: 1640px;
    @include media-breakpoint-up(sm) {
        padding: 0 25px;
    }
    @include media-breakpoint-up(md) {
        padding: 0 30px;
    }
    @include media-breakpoint-up(lg) {
        padding: 0 35px;
    }
    @include media-breakpoint-up(xl) {
        padding: 0 40px;
    }
    @include media-breakpoint-up(xxl) {
        padding: 0 50px;
    } 
}
.row {
    margin: 0 -10px;
}
.row > * {
    padding: 0 10px;
}

#maincontent {
    min-height: calc(100vh - 524px);
   
    @include media-breakpoint-down(lg) {
        margin-top: 60px;
        min-height: calc(100vh - 504px);
    }
    @include media-breakpoint-down(md) {
        min-height: calc(100vh - 600px);
    }
       
    @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 630px);
    } 
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
ul,
ol,
p,
a,
span {
    margin: 0;
    padding: 0;
    color: $brand-primary;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    list-style: none;
}


/*
* font families
*/

:root {
    --bs-font-sans-serif: 'Plus Jakarta Sans' !important;
    --bs-font-monospace: 'Plus Jakarta Sans' !important;
}
body, html {
    font-family: var(--bs-font-sans-serif) !important;
    font-weight: 400;
    color: $brand-primary;
}
* {
    font-family: var(--bs-font-sans-serif) !important;
}


// New Test
$PlusJakartaSans: 'Plus Jakarta Sans' !default;
$header-font: $PlusJakartaSans;
$body-font: $PlusJakartaSans !default;



//Text Defaults
$default-text-font: $PlusJakartaSans;
$default-text-font-weight: 400;
$default-text-font-size: 18px;
$default-text-line-height: 28px;
$default-text-letter-spacing: normal;
$default-font-weight: 400;
$font-weight-light: 300;
$font-weight-extralight: 200;
$font-weight-semibold: 600;

$font-family-PlusJakartaSans: 'Plus Jakarta Sans' !default;

b, strong {
    font-weight: $font-weight-semibold;
}

// /*
// * HEADERS
// */

$h1-font-family: $header-font;
$h1-font-size: 20px;
$h1-line-height: 30px;
$h1-letter-spacing: 0.2px;
$h1-font-style: normal;
$h1-font-weight: $font-weight-light;

$h2-font-family: $header-font;
$h2-font-size: 20px;
$h2-line-height: 30px;
$h2-letter-spacing: 0;
$h2-font-style: normal;
$h2-font-weight: $font-weight-light;

$h3-font-family: $header-font;
$h3-font-size: 18px;
$h3-line-height: 28px;
$h3-letter-spacing: 0;
$h3-font-style: normal;
$h3-font-weight: $font-weight-semibold;

$h4-font-family: $header-font;
$h4-font-size: 18px;
$h4-line-height: 28px;
$h4-letter-spacing: 0;
$h4-font-style: normal;
$h4-font-weight: $font-weight-light;

$h5-font-family: $header-font;
$h5-font-size: 16px;
$h5-line-height: 24px;
$h5-letter-spacing: 0;
$h5-font-style: normal;
$h5-font-weight: $font-weight-semibold;



// Medium desktop header values
$h1-md-font-family: $header-font;
$h1-md-font-size: 20px;
$h1-md-line-height: 30px;
$h1-md-letter-spacing: 0.2px;

$h2-md-font-family: $header-font;
$h2-md-font-size: 20px;
$h2-md-line-height: 30px;
$h2-md-letter-spacing: normal;

$h3-md-font-family: $header-font;
$h3-md-font-size: 18px;
$h3-md-line-height: 28px;
$h3-md-letter-spacing: normal;

$h4-md-font-family: $header-font;
$h4-md-font-size: 18px;
$h4-md-line-height: 28px;
$h4-md-letter-spacing: 0;

$h5-md-font-family: $header-font;
$h5-md-font-size: 16px;
$h5-md-line-height: 24px;
$h5-md-letter-spacing: 0;

// Large desktop header values
$h1-lg-font-family: $header-font;
$h1-lg-font-size: 30px;
$h1-lg-line-height: 40px;
$h1-lg-letter-spacing: normal;

$h2-lg-font-family: $header-font;
$h2-lg-font-size: 20px;
$h2-lg-line-height: 30px;
$h2-lg-letter-spacing: normal;

$h3-lg-font-family: $header-font;
$h3-lg-font-size: 20px;
$h3-lg-line-height: 30px;
$h3-lg-letter-spacing: normal;

$h4-lg-font-family: $header-font;
$h4-lg-font-size: 20px;
$h4-lg-line-height: 30px;
$h4-lg-letter-spacing: 0;

$h5-lg-font-family: $header-font;
$h5-lg-font-size: 16px;
$h5-lg-line-height: 24px;
$h5-lg-letter-spacing: 0;

// Double Extra Large desktop header values
$h1-xxl-font-family: $header-font;
$h1-xxl-font-size: 40px;
$h1-xxl-line-height: 50px;
$h1-xxl-letter-spacing: 0.4px;

$h2-xxl-font-family: $header-font;
$h2-xxl-font-size: 20px;
$h2-xxl-line-height: 30px;
$h2-xxl-letter-spacing: 0;

$h3-xxl-font-family: $header-font;
$h3-xxl-font-size: 20px;
$h3-xxl-line-height: 30px;
$h3-xxl-letter-spacing: 0;

$h4-xxl-font-family: $header-font;
$h4-xxl-font-size: 20px;
$h4-xxl-line-height: 30px;
$h4-xxl-letter-spacing: 0;

$h5-xxl-font-family: $header-font;
$h5-xxl-font-size: 16px;
$h5-xxl-line-height: 24px;
$h5-xxl-letter-spacing: normal;


$headers: (
    h1 $h1-font-family $h1-font-size $h1-line-height $h1-letter-spacing $h1-font-style $h1-font-weight $h1-md-font-size $h1-md-line-height $h1-md-letter-spacing $h1-lg-font-size $h1-lg-line-height $h1-lg-letter-spacing $h1-xxl-font-size $h1-xxl-line-height $h1-xxl-letter-spacing,

    h2 $h2-font-family $h2-font-size $h2-line-height $h2-letter-spacing $h2-font-style $h2-font-weight $h2-md-font-size $h2-md-line-height $h2-md-letter-spacing $h2-lg-font-size $h2-lg-line-height $h2-lg-letter-spacing $h2-xxl-font-size $h2-xxl-line-height $h2-xxl-letter-spacing,

    h3 $h3-font-family $h3-font-size $h3-line-height $h3-letter-spacing $h3-font-style $h3-font-weight $h3-md-font-size $h3-md-line-height $h3-md-letter-spacing $h3-lg-font-size $h3-lg-line-height $h3-lg-letter-spacing $h3-xxl-font-size $h3-xxl-line-height $h3-xxl-letter-spacing,

    h4 $h4-font-family $h4-font-size $h4-line-height $h4-letter-spacing $h4-font-style $h4-font-weight $h4-md-font-size $h4-md-line-height $h4-md-letter-spacing $h4-lg-font-size $h4-lg-line-height $h4-lg-letter-spacing $h4-xxl-font-size $h4-xxl-line-height $h4-xxl-letter-spacing,

    h5 $h5-font-family $h5-font-size $h5-line-height $h5-letter-spacing $h5-font-style $h5-font-weight $h5-md-font-size $h5-md-line-height $h5-md-letter-spacing $h5-lg-font-size $h5-lg-line-height $h5-lg-letter-spacing $h5-xxl-font-size $h5-xxl-line-height $h5-xxl-letter-spacing) !default;


// /*
// * NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
// */
$nav-link-font-family: $PlusJakartaSans;
$nav-link-font-weight: $default-text-font-weight;
$nav-link-font-size: 20px;
$nav-link-line-height: 28px;
$nav-link-letter-spacing: 0;
$sub-nav-link-font-size: 13px;
$nav-link-color: $brand-primary;
$nav-link-text-decoration: none;
$nav-link-text-decoration-hover: none;



$space-25: 25px;
$space-50: 50px;
$space-80: 80px;

// Large desktop header values
$space-20: 20px;
$space-sm-25: 25px;
$space-md-29: 29px;
$space-lg-35: 35px;
$space-xl-40: 40px;
$space-xxl-50: 50px;
// $space-md-100: 100px;

$spacers: (
    space-50 $space-20 $space-sm-25 $space-md-29 $space-lg-35 $space-xl-40 $space-xxl-50,
    // space-100 $space-50 $space-md-100
);


// Paragraphs
$paragraph-font-size: 16px;
$paragraph-line-height: 24px;
$paragraph-font-family: $PlusJakartaSans;
$paragraph-font-weight: $default-text-font-weight;

// Search Result Page
$search-title-size: 20px;
$search-cat-size: 13px;

// Global Font Sizes
$font-size-13: 13px;
