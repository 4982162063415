@import "~core/components/menu";

header {
    .main-header {
        .brand {
            @include media-breakpoint-down(lg) {
                margin: 0 auto;
            }
        }

        .menu-toggleable-left {
            .navbar {
                >.close-menu {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 60px;
                    height: 60px;
                    background-color: $default-bg;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                    opacity: 0;
                    visibility: hidden;

                    // @include media-breakpoint-down(sm) {
                    //     width: 74px;
                    // }

                    // @include media-breakpoint-down(xs) {
                    //     width: 60px;
                    // }

                    // .close-button {
                    //     margin-left: auto;
                    // }
                }

                @include media-breakpoint-up(lg) {
                    position: static;
                }

                @include media-breakpoint-down(lg) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 0 0 20px 30px;
                    height: 100%;
                }
                @include media-breakpoint-down(md) {
                    padding: 0 0 20px 25px;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0 0 20px 20px;
                }

                &.in {
                    .close-menu {
                        // left: 0;
                        // opacity: 1;
                        transition: 0.4s;
                    }

                    .scroll-arrow {
                        opacity: 1;
                    }
                }

                .mobile-top-header {
                    padding: 16px 0;
                    border-bottom: 1px solid $brand-primary;
                    width: 100%;
                    position: relative;
                    @include media-breakpoint-down(lg) {
                        padding: 18px 0;
                    }
                    .search {
                        display: none;
                    }

                    .acount-bar {
                        display: none;
                    }
                }

                .mobile-category-bar {
                    width: 100%;
                    @include media-breakpoint-down(lg) {
                        overflow: hidden;
                        border-bottom: 1px solid $brand-primary;                    
                    }
                }

                .menu-group {
                    @include media-breakpoint-up(lg) {
                        position: static;
                    }

                    @include media-breakpoint-down(lg) {
                        width: 100%;
                        padding: 0px;
                        flex: auto;
                        position: relative;
                    }

                    .nav {
                        // @include media-breakpoint-down(lg) {
                        //     border-top: 1px solid $brand-primary ;
                        // }
                        .nav-item {
                            @include media-breakpoint-up(lg) {
                                position: static;
                            }

                            @include media-breakpoint-down(lg) {
                                border-bottom: 1px solid $brand-primary; 
                            }
                             
                            .nav-link {
                                color: $brand-primary;
                                background-color: transparent;
                                padding: 28px 15px 0;
                                font-size: $nav-link-font-size;
                                line-height: 25px;
                                font-family: $PlusJakartaSans;
                                font-weight: 400;

                                @include media-breakpoint-only(lg) {
                                    position: relative;
                                    font-size: 18px;
                                    padding: 28px 12px 0;
                                }

                                @include media-breakpoint-down(lg) {
                                    padding: 17.5px 0;
                                    position: relative;
                                    font-size: 22px;
                                    font-family: $PlusJakartaSans;
                                    font-weight: 300;
                                }
                                &:focus{
                                    outline: 0;
                                    box-shadow: none;
                                }
                               
                                &::after {
                                    margin: 0;
                                    right: 20px;
                                    position: absolute;
                                    content: "";
                                    top: 45%;
                                    // background-image: url('../images/mob-nav-arrow.svg');
                                    background-repeat: no-repeat;
                                    // transform: translateY(-50%);
                                    width: 7px;
                                    height: 7px;
                                    border: 1px solid $brand-primary;
                                    border-width: 1px 0px 0px 1px;
                                    background-size: cover;
                                    transform: rotate(136deg);

                                    @include media-breakpoint-up(lg) {
                                        display: none;
                                    }
                                }
                               
                            }
                            &.show {
                                .nav-link {
                                    padding: 28px 15px 17px;
                                    @include media-breakpoint-down(lg) {
                                        padding: 18px 15px 17px 0;
                                    }
                                } 
                            }
                            &:last-child {
                                .nav-link {
                                    padding-right: 0;
                                }
                            }

                            &.dropdown {

                                .custom-drop {
                                    width: 100%;
                                    left: 0;
                                    padding: 50px 0 50px;
                                    
                                    @include media-breakpoint-up(lg) {
                                        // box-shadow: 0px 1px 3px #00000029;
                                        box-shadow: 0px 3px 7px -6px $brand-primary;
                                        // top: 130px;
                                        top: 70px;
                                        max-height: 90vh;
                                        overflow-y: auto;
                                    }

                                    @include media-breakpoint-between(lg, xl) {
                                        flex-direction: column;
                                        padding: 20px 0;
                                    }

                                    @include media-breakpoint-down(lg) {
                                        top: 0;
                                        padding: 0;
                                        left: inherit;
                                        display:flex;
                                        // transition:0.8s fade !important;
                                        opacity: 0;
                                        display: none;
                                    }

                                    &.show {
                                        .container {
                                            display: flex;
                                            // padding: 0 22px;
                                            @include media-breakpoint-up(lg) {
                                                flex-direction: column;
                                            }
                                            .row {
                                                // width: 100%;
                                            }
                                            .col-xl-8 {
                                                justify-content: space-between;
                                                .col {
                                                    flex: inherit;
                                                    width: calc(25% - 20px);
                                                    @include media-breakpoint-down(xl) {
                                                        width: calc(25% - 18px);
                                                    }
                                                }
                                            }
                                            .col-xl-10 {
                                                justify-content: space-between;
                                                @include media-breakpoint-down(xl) {
                                                    justify-content: start;
                                                }
                                                .col {
                                                    flex: inherit;
                                                    width: calc(20% - 20px);
                                                    @include media-breakpoint-down(xl) {
                                                        width: calc(20% - 27px);
                                                    }
                                                }
                                            }

                                            .dropdown-item {
                                                padding: 0;

                                                &.active {
                                                    background-color: $white;
                                                }

                                                .dropdown-link {
                                                    color: $brand-primary;
                                                    text-decoration: none;
                                                    padding: 0.425em 3em 0.425em 1em;
                                                    text-decoration: none;

                                                    &:focus {
                                                        background-color: $celeste;
                                                    }
                                                }
                                            }

                                            .cat-nav {
                                                ul {
                                                    @include media-breakpoint-down(xl) {
                                                        display: flex;
                                                    }

                                                    .dropdown-item {
                                                        padding: 0;
                                                        border: none;

                                                        @include media-breakpoint-down(xl) {
                                                            width: auto;

                                                            &:not(:last-child) {
                                                                margin-right: 28px;
                                                            }
                                                        }

                                                        &:hover {
                                                            background-color: transparent;
                                                        }

                                                        .dropdown-link {
                                                            color: $brand-primary;
                                                            font-size: 20px;
                                                            padding: 0 0 20px 0;
                                                            line-height: 25px;
                                                                &:focus {
                                                                    background-color: transparent;
                                                                }
                                                                &:hover {
                                                                    color: $brand-secondary;
                                                                }
                                                        }

                                                        &.active {
                                                            background-color: transparent;

                                                            .dropdown-link {
                                                                color: $brand-primary;
                                                            }
                                                        }

                                                        &:active {
                                                            background-color: $white;
                                                        }
                                                    }
                                                }
                                            }

                                            .cat-nav-items {

                                                .col {
                                                     
                                                    .cat-nav-items-title , .cat-nav-items-title-brand{
                                                        text-transform: uppercase;
                                                        color: $brand-primary;
                                                        font-size: $sub-nav-link-font-size;
                                                        font-family: $PlusJakartaSans;
                                                        font-weight: 600;
                                                        margin-bottom: 0px;
                                                        text-decoration: none;
                                                        letter-spacing: 0.65px;
                                                        line-height: 26px;

                                                        @include media-breakpoint-down(lg) {
                                                            font-size: $h2-font-size;
                                                        }
                                                    }

                                                    .cat-nav-items-list {
                                                        margin-bottom: 38px;

                                                        @include media-breakpoint-down(lg) {
                                                            display: none;
                                                        }

                                                        li {
                                                            padding: 1px 0 0;
                                                            border: none;
                                                            white-space: inherit;

                                                            &:hover {
                                                                background-color: transparent;
                                                            }

                                                            a {
                                                                color: $brand-primary;
                                                                font-size: $sub-nav-link-font-size;
                                                                font-family: $PlusJakartaSans;
                                                                font-weight: 400;
                                                                padding: 0;
                                                                text-decoration: none;

                                                                &:hover {
                                                                    color: $brand-secondary;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .nav-group {

                                                    .cat-nav-items-title, .cat-nav-items-title-brand {
                                                        text-transform: uppercase;
                                                        color: $brand-primary;
                                                        font-size: $sub-nav-link-font-size;
                                                        font-family: $PlusJakartaSans;
                                                        font-weight: 600;
                                                        margin: 0;
                                                        text-decoration: none;
                                                        letter-spacing: 0.65px;
                                                        line-height: 20px;
                                                        
                                                        @include media-breakpoint-down(lg) {
                                                            font-size: $h2-font-size;
                                                        }
                                                    }

                                                    .cat-nav-items-list {

                                                        @include media-breakpoint-down(lg) {
                                                            display: none;
                                                        }

                                                        li {
                                                            padding: 2px 0 0;
                                                            border: none;

                                                            &:hover {
                                                                background-color: transparent;
                                                            }

                                                            a {
                                                                color: $brand-primary;
                                                                font-size: $sub-nav-link-font-size;
                                                                font-family: $PlusJakartaSans;
                                                                font-weight: 400;
                                                                padding: 6px 0;
                                                                text-decoration: none;
                                                                line-height: 26px;

                                                                &:hover {
                                                                    color: $brand-secondary;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                &.kimball-brands {
                                                    flex-wrap: wrap;

                                                    .col {
                                                        // width: calc(25% - 0px);
                                                        margin-bottom: 35px;
                                                        // flex: inherit;

                                                        &:not(:last-child) {
                                                            margin-right: 0;
                                                        }
                                                        @include media-breakpoint-only(xl) {
                                                            // width: calc(25% - 0px);
                                                        }
                                                        @include media-breakpoint-down(xl) {
                                                            // width: calc(25% - 0px);
                                                        }
                                                    }
                                                    a {
                                                        cursor: pointer;
                                                        &:hover {
                                                            span {
                                                                color: $brand-secondary;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.show {
                                    .dropdown-menu {
                                        &.custom-drop {
                                            @include media-breakpoint-down(lg) {
                                                position: absolute;
                                                flex-direction: row;
                                                flex-wrap: wrap;
                                                opacity: 1;
                                                transition: 0.4s;
                                                right: 0;
                                                z-index: 1;
                                            }
                                            .container {
                                                @include media-breakpoint-down(lg) {
                                                    padding: 0;
                                                }
                                            }
                                            .row {
                                                @include media-breakpoint-down(lg) {
                                                    margin: 0;
                                                    // display: block  ;
                                                }
                                            }
                                        }
                                        a {
                                            &:hover {
                                                color: $brand-secondary;
                                                span {
                                                @include media-breakpoint-down(lg) {
                                                    color: $brand-secondary;
                                                 }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
                .mobile-bottom-footer {
                    position: absolute;
                    bottom: 30px;
                    @include media-breakpoint-down(sm) {
                        bottom: 50px;
                        padding-bottom: 20px;
                        background-color: $white;
                        width: 100%;
                        z-index: 9999;
                    }
                }
            }

            &.in {
                .navbar {
                    .menu-group {
                        .nav {
                            .nav-item {
                                &.dropdown {
                                    &.show {
                                        @include media-breakpoint-down(lg) {
                                            position: relative;
                                        }
                                    }

                                    .custom-drop {
                                        .nav-menu {
                                            .close-menu {
                                                margin: 0;

                                                .back {
                                                    button {
                                                        padding-bottom: 5px;
                                                    }
                                                }

                                                .close-button {
                                                    @include media-breakpoint-down(lg) {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }

                                        .top-category, &:active {
                                            &:focus {
                                                background-color: transparent;
                                                outline: none;
                                                border: none;
                                            } 
                                            @include media-breakpoint-down(lg) {
                                                width: auto;
                                                // margin-left: 11px;     
                                                font-weight: 300;
                                                font-size: $default-text-font-size;  
                                            }
                                            
                                            .nav-link {
                                                &::after {
                                                    display: none;
                                                }          
                                            }
                                             
                                        }

                                        .cat-nav {
                                            @include media-breakpoint-down(lg) {
                                                // display: block;
                                                // position: absolute;
                                                // width: 100%;
                                                // top: 60px;
                                                // left: 0;
                                                // height: calc(100vh - 210px);
                                                // overflow: scroll;
                                                position: static;
                                                height: auto;
                                                display: block;
                                            } 
                                            &.cat-nav-parent-li {
                                                @include media-breakpoint-down(lg) { 
                                                    // border-top: 1px solid $brand-primary;
                                                    padding: 0;
                                                }
                                          }
                                            @include media-breakpoint-down(lg) {
                                                ul {
                                                    display: block;
                                                    li {
                                                        border-top: 1px solid $brand-primary;
                                                        margin: 0;
                                                        a {
                                                            // padding: 18px 15px 17px 0;
                                                            padding: 15px 0;
                                                            color: $brand-primary;
                                                            position: relative;
                                                            // &::after {
                                                            //     margin: 0;
                                                            //     right: 20px;
                                                            //     position: absolute;
                                                            //     content: "";
                                                            //     top: 45%;
                                                            //     background-repeat: no-repeat;
                                                            //     width: 7px;
                                                            //     height: 7px;
                                                            //     border: 1px solid $brand-primary;
                                                            //     border-width: 1px 0px 0px 1px;
                                                            //     background-size: cover;
                                                            //     transform: rotate(136deg);
                                                            //     @include media-breakpoint-up(lg) {
                                                            //         display: none;
                                                            //     }
                                                            // }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .product-cat-nav-items.cat-nav-items {
                                            @include media-breakpoint-down(lg) {
                                                // position: absolute;
                                                // top: 0px;
                                                // height: calc(100vh - 150px);
                                                // right: 0;
                                                // padding: 0;
                                                border-bottom: 0;
                                                position: static;
                                                height: auto;
                                                display: block !important;
                                                .custom-back-button {
                                                    display: none;
                                                }
                                            }
                                            .nav-back-button {
                                                // padding: 18px 15px 17px 0;
                                                padding: 14px 15px 13px 0;
                                                button {
                                                    span {
                                                        padding-left: 11px;
                                                        font-size: 22px;
                                                        color: $brand-primary;
                                                        font-weight: 300;
                                                    }
                                                }
                                            }
                                        }
                                        .cat-nav-child-li {
                                            @include media-breakpoint-down(lg) {
                                                display: none;
                                                .brand-category {
                                                    li {
                                                        padding-left: 0;
                                                        border-color: $brand-primary;
                                                        &:hover, &:focus, &:active {
                                                            background-color: transparent;
                                                        }
                                                        a {
                                                            font-size: 20px;
                                                            line-height: 30px;
                                                            padding: 13.5px 0;
                                                            font-weight: 300;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .cat-nav-items {
                                            overflow-y: auto;
                                            // max-height: calc(100vh - 250px);
                                            max-height: 100vh;
                                            width: 100%;
                                            background-color: $white;
                                             border-bottom: 1px solid $brand-primary;
                                             @include media-breakpoint-down(lg) {
                                                padding: 0;
                                            }

                                            .col,
                                            .nav-group {
                                                @include media-breakpoint-down(lg) {
                                                    border-top: 1px solid $brand-primary;
                                                    &:last-child() {
                                                        border-bottom: 1px solid $brand-primary;
                                                    }
                                                }
                                                &.custom-active {
                                                    border: none;
                                                    .top-category {
                                                        @include media-breakpoint-down(sm) {
                                                            max-width: 85%;
                                                            white-space: normal;
                                                        }
                                                    }
                                                    .cat-nav-items-list {
                                                        @include media-breakpoint-down(lg) {
                                                            border: none;
                                                        }
                                                    }
                                                }

                                                .cat-nav-items-title, .cat-nav-items-title-brand {
                                                    margin: 0;
                                                    padding: 13.5px 0;
                                                    // border-bottom: 1px solid $brand-primary;
                                                    font-size: $default-text-font-size;
                                                    text-transform: capitalize;
                                                    font-family: $PlusJakartaSans;
                                                    font-weight: 300;
                                                    position: relative;
                                                     &:focus {
                                                        outline: 0;
                                                     }
                                                    // &:last-child {
                                                    //     border-bottom: 1px solid $brand-primary;
                                                    // }

                                                    @include media-breakpoint-down(lg) {
                                                        font-size: $h2-font-size;
                                                        padding-right: 30px;
                                                    }

                                                    &::after {
                                                        margin: 0;
                                                        right: 20px;
                                                        position: absolute;
                                                        content: "";
                                                        top: 45%;
                                                        // background-image: url('../images/mob-nav-arrow.svg');
                                                        background-repeat: no-repeat;
                                                        // transform: translateY(-50%);
                                                        width: 7px;
                                                        height: 7px;
                                                        border: 1px solid $brand-primary;
                                                        border-width: 1px 0px 0px 1px;
                                                        background-size: cover;
                                                        transform: rotate(136deg);

                                                        @include media-breakpoint-up(lg) {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                                            .col {
                                                @include media-breakpoint-down(lg) { 
                                                       &:last-child() {
                                                          border-bottom: none;
                                                      }
                                                          }
                                                }
                                            .custom-active {
                                                display: flex;
                                                align-items: center;
                                                flex-wrap: wrap;

                                                .top-category {
                                                    .cat-nav-items-title, .cat-nav-items-title-brand {
                                                        border: none;

                                                        &::after {
                                                            display: none;
                                                        }
                                                    }
                                                }

                                                .cat-nav-items-list {
                                                    width: 100%;
                                                    border-top: 1px solid $brand-primary;

                                                    .dropdown-item {
                                                        .text-decoration-none {
                                                            font-size: 22px;
                                                            display: block;
                                                            font-family: $PlusJakartaSans;
                                                            font-weight: 300;
                                                            // border-bottom: 1px solid $brand-primary;
                                                            padding: 17px 0;
                                                            white-space: normal;
                                                            @include media-breakpoint-down(lg) {
                                                                border-top: 1px solid $brand-primary;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &.kimball-brands {
                                                .cat-nav-items-title, .cat-nav-items-title-brand {
                                                    border: none;
                                                    &:last-child {
                                                        @include media-breakpoint-up(lg) {
                                                            border-bottom: none;
                                                        }
                                                    }

                                                    &::after {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                        &.mobile-custom-drop {
                                            .cat-nav {
                                                // @include media-breakpoint-down(lg) {
                                                //     ul {
                                                //         display: block;
                                                //         li {
                                                //             border-bottom: 1px solid $brand-primary;
                                                //             margin: 0;
                                                //             a {
                                                //                 padding: 18px 15px 17px 0;
                                                //                 color: $brand-primary;
                                                //                 position: relative;
                                                //                 &::after {
                                                //                     margin: 0;
                                                //                     right: 20px;
                                                //                     position: absolute;
                                                //                     content: "";
                                                //                     top: 45%;
                                                //                     background-repeat: no-repeat;
                                                //                     width: 7px;
                                                //                     height: 7px;
                                                //                     border: 1px solid $brand-primary;
                                                //                     border-width: 1px 0px 0px 1px;
                                                //                     background-size: cover;
                                                //                     transform: rotate(136deg);
                                                //                     @include media-breakpoint-up(lg) {
                                                //                         display: none;
                                                //                     }
                                                //                 }
                                                //             }
                                                //             .li-back-button {
                                                //                 display: none;
                                                //             }
                                                //             &.active {
                                                //                 .li-back-button {
                                                //                     display: block;
                                                //                     position: absolute;
                                                //                     top: 0;
                                                //                     background-color: #fff;
                                                //                     width: 100%;
                                                //                     &:after {
                                                //                         display: none;
                                                //                     }
                                                //                 }
                                                //                 .verical-category-ddp {
                                                //                     display: none;
                                                //                 }
                                                //             }
                                                //         }
                                                //     }
                                                // }
                                            }
                                            // .product-cat-nav-items {
                                            //     @include media-breakpoint-down(lg) {
                                            //         position: absolute;
                                            //         top: 0px;
                                            //         height: 100vh;
                                            //     }
                                            //     .nav-back-button {
                                            //         padding: 18px 15px 17px 0;
                                            //     }
                                            // }
                                            // .workplace-sub-collection {
                                            //     @include media-breakpoint-down(lg) {
                                            //         display: none;
                                            //     }
                                            // }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cat-nav-items {
                .cat-nav-items-title, .cat-nav-items-title-brand {
                   cursor: default;
                    &.product-menu {
                        cursor: pointer;
                        &:hover {
                            color: $brand-secondary !important;
                        }
                    }
                   }
        }
    }
}

// Product Nav CSS
.product-cat-nav-items {
    @include media-breakpoint-up(lg) {
        column-count: 4;
        grid-column: 3/-1;
        column-gap: 20px;
    }

    @include media-breakpoint-up(xl) {
        column-count: 5;
        grid-column: 3/-1;
        column-gap: 20px;
    }

    .nav-group {
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
            display: inline-block;
            width: 100%;
        }
    }
}


// Global CSS
.slick-list {
    padding-bottom: 10px;
}

.slick-dots {
    display: flex;
    bottom: 0;

    li {
        margin: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $ligh-brown;

        &.slick-active {
            background-color: $brand-secondary;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}
// brand name
// .brands-nav-Images{
//     .brand-name{
//         margin-top: 10px;
//     }
// }
// view all insight
// .view-insight{
//     margin-top: 10px;
// }