.product-listing {
    position: relative;
    // @include media-breakpoint-down(md) {
    //     padding: 80px 0;
    // }
    margin-top: 80px;
    @include media-breakpoint-up(sm) {
        margin-top: 85px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 35px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 40px;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: 50px;
    }
    @media screen and (max-width: 360px) {
        width: 100%;
    }
    .product-grid {
        .filter-bar {
            .filters {
                .button-filter {
                    margin-bottom: 10px;
                }
            }
        }
        .product-grid-header {
            @include media-breakpoint-down(sm) {
                gap: 20px 0;
                padding-bottom: 0px;
            }

            .pl-heading {
                // order: 2;
                // margin-bottom: 190px;
                // margin-bottom: 80px;
                @include media-breakpoint-down(xxl) {
                    // margin-bottom: 84px;
                }
               @include media-breakpoint-down(lg) {
                    // margin-bottom: 166px;
                    // margin-bottom: 49px;
                }
                @include media-breakpoint-down(md) {
                    // margin-bottom: 28px;
                    margin-top: -8px;
                }

                // @include media-breakpoint-down(sm) {
                //     margin-bottom: 0;
                // }
                &.idea-starter-cat{
                    margin-bottom: 0px;
                }
            }
            h1 {
                margin-bottom: 20px;
                @include media-breakpoint-down(sm) {
                    word-break: break-all;
                }
            }
            
        }

        .col-6 {
            padding: 0 10px;
            gap: 10px 0;
        }
        .small-plp-image-tile {
            // @include media-breakpoint-up(xxl) {
            //     width: 20%;
            // }
            @include media-breakpoint-up(lg) {
                width: 16.66667%;
            }
            // &.parent-list-view {
            //     width: 25%;
            //     @include media-breakpoint-up(lg) {
            //         width: 16.66667%;
            //     }
            //     .image-container {
            //         img {
            //             object-fit: cover;   
            //             @include media-breakpoint-up(sm) {
            //                 height: 190px;
            //             }
            //             @include media-breakpoint-up(md) {
            //                 height: 188px;
            //             }
            //             @include media-breakpoint-up(lg) {
            //                 height: 228px;
            //             }
            //             @include media-breakpoint-up(xl) {
            //                 height: 282px;
            //             }
            //             @include media-breakpoint-up(xxl) {
            //                 height: 320px;
            //             }
            //         }
            //     }
            // }
            .image-container {
                min-height: 200px;
                @include media-breakpoint-up(sm) {
                    min-height: 190px;
                }
                @include media-breakpoint-up(md) {
                    min-height: 188px;
                }
                @include media-breakpoint-up(lg) {
                    min-height: 228px;
                }
                @include media-breakpoint-up(xl) {
                    min-height: 282px;
                }
                @include media-breakpoint-up(xxl) {
                    min-height: 320px;
                }
                img {
                    object-fit: cover;   
                    @include media-breakpoint-up(sm) {
                        height: 190px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 188px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 228px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 282px;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 320px;
                    }
                }
            }
        }
        .large-plp-image-tile {
            // @include media-breakpoint-up(xxl) {
            //     width: 40%;
            // }
            @include media-breakpoint-up(lg) {
                width: 33.33333%;
            }
            // &.parent-list-view {
            //     width: 50%;
            //     @include media-breakpoint-up(lg) {
            //         width: 33.33333%;
            //     }
            //     .image-container {
            //         img {
            //             object-fit: cover;    
            //             @include media-breakpoint-up(sm) {
            //                 height: 190px;
            //             }
            //             @include media-breakpoint-up(md) {
            //                 height: 188px;
            //             }
            //             @include media-breakpoint-up(lg) {
            //                 height: 228px;
            //             }
            //             @include media-breakpoint-up(xl) {
            //                 height: 282px;
            //             }
            //             @include media-breakpoint-up(xxl) {
            //                 height: 320px;
            //             }
            //         }
            //     }
            // }
            .image-container {
                min-height: 200px;
                @include media-breakpoint-up(sm) {
                    min-height: 190px;
                }
                @include media-breakpoint-up(md) {
                    min-height: 188px;
                }
                @include media-breakpoint-up(lg) {
                    min-height: 228px;
                }
                @include media-breakpoint-up(xl) {
                    min-height: 282px;
                }
                @include media-breakpoint-up(xxl) {
                    min-height: 320px;
                }
                img {
                    object-fit: cover;    
                    @include media-breakpoint-up(sm) {
                        height: 190px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 188px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 228px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 282px;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 320px;
                    }
                }
            }
        }
        .sticky-filter{
            &.applied-filter-sticky{  
                .applied-filters{
                    .applied-filter-list{
                        margin-bottom: 5px;
                    }
                    .iconBar{
                        margin-bottom: 10px;
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .sticky-prod-coparison-bar {
        background-color: $sugarcane;
        padding: 17px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 17px 12px;
        }

        .left-area-bar {
            p {
                font-size: 13px;
                font-weight: 600;
                text-transform: uppercase;
                color: $brand-primary;
            }

            .added-products {
                list-style: none;
                display: flex;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                    gap: 10px;
                }

                li {
                    span {
                        margin-left: 7px;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 13px;
                    }

                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                }
            }
        }

        .right-area-bar {
            .button {
                border: 1px solid $brand-primary;
                border-radius: 50px;

                &:hover {
                    background-color: $brand-primary;
                    color: $white;
                }
            }
        }
    }
    .idea-started-product-list, .download-product-list {
        margin-top: 20px;
        align-items: end;
        // position: relative;
        // z-index: -1;
    
        @include media-breakpoint-down(xl) { 
            align-items: start;
        }
        @include media-breakpoint-down(lg) { 
            align-items: end;
        }
        li {
            padding-right: 0;
        }
    }
    .download-product-list {
        li {
            padding-left: 15px;
        }
    }  
}

.plp-no-result-found {
    @include top-bottom-padding;
    text-align: center;
    @include header(1);
}
.iconBar {
    display: inline-flex;
    // width:125px;
    width: 30%;
    justify-content: flex-end;
    margin-left: auto;
    margin-bottom: 30px;
    align-self: flex-start;
    
    @include media-breakpoint-only(sm) {
        width: 26%;
    }
    @media screen and (max-width: 374px) {
        margin-left: 0;
        width: auto;
    }
    @include media-breakpoint-down(sm) {
        align-items: center;
    }
    @include media-breakpoint-up(md) {
        width: 20%;
    }
    @include media-breakpoint-up(xl) {
        width: 15%;
    }
    button {
        background: none;
        box-shadow: none;
        border: none;
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
        &.active, &:hover{
            img{
                filter: invert(46%) sepia(24%) saturate(1009%) hue-rotate(350deg) brightness(88%) contrast(87%);
            } 
        }
    }
    [data-title]:hover:after {
        opacity: 1;
        // transition: all 0.1s ease 0.5s;
    }
    [data-title]:after {
        content: attr(data-title);
        color: #956839;
        font-size: 9px;
        position: absolute;
        top: 30px;
        left: -50%;
        white-space: nowrap;
        opacity: 0;
    }
    [data-title] {
        position: relative;
    }
    .sort-by {
        font-size: 13px;
        line-height: 28px;
        letter-spacing: 0.65px;
        margin-right: 21px;
        text-transform: uppercase;
        font-weight: 600;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
            line-height: 19px;
            margin-right: 8px;
        }
    }
} 
.downloadsList{
 .table{
    .gridCintainer {
        // display: grid;

        display: flex;
        justify-content: space-between;
        // @include media-breakpoint-up(lg) {
        //     grid-template-columns: 100%(7.8% 38.2% 24.3% 16%) - 150px;
        // }
        // @include media-breakpoint-up(xl) {
        //     grid-template-columns: 7.8% 38.2% 24.3% 16% 150px;
        // }
        // @include media-breakpoint-up(xxl) {
        //     grid-template-columns: 7% 39% 26% 18% 150px;
        // }
        
        // @include media-breakpoint-down(lg) {
        //     grid-template-columns: 7.8% 36.2% 20.3% 14% 22%;
        // }
        // @include media-breakpoint-down(md) {
        //     grid-template-columns: 8.8% 24.2% 21.3% 18% 30%;
        // }
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-wrap: wrap;
        }
        .gridCintainer-inner-block {
            display: grid;
            grid-template-columns: 7% 43% 30% 20%;
            width: calc(100% - 145px);
            padding: 0;
            background-color: transparent;
            @include media-breakpoint-only(sm) {
                grid-template-columns: 11% 35% 29% 25%;
            }
            @include media-breakpoint-down(sm) {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
            }
        }
        .download-file {
            width: 145px;
            display: flex;
        }
        .productImageContent {
            background-color: transparent;
            padding: 0;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            img {
                width: 100%;
                max-width: 45px;
                @include media-breakpoint-down(sm) {
                    max-width: 60px;
                }
            }
            .productImageLink {
                padding: 10px 0px 10px 20px;
                display: block;
                @include media-breakpoint-down(xl) {
                    padding: 21px 0px 20px 10px;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0 0 10px 10px;
                }
            }
        }
        .gridList{
            font-size: 15px;
            font-family: $font-family-PlusJakartaSans !important;
            color: $brand-primary;
            border: none;
            padding: 0;
            @include media-breakpoint-down(md) {
                font-size: 13px;
               // color: red;
            }
            .link-icon-btn{
               margin-top: 0;
            } 
            &.item1 {
                @include media-breakpoint-down(sm) {
                   width: 100%;
               }
            }
            &.item4 {
                @include media-breakpoint-up(sm) {
                     padding: 21px 10px;
                }
                .downloadFile {
                    @include media-breakpoint-down(sm) {
                       display: flex;
                   }
                }
                .add-to-fav {
                    img {
                        max-width: 17px;
                    }
                }
            }
            .fileName {
                @include media-breakpoint-up(sm) {
                    padding: 21px 10px;
                }
                @include media-breakpoint-down(md) {  
                    white-space: nowrap;
                    width:100%;
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                    white-space: normal;
                }
                &:hover {
                    color: $brand-primary;
                }
            }
        }
    }
    .headingContainer{
        //border-bottom: 1px solid $brand-primary;
        .gridList{
            font-weight: $font-weight-semibold;
            background: none;
            padding: 21px 10px;
            @include media-breakpoint-down(sm) {
              display: none;
               &:nth-child(2){
               display: block;
               width: 100%;
               }
            }
            
            button{
                background: none;
                box-shadow: none;
                border: none;
                margin-left: 5px;
                display: inline-block;
                padding: 0;
            }
        }
       
        .item3 {
            // grid-area: 1/4/2/4;
        }
    } 
    .detailContainer {
        .gridList {
            a {
                @include media-breakpoint-down(md) {
                    font-size: 13px;
                }
            }
        }
        &:hover {
            background-color: $cultured;
            a {
                color: $brand-secondary;
            }
            .gridList {
                &:hover {
                    a {
                        color: $brand-secondary;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm) {
            padding-block: 21px;
        }
        .gridList {
            // padding-inline:0 10px;
            line-height: 26px;
            font-weight: $default-font-weight;
            background-color: transparent;
            @include media-breakpoint-down(sm) {
                width: auto;
                padding: 0 10px;
            }

            &:first-child{
                padding-left:10px;
                @include media-breakpoint-down(md) {
                    // white-space: nowrap;
                    // width:17ch;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    width: 100%;
                }
                @include media-breakpoint-down(sm) {
                    white-space: inherit; 
                    overflow:inherit;
                    width: 100%;
                    margin-bottom:5px;
                }
            }
            &:last-child{
                // text-align: right;
                // display: flex;
                // gap: 0 20px;
                // justify-content: end;
                // flex-wrap: wrap;
                // padding-right: 20px;
                text-align: left;
                @include media-breakpoint-down(sm) {
                    text-align: left;
                    justify-content: start;
                    // width: 100%;
                    width: auto;
                    margin-top:0;
                }
                .add-to-fav {
                    margin-left: 10px;
                }
            }
            a{
                display: block;
                padding: 0;
                // font-size: 14px;
                font-size: 15px;
                font-weight: $default-font-weight;
                width: auto;
                @include media-breakpoint-down(lg) {
                    font-size: 13px;
                }
                &::before{
                   display: none; 
                }
                .download-icon {
                    margin-left: 10px;
                }
                &.downloadFile {
                    position: relative;
                    padding-right: 20px;
                    &:after {
                        content: '';
                        background-image: url(../images/download-icon.svg);
                        position: absolute;
                        right: 0;
                        top: 7px;
                        width: 11px;
                        height: 14px;
                        background-repeat: no-repeat;
                        background-position: 50%;
                    }
                }
            } 
           
        }
        .gridList.item2{
            @include media-breakpoint-down(sm) {
                position: relative;
                &::after{
                    content: "/";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                   
                }
            } 
        }
        &:nth-child(odd) {
            background-color: transparent;
            &:hover {
                background-color: $cultured;
            }
         }
    }
   
    
 }
}

.count-filter-seperation {
    margin: 0 5px;
    // white-space: pre-line;
    word-break: break-word;
    /* overflow-wrap: break-word; */
    @include media-breakpoint-down(sm) {
        white-space: pre-line;
    }
}

.product-PLP {
    .product {
        .product-tile {
            .tile-body {
                // padding: 11px 0;
                padding: 0;
                @include media-breakpoint-only(xl) {
                    padding-top: 19px;
                    padding-bottom: 10px;
                }
                @include media-breakpoint-between(md,lg) {
                    padding: 13px 0 10px;
                }
                .pdp-link {
                    .link {
                        font-size: 16px;
                        // margin-bottom: 11px;
                        margin-bottom: 0;
                        @include media-breakpoint-between(md,lg) {
                            margin-bottom: 10px;
                        }
                        sup {
                            font-size: .45em;
                            top: -8px;
                        }
                        span {
                            // font-weight: 600;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            letter-spacing: .65px;
                            text-transform: uppercase;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .tile-footer {
                margin-bottom: 0;
                display: none;
                .pdp-link-btn {
                    width: 32px;
                    height: 32px;
                    img {
                        width: 17px;
                    }
                }
            }
        }
    }
}
.product-listing {
    .product-grid {
        .product-PLP {
            .large-plp-image-tile, .small-plp-image-tile {
                .product {
                    // padding-bottom: 40px;
                    padding-bottom: 20px;
                    // @include media-breakpoint-up(xl) {
                    //     padding-bottom: 78px;
                    // }
                    // @include media-breakpoint-up(xxl) {
                    //     padding-bottom: 41px;
                    // }
                    .article-block.image-with-text {
                        padding-top: 0;
                        padding-bottom: 0;
                        &.contentTile-block {
                            a {
                                &:hover {
                                    * {
                                        color: $brand-primary;
                                    }
                                }
                                &.contentTile-block-link {
                                    position: absolute;
                                    height: 100%;
                                    width: 100%;
                                    left: 0;
                                    top: 0;
                                    z-index: 1;
                                    font-size: 0;
                                }
                            }
                            .image-description {
                                a {
                                    position: relative;
                                    z-index: 2;
                                }
                            }
                        }
                    }
                }
            }
            .applied-filters {
                .applied-filter-list {
                    margin-bottom: 71px;
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 49px;
                    }
                }
            }
            .pl-heading {
                .h1 {
                    @include media-breakpoint-between(md,lg) {
                        font-size: 60px;
                        line-height: 82px;
                        letter-spacing: 0.6px;
                    }
                }
            }

            
        }
    }
    .plp-product-list{
        .product-tile{
            .image-container{
                // .visualiztionIcon {
                //     position: absolute;
                //     left:10px;
                //     top:10px;
                //     z-index: 1;
                //     background: #fff;
                //     width: 30px;
                //     height: 30px;
                //     overflow: hidden;
                //     border-radius: 50px;
                //     transition: 0.5s ease-in-out;
                //     display: flex;
                //     align-items: center;

                //     img {
                //         max-width: 100%;
                //         padding: 6px;
                //         height: auto !important;
                //         width: auto;
                //         object-fit: inherit !important;
                //     }
                //     span{
                //         font-size:13px;
                //         text-transform: uppercase;
                //         position: relative;
                //         font-family: $font-family-PlusJakartaSans !important;
                //         color: $brand-primary;
                //         font-weight: $font-weight-semibold;
                      
                       

                //     }
                //     &:hover{
                //         width: 112px;
                      
                       
                //     }
                   
                // }
            }
        }
        .plp-list-view {
            // .prod-cat {
            //     display: none;
            // }
        }
        .plp-list-view,
        .plp-grid-view {
            .pdp-link {
                span {
                    // font-weight: 600;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: .65px;
                    text-transform: uppercase;
                }
            }
            .tile-footer {
                display: none;
            }
        }
        // .plp-grid-view {
        //     .pdp-link {
        //         span {
        //             display: none;
        //         }
        //     }
        // }
    }
    .product-grid {
        .product-PLP {
            .product {
                &.plp-list-view {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

.product-plp-wishListButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    // background: #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
}
