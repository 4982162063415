

html {
    border: transparent !important;
}
p {
    margin-bottom: 20px;
}
#ot-sdk-btn-floating.ot-floating-button {
    display: none;
}

.back-and-like-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    a {
        &:first-child {
            margin-right: 30px;
        }
    }
}

.img-info-overlay-parent {
    position: relative;

    .img-info-overlay {
        position: absolute;
        overflow: hidden;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        // opacity: 0;
        transition: all 500ms;

        @include media-breakpoint-up(md) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            width: 294px;
        }

        @include media-breakpoint-up(xl) {
            width: 360px;
        }

        @include media-breakpoint-up(xxl) {
            width: 370px;
        }

        .info-overlay-container {
            img {
                // display: inline-block;
                width: 11px;
                height: 14px;
                display: none;
            }

            .info-overlay-text-block {
                position: absolute;
                left: 0;
                bottom: 0;
                transform: translateY(100%);
                transition: all 500ms;
                opacity: 0;
                width: 100%;
                z-index: 9;

                @include media-breakpoint-down(md) {
                    height: 100%;
                }

                &.active {
                    opacity: 1;
                    transform: translateX(0);
                    transition: all 500ms;
                    // z-index: 2;
                    // z-index: 9;
                    z-index: 8;
                }

                * {
                    color: $white;
                    font-size: 13px;
                    line-height: 17px;
                }

                .global-download-icon {
                    display: inline-block;
                }

                .social-icons {
                    border-top: 0;
                    padding: 15px 20px 15px 60px;
                    background-color: $brand-primary;

                    a {
                        color: $white;
                        margin-right: 15px;
                        font-size: 13px;
                        line-height: 20px;
                        cursor: pointer;
                        background: rgba(0, 0, 0, .25) url("../images/hero-banner/download-arrow.svg");
                        background-repeat: no-repeat;
                        background-size: 17px 13px;
                        background-position: 0px center;
                        padding-left: 10px;

                        @include media-breakpoint-only(md) {
                            margin-right: 0;
                        }

                        span {
                            margin-left: 10px;
                            color: $white;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                    }
                }

                p {
                    &:empty {
                        height: 10px;
                    }
                }

                .info-overlay-content {
                    padding: 20px;
                    background-color: rgba(0, 0, 0, .7);

                    @include media-breakpoint-down(md) {
                        // padding: 20px 20px 0;
                        height: calc(100% - 50px);
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        background-color: transparent;
                    }

                    h6,
                    .infooverlay-title {
                        font-weight: $font-weight-semibold;
                        letter-spacing: 0.65px;
                        text-transform: uppercase;
                        line-height: 23px;
                        font-size: 13px;
                        color: $white;
                        margin-bottom: 10px;
                        display: block;

                        @include media-breakpoint-down(md) {
                            background: rgba(0, 0, 0, 0.7);
                            margin: 0;
                            margin-top: auto;
                            padding: 20px 20px 0;
                        }
                    }

                    .info-overlay-content-block {
                        overflow-y: auto;

                        // max-height: calc(100% - 50px );
                        @include media-breakpoint-down(md) {
                            background-color: rgba(0, 0, 0, .7);
                            padding: 10px 20px 20px;
                        }

                        @include media-breakpoint-up(md) {
                            max-height: 119px;
                        }

                        @include media-breakpoint-up(lg) {
                            max-height: 219px;
                        }

                        @include media-breakpoint-up(xl) {
                            max-height: 288px;
                        }

                        /* width */
                        &::-webkit-scrollbar {
                            width: 2px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: $Gray44;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: $white;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: $white;
                        }
                    }
                }
            }

            .info-overlay-icon {
                position: absolute;
                left: 10px;
                bottom: 10px;
                width: 30px;
                height: 30px;
                background: rgba(0, 0, 0, .25) url("../images/hero-banner/info-icon.svg");
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                border-radius: 50%;
                line-height: 10px;
                border: 0;
                z-index: 9;
                color: $white;
                opacity: 0;
                transition: .5s all;

                &:focus {
                    opacity: 1;
                }

                @include media-breakpoint-down(md) {
                    opacity: 1;
                }

                .cross {
                    display: none;
                    width: 11px;
                    height: 11px;
                }

                &:hover {
                    background-color: $brand-primary;
                }

                &.active {
                    background: $brand-primary url("../images/hero-banner/info-cross.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 11px;
                    // z-index: 91;
                    z-index: 9;
                    opacity: 1;
                    border: 1px solid $white;
                    // z-index: 999;

                    img {
                        display: none;
                        margin: auto;
                    }

                    .cross {
                        // display: block;
                        display: none;
                    }
                }
            }
        }
    }

    &:hover {
        .img-info-overlay {
            transition: all 500ms;
        }
    }

    &:hover {
        .img-info-overlay {
            .info-overlay-container {
                .info-overlay-icon {
                    opacity: 1;
                }
            }
        }
    }
}

a {

    &:not(.btn-check)+.btn:hover,
    .btn:first-child:hover {
        background: inherit;
        color: inherit;
        border-color: inherit;
    }
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    background: inherit;
    color: inherit;
    border-color: inherit;
}

// Skip Nav 
.skip {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: -4.2em;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all .2s ease-in-out;

    &:focus {
        top: 0;
        z-index: 10000000;
        transition: all .2s ease-in-out;
        
    }
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    transition: right 0.4s;
}

.fixed-position {
    overflow: hidden;
    .modal-background {
        z-index: 10;
        right: 0;
        left: 0;
        transition: right 0.4s;
    }
}

.mxt-product-config-container {
    .mxt-spins-fullscreen{
        z-index: 111 !important;
    }
}
.custom-visualizer-container{
    #kmb-config-overrides{
        .kmb-wrapper {
            .labeled-image-option{
                .product-info{
                    z-index: 1 !important;
                }
            } 
        }
    } 
}

.cloudnary-video {
    max-height: 100%;
    max-width: 100%;
}
video::-webkit-media-controls-overlay-play-button {
    @include media-breakpoint-down(lg) {
        opacity: 0;
    }
}
// video::-webkit-media-controls-start-playback-button {
//     display: none!important;
//     -webkit-appearance: none;
// }

.video-wrapper {
    position: relative;
    .video-title {
        position: absolute;
        left: 0;
        bottom: 0;
        color: #fff;
        width: 100%;
        text-align: center;
        background-color: rgba(0,0,0,.7);
        padding: 5px 0;
    }
}

/*-- CLOUDINARY VIDEO --*/
.video-js {
    .vjs-tech {
      position: static !important;
    }
}

.pageProtected {
    .modal-content{ 
        padding: 15px 20px 5px;
        box-shadow: 7px 8px 4px -10px;
    }
    .modal-body {
        input {
            width: 100%;
            height: 38px;
            &:focus {
                outline: none;
            }
            &:invalid {
                border: 1px solid $aethon-red;
            }
        }
        .passwordLabel {
            margin-bottom: 8px;
        }
        .errorMsg {
            margin-top: 5px;
            font-size: 12px;
            color: $aethon-red;
        }
    }
    .modal-footer {
        .button {
            border: 1px solid $brand-primary;
            background: $white;
            &:hover {
                background-color: black;
            }
        }
    }
}

.article-block {
    &.contentTile-block {
        padding: 0 0 20px;
        .image-description {
            a {
                position: relative;
                z-index: 2;
            }
        }
        .contentTile-block-link {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          z-index: 1;
          font-size: 0;
        }
      }
}

.surcharge-banner {
    padding-block: 10px;
    p {
        color: $main-red;
        font-size: 18px;
        font-weight: 500;
    }
}
.product-information {
    .surcharge-banner {
        margin-top: 5px;
    }
}
