.material-item {
    display: flex;
    flex-direction: column;
    .image-container {
        background-color: $smoke-white;
        position: relative;
        min-height: 118px;
        @include media-breakpoint-up(sm) {
            min-height: 190px;
        }
        @include media-breakpoint-up(md) {
            min-height: 167px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 103px;
        }
        @include media-breakpoint-up(xl) {
            min-height: 128px;
        }
        @include media-breakpoint-up(xxl) {
            min-height: 180px;
        }
        img {
            width: 100%;
        }
        span.material-banner {
            background: #000;
            color: #fff;
            font-size: 13px;
            line-height: 15px;
            text-transform: capitalize;
            text-align: center;
            padding: 2px 4px;
            position: absolute;
            left: 0;
            top: 20px;
            box-shadow: 0 0 5px #979696;
        }
    }
    .title-container {
        padding: 10px 0 40px;

        h6 {
            display: flex;
            flex-direction: column;

            span {
                font-size: 14px;
                line-height: 22px;
                font-weight: $font-weight-semibold;
            }
        }
    }
}