@import "~base/components/productTiles";

// Product Tile
.product {
    .product-tile {
        position: relative;
        min-height: auto;

        .compare-chcek {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
            cursor: pointer;

            button {
                margin: 0;
                padding: 0;
                background-color: transparent;
                border: none;
            }
        }

        .image-container {
            position: relative;
            overflow: visible;

            .tile-image {
                width: 100%;
            }

            .hover-tile-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: 0.5s;
            }

            &:hover {
                .hover-tile-image {
                    opacity: 1;
                    transition: 0.5s;
                }
            }
          
           
        }

        .tile-body {
            // margin: 20px 0 0;
            // padding: 20px 0;
            padding: 0;

            @include media-breakpoint-down(sm) {
                // margin: 0;
                padding: 10px 0 0;
            }

            .pdp-link {
                .link {
                    font-family: $PlusJakartaSans;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 25px;
                    // margin-bottom: 10px;
                    margin-bottom: 0;
                    color: $brand-primary;
                    display: block;
                    align-items: center;
                    // word-break: break-all;
                    word-break: break-word;
                    padding: 11px 0 20px;

                    &:hover {
                        color: $brand-primary;
                    }
                }
            }

            .prod-cat {
                p {
                    font-family: $PlusJakartaSans;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.65px;
                    text-transform: uppercase;
                }
            }
        }

        .tile-footer {
            opacity: 0;
            transition: 0.5s;
            // margin-bottom: 30px;

            .pdp-link-btn {
                border: 1px solid $dark-brown;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-only(md) {
                    display: none;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
                img {
                    max-width: 20px;
                }
            }
        }

        &:hover {
            .tile-footer {
                opacity: 1;
                transition: 0.5s;
            }
        }
    }
}

.related-product-tile {
    .image-container {
        img {
            width: 100%;
            // max-width: 370px !important;
            // max-height: 468px !important;
        }
    }
}

.kii-content-slider {
    .product {
        .product-tile {
            .tile-body {
                .pdp-link {
                    .link {
                        display: block;
                    }
                }
            }
        }
    }  
}

//visulazation button
.visualiztionIcon {
    position: absolute;
    left:10px;
    top:10px;
    // z-index: 2;
    z-index: 1;
    // background: #fff;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50px;
    transition: 0.5s ease-in-out;
    display: flex;
    align-items: center;
    background: #fff url(../images/hero-banner/config-icon.svg) no-repeat;
    background-size: 18px;
    background-position: 6px;

    img {
        max-width: 100%;
        padding: 6px;
        height: auto !important;
        width: auto !important;
        object-fit: inherit !important;
        display: none;
    }
    span {
        font-size:13px;
        text-transform: uppercase;
        position: relative;
        font-family: $font-family-PlusJakartaSans !important;
        color: $brand-primary !important;
        font-weight: $font-weight-semibold !important;
        margin: 0 0 0 32px !important;
        opacity: 0;
        transition: .3s ease-in-out;
        top: 1px;
    }
    &:hover{
        width: 122px;
        span {
            opacity: 1;
        }
    }
}