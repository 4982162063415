.products-comparison {
    padding: 50px 0 0;
    .compare-product-added {
        .product-tile {
            .image-container {
                img {
                    width: 100%;
                }
            }
            .product-tile-body {
                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    margin: 20px 0 0;
                }
                span {
                    font-size: 13px;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }

    .commpared-info {
        padding: 50px 0 0;
        .compared-info-group {
            h3 {
                font-weight: 600;
                margin-bottom: 20px;
            }
            table {
                margin-bottom: 0;
                tr {
                    td {
                        padding: 20px;
                    }
                    &:nth-of-type(odd) > * {
                        background-color: $light-blue;
                    }
                }
            }
        }
    }
}
.sticky-back-to-products {
    position: sticky;
    bottom: 0;
    padding: 20px 0;
    background-color: $sugarcane;
    margin: 50px 0;
    span {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
    }
}