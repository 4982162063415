@import "~core/components/header";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}  
header {
    background-color: $default-bg;
    position: relative;
    z-index: 13;

    @include media-breakpoint-up(lg) {
        z-index: 10;
        // margin-top: 90px;
        margin-top: 60px;
    }
    @include media-breakpoint-down(lg) {
        position: fixed;
        width: 100%;
        top: 0;
    }

    &:not(.stickyHeader) {
        .navbar-header {
            .pull-left {
                &>.container {
                    padding: 0;
                }
            }
        }
    }
    .header-spacer {
        @include media-breakpoint-up(lg) {
            position: fixed;
            // top: 89px;
            top: 59px;
            z-index: 1;
            width: 100%;
        }
    }
    .header-navigation {
        @include media-breakpoint-up(lg) {
            // margin-top: 60px;
        }
    }
    .top-header {
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            position: fixed;
            // padding: 0;
            // z-index: 1;
            z-index: 3;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $default-bg;
            // height: 90px;
            height: 60px;
            padding: 20px 0 0;
        }

        &::after {
            @include media-breakpoint-up(lg) {
                position: fixed;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 60px;
                z-index: 1;
                background-color: $default-bg;
                display: none;
            }
        }

        // &:not(.stickyHeader) {
        //     .navbar-header {
        //         .pull-left {
        //             & > .container {
        //                 padding: 0;
        //             }
        //         }
        //     }
        // }
        .navbar-header {
           overflow: visible;
            .vertical-categories {
                @include media-breakpoint-up(lg) {
                    margin: 0 auto;
                    position: relative;
                    z-index: 2;
                   
                }
            }

            .pull-right {
                @include media-breakpoint-up(lg) {
                    position: relative;
                    z-index: 2;
                }
            }
            // .pull-left {
            //     .search {
            //         padding: 12px 0 11px;
            //     }
            // }
            .header-icon-block {
                .search {
                    padding: 12px 0 11px;
                    display: none;
                    // position: fixed;
                    // top: 0;
                    // transform: none;
                    // left: auto;
                    // z-index: 10;
                    // margin-left: 20px;
                    // margin-top: 0;
                    // display: block !important;
                    @include media-breakpoint-up(lg) {
                        display: none ;
                        position: absolute;
                        left: 30px;
                        background-color: $default-bg;
                        padding: 0;
                        width: 320px;
                        // max-width: 300px;
                        right: 0;
                        .search-field {
                                padding: 18px 20px 10px 5px;
                        }
                    }
                    // @include media-breakpoint-up(xl) {
                    //     left: 84%;
                    // }
                    // @include media-breakpoint-up(xxl) {
                    //     left: auto;
                    //     max-width: 300px;
                        
                    // }
                }
                .top-header-nav-left {
                    @include media-breakpoint-up(md) {
                        max-width: 75%;
                    }
                    @include media-breakpoint-up(xl) {
                        max-width: 80%;
                    }
                    @include media-breakpoint-up(xxl) {
                        max-width: 87%;
                    }
                    .searchBar {
                        position: relative;
                        // .search  {
                        //     @include media-breakpoint-up(xxl) {
                        //         display: block ;
                        //     }
                        // }
                    }
                }

            } 
        }

        .top-header-nav-right {
            a {
                font-family: $PlusJakartaSans;
                font-weight: 600;
                letter-spacing: 0.65px;
                line-height: 16px;

                @include media-breakpoint-down(lg) {
                    font-size: 22px;
                }
            }
        }
    }
    .top-header-icons {
        @include media-breakpoint-up(lg) {
            top: 21px;
            top: 18px;
            z-index: 2;
            // position: fixed;
            position: static;
            justify-content: flex-end;
        }
        li {
            &.product-configurator {
                margin-right: 50px;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                @include media-breakpoint-down(lg) {
                    // order: 3;
                    // margin-right: 10px;
                    display: none;
                 }
            }
            &.searchBar {
                @include media-breakpoint-down(lg) {
                   order: 1;
                }
            }
            &.profile-section {
                position: relative;
                @include media-breakpoint-down(lg) {
                    order: 2;
                 }
                 .acount-bar  {
                    @include media-breakpoint-down(lg) {
                       display: none !important;
                     }
                 }
            }
        }
    }

    .vertical-categories {
        // @include media-breakpoint-up(lg) {
        //     padding-left: 144px;
        // }
  
        @include media-breakpoint-down(lg) {
            padding: 15px 0;
            overflow-x: auto;
            // border-bottom: 1px solid $brand-primary;
            flex-wrap: nowrap;
            margin-left: 0;
          
            .scroll-arrow{
                cursor: pointer;
            }
        }

        .scroll-arrow{
            @include media-breakpoint-down(lg) {
                display:none !important;
            }
            @media (max-width:500px){
                display:block !important;
            }
        }
       
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        li {
            display: flex;
            align-items: center;
            white-space: nowrap;

            a {
                font-size: $sub-nav-link-font-size;
                text-transform: uppercase;
                font-family: $PlusJakartaSans;
                font-weight: 600;
                color: $brand-primary;
                letter-spacing: 0.65px;
                padding: 6.5px 16px 7px;
                line-height: 16px;

                @include media-breakpoint-down(lg) {
                    padding: 7px 15px;
                }

                &:hover,
                &:focus {
                    color: $brand-primary;
                }
            }

            &:first-child {
                a {
                    @include media-breakpoint-down(lg) {
                        padding-left: 0;
                    }
                }
            }

            &:last-child {
                a {
                    @include media-breakpoint-down(lg) {
                        padding-right: 40px;
                    }
                }
            }
        } 
       
        .scrollLeft{
            right:21px;
        }

    }
    .scroll-arrow {
        position: fixed;
        top:61px;
        right: 0;
        opacity: 0;
        max-width: 80px;
        width:100%;
       
    
    }
    .slide {
        @include media-breakpoint-down(lg) {
        margin-left: -80px;
        
        }
        @media (max-width:440px){
            margin-left: -120px;
        }
        @media (max-width:399px){
            margin-left: -170px;
        }
       
     
    }
    .navScroll{
       
        @include media-breakpoint-down(lg) {
            margin-left: -130px;
        }
    }
    .search {
        @include media-breakpoint-up(lg) {
            background-color: $default-bg;
            position: absolute;
            // width: calc(100% - 180px);
            width: calc(100% - 142px);
            // z-index: 4;
            z-index: 3;
            top: -16px;
            left:0;
            margin: 0 !important;
        }
        @include media-breakpoint-down(lg) {
            width:calc(100% - 25px)
           
        }
        .site-search {
            .form-control {
                border-radius: 0;
                background-color: transparent;
                &.search-field-1 {
                    position: absolute;
                    top: 10px;
                    left: 5px;
                    color: rgba(0, 0, 0, 0.45);
                    z-index: -1;
                    text-align: left;
                    @include media-breakpoint-down(lg) {
                        top: 0;
                        left: 0;
                    }
                }

                @include media-breakpoint-up(lg) {
                    background-color: transparent;
                    // height: 63px;
                    line-height: 13px;
                    // padding: 10px 16px;
                    padding: 10px 16px 10px 0;
                }
            }
        }
    }

    .acount-bar {
        // @include media-breakpoint-up(lg) {
        //     background-color: $default-bg;
        //     position: absolute;
        //     // width: calc(100% - 220px);
        //     width: calc(100% - 142px);
        //     // z-index: 4;
        //     z-index: -1;
        //     top: 14px;
        //     left: 0;
        //     padding-left: 0;
        // }

        @include media-breakpoint-up(lg) {
            background-color: $default-bg;
            position: absolute;
            // width: calc(100% - 220px);
            z-index: 0;
            top: 10px;
            left: 30px;
            padding-left: 0;
            width: 100px;
        }

        .user {
            @include media-breakpoint-down(md) {
               overflow: auto;
            }
            @include media-breakpoint-up(lg) {
                height: 43px;
                align-items: center;
                a {
                    &:first-child {
                        padding-left: 0 !important;
                    }
                }
            }
            a {
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }

            }
        }
    }

    .main-header {
        @include media-breakpoint-up(lg) {
            min-height: 80px;
        }
        @include media-breakpoint-down(lg) {
           position: relative;
        }
        .brand {
            margin: 20px 0 0 0;
        }
        .navbar-toggler {
            margin-right: 0;
        }

        .menu-toggleable-left {
            &.navbar-toggleable-lg {
                @include media-breakpoint-down(lg) {
                    right: -100%;
                    left: inherit;
                    transition: 0.5s;
                }

                &.in {
                    left: inherit;
                    transition: 0.5s;

                    @include media-breakpoint-down(lg) {
                        left: inherit;
                        width: calc(100% - 60px);
                        right: 0;
                        margin: 0;
                    }

                    // @include media-breakpoint-down(md) {
                    //     width: calc(100% - 60px);
                    // }

                    .close-menu {
                        @include media-breakpoint-down(lg) {
                            // left: 0;
                            width: 60px;
                        }

                        // @include media-breakpoint-down(md) {
                        //     width: 74px;
                        // }

                        .close-button {
                            margin: auto;
                        }
                    }
                }
            }
        }

        .main-menu {
            @include media-breakpoint-up(lg) {
                background-color: #e0e7e7;
                z-index: 0;
            }
            // .container {
            //     height: 100%;
            //     padding: 0;
            // }
        }
        
        .toogle-button {
            position: absolute;
            left: 2%;
            width: 32px;
            height: 20px;
            top: 50%;
            transform: translate(0, -50%);
            @include media-breakpoint-down(sm) {
               left: 4%;
            }

            .icon-bar-1, .icon-bar-2, .icon-bar-3 {
                border-top: 1px solid $brand-primary;
                width: 100%;
                position: absolute;
                left: 0;
                transition: 0.5s;
            }
            .icon-bar-1 {
                top: 0;
            }
            .icon-bar-2 {
                top: 50%;
            }
            .icon-bar-3 {
                bottom: 0;
            }
        }
    }

    &.hover-menu {
        background-color: $white;
        @include media-breakpoint-down(lg) {
           padding: 10px 0;
        }
        .top-header {
            background-color: $white;
            &::after {
                background-color: $white;
            }
        }

        .main-header {
            .menu-toggleable-left {

                .navbar {
                    .menu-group {
                        .nav {
                            .nav-item {
                                .nav-link {
                                    color: $gray;

                                    @include media-breakpoint-down(lg) {
                                        color: $brand-primary;
                                    }
                                }

                                &.show {
                                    .nav-link {
                                        color: $brand-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .brand {
                margin: 10px 0 0 0;
            }
            .main-menu {
                background-color: $white;
            }
        }

        .search {
            @include media-breakpoint-up(lg) {
                background-color: $white;
            }

            .site-search {
                .form-control {
                    @include media-breakpoint-up(lg) {
                        background-color: $white;
                    }
                }
            }
        }

        .acount-bar {
            @include media-breakpoint-up(lg) {
                background-color: $white;
            }
        }
    }

    &.stickyHeader {
        .top-header {
            &::after {
                @include media-breakpoint-up(lg) {
                    position: fixed;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 60px;
                    z-index: 1;
                    background-color: $default-bg;
                }
            }

            .navbar-header {
                .pull-left {
                    @include media-breakpoint-up(lg) {
                        position: fixed;
                        left: 0;
                        z-index: 3;
                        top: 18px;
                        width: 100%;
                    }

                    // .container {
                    //     padding: 0 50px;
                    // }
                }

                // .search {
                //     position: fixed;
                //     // top: 0;
                //     top: 0;
                //     transform: none;
                //     left: auto;
                //     z-index: 10;
                //     margin-left: 20px;
                //     width: 100%;
                //     margin-top: 0;
                //     padding: 14px 0;
                // }

                .acount-bar {   
                    @include media-breakpoint-up(lg) {
                        position: absolute;
                        width: auto;
                        top: 0;
                        left: 30px;
                        transform: none;
                        z-index: 1;
                        margin-left: 0;
                        .user  {
                            height: 5px;
                        }
                    }
                }
            }
        }

        .menu-toggleable-left {
            &.navbar-toggleable-lg {
                @include media-breakpoint-up(lg) {
                    position: fixed;
                    // top: 0;
                    // top: 85px;
                    top: 60px;
                    right: 0;
                    transition: .3s ease-in-out;
                    z-index: 1;
                    width: 100%;
                }

                // >.container {
                //     padding: 0 12px;
                // }

                .navbar {
                    .menu-group {
                        .nav {
                            justify-content: end;

                            .nav-item {
                                .nav-link {
                                    @include media-breakpoint-up(lg) {
                                        padding: 18px 15px;
                                    }
                                }

                                &.dropdown {
                                    .custom-drop {
                                        &.show {
                                            position: fixed;
                                            // top: 60px;
                                            // top: 145px;
                                            top: 120px;
                                        }
                                    }
                                }
                                &.show {
                                    .nav-link {
                                        @include media-breakpoint-up(lg) {
                                            padding: 18px 15px;
                                        }
                                    }
                                    &:last-child {
                                        .nav-link {
                                            padding-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-menu {
            // .container {
            //     padding: 0 50px;
            //     @include media-breakpoint-down(xl) {
            //         padding: 0 44px;
            //     }
            //     @include media-breakpoint-down(md) {
            //         padding: 0 40px;
            //     }
            // }
            .container {
                padding: 0;

                @include media-breakpoint-up(lg) {
                    padding: 0 35px;
                }

                @include media-breakpoint-up(xl) {
                    padding: 0 40px;
                }

                @include media-breakpoint-up(xxl) {
                    padding: 0 50px;
                }
            }
        }

        &.hover-menu {
            z-index: 15;
            .navbar-toggleable-lg {
                background-color: $white;
            }
        }
        .header-spacer {
            @include media-breakpoint-up(lg) {
                z-index: 2;
            }
        }
    }
    nav {
        .main-header{
            .navbar-header{
                .logo-home {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    } 
}
 
.fixed-position {
    header {
        .main-header {
            .toogle-button {
                .icon-bar-1 {
                    top: 50%;
                    transform: rotate(45deg);
                }
                .icon-bar-2 {
                  display: none;
                }
                .icon-bar-3 {
                    top: 50%;
                    transform: rotate(-45deg);
                    bottom: auto;
                }
            }
        }
    }
}

header.headerWhite {
    .main-header {
        @include media-breakpoint-up(lg) {
            background: $white;

            .menu-toggleable-left{
                .navbar {
                    .menu-group {
                        .nav {
                            &>.nav-item {
                                &>.nav-link {
                                    color: $gray58;
    
                                    &:hover{
                                        color: $brand-primary;
                                    }
    
                                }
    
                            
                            }
                        }
                    }
                }
            }
        }
       
        
        
    }
}  