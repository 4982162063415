.pagination, .image-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    ul {
        align-items: center;
        list-style: none;
        padding: 0;
        display: flex;
        gap: 15px;

        li {
            font-size: 18px;
          
            &:not(.current-page) {
                opacity: 0.8;

                a {
                    font-weight: $default-font-weight;
                }

            }

            &.disabled {
                cursor: not-allowed;
                a {
                    pointer-events: none;
                }
            }

            &.current-page {
                font-weight: $font-weight-semibold;
            }

            a {
                font-weight: $font-weight-semibold;

                &:hover {
                    color: $brand-primary;
                }
            }
            .go-to-first, .go-to-previous, .go-to-last, .go-to-next {
               img {
                vertical-align: baseline;
               }
            }
            .go-to-next, .go-to-last {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
}