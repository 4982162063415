@charset "UTF-8";
/*!
   * Bootstrap  v5.3.3 (https://getbootstrap.com/)
   * Copyright 2011-2024 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545; }

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25; }

h1, .h1, .material-details-wrap .md-title, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color); }

h1, .h1, .material-details-wrap .md-title {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1, .material-details-wrap .md-title {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline; }
  a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.container, .sticky-filter.applied-filter-sticky .applied-filters,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .sticky-filter.applied-filter-sticky .applied-filters, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .sticky-filter.applied-filter-sticky .applied-filters, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .sticky-filter.applied-filter-sticky .applied-filters, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .sticky-filter.applied-filter-sticky .applied-filters, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .sticky-filter.applied-filter-sticky .applied-filters, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg); }

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0; }
  .form-control::-webkit-datetime-edit {
    display: block;
    padding: 0; }
  .form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1; }
  .form-control:disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg); }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: var(--bs-secondary-bg); }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color); }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e"); }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    appearance: none;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    appearance: none;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: var(--bs-secondary-color); }
    .form-range:disabled::-moz-range-thumb {
      background-color: var(--bs-secondary-color); }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
      position: absolute;
      inset: 1rem 0.375rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg);
      border-radius: var(--bs-border-radius); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: var(--bs-border-width) 0; }
  .form-floating > :disabled ~ label,
  .form-floating > .form-control:disabled ~ label {
    color: #6c757d; }
    .form-floating > :disabled ~ label::after,
    .form-floating > .form-control:disabled ~ label::after {
      background-color: var(--bs-secondary-bg); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color); }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: var(--bs-form-valid-color); }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--bs-form-valid-color); }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color); }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: var(--bs-form-invalid-color); }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color); }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked:focus-visible + .btn {
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d; }

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529; }

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg); }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm); }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: var(--bs-border-radius); }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: calc(var(--bs-border-width) * -1); }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1); }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .nav-link.disabled, .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd; }
  .nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius); }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap); }
  .nav-underline .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--bs-nav-underline-border-width) solid transparent; }
    .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
      border-bottom-color: currentcolor; }
  .nav-underline .nav-link.active,
  .nav-underline .show > .nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: currentcolor; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container, .sticky-filter.applied-filter-sticky .navbar > .applied-filters,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color); }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type > .accordion-header .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type > .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush > .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush > .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
    border-radius: 0; }
  .accordion-flush > .accordion-item > .accordion-collapse {
    border-radius: 0; }

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1); }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg); }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm); }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color); }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis); }

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis); }

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis); }

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis); }

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis); }

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis); }

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis); }

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis); }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-stacked > .progress {
  overflow: visible; }

.progress-stacked > .progress > .progress-bar {
  width: 100%; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis); }

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis); }

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis); }

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis); }

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis); }

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis); }

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis); }

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis); }

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity); }
  .btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity); }
  .btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity); }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity); }

.btn-close-white {
  filter: var(--bs-btn-close-white-filter); }

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/; }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/; }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000; }

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height); }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-primary:hover, .link-primary:focus {
    color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important; }

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important; }

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-success:hover, .link-success:focus {
    color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important; }

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-info:hover, .link-info:focus {
    color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important; }

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-warning:hover, .link-warning:focus {
    color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important; }

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-danger:hover, .link-danger:focus {
    color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important; }

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-light:hover, .link-light:focus {
    color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important; }

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-dark:hover, .link-dark:focus {
    color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important; }

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body-emphasis:hover, .link-body-emphasis:focus {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important; }

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color); }

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden; }
  .icon-link > .bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: 0.2s ease-in-out transform; }
    @media (prefers-reduced-motion: reduce) {
      .icon-link > .bi {
        transition: none; } }

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0)); }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }
  .visually-hidden:not(caption),
  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
    position: absolute !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.object-fit-contain {
  object-fit: contain !important; }

.object-fit-cover {
  object-fit: cover !important; }

.object-fit-fill {
  object-fit: fill !important; }

.object-fit-scale {
  object-fit: scale-down !important; }

.object-fit-none {
  object-fit: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-inline-grid {
  display: inline-grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: var(--bs-box-shadow) !important; }

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important; }

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important; }

.shadow-none {
  box-shadow: none !important; }

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity)); }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important; }

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important; }

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important; }

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important; }

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important; }

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important; }

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important; }

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.row-gap-0 {
  row-gap: 0 !important; }

.row-gap-1 {
  row-gap: 0.25rem !important; }

.row-gap-2 {
  row-gap: 0.5rem !important; }

.row-gap-3 {
  row-gap: 1rem !important; }

.row-gap-4 {
  row-gap: 1.5rem !important; }

.row-gap-5 {
  row-gap: 3rem !important; }

.column-gap-0 {
  column-gap: 0 !important; }

.column-gap-1 {
  column-gap: 0.25rem !important; }

.column-gap-2 {
  column-gap: 0.5rem !important; }

.column-gap-3 {
  column-gap: 1rem !important; }

.column-gap-4 {
  column-gap: 1.5rem !important; }

.column-gap-5 {
  column-gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important; }

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important; }

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important; }

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important; }

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important; }

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important; }

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important; }

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important; }

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important; }

.link-opacity-10 {
  --bs-link-opacity: 0.1; }

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1; }

.link-opacity-25 {
  --bs-link-opacity: 0.25; }

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25; }

.link-opacity-50 {
  --bs-link-opacity: 0.5; }

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5; }

.link-opacity-75 {
  --bs-link-opacity: 0.75; }

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75; }

.link-opacity-100 {
  --bs-link-opacity: 1; }

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1; }

.link-offset-1 {
  text-underline-offset: 0.125em !important; }

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important; }

.link-offset-2 {
  text-underline-offset: 0.25em !important; }

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important; }

.link-offset-3 {
  text-underline-offset: 0.375em !important; }

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important; }

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1; }

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important; }

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important; }

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important; }

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important; }

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important; }

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important; }

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important; }

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-2 {
  z-index: 2 !important; }

.z-3 {
  z-index: 3 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .object-fit-sm-contain {
    object-fit: contain !important; }
  .object-fit-sm-cover {
    object-fit: cover !important; }
  .object-fit-sm-fill {
    object-fit: fill !important; }
  .object-fit-sm-scale {
    object-fit: scale-down !important; }
  .object-fit-sm-none {
    object-fit: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-inline-grid {
    display: inline-grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .row-gap-sm-0 {
    row-gap: 0 !important; }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important; }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important; }
  .row-gap-sm-3 {
    row-gap: 1rem !important; }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important; }
  .row-gap-sm-5 {
    row-gap: 3rem !important; }
  .column-gap-sm-0 {
    column-gap: 0 !important; }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important; }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important; }
  .column-gap-sm-3 {
    column-gap: 1rem !important; }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important; }
  .column-gap-sm-5 {
    column-gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .object-fit-md-contain {
    object-fit: contain !important; }
  .object-fit-md-cover {
    object-fit: cover !important; }
  .object-fit-md-fill {
    object-fit: fill !important; }
  .object-fit-md-scale {
    object-fit: scale-down !important; }
  .object-fit-md-none {
    object-fit: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-inline-grid {
    display: inline-grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .row-gap-md-0 {
    row-gap: 0 !important; }
  .row-gap-md-1 {
    row-gap: 0.25rem !important; }
  .row-gap-md-2 {
    row-gap: 0.5rem !important; }
  .row-gap-md-3 {
    row-gap: 1rem !important; }
  .row-gap-md-4 {
    row-gap: 1.5rem !important; }
  .row-gap-md-5 {
    row-gap: 3rem !important; }
  .column-gap-md-0 {
    column-gap: 0 !important; }
  .column-gap-md-1 {
    column-gap: 0.25rem !important; }
  .column-gap-md-2 {
    column-gap: 0.5rem !important; }
  .column-gap-md-3 {
    column-gap: 1rem !important; }
  .column-gap-md-4 {
    column-gap: 1.5rem !important; }
  .column-gap-md-5 {
    column-gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .object-fit-lg-contain {
    object-fit: contain !important; }
  .object-fit-lg-cover {
    object-fit: cover !important; }
  .object-fit-lg-fill {
    object-fit: fill !important; }
  .object-fit-lg-scale {
    object-fit: scale-down !important; }
  .object-fit-lg-none {
    object-fit: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-inline-grid {
    display: inline-grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .row-gap-lg-0 {
    row-gap: 0 !important; }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important; }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important; }
  .row-gap-lg-3 {
    row-gap: 1rem !important; }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important; }
  .row-gap-lg-5 {
    row-gap: 3rem !important; }
  .column-gap-lg-0 {
    column-gap: 0 !important; }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important; }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important; }
  .column-gap-lg-3 {
    column-gap: 1rem !important; }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important; }
  .column-gap-lg-5 {
    column-gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .object-fit-xl-contain {
    object-fit: contain !important; }
  .object-fit-xl-cover {
    object-fit: cover !important; }
  .object-fit-xl-fill {
    object-fit: fill !important; }
  .object-fit-xl-scale {
    object-fit: scale-down !important; }
  .object-fit-xl-none {
    object-fit: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-inline-grid {
    display: inline-grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .row-gap-xl-0 {
    row-gap: 0 !important; }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xl-3 {
    row-gap: 1rem !important; }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xl-5 {
    row-gap: 3rem !important; }
  .column-gap-xl-0 {
    column-gap: 0 !important; }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xl-3 {
    column-gap: 1rem !important; }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xl-5 {
    column-gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .object-fit-xxl-contain {
    object-fit: contain !important; }
  .object-fit-xxl-cover {
    object-fit: cover !important; }
  .object-fit-xxl-fill {
    object-fit: fill !important; }
  .object-fit-xxl-scale {
    object-fit: scale-down !important; }
  .object-fit-xxl-none {
    object-fit: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-inline-grid {
    display: inline-grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .row-gap-xxl-0 {
    row-gap: 0 !important; }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xxl-3 {
    row-gap: 1rem !important; }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xxl-5 {
    row-gap: 3rem !important; }
  .column-gap-xxl-0 {
    column-gap: 0 !important; }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xxl-3 {
    column-gap: 1rem !important; }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xxl-5 {
    column-gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-inline-grid {
    display: inline-grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*
* COLORS
*/
:root {
  --scrollbar-color: "#000000"; }

/*
* GRID SYSTEM
*/
.container, .sticky-filter.applied-filter-sticky .applied-filters {
  padding: 0 20px;
  max-width: 1640px; }
  @media (min-width: 576px) {
    .container, .sticky-filter.applied-filter-sticky .applied-filters {
      padding: 0 25px; } }
  @media (min-width: 768px) {
    .container, .sticky-filter.applied-filter-sticky .applied-filters {
      padding: 0 30px; } }
  @media (min-width: 992px) {
    .container, .sticky-filter.applied-filter-sticky .applied-filters {
      padding: 0 35px; } }
  @media (min-width: 1200px) {
    .container, .sticky-filter.applied-filter-sticky .applied-filters {
      padding: 0 40px; } }
  @media (min-width: 1640px) {
    .container, .sticky-filter.applied-filter-sticky .applied-filters {
      padding: 0 50px; } }

.row {
  margin: 0 -10px; }

.row > * {
  padding: 0 10px; }

#maincontent {
  min-height: calc(100vh - 524px); }
  @media (max-width: 991.98px) {
    #maincontent {
      margin-top: 60px;
      min-height: calc(100vh - 504px); } }
  @media (max-width: 767.98px) {
    #maincontent {
      min-height: calc(100vh - 600px); } }
  @media (max-width: 575.98px) {
    #maincontent {
      min-height: calc(100vh - 630px); } }

h1, .h1, .material-details-wrap .md-title, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
li,
ul,
ol,
p,
a,
span {
  margin: 0;
  padding: 0;
  color: #000000;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  list-style: none; }

/*
* font families
*/
:root {
  --bs-font-sans-serif: 'Plus Jakarta Sans' !important;
  --bs-font-monospace: 'Plus Jakarta Sans' !important; }

body, html {
  font-family: var(--bs-font-sans-serif) !important;
  font-weight: 400;
  color: #000000; }

* {
  font-family: var(--bs-font-sans-serif) !important; }

b,
strong {
  font-weight: 600; }

h1, .h1, .material-details-wrap .md-title,
.h1 {
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-style: normal;
  font-weight: 300; }
  @media (min-width: 768px) {
    h1, .h1, .material-details-wrap .md-title,
    .h1 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.2px; } }
  @media (min-width: 992px) {
    h1, .h1, .material-details-wrap .md-title,
    .h1 {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: normal; } }
  @media (min-width: 1640px) {
    h1, .h1, .material-details-wrap .md-title,
    .h1 {
      font-size: 40px;
      line-height: 50px;
      letter-spacing: 0.4px; } }

h2, .h2,
.h2 {
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 300; }
  @media (min-width: 768px) {
    h2, .h2,
    .h2 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: normal; } }
  @media (min-width: 992px) {
    h2, .h2,
    .h2 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: normal; } }
  @media (min-width: 1640px) {
    h2, .h2,
    .h2 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0; } }

h3, .h3,
.h3 {
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 600; }
  @media (min-width: 768px) {
    h3, .h3,
    .h3 {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: normal; } }
  @media (min-width: 992px) {
    h3, .h3,
    .h3 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: normal; } }
  @media (min-width: 1640px) {
    h3, .h3,
    .h3 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0; } }

h4, .h4,
.h4 {
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 300; }
  @media (min-width: 768px) {
    h4, .h4,
    .h4 {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0; } }
  @media (min-width: 992px) {
    h4, .h4,
    .h4 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0; } }
  @media (min-width: 1640px) {
    h4, .h4,
    .h4 {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0; } }

h5, .h5,
.h5 {
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 600; }
  @media (min-width: 768px) {
    h5, .h5,
    .h5 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0; } }
  @media (min-width: 992px) {
    h5, .h5,
    .h5 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0; } }
  @media (min-width: 1640px) {
    h5, .h5,
    .h5 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: normal; } }

.text--brand-primary {
  color: #000000; }

.text--brand-secondary {
  color: #956839; }

.text--cultured {
  color: #f6f8f8; }

.text--light-blue {
  color: #f0f7f7; }

.text--neon-white {
  color: #f5f7f7; }

.text--celeste {
  color: #cdcdc1; }

.text--sugarcane {
  color: #f0f0ec; }

.text--green {
  color: #73630D; }

.text--seagreen {
  color: #c8d6d0; }

.text--azure {
  color: #eef3f1; }

.text--teal {
  color: #1C4844; }

.text--blush {
  color: #d8ada5; }

.text--light-pink {
  color: #f3e6e4; }

.text--brick {
  color: #A24F44; }

.text--brown {
  color: #a2765d; }

.text--ligh-brown {
  color: #e3d6ce; }

.text--maroon {
  color: #653734; }

.text--smoke-white {
  color: #f7f7f7; }

.text--gray {
  color: #767676; }

.text--gray58 {
  color: #949494; }

.text--white {
  color: #ffffff; }

.text--peach {
  color: #E3D5CE; }

.text--dark-brown {
  color: #8C602F; }

.text--default-bg {
  color: #e0e7e7; }

.text--white-smoke {
  color: #ededed; }

.text--platinum {
  color: #e6e6df; }

.text--aethon-red {
  color: #C71717; }

.text--Gray44 {
  color: #707070; }

.text--harvest-haze {
  color: #ce470d; }

.text--charcoal {
  color: #324048; }

.text--light-grayish-blue {
  color: #e9ecef; }

.text--pink {
  color: #FF00CE; }

.text--kh-highlight {
  color: #323E48; }

.text--kh-tint {
  color: #EFEFF0; }

.text--kh-default {
  color: #D6D8DA; }

@media (min-width: 768px) {
  .text--md--brand-primary {
    color: #000000; }
  .text--md--brand-secondary {
    color: #956839; }
  .text--md--cultured {
    color: #f6f8f8; }
  .text--md--light-blue {
    color: #f0f7f7; }
  .text--md--neon-white {
    color: #f5f7f7; }
  .text--md--celeste {
    color: #cdcdc1; }
  .text--md--sugarcane {
    color: #f0f0ec; }
  .text--md--green {
    color: #73630D; }
  .text--md--seagreen {
    color: #c8d6d0; }
  .text--md--azure {
    color: #eef3f1; }
  .text--md--teal {
    color: #1C4844; }
  .text--md--blush {
    color: #d8ada5; }
  .text--md--light-pink {
    color: #f3e6e4; }
  .text--md--brick {
    color: #A24F44; }
  .text--md--brown {
    color: #a2765d; }
  .text--md--ligh-brown {
    color: #e3d6ce; }
  .text--md--maroon {
    color: #653734; }
  .text--md--smoke-white {
    color: #f7f7f7; }
  .text--md--gray {
    color: #767676; }
  .text--md--gray58 {
    color: #949494; }
  .text--md--white {
    color: #ffffff; }
  .text--md--peach {
    color: #E3D5CE; }
  .text--md--dark-brown {
    color: #8C602F; }
  .text--md--default-bg {
    color: #e0e7e7; }
  .text--md--white-smoke {
    color: #ededed; }
  .text--md--platinum {
    color: #e6e6df; }
  .text--md--aethon-red {
    color: #C71717; }
  .text--md--Gray44 {
    color: #707070; }
  .text--md--harvest-haze {
    color: #ce470d; }
  .text--md--charcoal {
    color: #324048; }
  .text--md--light-grayish-blue {
    color: #e9ecef; }
  .text--md--pink {
    color: #FF00CE; }
  .text--md--kh-highlight {
    color: #323E48; }
  .text--md--kh-tint {
    color: #EFEFF0; }
  .text--md--kh-default {
    color: #D6D8DA; } }

@media (min-width: 992px) {
  .text--lg--brand-primary {
    color: #000000; }
  .text--lg--brand-secondary {
    color: #956839; }
  .text--lg--cultured {
    color: #f6f8f8; }
  .text--lg--light-blue {
    color: #f0f7f7; }
  .text--lg--neon-white {
    color: #f5f7f7; }
  .text--lg--celeste {
    color: #cdcdc1; }
  .text--lg--sugarcane {
    color: #f0f0ec; }
  .text--lg--green {
    color: #73630D; }
  .text--lg--seagreen {
    color: #c8d6d0; }
  .text--lg--azure {
    color: #eef3f1; }
  .text--lg--teal {
    color: #1C4844; }
  .text--lg--blush {
    color: #d8ada5; }
  .text--lg--light-pink {
    color: #f3e6e4; }
  .text--lg--brick {
    color: #A24F44; }
  .text--lg--brown {
    color: #a2765d; }
  .text--lg--ligh-brown {
    color: #e3d6ce; }
  .text--lg--maroon {
    color: #653734; }
  .text--lg--smoke-white {
    color: #f7f7f7; }
  .text--lg--gray {
    color: #767676; }
  .text--lg--gray58 {
    color: #949494; }
  .text--lg--white {
    color: #ffffff; }
  .text--lg--peach {
    color: #E3D5CE; }
  .text--lg--dark-brown {
    color: #8C602F; }
  .text--lg--default-bg {
    color: #e0e7e7; }
  .text--lg--white-smoke {
    color: #ededed; }
  .text--lg--platinum {
    color: #e6e6df; }
  .text--lg--aethon-red {
    color: #C71717; }
  .text--lg--Gray44 {
    color: #707070; }
  .text--lg--harvest-haze {
    color: #ce470d; }
  .text--lg--charcoal {
    color: #324048; }
  .text--lg--light-grayish-blue {
    color: #e9ecef; }
  .text--lg--pink {
    color: #FF00CE; }
  .text--lg--kh-highlight {
    color: #323E48; }
  .text--lg--kh-tint {
    color: #EFEFF0; }
  .text--lg--kh-default {
    color: #D6D8DA; } }

.background--brand-primary {
  background-color: #000000; }

.background--brand-secondary {
  background-color: #956839; }

.background--cultured {
  background-color: #f6f8f8; }

.background--light-blue {
  background-color: #f0f7f7; }

.background--neon-white {
  background-color: #f5f7f7; }

.background--celeste {
  background-color: #cdcdc1; }

.background--sugarcane {
  background-color: #f0f0ec; }

.background--green {
  background-color: #73630D; }

.background--seagreen {
  background-color: #c8d6d0; }

.background--azure {
  background-color: #eef3f1; }

.background--teal {
  background-color: #1C4844; }

.background--blush {
  background-color: #d8ada5; }

.background--light-pink {
  background-color: #f3e6e4; }

.background--brick {
  background-color: #A24F44; }

.background--brown {
  background-color: #a2765d; }

.background--ligh-brown {
  background-color: #e3d6ce; }

.background--maroon {
  background-color: #653734; }

.background--smoke-white {
  background-color: #f7f7f7; }

.background--gray {
  background-color: #767676; }

.background--gray58 {
  background-color: #949494; }

.background--white {
  background-color: #ffffff; }

.background--peach {
  background-color: #E3D5CE; }

.background--dark-brown {
  background-color: #8C602F; }

.background--default-bg {
  background-color: #e0e7e7; }

.background--white-smoke {
  background-color: #ededed; }

.background--platinum {
  background-color: #e6e6df; }

.background--aethon-red {
  background-color: #C71717; }

.background--Gray44 {
  background-color: #707070; }

.background--harvest-haze {
  background-color: #ce470d; }

.background--charcoal {
  background-color: #324048; }

.background--light-grayish-blue {
  background-color: #e9ecef; }

.background--pink {
  background-color: #FF00CE; }

.background--kh-highlight {
  background-color: #323E48; }

.background--kh-tint {
  background-color: #EFEFF0; }

.background--kh-default {
  background-color: #D6D8DA; }

@media (min-width: 768px) {
  .background--md--brand-primary {
    background-color: #000000; }
  .background--md--brand-secondary {
    background-color: #956839; }
  .background--md--cultured {
    background-color: #f6f8f8; }
  .background--md--light-blue {
    background-color: #f0f7f7; }
  .background--md--neon-white {
    background-color: #f5f7f7; }
  .background--md--celeste {
    background-color: #cdcdc1; }
  .background--md--sugarcane {
    background-color: #f0f0ec; }
  .background--md--green {
    background-color: #73630D; }
  .background--md--seagreen {
    background-color: #c8d6d0; }
  .background--md--azure {
    background-color: #eef3f1; }
  .background--md--teal {
    background-color: #1C4844; }
  .background--md--blush {
    background-color: #d8ada5; }
  .background--md--light-pink {
    background-color: #f3e6e4; }
  .background--md--brick {
    background-color: #A24F44; }
  .background--md--brown {
    background-color: #a2765d; }
  .background--md--ligh-brown {
    background-color: #e3d6ce; }
  .background--md--maroon {
    background-color: #653734; }
  .background--md--smoke-white {
    background-color: #f7f7f7; }
  .background--md--gray {
    background-color: #767676; }
  .background--md--gray58 {
    background-color: #949494; }
  .background--md--white {
    background-color: #ffffff; }
  .background--md--peach {
    background-color: #E3D5CE; }
  .background--md--dark-brown {
    background-color: #8C602F; }
  .background--md--default-bg {
    background-color: #e0e7e7; }
  .background--md--white-smoke {
    background-color: #ededed; }
  .background--md--platinum {
    background-color: #e6e6df; }
  .background--md--aethon-red {
    background-color: #C71717; }
  .background--md--Gray44 {
    background-color: #707070; }
  .background--md--harvest-haze {
    background-color: #ce470d; }
  .background--md--charcoal {
    background-color: #324048; }
  .background--md--light-grayish-blue {
    background-color: #e9ecef; }
  .background--md--pink {
    background-color: #FF00CE; }
  .background--md--kh-highlight {
    background-color: #323E48; }
  .background--md--kh-tint {
    background-color: #EFEFF0; }
  .background--md--kh-default {
    background-color: #D6D8DA; } }

@media (min-width: 992px) {
  .background--lg--brand-primary {
    background-color: #000000; }
  .background--lg--brand-secondary {
    background-color: #956839; }
  .background--lg--cultured {
    background-color: #f6f8f8; }
  .background--lg--light-blue {
    background-color: #f0f7f7; }
  .background--lg--neon-white {
    background-color: #f5f7f7; }
  .background--lg--celeste {
    background-color: #cdcdc1; }
  .background--lg--sugarcane {
    background-color: #f0f0ec; }
  .background--lg--green {
    background-color: #73630D; }
  .background--lg--seagreen {
    background-color: #c8d6d0; }
  .background--lg--azure {
    background-color: #eef3f1; }
  .background--lg--teal {
    background-color: #1C4844; }
  .background--lg--blush {
    background-color: #d8ada5; }
  .background--lg--light-pink {
    background-color: #f3e6e4; }
  .background--lg--brick {
    background-color: #A24F44; }
  .background--lg--brown {
    background-color: #a2765d; }
  .background--lg--ligh-brown {
    background-color: #e3d6ce; }
  .background--lg--maroon {
    background-color: #653734; }
  .background--lg--smoke-white {
    background-color: #f7f7f7; }
  .background--lg--gray {
    background-color: #767676; }
  .background--lg--gray58 {
    background-color: #949494; }
  .background--lg--white {
    background-color: #ffffff; }
  .background--lg--peach {
    background-color: #E3D5CE; }
  .background--lg--dark-brown {
    background-color: #8C602F; }
  .background--lg--default-bg {
    background-color: #e0e7e7; }
  .background--lg--white-smoke {
    background-color: #ededed; }
  .background--lg--platinum {
    background-color: #e6e6df; }
  .background--lg--aethon-red {
    background-color: #C71717; }
  .background--lg--Gray44 {
    background-color: #707070; }
  .background--lg--harvest-haze {
    background-color: #ce470d; }
  .background--lg--charcoal {
    background-color: #324048; }
  .background--lg--light-grayish-blue {
    background-color: #e9ecef; }
  .background--lg--pink {
    background-color: #FF00CE; }
  .background--lg--kh-highlight {
    background-color: #323E48; }
  .background--lg--kh-tint {
    background-color: #EFEFF0; }
  .background--lg--kh-default {
    background-color: #D6D8DA; } }

.border--brand-primary {
  border-color: #000000; }

.border--brand-secondary {
  border-color: #956839; }

.border--cultured {
  border-color: #f6f8f8; }

.border--light-blue {
  border-color: #f0f7f7; }

.border--neon-white {
  border-color: #f5f7f7; }

.border--celeste {
  border-color: #cdcdc1; }

.border--sugarcane {
  border-color: #f0f0ec; }

.border--green {
  border-color: #73630D; }

.border--seagreen {
  border-color: #c8d6d0; }

.border--azure {
  border-color: #eef3f1; }

.border--teal {
  border-color: #1C4844; }

.border--blush {
  border-color: #d8ada5; }

.border--light-pink {
  border-color: #f3e6e4; }

.border--brick {
  border-color: #A24F44; }

.border--brown {
  border-color: #a2765d; }

.border--ligh-brown {
  border-color: #e3d6ce; }

.border--maroon {
  border-color: #653734; }

.border--smoke-white {
  border-color: #f7f7f7; }

.border--gray {
  border-color: #767676; }

.border--gray58 {
  border-color: #949494; }

.border--white {
  border-color: #ffffff; }

.border--peach {
  border-color: #E3D5CE; }

.border--dark-brown {
  border-color: #8C602F; }

.border--default-bg {
  border-color: #e0e7e7; }

.border--white-smoke {
  border-color: #ededed; }

.border--platinum {
  border-color: #e6e6df; }

.border--aethon-red {
  border-color: #C71717; }

.border--Gray44 {
  border-color: #707070; }

.border--harvest-haze {
  border-color: #ce470d; }

.border--charcoal {
  border-color: #324048; }

.border--light-grayish-blue {
  border-color: #e9ecef; }

.border--pink {
  border-color: #FF00CE; }

.border--kh-highlight {
  border-color: #323E48; }

.border--kh-tint {
  border-color: #EFEFF0; }

.border--kh-default {
  border-color: #D6D8DA; }

@media (min-width: 768px) {
  .border--md--brand-primary {
    border-color: #000000; }
  .border--md--brand-secondary {
    border-color: #956839; }
  .border--md--cultured {
    border-color: #f6f8f8; }
  .border--md--light-blue {
    border-color: #f0f7f7; }
  .border--md--neon-white {
    border-color: #f5f7f7; }
  .border--md--celeste {
    border-color: #cdcdc1; }
  .border--md--sugarcane {
    border-color: #f0f0ec; }
  .border--md--green {
    border-color: #73630D; }
  .border--md--seagreen {
    border-color: #c8d6d0; }
  .border--md--azure {
    border-color: #eef3f1; }
  .border--md--teal {
    border-color: #1C4844; }
  .border--md--blush {
    border-color: #d8ada5; }
  .border--md--light-pink {
    border-color: #f3e6e4; }
  .border--md--brick {
    border-color: #A24F44; }
  .border--md--brown {
    border-color: #a2765d; }
  .border--md--ligh-brown {
    border-color: #e3d6ce; }
  .border--md--maroon {
    border-color: #653734; }
  .border--md--smoke-white {
    border-color: #f7f7f7; }
  .border--md--gray {
    border-color: #767676; }
  .border--md--gray58 {
    border-color: #949494; }
  .border--md--white {
    border-color: #ffffff; }
  .border--md--peach {
    border-color: #E3D5CE; }
  .border--md--dark-brown {
    border-color: #8C602F; }
  .border--md--default-bg {
    border-color: #e0e7e7; }
  .border--md--white-smoke {
    border-color: #ededed; }
  .border--md--platinum {
    border-color: #e6e6df; }
  .border--md--aethon-red {
    border-color: #C71717; }
  .border--md--Gray44 {
    border-color: #707070; }
  .border--md--harvest-haze {
    border-color: #ce470d; }
  .border--md--charcoal {
    border-color: #324048; }
  .border--md--light-grayish-blue {
    border-color: #e9ecef; }
  .border--md--pink {
    border-color: #FF00CE; }
  .border--md--kh-highlight {
    border-color: #323E48; }
  .border--md--kh-tint {
    border-color: #EFEFF0; }
  .border--md--kh-default {
    border-color: #D6D8DA; } }

@media (min-width: 992px) {
  .border--lg--brand-primary {
    border-color: #000000; }
  .border--lg--brand-secondary {
    border-color: #956839; }
  .border--lg--cultured {
    border-color: #f6f8f8; }
  .border--lg--light-blue {
    border-color: #f0f7f7; }
  .border--lg--neon-white {
    border-color: #f5f7f7; }
  .border--lg--celeste {
    border-color: #cdcdc1; }
  .border--lg--sugarcane {
    border-color: #f0f0ec; }
  .border--lg--green {
    border-color: #73630D; }
  .border--lg--seagreen {
    border-color: #c8d6d0; }
  .border--lg--azure {
    border-color: #eef3f1; }
  .border--lg--teal {
    border-color: #1C4844; }
  .border--lg--blush {
    border-color: #d8ada5; }
  .border--lg--light-pink {
    border-color: #f3e6e4; }
  .border--lg--brick {
    border-color: #A24F44; }
  .border--lg--brown {
    border-color: #a2765d; }
  .border--lg--ligh-brown {
    border-color: #e3d6ce; }
  .border--lg--maroon {
    border-color: #653734; }
  .border--lg--smoke-white {
    border-color: #f7f7f7; }
  .border--lg--gray {
    border-color: #767676; }
  .border--lg--gray58 {
    border-color: #949494; }
  .border--lg--white {
    border-color: #ffffff; }
  .border--lg--peach {
    border-color: #E3D5CE; }
  .border--lg--dark-brown {
    border-color: #8C602F; }
  .border--lg--default-bg {
    border-color: #e0e7e7; }
  .border--lg--white-smoke {
    border-color: #ededed; }
  .border--lg--platinum {
    border-color: #e6e6df; }
  .border--lg--aethon-red {
    border-color: #C71717; }
  .border--lg--Gray44 {
    border-color: #707070; }
  .border--lg--harvest-haze {
    border-color: #ce470d; }
  .border--lg--charcoal {
    border-color: #324048; }
  .border--lg--light-grayish-blue {
    border-color: #e9ecef; }
  .border--lg--pink {
    border-color: #FF00CE; }
  .border--lg--kh-highlight {
    border-color: #323E48; }
  .border--lg--kh-tint {
    border-color: #EFEFF0; }
  .border--lg--kh-default {
    border-color: #D6D8DA; } }

.font-size--0 {
  font-size: 0px !important; }

@media (min-width: 576px) {
  .font-size--sm--0 {
    font-size: 0px !important; } }

@media (min-width: 768px) {
  .font-size--md--0 {
    font-size: 0px !important; } }

@media (min-width: 992px) {
  .font-size--lg--0 {
    font-size: 0px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--0 {
    font-size: 0px !important; } }

.font-size--1 {
  font-size: 1px !important; }

@media (min-width: 576px) {
  .font-size--sm--1 {
    font-size: 1px !important; } }

@media (min-width: 768px) {
  .font-size--md--1 {
    font-size: 1px !important; } }

@media (min-width: 992px) {
  .font-size--lg--1 {
    font-size: 1px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--1 {
    font-size: 1px !important; } }

.font-size--2 {
  font-size: 2px !important; }

@media (min-width: 576px) {
  .font-size--sm--2 {
    font-size: 2px !important; } }

@media (min-width: 768px) {
  .font-size--md--2 {
    font-size: 2px !important; } }

@media (min-width: 992px) {
  .font-size--lg--2 {
    font-size: 2px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--2 {
    font-size: 2px !important; } }

.font-size--3 {
  font-size: 3px !important; }

@media (min-width: 576px) {
  .font-size--sm--3 {
    font-size: 3px !important; } }

@media (min-width: 768px) {
  .font-size--md--3 {
    font-size: 3px !important; } }

@media (min-width: 992px) {
  .font-size--lg--3 {
    font-size: 3px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--3 {
    font-size: 3px !important; } }

.font-size--4 {
  font-size: 4px !important; }

@media (min-width: 576px) {
  .font-size--sm--4 {
    font-size: 4px !important; } }

@media (min-width: 768px) {
  .font-size--md--4 {
    font-size: 4px !important; } }

@media (min-width: 992px) {
  .font-size--lg--4 {
    font-size: 4px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--4 {
    font-size: 4px !important; } }

.font-size--5 {
  font-size: 5px !important; }

@media (min-width: 576px) {
  .font-size--sm--5 {
    font-size: 5px !important; } }

@media (min-width: 768px) {
  .font-size--md--5 {
    font-size: 5px !important; } }

@media (min-width: 992px) {
  .font-size--lg--5 {
    font-size: 5px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--5 {
    font-size: 5px !important; } }

.font-size--6 {
  font-size: 6px !important; }

@media (min-width: 576px) {
  .font-size--sm--6 {
    font-size: 6px !important; } }

@media (min-width: 768px) {
  .font-size--md--6 {
    font-size: 6px !important; } }

@media (min-width: 992px) {
  .font-size--lg--6 {
    font-size: 6px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--6 {
    font-size: 6px !important; } }

.font-size--7 {
  font-size: 7px !important; }

@media (min-width: 576px) {
  .font-size--sm--7 {
    font-size: 7px !important; } }

@media (min-width: 768px) {
  .font-size--md--7 {
    font-size: 7px !important; } }

@media (min-width: 992px) {
  .font-size--lg--7 {
    font-size: 7px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--7 {
    font-size: 7px !important; } }

.font-size--8 {
  font-size: 8px !important; }

@media (min-width: 576px) {
  .font-size--sm--8 {
    font-size: 8px !important; } }

@media (min-width: 768px) {
  .font-size--md--8 {
    font-size: 8px !important; } }

@media (min-width: 992px) {
  .font-size--lg--8 {
    font-size: 8px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--8 {
    font-size: 8px !important; } }

.font-size--9 {
  font-size: 9px !important; }

@media (min-width: 576px) {
  .font-size--sm--9 {
    font-size: 9px !important; } }

@media (min-width: 768px) {
  .font-size--md--9 {
    font-size: 9px !important; } }

@media (min-width: 992px) {
  .font-size--lg--9 {
    font-size: 9px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--9 {
    font-size: 9px !important; } }

.font-size--10 {
  font-size: 10px !important; }

@media (min-width: 576px) {
  .font-size--sm--10 {
    font-size: 10px !important; } }

@media (min-width: 768px) {
  .font-size--md--10 {
    font-size: 10px !important; } }

@media (min-width: 992px) {
  .font-size--lg--10 {
    font-size: 10px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--10 {
    font-size: 10px !important; } }

.font-size--11 {
  font-size: 11px !important; }

@media (min-width: 576px) {
  .font-size--sm--11 {
    font-size: 11px !important; } }

@media (min-width: 768px) {
  .font-size--md--11 {
    font-size: 11px !important; } }

@media (min-width: 992px) {
  .font-size--lg--11 {
    font-size: 11px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--11 {
    font-size: 11px !important; } }

.font-size--12 {
  font-size: 12px !important; }

@media (min-width: 576px) {
  .font-size--sm--12 {
    font-size: 12px !important; } }

@media (min-width: 768px) {
  .font-size--md--12 {
    font-size: 12px !important; } }

@media (min-width: 992px) {
  .font-size--lg--12 {
    font-size: 12px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--12 {
    font-size: 12px !important; } }

.font-size--13 {
  font-size: 13px !important; }

@media (min-width: 576px) {
  .font-size--sm--13 {
    font-size: 13px !important; } }

@media (min-width: 768px) {
  .font-size--md--13 {
    font-size: 13px !important; } }

@media (min-width: 992px) {
  .font-size--lg--13 {
    font-size: 13px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--13 {
    font-size: 13px !important; } }

.font-size--14 {
  font-size: 14px !important; }

@media (min-width: 576px) {
  .font-size--sm--14 {
    font-size: 14px !important; } }

@media (min-width: 768px) {
  .font-size--md--14 {
    font-size: 14px !important; } }

@media (min-width: 992px) {
  .font-size--lg--14 {
    font-size: 14px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--14 {
    font-size: 14px !important; } }

.font-size--15 {
  font-size: 15px !important; }

@media (min-width: 576px) {
  .font-size--sm--15 {
    font-size: 15px !important; } }

@media (min-width: 768px) {
  .font-size--md--15 {
    font-size: 15px !important; } }

@media (min-width: 992px) {
  .font-size--lg--15 {
    font-size: 15px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--15 {
    font-size: 15px !important; } }

.font-size--16 {
  font-size: 16px !important; }

@media (min-width: 576px) {
  .font-size--sm--16 {
    font-size: 16px !important; } }

@media (min-width: 768px) {
  .font-size--md--16 {
    font-size: 16px !important; } }

@media (min-width: 992px) {
  .font-size--lg--16 {
    font-size: 16px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--16 {
    font-size: 16px !important; } }

.font-size--17 {
  font-size: 17px !important; }

@media (min-width: 576px) {
  .font-size--sm--17 {
    font-size: 17px !important; } }

@media (min-width: 768px) {
  .font-size--md--17 {
    font-size: 17px !important; } }

@media (min-width: 992px) {
  .font-size--lg--17 {
    font-size: 17px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--17 {
    font-size: 17px !important; } }

.font-size--18 {
  font-size: 18px !important; }

@media (min-width: 576px) {
  .font-size--sm--18 {
    font-size: 18px !important; } }

@media (min-width: 768px) {
  .font-size--md--18 {
    font-size: 18px !important; } }

@media (min-width: 992px) {
  .font-size--lg--18 {
    font-size: 18px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--18 {
    font-size: 18px !important; } }

.font-size--19 {
  font-size: 19px !important; }

@media (min-width: 576px) {
  .font-size--sm--19 {
    font-size: 19px !important; } }

@media (min-width: 768px) {
  .font-size--md--19 {
    font-size: 19px !important; } }

@media (min-width: 992px) {
  .font-size--lg--19 {
    font-size: 19px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--19 {
    font-size: 19px !important; } }

.font-size--20 {
  font-size: 20px !important; }

@media (min-width: 576px) {
  .font-size--sm--20 {
    font-size: 20px !important; } }

@media (min-width: 768px) {
  .font-size--md--20 {
    font-size: 20px !important; } }

@media (min-width: 992px) {
  .font-size--lg--20 {
    font-size: 20px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--20 {
    font-size: 20px !important; } }

.font-size--21 {
  font-size: 21px !important; }

@media (min-width: 576px) {
  .font-size--sm--21 {
    font-size: 21px !important; } }

@media (min-width: 768px) {
  .font-size--md--21 {
    font-size: 21px !important; } }

@media (min-width: 992px) {
  .font-size--lg--21 {
    font-size: 21px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--21 {
    font-size: 21px !important; } }

.font-size--22 {
  font-size: 22px !important; }

@media (min-width: 576px) {
  .font-size--sm--22 {
    font-size: 22px !important; } }

@media (min-width: 768px) {
  .font-size--md--22 {
    font-size: 22px !important; } }

@media (min-width: 992px) {
  .font-size--lg--22 {
    font-size: 22px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--22 {
    font-size: 22px !important; } }

.font-size--23 {
  font-size: 23px !important; }

@media (min-width: 576px) {
  .font-size--sm--23 {
    font-size: 23px !important; } }

@media (min-width: 768px) {
  .font-size--md--23 {
    font-size: 23px !important; } }

@media (min-width: 992px) {
  .font-size--lg--23 {
    font-size: 23px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--23 {
    font-size: 23px !important; } }

.font-size--24 {
  font-size: 24px !important; }

@media (min-width: 576px) {
  .font-size--sm--24 {
    font-size: 24px !important; } }

@media (min-width: 768px) {
  .font-size--md--24 {
    font-size: 24px !important; } }

@media (min-width: 992px) {
  .font-size--lg--24 {
    font-size: 24px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--24 {
    font-size: 24px !important; } }

.font-size--25 {
  font-size: 25px !important; }

@media (min-width: 576px) {
  .font-size--sm--25 {
    font-size: 25px !important; } }

@media (min-width: 768px) {
  .font-size--md--25 {
    font-size: 25px !important; } }

@media (min-width: 992px) {
  .font-size--lg--25 {
    font-size: 25px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--25 {
    font-size: 25px !important; } }

.font-size--26 {
  font-size: 26px !important; }

@media (min-width: 576px) {
  .font-size--sm--26 {
    font-size: 26px !important; } }

@media (min-width: 768px) {
  .font-size--md--26 {
    font-size: 26px !important; } }

@media (min-width: 992px) {
  .font-size--lg--26 {
    font-size: 26px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--26 {
    font-size: 26px !important; } }

.font-size--27 {
  font-size: 27px !important; }

@media (min-width: 576px) {
  .font-size--sm--27 {
    font-size: 27px !important; } }

@media (min-width: 768px) {
  .font-size--md--27 {
    font-size: 27px !important; } }

@media (min-width: 992px) {
  .font-size--lg--27 {
    font-size: 27px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--27 {
    font-size: 27px !important; } }

.font-size--28 {
  font-size: 28px !important; }

@media (min-width: 576px) {
  .font-size--sm--28 {
    font-size: 28px !important; } }

@media (min-width: 768px) {
  .font-size--md--28 {
    font-size: 28px !important; } }

@media (min-width: 992px) {
  .font-size--lg--28 {
    font-size: 28px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--28 {
    font-size: 28px !important; } }

.font-size--29 {
  font-size: 29px !important; }

@media (min-width: 576px) {
  .font-size--sm--29 {
    font-size: 29px !important; } }

@media (min-width: 768px) {
  .font-size--md--29 {
    font-size: 29px !important; } }

@media (min-width: 992px) {
  .font-size--lg--29 {
    font-size: 29px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--29 {
    font-size: 29px !important; } }

.font-size--30 {
  font-size: 30px !important; }

@media (min-width: 576px) {
  .font-size--sm--30 {
    font-size: 30px !important; } }

@media (min-width: 768px) {
  .font-size--md--30 {
    font-size: 30px !important; } }

@media (min-width: 992px) {
  .font-size--lg--30 {
    font-size: 30px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--30 {
    font-size: 30px !important; } }

.font-size--31 {
  font-size: 31px !important; }

@media (min-width: 576px) {
  .font-size--sm--31 {
    font-size: 31px !important; } }

@media (min-width: 768px) {
  .font-size--md--31 {
    font-size: 31px !important; } }

@media (min-width: 992px) {
  .font-size--lg--31 {
    font-size: 31px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--31 {
    font-size: 31px !important; } }

.font-size--32 {
  font-size: 32px !important; }

@media (min-width: 576px) {
  .font-size--sm--32 {
    font-size: 32px !important; } }

@media (min-width: 768px) {
  .font-size--md--32 {
    font-size: 32px !important; } }

@media (min-width: 992px) {
  .font-size--lg--32 {
    font-size: 32px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--32 {
    font-size: 32px !important; } }

.font-size--33 {
  font-size: 33px !important; }

@media (min-width: 576px) {
  .font-size--sm--33 {
    font-size: 33px !important; } }

@media (min-width: 768px) {
  .font-size--md--33 {
    font-size: 33px !important; } }

@media (min-width: 992px) {
  .font-size--lg--33 {
    font-size: 33px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--33 {
    font-size: 33px !important; } }

.font-size--34 {
  font-size: 34px !important; }

@media (min-width: 576px) {
  .font-size--sm--34 {
    font-size: 34px !important; } }

@media (min-width: 768px) {
  .font-size--md--34 {
    font-size: 34px !important; } }

@media (min-width: 992px) {
  .font-size--lg--34 {
    font-size: 34px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--34 {
    font-size: 34px !important; } }

.font-size--35 {
  font-size: 35px !important; }

@media (min-width: 576px) {
  .font-size--sm--35 {
    font-size: 35px !important; } }

@media (min-width: 768px) {
  .font-size--md--35 {
    font-size: 35px !important; } }

@media (min-width: 992px) {
  .font-size--lg--35 {
    font-size: 35px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--35 {
    font-size: 35px !important; } }

.font-size--36 {
  font-size: 36px !important; }

@media (min-width: 576px) {
  .font-size--sm--36 {
    font-size: 36px !important; } }

@media (min-width: 768px) {
  .font-size--md--36 {
    font-size: 36px !important; } }

@media (min-width: 992px) {
  .font-size--lg--36 {
    font-size: 36px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--36 {
    font-size: 36px !important; } }

.font-size--37 {
  font-size: 37px !important; }

@media (min-width: 576px) {
  .font-size--sm--37 {
    font-size: 37px !important; } }

@media (min-width: 768px) {
  .font-size--md--37 {
    font-size: 37px !important; } }

@media (min-width: 992px) {
  .font-size--lg--37 {
    font-size: 37px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--37 {
    font-size: 37px !important; } }

.font-size--38 {
  font-size: 38px !important; }

@media (min-width: 576px) {
  .font-size--sm--38 {
    font-size: 38px !important; } }

@media (min-width: 768px) {
  .font-size--md--38 {
    font-size: 38px !important; } }

@media (min-width: 992px) {
  .font-size--lg--38 {
    font-size: 38px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--38 {
    font-size: 38px !important; } }

.font-size--39 {
  font-size: 39px !important; }

@media (min-width: 576px) {
  .font-size--sm--39 {
    font-size: 39px !important; } }

@media (min-width: 768px) {
  .font-size--md--39 {
    font-size: 39px !important; } }

@media (min-width: 992px) {
  .font-size--lg--39 {
    font-size: 39px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--39 {
    font-size: 39px !important; } }

.font-size--40 {
  font-size: 40px !important; }

@media (min-width: 576px) {
  .font-size--sm--40 {
    font-size: 40px !important; } }

@media (min-width: 768px) {
  .font-size--md--40 {
    font-size: 40px !important; } }

@media (min-width: 992px) {
  .font-size--lg--40 {
    font-size: 40px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--40 {
    font-size: 40px !important; } }

.font-size--41 {
  font-size: 41px !important; }

@media (min-width: 576px) {
  .font-size--sm--41 {
    font-size: 41px !important; } }

@media (min-width: 768px) {
  .font-size--md--41 {
    font-size: 41px !important; } }

@media (min-width: 992px) {
  .font-size--lg--41 {
    font-size: 41px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--41 {
    font-size: 41px !important; } }

.font-size--42 {
  font-size: 42px !important; }

@media (min-width: 576px) {
  .font-size--sm--42 {
    font-size: 42px !important; } }

@media (min-width: 768px) {
  .font-size--md--42 {
    font-size: 42px !important; } }

@media (min-width: 992px) {
  .font-size--lg--42 {
    font-size: 42px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--42 {
    font-size: 42px !important; } }

.font-size--43 {
  font-size: 43px !important; }

@media (min-width: 576px) {
  .font-size--sm--43 {
    font-size: 43px !important; } }

@media (min-width: 768px) {
  .font-size--md--43 {
    font-size: 43px !important; } }

@media (min-width: 992px) {
  .font-size--lg--43 {
    font-size: 43px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--43 {
    font-size: 43px !important; } }

.font-size--44 {
  font-size: 44px !important; }

@media (min-width: 576px) {
  .font-size--sm--44 {
    font-size: 44px !important; } }

@media (min-width: 768px) {
  .font-size--md--44 {
    font-size: 44px !important; } }

@media (min-width: 992px) {
  .font-size--lg--44 {
    font-size: 44px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--44 {
    font-size: 44px !important; } }

.font-size--45 {
  font-size: 45px !important; }

@media (min-width: 576px) {
  .font-size--sm--45 {
    font-size: 45px !important; } }

@media (min-width: 768px) {
  .font-size--md--45 {
    font-size: 45px !important; } }

@media (min-width: 992px) {
  .font-size--lg--45 {
    font-size: 45px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--45 {
    font-size: 45px !important; } }

.font-size--46 {
  font-size: 46px !important; }

@media (min-width: 576px) {
  .font-size--sm--46 {
    font-size: 46px !important; } }

@media (min-width: 768px) {
  .font-size--md--46 {
    font-size: 46px !important; } }

@media (min-width: 992px) {
  .font-size--lg--46 {
    font-size: 46px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--46 {
    font-size: 46px !important; } }

.font-size--47 {
  font-size: 47px !important; }

@media (min-width: 576px) {
  .font-size--sm--47 {
    font-size: 47px !important; } }

@media (min-width: 768px) {
  .font-size--md--47 {
    font-size: 47px !important; } }

@media (min-width: 992px) {
  .font-size--lg--47 {
    font-size: 47px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--47 {
    font-size: 47px !important; } }

.font-size--48 {
  font-size: 48px !important; }

@media (min-width: 576px) {
  .font-size--sm--48 {
    font-size: 48px !important; } }

@media (min-width: 768px) {
  .font-size--md--48 {
    font-size: 48px !important; } }

@media (min-width: 992px) {
  .font-size--lg--48 {
    font-size: 48px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--48 {
    font-size: 48px !important; } }

.font-size--49 {
  font-size: 49px !important; }

@media (min-width: 576px) {
  .font-size--sm--49 {
    font-size: 49px !important; } }

@media (min-width: 768px) {
  .font-size--md--49 {
    font-size: 49px !important; } }

@media (min-width: 992px) {
  .font-size--lg--49 {
    font-size: 49px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--49 {
    font-size: 49px !important; } }

.font-size--50 {
  font-size: 50px !important; }

@media (min-width: 576px) {
  .font-size--sm--50 {
    font-size: 50px !important; } }

@media (min-width: 768px) {
  .font-size--md--50 {
    font-size: 50px !important; } }

@media (min-width: 992px) {
  .font-size--lg--50 {
    font-size: 50px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--50 {
    font-size: 50px !important; } }

.font-size--51 {
  font-size: 51px !important; }

@media (min-width: 576px) {
  .font-size--sm--51 {
    font-size: 51px !important; } }

@media (min-width: 768px) {
  .font-size--md--51 {
    font-size: 51px !important; } }

@media (min-width: 992px) {
  .font-size--lg--51 {
    font-size: 51px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--51 {
    font-size: 51px !important; } }

.font-size--52 {
  font-size: 52px !important; }

@media (min-width: 576px) {
  .font-size--sm--52 {
    font-size: 52px !important; } }

@media (min-width: 768px) {
  .font-size--md--52 {
    font-size: 52px !important; } }

@media (min-width: 992px) {
  .font-size--lg--52 {
    font-size: 52px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--52 {
    font-size: 52px !important; } }

.font-size--53 {
  font-size: 53px !important; }

@media (min-width: 576px) {
  .font-size--sm--53 {
    font-size: 53px !important; } }

@media (min-width: 768px) {
  .font-size--md--53 {
    font-size: 53px !important; } }

@media (min-width: 992px) {
  .font-size--lg--53 {
    font-size: 53px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--53 {
    font-size: 53px !important; } }

.font-size--54 {
  font-size: 54px !important; }

@media (min-width: 576px) {
  .font-size--sm--54 {
    font-size: 54px !important; } }

@media (min-width: 768px) {
  .font-size--md--54 {
    font-size: 54px !important; } }

@media (min-width: 992px) {
  .font-size--lg--54 {
    font-size: 54px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--54 {
    font-size: 54px !important; } }

.font-size--55 {
  font-size: 55px !important; }

@media (min-width: 576px) {
  .font-size--sm--55 {
    font-size: 55px !important; } }

@media (min-width: 768px) {
  .font-size--md--55 {
    font-size: 55px !important; } }

@media (min-width: 992px) {
  .font-size--lg--55 {
    font-size: 55px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--55 {
    font-size: 55px !important; } }

.font-size--56 {
  font-size: 56px !important; }

@media (min-width: 576px) {
  .font-size--sm--56 {
    font-size: 56px !important; } }

@media (min-width: 768px) {
  .font-size--md--56 {
    font-size: 56px !important; } }

@media (min-width: 992px) {
  .font-size--lg--56 {
    font-size: 56px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--56 {
    font-size: 56px !important; } }

.font-size--57 {
  font-size: 57px !important; }

@media (min-width: 576px) {
  .font-size--sm--57 {
    font-size: 57px !important; } }

@media (min-width: 768px) {
  .font-size--md--57 {
    font-size: 57px !important; } }

@media (min-width: 992px) {
  .font-size--lg--57 {
    font-size: 57px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--57 {
    font-size: 57px !important; } }

.font-size--58 {
  font-size: 58px !important; }

@media (min-width: 576px) {
  .font-size--sm--58 {
    font-size: 58px !important; } }

@media (min-width: 768px) {
  .font-size--md--58 {
    font-size: 58px !important; } }

@media (min-width: 992px) {
  .font-size--lg--58 {
    font-size: 58px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--58 {
    font-size: 58px !important; } }

.font-size--59 {
  font-size: 59px !important; }

@media (min-width: 576px) {
  .font-size--sm--59 {
    font-size: 59px !important; } }

@media (min-width: 768px) {
  .font-size--md--59 {
    font-size: 59px !important; } }

@media (min-width: 992px) {
  .font-size--lg--59 {
    font-size: 59px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--59 {
    font-size: 59px !important; } }

.font-size--60 {
  font-size: 60px !important; }

@media (min-width: 576px) {
  .font-size--sm--60 {
    font-size: 60px !important; } }

@media (min-width: 768px) {
  .font-size--md--60 {
    font-size: 60px !important; } }

@media (min-width: 992px) {
  .font-size--lg--60 {
    font-size: 60px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--60 {
    font-size: 60px !important; } }

.font-size--61 {
  font-size: 61px !important; }

@media (min-width: 576px) {
  .font-size--sm--61 {
    font-size: 61px !important; } }

@media (min-width: 768px) {
  .font-size--md--61 {
    font-size: 61px !important; } }

@media (min-width: 992px) {
  .font-size--lg--61 {
    font-size: 61px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--61 {
    font-size: 61px !important; } }

.font-size--62 {
  font-size: 62px !important; }

@media (min-width: 576px) {
  .font-size--sm--62 {
    font-size: 62px !important; } }

@media (min-width: 768px) {
  .font-size--md--62 {
    font-size: 62px !important; } }

@media (min-width: 992px) {
  .font-size--lg--62 {
    font-size: 62px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--62 {
    font-size: 62px !important; } }

.font-size--63 {
  font-size: 63px !important; }

@media (min-width: 576px) {
  .font-size--sm--63 {
    font-size: 63px !important; } }

@media (min-width: 768px) {
  .font-size--md--63 {
    font-size: 63px !important; } }

@media (min-width: 992px) {
  .font-size--lg--63 {
    font-size: 63px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--63 {
    font-size: 63px !important; } }

.font-size--64 {
  font-size: 64px !important; }

@media (min-width: 576px) {
  .font-size--sm--64 {
    font-size: 64px !important; } }

@media (min-width: 768px) {
  .font-size--md--64 {
    font-size: 64px !important; } }

@media (min-width: 992px) {
  .font-size--lg--64 {
    font-size: 64px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--64 {
    font-size: 64px !important; } }

.font-size--65 {
  font-size: 65px !important; }

@media (min-width: 576px) {
  .font-size--sm--65 {
    font-size: 65px !important; } }

@media (min-width: 768px) {
  .font-size--md--65 {
    font-size: 65px !important; } }

@media (min-width: 992px) {
  .font-size--lg--65 {
    font-size: 65px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--65 {
    font-size: 65px !important; } }

.font-size--66 {
  font-size: 66px !important; }

@media (min-width: 576px) {
  .font-size--sm--66 {
    font-size: 66px !important; } }

@media (min-width: 768px) {
  .font-size--md--66 {
    font-size: 66px !important; } }

@media (min-width: 992px) {
  .font-size--lg--66 {
    font-size: 66px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--66 {
    font-size: 66px !important; } }

.font-size--67 {
  font-size: 67px !important; }

@media (min-width: 576px) {
  .font-size--sm--67 {
    font-size: 67px !important; } }

@media (min-width: 768px) {
  .font-size--md--67 {
    font-size: 67px !important; } }

@media (min-width: 992px) {
  .font-size--lg--67 {
    font-size: 67px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--67 {
    font-size: 67px !important; } }

.font-size--68 {
  font-size: 68px !important; }

@media (min-width: 576px) {
  .font-size--sm--68 {
    font-size: 68px !important; } }

@media (min-width: 768px) {
  .font-size--md--68 {
    font-size: 68px !important; } }

@media (min-width: 992px) {
  .font-size--lg--68 {
    font-size: 68px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--68 {
    font-size: 68px !important; } }

.font-size--69 {
  font-size: 69px !important; }

@media (min-width: 576px) {
  .font-size--sm--69 {
    font-size: 69px !important; } }

@media (min-width: 768px) {
  .font-size--md--69 {
    font-size: 69px !important; } }

@media (min-width: 992px) {
  .font-size--lg--69 {
    font-size: 69px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--69 {
    font-size: 69px !important; } }

.font-size--70 {
  font-size: 70px !important; }

@media (min-width: 576px) {
  .font-size--sm--70 {
    font-size: 70px !important; } }

@media (min-width: 768px) {
  .font-size--md--70 {
    font-size: 70px !important; } }

@media (min-width: 992px) {
  .font-size--lg--70 {
    font-size: 70px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--70 {
    font-size: 70px !important; } }

.font-size--71 {
  font-size: 71px !important; }

@media (min-width: 576px) {
  .font-size--sm--71 {
    font-size: 71px !important; } }

@media (min-width: 768px) {
  .font-size--md--71 {
    font-size: 71px !important; } }

@media (min-width: 992px) {
  .font-size--lg--71 {
    font-size: 71px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--71 {
    font-size: 71px !important; } }

.font-size--72 {
  font-size: 72px !important; }

@media (min-width: 576px) {
  .font-size--sm--72 {
    font-size: 72px !important; } }

@media (min-width: 768px) {
  .font-size--md--72 {
    font-size: 72px !important; } }

@media (min-width: 992px) {
  .font-size--lg--72 {
    font-size: 72px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--72 {
    font-size: 72px !important; } }

.font-size--73 {
  font-size: 73px !important; }

@media (min-width: 576px) {
  .font-size--sm--73 {
    font-size: 73px !important; } }

@media (min-width: 768px) {
  .font-size--md--73 {
    font-size: 73px !important; } }

@media (min-width: 992px) {
  .font-size--lg--73 {
    font-size: 73px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--73 {
    font-size: 73px !important; } }

.font-size--74 {
  font-size: 74px !important; }

@media (min-width: 576px) {
  .font-size--sm--74 {
    font-size: 74px !important; } }

@media (min-width: 768px) {
  .font-size--md--74 {
    font-size: 74px !important; } }

@media (min-width: 992px) {
  .font-size--lg--74 {
    font-size: 74px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--74 {
    font-size: 74px !important; } }

.font-size--75 {
  font-size: 75px !important; }

@media (min-width: 576px) {
  .font-size--sm--75 {
    font-size: 75px !important; } }

@media (min-width: 768px) {
  .font-size--md--75 {
    font-size: 75px !important; } }

@media (min-width: 992px) {
  .font-size--lg--75 {
    font-size: 75px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--75 {
    font-size: 75px !important; } }

.font-size--76 {
  font-size: 76px !important; }

@media (min-width: 576px) {
  .font-size--sm--76 {
    font-size: 76px !important; } }

@media (min-width: 768px) {
  .font-size--md--76 {
    font-size: 76px !important; } }

@media (min-width: 992px) {
  .font-size--lg--76 {
    font-size: 76px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--76 {
    font-size: 76px !important; } }

.font-size--77 {
  font-size: 77px !important; }

@media (min-width: 576px) {
  .font-size--sm--77 {
    font-size: 77px !important; } }

@media (min-width: 768px) {
  .font-size--md--77 {
    font-size: 77px !important; } }

@media (min-width: 992px) {
  .font-size--lg--77 {
    font-size: 77px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--77 {
    font-size: 77px !important; } }

.font-size--78 {
  font-size: 78px !important; }

@media (min-width: 576px) {
  .font-size--sm--78 {
    font-size: 78px !important; } }

@media (min-width: 768px) {
  .font-size--md--78 {
    font-size: 78px !important; } }

@media (min-width: 992px) {
  .font-size--lg--78 {
    font-size: 78px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--78 {
    font-size: 78px !important; } }

.font-size--79 {
  font-size: 79px !important; }

@media (min-width: 576px) {
  .font-size--sm--79 {
    font-size: 79px !important; } }

@media (min-width: 768px) {
  .font-size--md--79 {
    font-size: 79px !important; } }

@media (min-width: 992px) {
  .font-size--lg--79 {
    font-size: 79px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--79 {
    font-size: 79px !important; } }

.font-size--80 {
  font-size: 80px !important; }

@media (min-width: 576px) {
  .font-size--sm--80 {
    font-size: 80px !important; } }

@media (min-width: 768px) {
  .font-size--md--80 {
    font-size: 80px !important; } }

@media (min-width: 992px) {
  .font-size--lg--80 {
    font-size: 80px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--80 {
    font-size: 80px !important; } }

.font-size--81 {
  font-size: 81px !important; }

@media (min-width: 576px) {
  .font-size--sm--81 {
    font-size: 81px !important; } }

@media (min-width: 768px) {
  .font-size--md--81 {
    font-size: 81px !important; } }

@media (min-width: 992px) {
  .font-size--lg--81 {
    font-size: 81px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--81 {
    font-size: 81px !important; } }

.font-size--82 {
  font-size: 82px !important; }

@media (min-width: 576px) {
  .font-size--sm--82 {
    font-size: 82px !important; } }

@media (min-width: 768px) {
  .font-size--md--82 {
    font-size: 82px !important; } }

@media (min-width: 992px) {
  .font-size--lg--82 {
    font-size: 82px !important; } }

@media (min-width: 1640px) {
  .font-size--xxl--82 {
    font-size: 82px !important; } }

.line-height--0 {
  line-height: 0px !important; }

@media (min-width: 576px) {
  .line-height--sm--0 {
    line-height: 0px !important; } }

@media (min-width: 768px) {
  .line-height--md--0 {
    line-height: 0px !important; } }

@media (min-width: 992px) {
  .line-height--lg--0 {
    line-height: 0px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--0 {
    line-height: 0px !important; } }

.line-height--1 {
  line-height: 1px !important; }

@media (min-width: 576px) {
  .line-height--sm--1 {
    line-height: 1px !important; } }

@media (min-width: 768px) {
  .line-height--md--1 {
    line-height: 1px !important; } }

@media (min-width: 992px) {
  .line-height--lg--1 {
    line-height: 1px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--1 {
    line-height: 1px !important; } }

.line-height--2 {
  line-height: 2px !important; }

@media (min-width: 576px) {
  .line-height--sm--2 {
    line-height: 2px !important; } }

@media (min-width: 768px) {
  .line-height--md--2 {
    line-height: 2px !important; } }

@media (min-width: 992px) {
  .line-height--lg--2 {
    line-height: 2px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--2 {
    line-height: 2px !important; } }

.line-height--3 {
  line-height: 3px !important; }

@media (min-width: 576px) {
  .line-height--sm--3 {
    line-height: 3px !important; } }

@media (min-width: 768px) {
  .line-height--md--3 {
    line-height: 3px !important; } }

@media (min-width: 992px) {
  .line-height--lg--3 {
    line-height: 3px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--3 {
    line-height: 3px !important; } }

.line-height--4 {
  line-height: 4px !important; }

@media (min-width: 576px) {
  .line-height--sm--4 {
    line-height: 4px !important; } }

@media (min-width: 768px) {
  .line-height--md--4 {
    line-height: 4px !important; } }

@media (min-width: 992px) {
  .line-height--lg--4 {
    line-height: 4px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--4 {
    line-height: 4px !important; } }

.line-height--5 {
  line-height: 5px !important; }

@media (min-width: 576px) {
  .line-height--sm--5 {
    line-height: 5px !important; } }

@media (min-width: 768px) {
  .line-height--md--5 {
    line-height: 5px !important; } }

@media (min-width: 992px) {
  .line-height--lg--5 {
    line-height: 5px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--5 {
    line-height: 5px !important; } }

.line-height--6 {
  line-height: 6px !important; }

@media (min-width: 576px) {
  .line-height--sm--6 {
    line-height: 6px !important; } }

@media (min-width: 768px) {
  .line-height--md--6 {
    line-height: 6px !important; } }

@media (min-width: 992px) {
  .line-height--lg--6 {
    line-height: 6px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--6 {
    line-height: 6px !important; } }

.line-height--7 {
  line-height: 7px !important; }

@media (min-width: 576px) {
  .line-height--sm--7 {
    line-height: 7px !important; } }

@media (min-width: 768px) {
  .line-height--md--7 {
    line-height: 7px !important; } }

@media (min-width: 992px) {
  .line-height--lg--7 {
    line-height: 7px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--7 {
    line-height: 7px !important; } }

.line-height--8 {
  line-height: 8px !important; }

@media (min-width: 576px) {
  .line-height--sm--8 {
    line-height: 8px !important; } }

@media (min-width: 768px) {
  .line-height--md--8 {
    line-height: 8px !important; } }

@media (min-width: 992px) {
  .line-height--lg--8 {
    line-height: 8px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--8 {
    line-height: 8px !important; } }

.line-height--9 {
  line-height: 9px !important; }

@media (min-width: 576px) {
  .line-height--sm--9 {
    line-height: 9px !important; } }

@media (min-width: 768px) {
  .line-height--md--9 {
    line-height: 9px !important; } }

@media (min-width: 992px) {
  .line-height--lg--9 {
    line-height: 9px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--9 {
    line-height: 9px !important; } }

.line-height--10 {
  line-height: 10px !important; }

@media (min-width: 576px) {
  .line-height--sm--10 {
    line-height: 10px !important; } }

@media (min-width: 768px) {
  .line-height--md--10 {
    line-height: 10px !important; } }

@media (min-width: 992px) {
  .line-height--lg--10 {
    line-height: 10px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--10 {
    line-height: 10px !important; } }

.line-height--11 {
  line-height: 11px !important; }

@media (min-width: 576px) {
  .line-height--sm--11 {
    line-height: 11px !important; } }

@media (min-width: 768px) {
  .line-height--md--11 {
    line-height: 11px !important; } }

@media (min-width: 992px) {
  .line-height--lg--11 {
    line-height: 11px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--11 {
    line-height: 11px !important; } }

.line-height--12 {
  line-height: 12px !important; }

@media (min-width: 576px) {
  .line-height--sm--12 {
    line-height: 12px !important; } }

@media (min-width: 768px) {
  .line-height--md--12 {
    line-height: 12px !important; } }

@media (min-width: 992px) {
  .line-height--lg--12 {
    line-height: 12px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--12 {
    line-height: 12px !important; } }

.line-height--13 {
  line-height: 13px !important; }

@media (min-width: 576px) {
  .line-height--sm--13 {
    line-height: 13px !important; } }

@media (min-width: 768px) {
  .line-height--md--13 {
    line-height: 13px !important; } }

@media (min-width: 992px) {
  .line-height--lg--13 {
    line-height: 13px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--13 {
    line-height: 13px !important; } }

.line-height--14 {
  line-height: 14px !important; }

@media (min-width: 576px) {
  .line-height--sm--14 {
    line-height: 14px !important; } }

@media (min-width: 768px) {
  .line-height--md--14 {
    line-height: 14px !important; } }

@media (min-width: 992px) {
  .line-height--lg--14 {
    line-height: 14px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--14 {
    line-height: 14px !important; } }

.line-height--15 {
  line-height: 15px !important; }

@media (min-width: 576px) {
  .line-height--sm--15 {
    line-height: 15px !important; } }

@media (min-width: 768px) {
  .line-height--md--15 {
    line-height: 15px !important; } }

@media (min-width: 992px) {
  .line-height--lg--15 {
    line-height: 15px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--15 {
    line-height: 15px !important; } }

.line-height--16 {
  line-height: 16px !important; }

@media (min-width: 576px) {
  .line-height--sm--16 {
    line-height: 16px !important; } }

@media (min-width: 768px) {
  .line-height--md--16 {
    line-height: 16px !important; } }

@media (min-width: 992px) {
  .line-height--lg--16 {
    line-height: 16px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--16 {
    line-height: 16px !important; } }

.line-height--17 {
  line-height: 17px !important; }

@media (min-width: 576px) {
  .line-height--sm--17 {
    line-height: 17px !important; } }

@media (min-width: 768px) {
  .line-height--md--17 {
    line-height: 17px !important; } }

@media (min-width: 992px) {
  .line-height--lg--17 {
    line-height: 17px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--17 {
    line-height: 17px !important; } }

.line-height--18 {
  line-height: 18px !important; }

@media (min-width: 576px) {
  .line-height--sm--18 {
    line-height: 18px !important; } }

@media (min-width: 768px) {
  .line-height--md--18 {
    line-height: 18px !important; } }

@media (min-width: 992px) {
  .line-height--lg--18 {
    line-height: 18px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--18 {
    line-height: 18px !important; } }

.line-height--19 {
  line-height: 19px !important; }

@media (min-width: 576px) {
  .line-height--sm--19 {
    line-height: 19px !important; } }

@media (min-width: 768px) {
  .line-height--md--19 {
    line-height: 19px !important; } }

@media (min-width: 992px) {
  .line-height--lg--19 {
    line-height: 19px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--19 {
    line-height: 19px !important; } }

.line-height--20 {
  line-height: 20px !important; }

@media (min-width: 576px) {
  .line-height--sm--20 {
    line-height: 20px !important; } }

@media (min-width: 768px) {
  .line-height--md--20 {
    line-height: 20px !important; } }

@media (min-width: 992px) {
  .line-height--lg--20 {
    line-height: 20px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--20 {
    line-height: 20px !important; } }

.line-height--21 {
  line-height: 21px !important; }

@media (min-width: 576px) {
  .line-height--sm--21 {
    line-height: 21px !important; } }

@media (min-width: 768px) {
  .line-height--md--21 {
    line-height: 21px !important; } }

@media (min-width: 992px) {
  .line-height--lg--21 {
    line-height: 21px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--21 {
    line-height: 21px !important; } }

.line-height--22 {
  line-height: 22px !important; }

@media (min-width: 576px) {
  .line-height--sm--22 {
    line-height: 22px !important; } }

@media (min-width: 768px) {
  .line-height--md--22 {
    line-height: 22px !important; } }

@media (min-width: 992px) {
  .line-height--lg--22 {
    line-height: 22px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--22 {
    line-height: 22px !important; } }

.line-height--23 {
  line-height: 23px !important; }

@media (min-width: 576px) {
  .line-height--sm--23 {
    line-height: 23px !important; } }

@media (min-width: 768px) {
  .line-height--md--23 {
    line-height: 23px !important; } }

@media (min-width: 992px) {
  .line-height--lg--23 {
    line-height: 23px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--23 {
    line-height: 23px !important; } }

.line-height--24 {
  line-height: 24px !important; }

@media (min-width: 576px) {
  .line-height--sm--24 {
    line-height: 24px !important; } }

@media (min-width: 768px) {
  .line-height--md--24 {
    line-height: 24px !important; } }

@media (min-width: 992px) {
  .line-height--lg--24 {
    line-height: 24px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--24 {
    line-height: 24px !important; } }

.line-height--25 {
  line-height: 25px !important; }

@media (min-width: 576px) {
  .line-height--sm--25 {
    line-height: 25px !important; } }

@media (min-width: 768px) {
  .line-height--md--25 {
    line-height: 25px !important; } }

@media (min-width: 992px) {
  .line-height--lg--25 {
    line-height: 25px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--25 {
    line-height: 25px !important; } }

.line-height--26 {
  line-height: 26px !important; }

@media (min-width: 576px) {
  .line-height--sm--26 {
    line-height: 26px !important; } }

@media (min-width: 768px) {
  .line-height--md--26 {
    line-height: 26px !important; } }

@media (min-width: 992px) {
  .line-height--lg--26 {
    line-height: 26px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--26 {
    line-height: 26px !important; } }

.line-height--27 {
  line-height: 27px !important; }

@media (min-width: 576px) {
  .line-height--sm--27 {
    line-height: 27px !important; } }

@media (min-width: 768px) {
  .line-height--md--27 {
    line-height: 27px !important; } }

@media (min-width: 992px) {
  .line-height--lg--27 {
    line-height: 27px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--27 {
    line-height: 27px !important; } }

.line-height--28 {
  line-height: 28px !important; }

@media (min-width: 576px) {
  .line-height--sm--28 {
    line-height: 28px !important; } }

@media (min-width: 768px) {
  .line-height--md--28 {
    line-height: 28px !important; } }

@media (min-width: 992px) {
  .line-height--lg--28 {
    line-height: 28px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--28 {
    line-height: 28px !important; } }

.line-height--29 {
  line-height: 29px !important; }

@media (min-width: 576px) {
  .line-height--sm--29 {
    line-height: 29px !important; } }

@media (min-width: 768px) {
  .line-height--md--29 {
    line-height: 29px !important; } }

@media (min-width: 992px) {
  .line-height--lg--29 {
    line-height: 29px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--29 {
    line-height: 29px !important; } }

.line-height--30 {
  line-height: 30px !important; }

@media (min-width: 576px) {
  .line-height--sm--30 {
    line-height: 30px !important; } }

@media (min-width: 768px) {
  .line-height--md--30 {
    line-height: 30px !important; } }

@media (min-width: 992px) {
  .line-height--lg--30 {
    line-height: 30px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--30 {
    line-height: 30px !important; } }

.line-height--31 {
  line-height: 31px !important; }

@media (min-width: 576px) {
  .line-height--sm--31 {
    line-height: 31px !important; } }

@media (min-width: 768px) {
  .line-height--md--31 {
    line-height: 31px !important; } }

@media (min-width: 992px) {
  .line-height--lg--31 {
    line-height: 31px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--31 {
    line-height: 31px !important; } }

.line-height--32 {
  line-height: 32px !important; }

@media (min-width: 576px) {
  .line-height--sm--32 {
    line-height: 32px !important; } }

@media (min-width: 768px) {
  .line-height--md--32 {
    line-height: 32px !important; } }

@media (min-width: 992px) {
  .line-height--lg--32 {
    line-height: 32px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--32 {
    line-height: 32px !important; } }

.line-height--33 {
  line-height: 33px !important; }

@media (min-width: 576px) {
  .line-height--sm--33 {
    line-height: 33px !important; } }

@media (min-width: 768px) {
  .line-height--md--33 {
    line-height: 33px !important; } }

@media (min-width: 992px) {
  .line-height--lg--33 {
    line-height: 33px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--33 {
    line-height: 33px !important; } }

.line-height--34 {
  line-height: 34px !important; }

@media (min-width: 576px) {
  .line-height--sm--34 {
    line-height: 34px !important; } }

@media (min-width: 768px) {
  .line-height--md--34 {
    line-height: 34px !important; } }

@media (min-width: 992px) {
  .line-height--lg--34 {
    line-height: 34px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--34 {
    line-height: 34px !important; } }

.line-height--35 {
  line-height: 35px !important; }

@media (min-width: 576px) {
  .line-height--sm--35 {
    line-height: 35px !important; } }

@media (min-width: 768px) {
  .line-height--md--35 {
    line-height: 35px !important; } }

@media (min-width: 992px) {
  .line-height--lg--35 {
    line-height: 35px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--35 {
    line-height: 35px !important; } }

.line-height--36 {
  line-height: 36px !important; }

@media (min-width: 576px) {
  .line-height--sm--36 {
    line-height: 36px !important; } }

@media (min-width: 768px) {
  .line-height--md--36 {
    line-height: 36px !important; } }

@media (min-width: 992px) {
  .line-height--lg--36 {
    line-height: 36px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--36 {
    line-height: 36px !important; } }

.line-height--37 {
  line-height: 37px !important; }

@media (min-width: 576px) {
  .line-height--sm--37 {
    line-height: 37px !important; } }

@media (min-width: 768px) {
  .line-height--md--37 {
    line-height: 37px !important; } }

@media (min-width: 992px) {
  .line-height--lg--37 {
    line-height: 37px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--37 {
    line-height: 37px !important; } }

.line-height--38 {
  line-height: 38px !important; }

@media (min-width: 576px) {
  .line-height--sm--38 {
    line-height: 38px !important; } }

@media (min-width: 768px) {
  .line-height--md--38 {
    line-height: 38px !important; } }

@media (min-width: 992px) {
  .line-height--lg--38 {
    line-height: 38px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--38 {
    line-height: 38px !important; } }

.line-height--39 {
  line-height: 39px !important; }

@media (min-width: 576px) {
  .line-height--sm--39 {
    line-height: 39px !important; } }

@media (min-width: 768px) {
  .line-height--md--39 {
    line-height: 39px !important; } }

@media (min-width: 992px) {
  .line-height--lg--39 {
    line-height: 39px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--39 {
    line-height: 39px !important; } }

.line-height--40 {
  line-height: 40px !important; }

@media (min-width: 576px) {
  .line-height--sm--40 {
    line-height: 40px !important; } }

@media (min-width: 768px) {
  .line-height--md--40 {
    line-height: 40px !important; } }

@media (min-width: 992px) {
  .line-height--lg--40 {
    line-height: 40px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--40 {
    line-height: 40px !important; } }

.line-height--41 {
  line-height: 41px !important; }

@media (min-width: 576px) {
  .line-height--sm--41 {
    line-height: 41px !important; } }

@media (min-width: 768px) {
  .line-height--md--41 {
    line-height: 41px !important; } }

@media (min-width: 992px) {
  .line-height--lg--41 {
    line-height: 41px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--41 {
    line-height: 41px !important; } }

.line-height--42 {
  line-height: 42px !important; }

@media (min-width: 576px) {
  .line-height--sm--42 {
    line-height: 42px !important; } }

@media (min-width: 768px) {
  .line-height--md--42 {
    line-height: 42px !important; } }

@media (min-width: 992px) {
  .line-height--lg--42 {
    line-height: 42px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--42 {
    line-height: 42px !important; } }

.line-height--43 {
  line-height: 43px !important; }

@media (min-width: 576px) {
  .line-height--sm--43 {
    line-height: 43px !important; } }

@media (min-width: 768px) {
  .line-height--md--43 {
    line-height: 43px !important; } }

@media (min-width: 992px) {
  .line-height--lg--43 {
    line-height: 43px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--43 {
    line-height: 43px !important; } }

.line-height--44 {
  line-height: 44px !important; }

@media (min-width: 576px) {
  .line-height--sm--44 {
    line-height: 44px !important; } }

@media (min-width: 768px) {
  .line-height--md--44 {
    line-height: 44px !important; } }

@media (min-width: 992px) {
  .line-height--lg--44 {
    line-height: 44px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--44 {
    line-height: 44px !important; } }

.line-height--45 {
  line-height: 45px !important; }

@media (min-width: 576px) {
  .line-height--sm--45 {
    line-height: 45px !important; } }

@media (min-width: 768px) {
  .line-height--md--45 {
    line-height: 45px !important; } }

@media (min-width: 992px) {
  .line-height--lg--45 {
    line-height: 45px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--45 {
    line-height: 45px !important; } }

.line-height--46 {
  line-height: 46px !important; }

@media (min-width: 576px) {
  .line-height--sm--46 {
    line-height: 46px !important; } }

@media (min-width: 768px) {
  .line-height--md--46 {
    line-height: 46px !important; } }

@media (min-width: 992px) {
  .line-height--lg--46 {
    line-height: 46px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--46 {
    line-height: 46px !important; } }

.line-height--47 {
  line-height: 47px !important; }

@media (min-width: 576px) {
  .line-height--sm--47 {
    line-height: 47px !important; } }

@media (min-width: 768px) {
  .line-height--md--47 {
    line-height: 47px !important; } }

@media (min-width: 992px) {
  .line-height--lg--47 {
    line-height: 47px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--47 {
    line-height: 47px !important; } }

.line-height--48 {
  line-height: 48px !important; }

@media (min-width: 576px) {
  .line-height--sm--48 {
    line-height: 48px !important; } }

@media (min-width: 768px) {
  .line-height--md--48 {
    line-height: 48px !important; } }

@media (min-width: 992px) {
  .line-height--lg--48 {
    line-height: 48px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--48 {
    line-height: 48px !important; } }

.line-height--49 {
  line-height: 49px !important; }

@media (min-width: 576px) {
  .line-height--sm--49 {
    line-height: 49px !important; } }

@media (min-width: 768px) {
  .line-height--md--49 {
    line-height: 49px !important; } }

@media (min-width: 992px) {
  .line-height--lg--49 {
    line-height: 49px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--49 {
    line-height: 49px !important; } }

.line-height--50 {
  line-height: 50px !important; }

@media (min-width: 576px) {
  .line-height--sm--50 {
    line-height: 50px !important; } }

@media (min-width: 768px) {
  .line-height--md--50 {
    line-height: 50px !important; } }

@media (min-width: 992px) {
  .line-height--lg--50 {
    line-height: 50px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--50 {
    line-height: 50px !important; } }

.line-height--51 {
  line-height: 51px !important; }

@media (min-width: 576px) {
  .line-height--sm--51 {
    line-height: 51px !important; } }

@media (min-width: 768px) {
  .line-height--md--51 {
    line-height: 51px !important; } }

@media (min-width: 992px) {
  .line-height--lg--51 {
    line-height: 51px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--51 {
    line-height: 51px !important; } }

.line-height--52 {
  line-height: 52px !important; }

@media (min-width: 576px) {
  .line-height--sm--52 {
    line-height: 52px !important; } }

@media (min-width: 768px) {
  .line-height--md--52 {
    line-height: 52px !important; } }

@media (min-width: 992px) {
  .line-height--lg--52 {
    line-height: 52px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--52 {
    line-height: 52px !important; } }

.line-height--53 {
  line-height: 53px !important; }

@media (min-width: 576px) {
  .line-height--sm--53 {
    line-height: 53px !important; } }

@media (min-width: 768px) {
  .line-height--md--53 {
    line-height: 53px !important; } }

@media (min-width: 992px) {
  .line-height--lg--53 {
    line-height: 53px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--53 {
    line-height: 53px !important; } }

.line-height--54 {
  line-height: 54px !important; }

@media (min-width: 576px) {
  .line-height--sm--54 {
    line-height: 54px !important; } }

@media (min-width: 768px) {
  .line-height--md--54 {
    line-height: 54px !important; } }

@media (min-width: 992px) {
  .line-height--lg--54 {
    line-height: 54px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--54 {
    line-height: 54px !important; } }

.line-height--55 {
  line-height: 55px !important; }

@media (min-width: 576px) {
  .line-height--sm--55 {
    line-height: 55px !important; } }

@media (min-width: 768px) {
  .line-height--md--55 {
    line-height: 55px !important; } }

@media (min-width: 992px) {
  .line-height--lg--55 {
    line-height: 55px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--55 {
    line-height: 55px !important; } }

.line-height--56 {
  line-height: 56px !important; }

@media (min-width: 576px) {
  .line-height--sm--56 {
    line-height: 56px !important; } }

@media (min-width: 768px) {
  .line-height--md--56 {
    line-height: 56px !important; } }

@media (min-width: 992px) {
  .line-height--lg--56 {
    line-height: 56px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--56 {
    line-height: 56px !important; } }

.line-height--57 {
  line-height: 57px !important; }

@media (min-width: 576px) {
  .line-height--sm--57 {
    line-height: 57px !important; } }

@media (min-width: 768px) {
  .line-height--md--57 {
    line-height: 57px !important; } }

@media (min-width: 992px) {
  .line-height--lg--57 {
    line-height: 57px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--57 {
    line-height: 57px !important; } }

.line-height--58 {
  line-height: 58px !important; }

@media (min-width: 576px) {
  .line-height--sm--58 {
    line-height: 58px !important; } }

@media (min-width: 768px) {
  .line-height--md--58 {
    line-height: 58px !important; } }

@media (min-width: 992px) {
  .line-height--lg--58 {
    line-height: 58px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--58 {
    line-height: 58px !important; } }

.line-height--59 {
  line-height: 59px !important; }

@media (min-width: 576px) {
  .line-height--sm--59 {
    line-height: 59px !important; } }

@media (min-width: 768px) {
  .line-height--md--59 {
    line-height: 59px !important; } }

@media (min-width: 992px) {
  .line-height--lg--59 {
    line-height: 59px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--59 {
    line-height: 59px !important; } }

.line-height--60 {
  line-height: 60px !important; }

@media (min-width: 576px) {
  .line-height--sm--60 {
    line-height: 60px !important; } }

@media (min-width: 768px) {
  .line-height--md--60 {
    line-height: 60px !important; } }

@media (min-width: 992px) {
  .line-height--lg--60 {
    line-height: 60px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--60 {
    line-height: 60px !important; } }

.line-height--61 {
  line-height: 61px !important; }

@media (min-width: 576px) {
  .line-height--sm--61 {
    line-height: 61px !important; } }

@media (min-width: 768px) {
  .line-height--md--61 {
    line-height: 61px !important; } }

@media (min-width: 992px) {
  .line-height--lg--61 {
    line-height: 61px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--61 {
    line-height: 61px !important; } }

.line-height--62 {
  line-height: 62px !important; }

@media (min-width: 576px) {
  .line-height--sm--62 {
    line-height: 62px !important; } }

@media (min-width: 768px) {
  .line-height--md--62 {
    line-height: 62px !important; } }

@media (min-width: 992px) {
  .line-height--lg--62 {
    line-height: 62px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--62 {
    line-height: 62px !important; } }

.line-height--63 {
  line-height: 63px !important; }

@media (min-width: 576px) {
  .line-height--sm--63 {
    line-height: 63px !important; } }

@media (min-width: 768px) {
  .line-height--md--63 {
    line-height: 63px !important; } }

@media (min-width: 992px) {
  .line-height--lg--63 {
    line-height: 63px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--63 {
    line-height: 63px !important; } }

.line-height--64 {
  line-height: 64px !important; }

@media (min-width: 576px) {
  .line-height--sm--64 {
    line-height: 64px !important; } }

@media (min-width: 768px) {
  .line-height--md--64 {
    line-height: 64px !important; } }

@media (min-width: 992px) {
  .line-height--lg--64 {
    line-height: 64px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--64 {
    line-height: 64px !important; } }

.line-height--65 {
  line-height: 65px !important; }

@media (min-width: 576px) {
  .line-height--sm--65 {
    line-height: 65px !important; } }

@media (min-width: 768px) {
  .line-height--md--65 {
    line-height: 65px !important; } }

@media (min-width: 992px) {
  .line-height--lg--65 {
    line-height: 65px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--65 {
    line-height: 65px !important; } }

.line-height--66 {
  line-height: 66px !important; }

@media (min-width: 576px) {
  .line-height--sm--66 {
    line-height: 66px !important; } }

@media (min-width: 768px) {
  .line-height--md--66 {
    line-height: 66px !important; } }

@media (min-width: 992px) {
  .line-height--lg--66 {
    line-height: 66px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--66 {
    line-height: 66px !important; } }

.line-height--67 {
  line-height: 67px !important; }

@media (min-width: 576px) {
  .line-height--sm--67 {
    line-height: 67px !important; } }

@media (min-width: 768px) {
  .line-height--md--67 {
    line-height: 67px !important; } }

@media (min-width: 992px) {
  .line-height--lg--67 {
    line-height: 67px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--67 {
    line-height: 67px !important; } }

.line-height--68 {
  line-height: 68px !important; }

@media (min-width: 576px) {
  .line-height--sm--68 {
    line-height: 68px !important; } }

@media (min-width: 768px) {
  .line-height--md--68 {
    line-height: 68px !important; } }

@media (min-width: 992px) {
  .line-height--lg--68 {
    line-height: 68px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--68 {
    line-height: 68px !important; } }

.line-height--69 {
  line-height: 69px !important; }

@media (min-width: 576px) {
  .line-height--sm--69 {
    line-height: 69px !important; } }

@media (min-width: 768px) {
  .line-height--md--69 {
    line-height: 69px !important; } }

@media (min-width: 992px) {
  .line-height--lg--69 {
    line-height: 69px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--69 {
    line-height: 69px !important; } }

.line-height--70 {
  line-height: 70px !important; }

@media (min-width: 576px) {
  .line-height--sm--70 {
    line-height: 70px !important; } }

@media (min-width: 768px) {
  .line-height--md--70 {
    line-height: 70px !important; } }

@media (min-width: 992px) {
  .line-height--lg--70 {
    line-height: 70px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--70 {
    line-height: 70px !important; } }

.line-height--71 {
  line-height: 71px !important; }

@media (min-width: 576px) {
  .line-height--sm--71 {
    line-height: 71px !important; } }

@media (min-width: 768px) {
  .line-height--md--71 {
    line-height: 71px !important; } }

@media (min-width: 992px) {
  .line-height--lg--71 {
    line-height: 71px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--71 {
    line-height: 71px !important; } }

.line-height--72 {
  line-height: 72px !important; }

@media (min-width: 576px) {
  .line-height--sm--72 {
    line-height: 72px !important; } }

@media (min-width: 768px) {
  .line-height--md--72 {
    line-height: 72px !important; } }

@media (min-width: 992px) {
  .line-height--lg--72 {
    line-height: 72px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--72 {
    line-height: 72px !important; } }

.line-height--73 {
  line-height: 73px !important; }

@media (min-width: 576px) {
  .line-height--sm--73 {
    line-height: 73px !important; } }

@media (min-width: 768px) {
  .line-height--md--73 {
    line-height: 73px !important; } }

@media (min-width: 992px) {
  .line-height--lg--73 {
    line-height: 73px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--73 {
    line-height: 73px !important; } }

.line-height--74 {
  line-height: 74px !important; }

@media (min-width: 576px) {
  .line-height--sm--74 {
    line-height: 74px !important; } }

@media (min-width: 768px) {
  .line-height--md--74 {
    line-height: 74px !important; } }

@media (min-width: 992px) {
  .line-height--lg--74 {
    line-height: 74px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--74 {
    line-height: 74px !important; } }

.line-height--75 {
  line-height: 75px !important; }

@media (min-width: 576px) {
  .line-height--sm--75 {
    line-height: 75px !important; } }

@media (min-width: 768px) {
  .line-height--md--75 {
    line-height: 75px !important; } }

@media (min-width: 992px) {
  .line-height--lg--75 {
    line-height: 75px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--75 {
    line-height: 75px !important; } }

.line-height--76 {
  line-height: 76px !important; }

@media (min-width: 576px) {
  .line-height--sm--76 {
    line-height: 76px !important; } }

@media (min-width: 768px) {
  .line-height--md--76 {
    line-height: 76px !important; } }

@media (min-width: 992px) {
  .line-height--lg--76 {
    line-height: 76px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--76 {
    line-height: 76px !important; } }

.line-height--77 {
  line-height: 77px !important; }

@media (min-width: 576px) {
  .line-height--sm--77 {
    line-height: 77px !important; } }

@media (min-width: 768px) {
  .line-height--md--77 {
    line-height: 77px !important; } }

@media (min-width: 992px) {
  .line-height--lg--77 {
    line-height: 77px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--77 {
    line-height: 77px !important; } }

.line-height--78 {
  line-height: 78px !important; }

@media (min-width: 576px) {
  .line-height--sm--78 {
    line-height: 78px !important; } }

@media (min-width: 768px) {
  .line-height--md--78 {
    line-height: 78px !important; } }

@media (min-width: 992px) {
  .line-height--lg--78 {
    line-height: 78px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--78 {
    line-height: 78px !important; } }

.line-height--79 {
  line-height: 79px !important; }

@media (min-width: 576px) {
  .line-height--sm--79 {
    line-height: 79px !important; } }

@media (min-width: 768px) {
  .line-height--md--79 {
    line-height: 79px !important; } }

@media (min-width: 992px) {
  .line-height--lg--79 {
    line-height: 79px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--79 {
    line-height: 79px !important; } }

.line-height--80 {
  line-height: 80px !important; }

@media (min-width: 576px) {
  .line-height--sm--80 {
    line-height: 80px !important; } }

@media (min-width: 768px) {
  .line-height--md--80 {
    line-height: 80px !important; } }

@media (min-width: 992px) {
  .line-height--lg--80 {
    line-height: 80px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--80 {
    line-height: 80px !important; } }

.line-height--81 {
  line-height: 81px !important; }

@media (min-width: 576px) {
  .line-height--sm--81 {
    line-height: 81px !important; } }

@media (min-width: 768px) {
  .line-height--md--81 {
    line-height: 81px !important; } }

@media (min-width: 992px) {
  .line-height--lg--81 {
    line-height: 81px !important; } }

@media (min-width: 1640px) {
  .line-height--xxl--81 {
    line-height: 81px !important; } }

.button {
  width: auto;
  display: inline-block;
  cursor: pointer;
  line-height: 16px;
  text-align: center;
  padding: 0;
  font-size: 13px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  color: #000000;
  background-color: transparent;
  margin-top: 15px; }
  .button:hover {
    color: inherit; }
  .button:focus {
    box-shadow: none;
    color: inherit; }
  .button-primary {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    position: relative;
    top: -5px;
    transition: 0.4s;
    padding: 10px 0 0;
    letter-spacing: .65px;
    line-height: 20px; }
    .button-primary:hover {
      color: inherit; }
    .button-primary:focus {
      box-shadow: none;
      color: inherit; }
    .button-primary:after {
      content: '';
      position: absolute;
      top: calc(100% + 3px);
      left: 0;
      width: 100%;
      border-bottom: 1px solid #000000;
      transition: 0.4s; }
    .button-primary:hover {
      color: #000000;
      top: 0; }
      .button-primary:hover:after {
        top: 0; }
    .button-primary:focus {
      color: #000000; }
    .button-primary:active {
      background-color: transparent;
      color: inherit; }
  .button-secondary {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    padding: 10px 0 0;
    letter-spacing: .65px;
    line-height: 20px;
    margin-top: 0;
    font-size: 15px; }
    .button-secondary:hover {
      color: inherit; }
    .button-secondary:focus {
      box-shadow: none;
      color: inherit; }
    .button-secondary:hover {
      color: #956839;
      border-bottom: 1px solid #956839; }
    .button-secondary:focus {
      color: #956839;
      font-weight: 600;
      border-bottom: 1px solid #956839; }
    .button-secondary:active, .button-secondary.active {
      background-color: transparent;
      color: #956839;
      font-weight: 600;
      border-bottom: 1px solid #956839; }
  .button-outline {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    border-radius: 50px;
    border: 1px solid #000000;
    padding: 6px 11px;
    margin-top: 0;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.65px; }
    .button-outline:hover {
      color: inherit; }
    .button-outline:focus {
      box-shadow: none;
      color: inherit; }
    .button-outline:hover {
      background-color: #000000;
      color: #ffffff; }
    .button-outline[class*="text--"] {
      color: inherit; }
    .button-outline[class*="border--"] {
      border-color: inherit; }
  .button-tertiary {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    border-radius: 50px;
    border: 1px solid transparent;
    padding: 7px 10px 5px;
    margin-top: 0; }
    .button-tertiary:hover {
      color: inherit; }
    .button-tertiary:focus {
      box-shadow: none;
      color: inherit; }
    .button-tertiary:hover {
      background-color: #000000;
      color: #ffffff; }
  .button-filter {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    border-radius: 50px;
    background-color: #ededed;
    padding: 7px 10px 5px;
    margin-top: 0;
    color: #000000; }
    .button-filter:hover {
      color: inherit; }
    .button-filter:focus {
      box-shadow: none;
      color: inherit; }
    .button-filter:hover {
      background-color: #956839;
      color: #ffffff; }
  .button-arrow {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    border: 1px solid #956839;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .button-arrow:hover {
      color: inherit; }
    .button-arrow:focus {
      box-shadow: none;
      color: inherit; }
    .button-arrow:hover, .button-arrow:focus {
      border-color: #956839; }
    .button-arrow img {
      max-width: 15px; }
  .button-document {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    padding: 3px 6px 3px 19px;
    position: relative;
    display: inline-block;
    margin: 0;
    color: #956839;
    text-transform: capitalize;
    font-weight: 300;
    font-size: 16px; }
    .button-document:hover {
      color: inherit; }
    .button-document:focus {
      box-shadow: none;
      color: inherit; }
    .button-document:before, .button-document:after {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 11px;
      height: 14px;
      display: block; }
    .button-document:before {
      border: 1px solid #956839; }
    .button-document:after {
      width: 7.8px;
      top: 8px;
      left: 4px;
      transform: rotate(45deg);
      border-top: 1px solid #956839;
      height: 0; }
  .button-configure {
    width: auto;
    display: inline-block;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
    padding: 0;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000;
    background-color: transparent;
    margin-top: 15px;
    padding: 6px 11px 6px 29px;
    position: relative;
    display: inline-block;
    font-size: 13px;
    border: 1px solid #000000;
    border-radius: 50px;
    background: url(../../../../images/hero-banner/config-icon.svg) no-repeat 5px 5px; }
    .button-configure:hover {
      color: inherit; }
    .button-configure:focus {
      box-shadow: none;
      color: inherit; }
    .button-configure:hover {
      border-color: #000000;
      color: #fff;
      background-color: #000000; }

.space-50 {
  height: 20px; }
  @media (min-width: 576px) {
    .space-50 {
      height: 25px; } }
  @media (min-width: 768px) {
    .space-50 {
      height: 29px; } }
  @media (min-width: 992px) {
    .space-50 {
      height: 35px; } }
  @media (min-width: 1200px) {
    .space-50 {
      height: 40px; } }
  @media (min-width: 1640px) {
    .space-50 {
      height: 50px; } }

p,
.p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Plus Jakarta Sans";
  font-weight: 300; }

a:hover {
  color: #956839; }

body {
  font-family: "Plus Jakarta Sans"; }

.font-weight-extra-light {
  font-weight: 200; }

.font-weight-light {
  font-weight: 300; }

.font-weight-regular {
  font-weight: 400; }

.font-weight-semibold {
  font-weight: 600; }

.link-icon-btn {
  font-size: 18px;
  padding: 3px 6px 3px 24px;
  position: relative;
  margin-top: 30px;
  display: inline-block;
  color: #000000; }
  .link-icon-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 11px;
    height: 14px;
    background-image: url("../images/download-icon.svg");
    background-repeat: no-repeat;
    background-position: center; }

.s-cat-title {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.65px;
  line-height: 16px; }

.product-slider-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  /*-- track --*/
  /*-- handle --*/
  /*-- handle on hover --*/ }
  .product-slider-wrapper .product-slide:first-child {
    margin-left: 0; }
  .product-slider-wrapper::-webkit-scrollbar {
    height: 10px; }
  .product-slider-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), #956839, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); }
  .product-slider-wrapper::-webkit-scrollbar-thumb {
    background: #956839;
    border-radius: 10px; }
  .product-slider-wrapper::-webkit-scrollbar-thumb:hover {
    background: #956839; }

.kii-carousel.scrollbarbgcolor .product-slider-wrapper::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: 10px; }

.kii-carousel.scrollbarbgcolor .product-slider-wrapper::-webkit-scrollbar-track {
  background: linear-gradient(360deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), var(--scrollbar-color), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); }

@-moz-document url-prefix() {
  .progressbar-slider-wrapper {
    position: relative; }
    .progressbar-slider-wrapper:after {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #956839;
      width: block; }
    .progressbar-slider-wrapper .product-slider-wrapper {
      scrollbar-color: #956839 transparent;
      scrollbar-width: thin; }
      .progressbar-slider-wrapper .product-slider-wrapper:hover, .progressbar-slider-wrapper .product-slider-wrapper:focus {
        scrollbar-color: #956839 transparent; }
    .progressbar-slider-wrapper.scrollbarbgcolor:after {
      background-color: var(--scrollbar-color); }
    .progressbar-slider-wrapper.scrollbarbgcolor .product-slider-wrapper {
      scrollbar-color: var(--scrollbar-color) transparent; }
      .progressbar-slider-wrapper.scrollbarbgcolor .product-slider-wrapper:hover, .progressbar-slider-wrapper.scrollbarbgcolor .product-slider-wrapper:focus {
        scrollbar-color: var(--scrollbar-color) transparent; } }

.kii-gallery-slider {
  padding-bottom: 40px; }
  .kii-gallery-slider .kii-gallery-slide {
    padding: 0;
    margin-left: 15px;
    overflow: hidden;
    min-height: 179px; }
    .kii-gallery-slider .kii-gallery-slide:first-child {
      margin-left: 0; }
    @media (max-width: 767.98px) {
      .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .social-icons {
        padding: 4px 5px 6px 34px;
        height: 43px; } }
    @media (max-width: 767.98px) {
      .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .social-icons .download-image {
        height: 100%; }
        .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .social-icons .download-image a {
          display: flex;
          align-items: center;
          height: 100%; }
          .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .social-icons .download-image a span {
            font-size: 12px;
            margin-left: 6px;
            text-align: center; }
          .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .social-icons .download-image a img {
            height: 15px; } }
    @media (max-width: 767.98px) {
      .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .info-overlay-icon {
        width: 20px;
        height: 20px;
        background-size: 8px;
        bottom: 12px;
        left: 5px; }
        .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .info-overlay-icon.active {
          background-size: 8px; } }
    @media (max-width: 767.98px) {
      .kii-gallery-slider .kii-gallery-slide .img-info-overlay-parent .img-info-overlay .info-overlay-content {
        height: calc(100% - 43px); } }
    @media (max-width: 1639.98px) {
      .kii-gallery-slider .kii-gallery-slide img {
        width: 100%;
        object-fit: cover; } }
    @media (min-width: 1200px) and (max-width: 1639.98px) {
      .kii-gallery-slider .kii-gallery-slide img {
        height: 544px; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .kii-gallery-slider .kii-gallery-slide img {
        height: 447px; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .kii-gallery-slider .kii-gallery-slide img {
        height: 343px; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .kii-gallery-slider .kii-gallery-slide img {
        height: 223px; } }
    @media (max-width: 575.98px) {
      .kii-gallery-slider .kii-gallery-slide img {
        height: 179px; } }
    .kii-gallery-slider .kii-gallery-slide img.download-icon {
      height: auto;
      width: auto;
      object-fit: none; }

.common-product-link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: transparent;
  opacity: 0;
  font-size: 0; }

.back-button {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.65px;
  color: #000000;
  position: relative;
  padding: 0 0 0 31px;
  text-transform: uppercase; }
  .back-button::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
    width: 19px;
    height: 19px;
    background-image: url("../images/back-btn.svg");
    background-repeat: no-repeat;
    background-position: center; }
  .back-button:hover {
    color: #000000; }

.fav-button {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.65px;
  color: #000000;
  position: relative;
  padding: 0 0 0 32px;
  text-transform: uppercase; }
  .fav-button::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
    width: 20px;
    height: 19px;
    background-image: url("../images/fav-icon.svg");
    background-repeat: no-repeat;
    background-position: center; }

.region {
  margin-bottom: 0; }

.padding-top-bottom-50 {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (min-width: 576px) {
    .padding-top-bottom-50 {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .padding-top-bottom-50 {
      padding-top: 29px;
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .padding-top-bottom-50 {
      padding-top: 35px;
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .padding-top-bottom-50 {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .padding-top-bottom-50 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.margin-top-bottom-50 {
  margin-top: 20px;
  margin-bottom: 20px; }
  @media (min-width: 576px) {
    .margin-top-bottom-50 {
      margin-top: 25px;
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .margin-top-bottom-50 {
      margin-top: 29px;
      margin-bottom: 29px; } }
  @media (min-width: 992px) {
    .margin-top-bottom-50 {
      margin-top: 35px;
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .margin-top-bottom-50 {
      margin-top: 40px;
      margin-bottom: 40px; } }
  @media (min-width: 1640px) {
    .margin-top-bottom-50 {
      margin-top: 50px;
      margin-bottom: 50px; } }

.padding-top-50 {
  padding-top: 20px; }
  @media (min-width: 576px) {
    .padding-top-50 {
      padding-top: 25px; } }
  @media (min-width: 768px) {
    .padding-top-50 {
      padding-top: 29px; } }
  @media (min-width: 992px) {
    .padding-top-50 {
      padding-top: 35px; } }
  @media (min-width: 1200px) {
    .padding-top-50 {
      padding-top: 40px; } }
  @media (min-width: 1640px) {
    .padding-top-50 {
      padding-top: 50px; } }

.padding-bottom-50 {
  padding-bottom: 20px; }
  @media (min-width: 576px) {
    .padding-bottom-50 {
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .padding-bottom-50 {
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .padding-bottom-50 {
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .padding-bottom-50 {
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .padding-bottom-50 {
      padding-bottom: 50px; } }

.margin-top-50 {
  margin-top: 20px; }
  @media (min-width: 576px) {
    .margin-top-50 {
      margin-top: 25px; } }
  @media (min-width: 768px) {
    .margin-top-50 {
      margin-top: 29px; } }
  @media (min-width: 992px) {
    .margin-top-50 {
      margin-top: 35px; } }
  @media (min-width: 1200px) {
    .margin-top-50 {
      margin-top: 40px; } }
  @media (min-width: 1640px) {
    .margin-top-50 {
      margin-top: 50px; } }

.margin-bottom-50 {
  margin-bottom: 20px; }
  @media (min-width: 576px) {
    .margin-bottom-50 {
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .margin-bottom-50 {
      margin-bottom: 29px; } }
  @media (min-width: 992px) {
    .margin-bottom-50 {
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .margin-bottom-50 {
      margin-bottom: 40px; } }
  @media (min-width: 1640px) {
    .margin-bottom-50 {
      margin-bottom: 50px; } }

.disable-image-download:hover .img-info-overlay,
.disable-image-download:hover .image-download-icon {
  display: none; }

.smalltitle {
  font-size: 13px;
  letter-spacing: 0.65px;
  font-weight: 600; }

html {
  border: transparent !important; }

p {
  margin-bottom: 20px; }

#ot-sdk-btn-floating.ot-floating-button {
  display: none; }

.back-and-like-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0; }
  .back-and-like-btn a:first-child {
    margin-right: 30px; }

.img-info-overlay-parent {
  position: relative; }
  .img-info-overlay-parent .img-info-overlay {
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 500ms; }
    @media (min-width: 768px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 100%; } }
    @media (min-width: 992px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 294px; } }
    @media (min-width: 1200px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 360px; } }
    @media (min-width: 1640px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 370px; } }
    .img-info-overlay-parent .img-info-overlay .info-overlay-container img {
      width: 11px;
      height: 14px;
      display: none; }
    .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      transition: all 500ms;
      opacity: 0;
      width: 100%;
      z-index: 9; }
      @media (max-width: 767.98px) {
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block {
          height: 100%; } }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block.active {
        opacity: 1;
        transform: translateX(0);
        transition: all 500ms;
        z-index: 8; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block * {
        color: #ffffff;
        font-size: 13px;
        line-height: 17px; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .global-download-icon {
        display: inline-block; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons {
        border-top: 0;
        padding: 15px 20px 15px 60px;
        background-color: #000000; }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons a {
          color: #ffffff;
          margin-right: 15px;
          font-size: 13px;
          line-height: 20px;
          cursor: pointer;
          background: rgba(0, 0, 0, 0.25) url("../images/hero-banner/download-arrow.svg");
          background-repeat: no-repeat;
          background-size: 17px 13px;
          background-position: 0px center;
          padding-left: 10px; }
          @media (min-width: 768px) and (max-width: 991.98px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons a {
              margin-right: 0; } }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons a span {
            margin-left: 10px;
            color: #ffffff; }
        @media (max-width: 767.98px) {
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons {
            position: absolute;
            bottom: 0;
            width: 100%; } }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block p:empty {
        height: 10px; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content {
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.7); }
        @media (max-width: 767.98px) {
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content {
            height: calc(100% - 50px);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 0;
            background-color: transparent; } }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content h6, .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .h6,
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .infooverlay-title {
          font-weight: 600;
          letter-spacing: 0.65px;
          text-transform: uppercase;
          line-height: 23px;
          font-size: 13px;
          color: #ffffff;
          margin-bottom: 10px;
          display: block; }
          @media (max-width: 767.98px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content h6, .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .h6,
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .infooverlay-title {
              background: rgba(0, 0, 0, 0.7);
              margin: 0;
              margin-top: auto;
              padding: 20px 20px 0; } }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
          overflow-y: auto;
          /* width */
          /* Track */
          /* Handle */
          /* Handle on hover */ }
          @media (max-width: 767.98px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              background-color: rgba(0, 0, 0, 0.7);
              padding: 10px 20px 20px; } }
          @media (min-width: 768px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              max-height: 119px; } }
          @media (min-width: 992px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              max-height: 219px; } }
          @media (min-width: 1200px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              max-height: 288px; } }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar {
            width: 2px; }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar-track {
            background: #707070; }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar-thumb {
            background: #ffffff; }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar-thumb:hover {
            background: #ffffff; }
    .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon {
      position: absolute;
      left: 10px;
      bottom: 10px;
      width: 30px;
      height: 30px;
      background: rgba(0, 0, 0, 0.25) url("../images/hero-banner/info-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      border-radius: 50%;
      line-height: 10px;
      border: 0;
      z-index: 9;
      color: #ffffff;
      opacity: 0;
      transition: .5s all; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon:focus {
        opacity: 1; }
      @media (max-width: 767.98px) {
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon {
          opacity: 1; } }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon .cross {
        display: none;
        width: 11px;
        height: 11px; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon:hover {
        background-color: #000000; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon.active {
        background: #000000 url("../images/hero-banner/info-cross.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 11px;
        z-index: 9;
        opacity: 1;
        border: 1px solid #ffffff; }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon.active img {
          display: none;
          margin: auto; }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon.active .cross {
          display: none; }
  .img-info-overlay-parent:hover .img-info-overlay {
    transition: all 500ms; }
  .img-info-overlay-parent:hover .img-info-overlay .info-overlay-container .info-overlay-icon {
    opacity: 1; }

a:not(.btn-check) + .btn:hover,
a .btn:first-child:hover {
  background: inherit;
  color: inherit;
  border-color: inherit; }

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  background: inherit;
  color: inherit;
  border-color: inherit; }

.skip {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #ffffff;
  transition: all .2s ease-in-out; }
  .skip:focus {
    top: 0;
    z-index: 10000000;
    transition: all .2s ease-in-out; }

.modal-background {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  transition: right 0.4s; }

.fixed-position {
  overflow: hidden; }
  .fixed-position .modal-background {
    z-index: 10;
    right: 0;
    left: 0;
    transition: right 0.4s; }

.mxt-product-config-container .mxt-spins-fullscreen {
  z-index: 111 !important; }

.custom-visualizer-container #kmb-config-overrides .kmb-wrapper .labeled-image-option .product-info {
  z-index: 1 !important; }

.cloudnary-video {
  max-height: 100%;
  max-width: 100%; }

@media (max-width: 991.98px) {
  video::-webkit-media-controls-overlay-play-button {
    opacity: 0; } }

.video-wrapper {
  position: relative; }
  .video-wrapper .video-title {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 0; }

/*-- CLOUDINARY VIDEO --*/
.video-js .vjs-tech {
  position: static !important; }

.pageProtected .modal-content {
  padding: 15px 20px 5px;
  box-shadow: 7px 8px 4px -10px; }

.pageProtected .modal-body input {
  width: 100%;
  height: 38px; }
  .pageProtected .modal-body input:focus {
    outline: none; }
  .pageProtected .modal-body input:invalid {
    border: 1px solid #C71717; }

.pageProtected .modal-body .passwordLabel {
  margin-bottom: 8px; }

.pageProtected .modal-body .errorMsg {
  margin-top: 5px;
  font-size: 12px;
  color: #C71717; }

.pageProtected .modal-footer .button {
  border: 1px solid #000000;
  background: #ffffff; }
  .pageProtected .modal-footer .button:hover {
    background-color: black; }

.article-block.contentTile-block {
  padding: 0 0 20px; }
  .article-block.contentTile-block .image-description a {
    position: relative;
    z-index: 2; }
  .article-block.contentTile-block .contentTile-block-link {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    font-size: 0; }

.surcharge-banner {
  padding-block: 10px; }
  .surcharge-banner p {
    color: #ff0000;
    font-size: 18px;
    font-weight: 500; }

.product-information .surcharge-banner {
  margin-top: 5px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.veil {
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .veil .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000; }

.spinner {
  width: 80px;
  height: 80px;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px; }

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #ffffff;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out; }

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s; }

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0d6efd;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0d6efd;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #0d6efd;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #73630D;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #73630D;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #73630D;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffffff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffffff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffffff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.attribute {
  margin-top: 0.938em; }
  .attribute label {
    display: block; }

.swatch a {
  text-decoration: none; }

.primary-images {
  margin: 0;
  padding: 0; }

.prices-add-to-cart-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 50;
  padding-right: 0;
  padding-left: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
  @media (min-width: 576px) {
    .prices-add-to-cart-actions {
      position: static;
      padding: 0 0.9375em; } }
  .prices-add-to-cart-actions .price {
    text-align: center; }

.prices {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center; }

.cart-and-ipay {
  text-align: center; }
  @media (max-width: 575.98px) {
    .cart-and-ipay {
      padding-bottom: 26px; }
      .cart-and-ipay .btn {
        width: 98%;
        margin: 1%;
        display: block; } }

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .add-to-basket-alert.show {
    display: block; }

.simple-quantity {
  margin-top: 1em; }

.main-attributes {
  margin-top: 1em; }

.size-chart {
  margin-top: 1.071em; }

div.availability {
  margin-top: 1.071em; }

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc; }
  .bundle-item:last-child {
    border-bottom: none; }

.container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
  margin-top: 2em;
  margin-bottom: 2em; }
  @media (max-width: 575.98px) {
    .container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
      margin-top: 0;
      margin-bottom: 0; } }

.product-option:not(:first-child) {
  margin-top: 1.071em; }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0d6efd;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0d6efd;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #0d6efd;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #73630D;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #73630D;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #73630D;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffffff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffffff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffffff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.attribute {
  margin-top: 0.938em; }
  .attribute label {
    display: block; }

.swatch a {
  text-decoration: none; }

.primary-images {
  margin: 0;
  padding: 0; }

.prices-add-to-cart-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 50;
  padding-right: 0;
  padding-left: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
  @media (min-width: 576px) {
    .prices-add-to-cart-actions {
      position: static;
      padding: 0 0.9375em; } }
  .prices-add-to-cart-actions .price {
    text-align: center; }

.prices {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center; }

.cart-and-ipay {
  text-align: center; }
  @media (max-width: 575.98px) {
    .cart-and-ipay {
      padding-bottom: 26px; }
      .cart-and-ipay .btn {
        width: 98%;
        margin: 1%;
        display: block; } }

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .add-to-basket-alert.show {
    display: block; }

.simple-quantity {
  margin-top: 1em; }

.main-attributes {
  margin-top: 1em; }

.size-chart {
  margin-top: 1.071em; }

div.availability {
  margin-top: 1.071em; }

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc; }
  .bundle-item:last-child {
    border-bottom: none; }

.container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
  margin-top: 2em;
  margin-bottom: 2em; }
  @media (max-width: 575.98px) {
    .container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
      margin-top: 0;
      margin-bottom: 0; } }

.product-option:not(:first-child) {
  margin-top: 1.071em; }

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: 56.25em; }
  .quick-view-dialog .selectable-bonus-product-line-item,
  .choose-bonus-product-dialog .selectable-bonus-product-line-item {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    padding-top: 0.3em;
    padding-bottom: 0.3em; }
  .quick-view-dialog .beenSelected,
  .choose-bonus-product-dialog .beenSelected {
    background-color: #eee; }
  .quick-view-dialog .modal-header,
  .choose-bonus-product-dialog .modal-header {
    background-color: #eee;
    border-bottom: 2px solid #ccc;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem; }
    .quick-view-dialog .modal-header .full-pdp-link,
    .choose-bonus-product-dialog .modal-header .full-pdp-link {
      color: var(--skin-primary-color-1); }
    .quick-view-dialog .modal-header .close,
    .choose-bonus-product-dialog .modal-header .close {
      font-size: 2rem;
      line-height: 1.5rem; }
  .quick-view-dialog .modal-title,
  .choose-bonus-product-dialog .modal-title {
    font-size: 1em; }
  .quick-view-dialog .product-name,
  .choose-bonus-product-dialog .product-name {
    font-size: 1.875em; }
  .quick-view-dialog .swatch-circle,
  .choose-bonus-product-dialog .swatch-circle {
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: 2.5em;
    height: 2.5em;
    background: #ffffff;
    -moz-border-radius: 1.25em;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative; }
    .quick-view-dialog .swatch-circle.color-value[data-selected=true]::after,
    .choose-bonus-product-dialog .swatch-circle.color-value[data-selected=true]::after {
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      left: 0.295em;
      position: absolute; }
    .quick-view-dialog .swatch-circle.color-value.selected::after,
    .choose-bonus-product-dialog .swatch-circle.color-value.selected::after {
      background: #ffffff;
      border-radius: 50%;
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      height: 0.75em;
      left: 0.31em;
      line-height: 0.8em;
      position: absolute;
      top: 0.35em;
      width: 0.8em; }
    .quick-view-dialog .swatch-circle i.fa-times-circle,
    .choose-bonus-product-dialog .swatch-circle i.fa-times-circle {
      background: #ffffff;
      border-radius: 50%;
      height: 0.75em;
      line-height: 0.8em;
      width: 0.8em; }
  .quick-view-dialog a[disabled] .swatch-circle,
  .choose-bonus-product-dialog a[disabled] .swatch-circle {
    cursor: not-allowed; }
    .quick-view-dialog a[disabled] .swatch-circle.color-value.selected::after,
    .choose-bonus-product-dialog a[disabled] .swatch-circle.color-value.selected::after {
      background-color: #495057; }
  .quick-view-dialog .availablity-container,
  .choose-bonus-product-dialog .availablity-container {
    text-align: right; }
  .quick-view-dialog .availablity-container,
  .quick-view-dialog .size-chart,
  .choose-bonus-product-dialog .availablity-container,
  .choose-bonus-product-dialog .size-chart {
    margin-top: 0.938em; }
  .quick-view-dialog .modal-content,
  .choose-bonus-product-dialog .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .quick-view-dialog .modal-body,
  .choose-bonus-product-dialog .modal-body {
    max-height: 28.125em;
    /* 450/16 */
    overflow-y: auto; }
  .quick-view-dialog button.close,
  .choose-bonus-product-dialog button.close {
    font-size: 1.25em; }
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    background-color: #ffffff;
    border: none;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
    .quick-view-dialog .modal-footer .prices .price,
    .choose-bonus-product-dialog .modal-footer .prices .price {
      font-size: 1.6em; }
  .quick-view-dialog .prices .sales,
  .choose-bonus-product-dialog .prices .sales {
    font-size: 1.5rem; }
  .quick-view-dialog .promotions,
  .choose-bonus-product-dialog .promotions {
    text-align: left;
    color: #dc3545; }
  @media (max-width: 575.98px) {
    .quick-view-dialog .bonus-summary,
    .choose-bonus-product-dialog .bonus-summary {
      font-size: 0.625em; } }
  @media (max-width: 575.98px) {
    .quick-view-dialog .bonus-summary .bonus-product-name,
    .choose-bonus-product-dialog .bonus-summary .bonus-product-name {
      padding: 0; } }
  .quick-view-dialog .pre-cart-products,
  .choose-bonus-product-dialog .pre-cart-products {
    margin-right: 0.125em; }
  .quick-view-dialog .color-attribute,
  .choose-bonus-product-dialog .color-attribute {
    border: none;
    padding: 0;
    background: none; }
  .quick-view-dialog .non-input-label,
  .choose-bonus-product-dialog .non-input-label {
    display: block;
    margin-bottom: 0.5rem; }

.product-tile {
  min-height: 13.4375em;
  border: 0;
  margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .product-tile {
      min-height: 23.4375em; } }
  @media (max-width: 575.98px) {
    .product-tile {
      min-height: 19.6875em; } }
  .product-tile .tile-body {
    padding: 0.625em 0 1.875em; }
    .product-tile .tile-body .color-swatches {
      min-height: 2.25em; }
      .product-tile .tile-body .color-swatches .product-tile-color-label {
        cursor: pointer;
        font-size: 1em; }
        @media (max-width: 767.98px) {
          .product-tile .tile-body .color-swatches .product-tile-color-label {
            font-size: 0.9375em; } }
        @media (max-width: 575.98px) {
          .product-tile .tile-body .color-swatches .product-tile-color-label {
            font-size: 0.8125em; } }
    .product-tile .tile-body .price {
      font-size: 1.125em;
      margin-bottom: 0; }
      @media (max-width: 767.98px) {
        .product-tile .tile-body .price {
          font-size: 1.0625em; } }
      @media (max-width: 575.98px) {
        .product-tile .tile-body .price {
          font-size: 1em; } }
      .product-tile .tile-body .price .tiered {
        font-size: 0.875em; }
        .product-tile .tile-body .price .tiered .value {
          font-weight: bold; }
    .product-tile .tile-body .coming-soon-tile {
      text-align: center; }
    .product-tile .tile-body .pdp-link {
      line-height: 1.2; }
      .product-tile .tile-body .pdp-link a {
        font-size: 1em;
        text-decoration: none; }
        @media (max-width: 767.98px) {
          .product-tile .tile-body .pdp-link a {
            font-size: 0.9375em; } }
        @media (max-width: 575.98px) {
          .product-tile .tile-body .pdp-link a {
            font-size: 0.8125em; } }
    .product-tile .tile-body .ratings {
      font-size: 0.9em; }
  .product-tile .image-container {
    position: relative;
    overflow: auto; }
    .product-tile .image-container .quickview {
      position: absolute;
      bottom: 1rem;
      right: 1rem; }
      .product-tile .image-container .quickview i.fa-expand {
        color: rgba(0, 0, 0, 0.7); }
      .product-tile .image-container .quickview i.fa-circle {
        color: rgba(255, 255, 255, 0.7);
        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
    .product-tile .image-container a {
      display: block; }
      .product-tile .image-container a .tile-image {
        width: 100%; }
  .product-tile .swatches a {
    text-decoration: none; }
  .product-tile .swatch-circle {
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: 1.8em;
    height: 1.8em;
    background: #ffffff;
    -moz-border-radius: 0.9em;
    border-radius: 0.9em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative; }
    .product-tile .swatch-circle.color-value[data-selected=true]::after {
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      left: 0.295em;
      position: absolute; }
    .product-tile .swatch-circle.color-value.selected::after {
      background: #ffffff;
      border-radius: 50%;
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      height: 0.75em;
      left: 0.31em;
      line-height: 0.8em;
      position: absolute;
      top: 0.35em;
      width: 0.8em; }
    .product-tile .swatch-circle i.fa-times-circle {
      background: #ffffff;
      border-radius: 50%;
      height: 0.75em;
      line-height: 0.8em;
      width: 0.8em; }

#chooseBonusProductModal .modal-footer .container, #chooseBonusProductModal .modal-footer .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky #chooseBonusProductModal .modal-footer .applied-filters {
  margin-left: 0;
  width: 100%;
  margin-right: 0; }

#chooseBonusProductModal .select-cbp-container {
  margin-top: auto;
  margin-bottom: auto; }

#chooseBonusProductModal .product-name-wrapper {
  width: 100%; }

#chooseBonusProductModal .bonus-quantity,
#chooseBonusProductModal .bonus-option {
  margin-top: 0.938em; }

#chooseBonusProductModal .bonus-quantity-select {
  min-width: 5em; }

#chooseBonusProductModal .select-bonus-product {
  margin-top: 1em; }

#chooseBonusProductModal .selected-pid {
  border: 1px solid #ccc; }
  #chooseBonusProductModal .selected-pid .bonus-product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

#chooseBonusProductModal .bonus-product-price {
  text-align: center;
  margin-top: 1em; }

.product .product-tile .tile-body .pdp-link .link {
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
  color: #000000;
  display: block;
  align-items: center;
  word-break: break-word; }

.product-listing {
  position: relative;
  margin-top: 80px; }
  @media (min-width: 576px) {
    .product-listing {
      margin-top: 85px; } }
  @media (min-width: 768px) {
    .product-listing {
      margin-top: 90px; } }
  @media (min-width: 992px) {
    .product-listing {
      margin-top: 35px; } }
  @media (min-width: 1200px) {
    .product-listing {
      margin-top: 40px; } }
  @media (min-width: 1640px) {
    .product-listing {
      margin-top: 50px; } }
  @media screen and (max-width: 360px) {
    .product-listing {
      width: 100%; } }
  .product-listing .product-grid .filter-bar .filters .button-filter {
    margin-bottom: 10px; }
  @media (max-width: 575.98px) {
    .product-listing .product-grid .product-grid-header {
      gap: 20px 0;
      padding-bottom: 0px; } }
  @media (max-width: 767.98px) {
    .product-listing .product-grid .product-grid-header .pl-heading {
      margin-top: -8px; } }
  .product-listing .product-grid .product-grid-header .pl-heading.idea-starter-cat {
    margin-bottom: 0px; }
  .product-listing .product-grid .product-grid-header h1, .product-listing .product-grid .product-grid-header .h1, .product-listing .product-grid .product-grid-header .material-details-wrap .md-title, .material-details-wrap .product-listing .product-grid .product-grid-header .md-title {
    margin-bottom: 20px; }
    @media (max-width: 575.98px) {
      .product-listing .product-grid .product-grid-header h1, .product-listing .product-grid .product-grid-header .h1, .product-listing .product-grid .product-grid-header .material-details-wrap .md-title, .material-details-wrap .product-listing .product-grid .product-grid-header .md-title {
        word-break: break-all; } }
  .product-listing .product-grid .product-grid-header .surcharge-banner {
    margin-bottom: 20px; }
  .product-listing .product-grid .col-6 {
    padding: 0 10px;
    gap: 10px 0; }
  @media (min-width: 992px) {
    .product-listing .product-grid .small-plp-image-tile {
      width: 16.66667%; } }
  .product-listing .product-grid .small-plp-image-tile .image-container {
    min-height: 200px; }
    @media (min-width: 576px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 190px; } }
    @media (min-width: 768px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 188px; } }
    @media (min-width: 992px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 228px; } }
    @media (min-width: 1200px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 282px; } }
    @media (min-width: 1640px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 320px; } }
    .product-listing .product-grid .small-plp-image-tile .image-container img {
      object-fit: cover; }
      @media (min-width: 576px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 190px; } }
      @media (min-width: 768px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 188px; } }
      @media (min-width: 992px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 228px; } }
      @media (min-width: 1200px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 282px; } }
      @media (min-width: 1640px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 320px; } }
  @media (min-width: 992px) {
    .product-listing .product-grid .large-plp-image-tile {
      width: 33.33333%; } }
  .product-listing .product-grid .large-plp-image-tile .image-container {
    min-height: 200px; }
    @media (min-width: 576px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 190px; } }
    @media (min-width: 768px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 188px; } }
    @media (min-width: 992px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 228px; } }
    @media (min-width: 1200px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 282px; } }
    @media (min-width: 1640px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 320px; } }
    .product-listing .product-grid .large-plp-image-tile .image-container img {
      object-fit: cover; }
      @media (min-width: 576px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 190px; } }
      @media (min-width: 768px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 188px; } }
      @media (min-width: 992px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 228px; } }
      @media (min-width: 1200px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 282px; } }
      @media (min-width: 1640px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 320px; } }
  .product-listing .product-grid .sticky-filter.applied-filter-sticky .applied-filters .applied-filter-list {
    margin-bottom: 5px; }
  .product-listing .product-grid .sticky-filter.applied-filter-sticky .applied-filters .iconBar {
    margin-bottom: 10px; }
    @media (max-width: 575.98px) {
      .product-listing .product-grid .sticky-filter.applied-filter-sticky .applied-filters .iconBar {
        margin-bottom: 15px; } }
  .product-listing .sticky-prod-coparison-bar {
    background-color: #f0f0ec;
    padding: 17px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .product-listing .sticky-prod-coparison-bar {
        flex-direction: column;
        padding: 17px 12px; } }
    .product-listing .sticky-prod-coparison-bar .left-area-bar p {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: #000000; }
    .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products {
      list-style: none;
      display: flex;
      align-items: center; }
      @media (max-width: 575.98px) {
        .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products {
          flex-wrap: wrap;
          gap: 10px; } }
      .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products li span {
        margin-left: 7px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px; }
      .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products li:not(:last-child) {
        margin-right: 30px; }
    .product-listing .sticky-prod-coparison-bar .right-area-bar .button {
      border: 1px solid #000000;
      border-radius: 50px; }
      .product-listing .sticky-prod-coparison-bar .right-area-bar .button:hover {
        background-color: #000000;
        color: #ffffff; }
  .product-listing .idea-started-product-list, .product-listing .download-product-list {
    margin-top: 20px;
    align-items: end; }
    @media (max-width: 1199.98px) {
      .product-listing .idea-started-product-list, .product-listing .download-product-list {
        align-items: start; } }
    @media (max-width: 991.98px) {
      .product-listing .idea-started-product-list, .product-listing .download-product-list {
        align-items: end; } }
    .product-listing .idea-started-product-list li, .product-listing .download-product-list li {
      padding-right: 0; }

.plp-no-result-found {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-style: normal;
  font-weight: 300; }
  @media (min-width: 576px) {
    .plp-no-result-found {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .plp-no-result-found {
      padding-top: 29px;
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .plp-no-result-found {
      padding-top: 35px;
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .plp-no-result-found {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .plp-no-result-found {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media (min-width: 768px) {
    .plp-no-result-found {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.2px; } }
  @media (min-width: 992px) {
    .plp-no-result-found {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: normal; } }
  @media (min-width: 1640px) {
    .plp-no-result-found {
      font-size: 40px;
      line-height: 50px;
      letter-spacing: 0.4px; } }

.iconBar {
  display: inline-flex;
  width: 30%;
  justify-content: flex-end;
  margin-left: auto;
  margin-bottom: 30px;
  align-self: flex-start; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .iconBar {
      width: 26%; } }
  @media screen and (max-width: 374px) {
    .iconBar {
      margin-left: 0;
      width: auto; } }
  @media (max-width: 575.98px) {
    .iconBar {
      align-items: center; } }
  @media (min-width: 768px) {
    .iconBar {
      width: 20%; } }
  @media (min-width: 1200px) {
    .iconBar {
      width: 15%; } }
  .iconBar button {
    background: none;
    box-shadow: none;
    border: none;
    margin-right: 10px; }
    .iconBar button:last-child {
      margin-right: 0; }
    .iconBar button.active img, .iconBar button:hover img {
      filter: invert(46%) sepia(24%) saturate(1009%) hue-rotate(350deg) brightness(88%) contrast(87%); }
  .iconBar [data-title]:hover:after {
    opacity: 1; }
  .iconBar [data-title]:after {
    content: attr(data-title);
    color: #956839;
    font-size: 9px;
    position: absolute;
    top: 30px;
    left: -50%;
    white-space: nowrap;
    opacity: 0; }
  .iconBar [data-title] {
    position: relative; }
  .iconBar .sort-by {
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.65px;
    margin-right: 21px;
    text-transform: uppercase;
    font-weight: 600; }
    @media (max-width: 575.98px) {
      .iconBar .sort-by {
        font-size: 12px;
        line-height: 19px;
        margin-right: 8px; } }

.downloadsList .table .gridCintainer {
  display: grid;
  grid-template-columns: 1fr repeat(2, 239px) 110px; }
  @media (max-width: 991.98px) {
    .downloadsList .table .gridCintainer {
      grid-template-columns: 1fr repeat(2, 170px) 110px; } }
  @media (max-width: 767.98px) {
    .downloadsList .table .gridCintainer {
      grid-template-columns: 1fr repeat(3, 110px); } }
  @media (max-width: 575.98px) {
    .downloadsList .table .gridCintainer {
      display: flex;
      flex-wrap: wrap; } }
  .downloadsList .table .gridCintainer .gridList {
    font-size: 14px;
    font-family: "Plus Jakarta Sans" !important;
    color: #000000;
    border: none;
    padding: 21px 10px; }
    .downloadsList .table .gridCintainer .gridList .link-icon-btn {
      margin-top: 0; }

.downloadsList .table .headingContainer .gridList {
  font-weight: 600;
  background: none;
  padding-inline: 0; }
  @media (max-width: 575.98px) {
    .downloadsList .table .headingContainer .gridList {
      display: none; }
      .downloadsList .table .headingContainer .gridList:first-child {
        display: block;
        width: 100%; } }
  .downloadsList .table .headingContainer .gridList button {
    background: none;
    box-shadow: none;
    border: none;
    margin-left: 5px;
    display: inline-block;
    padding: 0; }

@media (max-width: 575.98px) {
  .downloadsList .table .detailContainer {
    padding-block: 21px; } }

.downloadsList .table .detailContainer .gridList {
  padding-inline: 0 10px;
  line-height: 26px;
  font-weight: 400; }
  @media (max-width: 575.98px) {
    .downloadsList .table .detailContainer .gridList {
      width: auto;
      padding: 0 10px; } }
  .downloadsList .table .detailContainer .gridList:first-child {
    padding-left: 10px; }
    @media (max-width: 767.98px) {
      .downloadsList .table .detailContainer .gridList:first-child {
        white-space: nowrap;
        width: 17ch;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media (max-width: 575.98px) {
      .downloadsList .table .detailContainer .gridList:first-child {
        white-space: inherit;
        overflow: inherit;
        width: 100%;
        margin-bottom: 5px; } }
  .downloadsList .table .detailContainer .gridList:last-child {
    text-align: right; }
    @media (max-width: 575.98px) {
      .downloadsList .table .detailContainer .gridList:last-child {
        text-align: left;
        width: 100%;
        margin-top: 5px; } }
  .downloadsList .table .detailContainer .gridList a {
    display: block;
    padding: 0;
    font-size: 14px;
    font-weight: 400; }
    .downloadsList .table .detailContainer .gridList a::before {
      display: none; }
    .downloadsList .table .detailContainer .gridList a .download-icon {
      margin-left: 10px; }

@media (max-width: 575.98px) {
  .downloadsList .table .detailContainer .gridList.item2 {
    position: relative; }
    .downloadsList .table .detailContainer .gridList.item2::after {
      content: "/";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }

.downloadsList .table .detailContainer:nth-child(odd) {
  background-color: #f6f8f8; }

.count-filter-seperation {
  margin: 0 5px;
  word-break: break-word; }
  @media (max-width: 575.98px) {
    .count-filter-seperation {
      white-space: pre-line; } }

.product-PLP .product .product-tile .tile-body {
  padding: 11px 0; }
  @media (min-width: 1200px) and (max-width: 1639.98px) {
    .product-PLP .product .product-tile .tile-body {
      padding-top: 19px;
      padding-bottom: 10px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .product-PLP .product .product-tile .tile-body {
      padding: 13px 0 10px; } }
  .product-PLP .product .product-tile .tile-body .pdp-link .link {
    margin-bottom: 11px;
    font-size: 16px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .product-PLP .product .product-tile .tile-body .pdp-link .link {
        margin-bottom: 10px; } }
    .product-PLP .product .product-tile .tile-body .pdp-link .link sup {
      font-size: .45em;
      top: -8px; }
    .product-PLP .product .product-tile .tile-body .pdp-link .link span {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: .65px;
      text-transform: uppercase;
      margin-left: 5px; }

.product-PLP .product .product-tile .tile-footer {
  margin-bottom: 0;
  display: none; }
  .product-PLP .product .product-tile .tile-footer .pdp-link-btn {
    width: 32px;
    height: 32px; }
    .product-PLP .product .product-tile .tile-footer .pdp-link-btn img {
      width: 17px; }

.product-listing .product-grid .product-PLP .large-plp-image-tile .product, .product-listing .product-grid .product-PLP .small-plp-image-tile .product {
  padding-bottom: 20px; }
  .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text {
    padding-top: 0;
    padding-bottom: 0; }
    .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text img, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text img {
      object-fit: cover; }
      @media (min-width: 576px) {
        .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text img, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text img {
          height: 190px; } }
      @media (min-width: 768px) {
        .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text img, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text img {
          height: 188px; } }
      @media (min-width: 992px) {
        .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text img, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text img {
          height: 228px; } }
      @media (min-width: 1200px) {
        .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text img, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text img {
          height: 282px; } }
      @media (min-width: 1640px) {
        .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text img, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text img {
          height: 320px; } }

.product-listing .product-grid .product-PLP .applied-filters .applied-filter-list {
  margin-bottom: 71px; }
  @media (max-width: 991.98px) {
    .product-listing .product-grid .product-PLP .applied-filters .applied-filter-list {
      margin-bottom: 49px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .product-listing .product-grid .product-PLP .pl-heading .h1 {
    font-size: 60px;
    line-height: 82px;
    letter-spacing: 0.6px; } }

.filter-bar {
  padding: 5px 0px;
  background-color: #ffffff;
  width: 100%; }
  @media (max-width: 1639.98px) {
    .filter-bar {
      top: -47px;
      padding: 10px 0; } }
  @media (max-width: 1199.98px) {
    .filter-bar {
      padding: 6px 0; } }
  .filter-bar.filter-shadow {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07); }
  .filter-bar.filter-sticky {
    position: fixed;
    padding: 15px 0px 5px;
    width: 100%;
    background: #ffffff;
    top: 60px;
    z-index: 10;
    right: 0; }
    @media (max-width: 1639.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 40px 5px; } }
    @media (max-width: 991.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 30px 5px; } }
    @media (max-width: 767.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 25px 5px; } }
    @media (max-width: 575.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 20px 5px; } }
    .filter-bar.filter-sticky .search-field {
      top: 23px; }
      @media (max-width: 575.98px) {
        .filter-bar.filter-sticky .search-field {
          margin-top: 0; } }
      @media (max-width: 767.98px) {
        .filter-bar.filter-sticky .search-field {
          top: 23px; } }
  .filter-bar .filters {
    flex-wrap: nowrap;
    max-width: 1540px;
    margin: auto; }
    @media (max-width: 767.98px) {
      .filter-bar .filters {
        flex-wrap: wrap; } }
    .filter-bar .filters .button-filter {
      position: relative;
      z-index: 3;
      margin-bottom: 10px;
      margin-right: 10px; }
      .filter-bar .filters .button-filter:hover {
        background-color: #956839;
        color: #ffffff; }
    .filter-bar .filters .show-filter-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #ffffff;
      padding: 76px 0px 50px;
      z-index: 2;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07); }
      @media (max-width: 991.98px) {
        .filter-bar .filters .show-filter-bar {
          padding: 60px 0 40px; } }
      @media (max-width: 767.98px) {
        .filter-bar .filters .show-filter-bar {
          padding: 50px 0 20px; } }
      .filter-bar .filters .show-filter-bar .nav-pills .nav-link {
        color: #000000;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        padding: 0;
        text-align: left; }
        @media (min-width: 768px) {
          .filter-bar .filters .show-filter-bar .nav-pills .nav-link {
            margin-inline-end: auto; } }
        .filter-bar .filters .show-filter-bar .nav-pills .nav-link:hover {
          color: #956839; }
        .filter-bar .filters .show-filter-bar .nav-pills .nav-link.active {
          background-color: #ffffff;
          color: #956839; }
      .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
        display: inline-grid;
        grid-auto-flow: column;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
        overflow: auto;
        column-gap: 40px; }
        @media (max-width: 575.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            display: block; } }
        @media (min-width: 576px) and (max-width: 767.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            display: block;
            column-count: 2; } }
        @media (max-width: 991.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            column-gap: 20px; } }
        @media (max-width: 767.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            column-gap: 10px; } }
        .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item,
        .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item {
          width: 100%; }
          @media (min-width: 992px) {
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item,
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item {
              width: 50%; } }
          @media (min-width: 992px) {
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item,
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item {
              width: 20%; } }
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link,
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link {
            font-size: 13px;
            line-height: 26px;
            color: #000000;
            border: none;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            white-space: nowrap;
            margin-left: 15px; }
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link span,
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link span {
              white-space: normal;
              text-align: left; }
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link span:hover,
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link span:hover {
                color: #956839; }
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link span.selected,
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link span.selected {
                color: #956839; }
        @media (min-width: 992px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item {
            width: auto; } }
        .filter-bar .filters .show-filter-bar .tab-content .tab-pane:not(.active) {
          height: 0;
          display: none; }
      .filter-bar .filters .show-filter-bar .tab-content .custom-filter-item,
      .filter-bar .filters .show-filter-bar .tab-content .filter-item {
        position: relative; }
        .filter-bar .filters .show-filter-bar .tab-content .custom-filter-item img,
        .filter-bar .filters .show-filter-bar .tab-content .filter-item img {
          position: absolute;
          left: 0;
          height: 8px;
          top: 8px; }
      .filter-bar .filters .show-filter-bar .nav button {
        position: relative; }
        .filter-bar .filters .show-filter-bar .nav button.unchecked {
          color: #000000; }
          .filter-bar .filters .show-filter-bar .nav button.unchecked::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            height: 19px;
            width: 19px;
            border: 1px solid #000000;
            border-radius: 50px;
            transform: translate(0, -50%); }
          .filter-bar .filters .show-filter-bar .nav button.unchecked.nav-link {
            padding-left: 31px; }
        .filter-bar .filters .show-filter-bar .nav button.checked {
          color: #956839; }
          .filter-bar .filters .show-filter-bar .nav button.checked::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            height: 19px;
            width: 19px;
            border: none;
            background-image: url(../images/products/checked-icon.svg);
            transform: translate(0, -50%); }
          .filter-bar .filters .show-filter-bar .nav button.checked.nav-link {
            padding-left: 31px; }
      .filter-bar .filters .show-filter-bar .nav .custom-filters {
        margin-top: 10px; }
        .filter-bar .filters .show-filter-bar .nav .custom-filters .filter-value {
          margin-top: 8px; }
          .filter-bar .filters .show-filter-bar .nav .custom-filters .filter-value:first-child {
            margin-top: 0; }
      .filter-bar .filters .show-filter-bar .col-6 {
        padding: 0;
        gap: 10px 0; }
  @media (max-width: 575.98px) {
    .filter-bar {
      order: 1; } }

.sticky-filter.applied-filter-sticky {
  position: fixed;
  right: 0;
  top: 118px;
  background: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
  padding: 5px 0px 5px;
  width: 100%;
  z-index: 9; }
  .sticky-filter.applied-filter-sticky .applied-filters .applied-filter-list {
    margin-bottom: 5px; }

.applied-filters {
  width: 100%;
  display: flex;
  margin: auto;
  position: relative;
  z-index: 1;
  gap: 0 30px; }
  @media (max-width: 575.98px) {
    .applied-filters {
      gap: 0 10px; } }
  @media screen and (max-width: 360px) {
    .applied-filters {
      flex-wrap: wrap;
      justify-content: space-between; } }
  .applied-filters p {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    align-self: flex-start;
    letter-spacing: 0.65px; }
    @media (max-width: 575.98px) {
      .applied-filters p {
        font-size: 12px;
        word-break: break-word;
        max-width: 220px; } }
  .applied-filters .applied-filter-list {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 11px 30px;
    flex-wrap: wrap;
    max-width: calc(100% - 46px);
    overflow-x: auto;
    cursor: auto;
    margin-bottom: 30px;
    margin-left: 4px;
    width: 80%; }
    @media (min-width: 1640px) {
      .applied-filters .applied-filter-list {
        margin-left: 8px; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .applied-filters .applied-filter-list {
        width: 74%; } }
    @media (max-width: 575.98px) {
      .applied-filters .applied-filter-list {
        width: 99%;
        gap: 10px 20px;
        max-width: 100%; } }
    @media screen and (max-width: 360px) {
      .applied-filters .applied-filter-list {
        width: 100%; } }
    @media (min-width: 768px) {
      .applied-filters .applied-filter-list {
        width: 80%; } }
    @media (min-width: 1200px) {
      .applied-filters .applied-filter-list {
        width: 85%; } }
    @media (max-width: 767.98px) {
      .applied-filters .applied-filter-list {
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .applied-filters .applied-filter-list {
        gap: 10px 20px;
        max-width: 100%; } }
    .applied-filters .applied-filter-list::-webkit-scrollbar {
      width: 0;
      height: 0; }
    .applied-filters .applied-filter-list .filter-value,
    .applied-filters .applied-filter-list .image-video {
      float: inherit;
      display: flex;
      gap: 0 7px;
      white-space: pre-wrap; }
      .applied-filters .applied-filter-list .filter-value button,
      .applied-filters .applied-filter-list .image-video button {
        margin: 0;
        display: flex; }
      .applied-filters .applied-filter-list .filter-value span,
      .applied-filters .applied-filter-list .image-video span {
        color: #956839;
        font-size: 13px; }
    .applied-filters .applied-filter-list .custom-filter-reset,
    .applied-filters .applied-filter-list .custom-filter-clearfilter {
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 0 5px;
      white-space: nowrap; }
      .applied-filters .applied-filter-list .custom-filter-reset span,
      .applied-filters .applied-filter-list .custom-filter-clearfilter span {
        color: #ce470d;
        text-transform: capitalize; }
  @media (max-width: 575.98px) {
    .applied-filters {
      order: 3; } }

.filter-library {
  margin: 30px 0 40px 0; }
  .filter-library .filter-image-list {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 20px; }
    @media (max-width: 1199.98px) {
      .filter-library .filter-image-list {
        margin-bottom: 40px;
        gap: 67px; } }
    @media (max-width: 991.98px) {
      .filter-library .filter-image-list {
        gap: 20px; } }
    @media (max-width: 767.98px) {
      .filter-library .filter-image-list {
        display: block;
        margin-bottom: 20px; } }
    .filter-library .filter-image-list ul {
      display: grid;
      gap: 20px;
      grid-template-columns: auto auto auto auto auto auto auto auto auto auto; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list ul {
          display: flex;
          flex-wrap: wrap; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list ul {
          gap: 16px 20px; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list ul {
          overflow-x: auto;
          flex-wrap: nowrap;
          -ms-overflow-style: none;
          scrollbar-width: none;
          gap: 10px; } }
      .filter-library .filter-image-list ul::-webkit-scrollbar {
        display: none; }
    .filter-library .filter-image-list .filter-text {
      flex: 0 0 7.1333%; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list .filter-text {
          flex: 0 0 6.7111%; } }
      @media (max-width: 1199.98px) {
        .filter-library .filter-image-list .filter-text {
          flex: 0 0 9.66%; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list .filter-text {
          flex: 0 0 14.4%; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list .filter-text {
          margin-bottom: 10px; } }
    .filter-library .filter-image-list .product-details {
      display: block;
      width: 110px;
      overflow-wrap: break-word;
      background: none;
      border: none;
      text-align: left;
      line-height: 18px; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list .product-details {
          width: 84px; } }
      @media (max-width: 1199.98px) {
        .filter-library .filter-image-list .product-details {
          width: 137px; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list .product-details {
          width: 101px; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list .product-details {
          width: 162px; } }
      @media (max-width: 575.98px) {
        .filter-library .filter-image-list .product-details {
          width: 98px; } }
      .filter-library .filter-image-list .product-details:hover span {
        color: #956839; }
      .filter-library .filter-image-list .product-details.active-filter .product-filter-name {
        color: #956839; }
    .filter-library .filter-image-list .product-filter-name {
      font-size: 13px;
      line-height: 18px; }
    .filter-library .filter-image-list .narrow-image {
      height: 100%;
      width: 100%;
      max-height: 38px;
      margin-bottom: 5px; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 30px; } }
      @media (max-width: 1199.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 47px; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 35px; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 56px; } }
      @media (max-width: 575.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 34px; } }
    .filter-library .filter-image-list p {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.65px;
      line-height: 18px;
      text-transform: uppercase;
      white-space: break-spaces; }
      @media (min-width: 1200px) and (max-width: 1639.98px) {
        .filter-library .filter-image-list p {
          letter-spacing: 0px;
          font-size: 12px; } }

.common-listing-header .search-products .search-product {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600; }

.common-listing-header .pl-heading {
  margin: 20px 0; }

.spacer-line {
  background-color: #000000;
  margin: 0;
  border: 0;
  height: 1px;
  position: relative;
  z-index: -1; }

.square-product-grid .product .image-container {
  margin: 0 0 10px; }
  .square-product-grid .product .image-container img {
    width: 100%; }

.square-product-grid .product .title-container {
  padding: 0;
  margin-bottom: 40px; }
  .square-product-grid .product .title-container .is-Title {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    color: #000000; }
  .square-product-grid .product .title-container .price-config {
    margin: 10px 0 0;
    display: flex;
    flex-direction: column; }
    .square-product-grid .product .title-container .price-config span {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.65px;
      font-weight: 600;
      color: #000000; }

.material-list .material-list-item .material-list-item-title {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000; }
  .material-list .material-list-item .material-list-item-title h4, .material-list .material-list-item .material-list-item-title .h4 {
    font-size: 25px;
    padding: 20px 0;
    line-height: 22px;
    font-weight: 600; }

.material-list .material-list-item .material-list-item-subtitle h6, .material-list .material-list-item .material-list-item-subtitle .h6 {
  font-size: 14px;
  padding: 20px 0 13px;
  font-weight: 600;
  line-height: 22px; }

.material-list .material-list-item .material-list-items .mli-title {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.65px; }

.material-list .material-list-item .material-list-items .product .product-tile .tile-body {
  display: flex;
  flex-direction: column; }
  .material-list .material-list-item .material-list-items .product .product-tile .tile-body span {
    font-size: 18px;
    line-height: 22px; }

.material-list .material-list-item:last-child .spacer-line {
  display: none; }

.filters .product-search {
  margin-left: 10px;
  z-index: 2; }
  .filters .product-search .suggestions-wrapper {
    position: absolute;
    top: 65px;
    z-index: 1;
    display: none !important; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .filters .product-search .suggestions-wrapper {
        left: 100px; } }
    .filters .product-search .suggestions-wrapper .suggestions {
      padding-top: 10px; }
      @media (max-width: 991.98px) {
        .filters .product-search .suggestions-wrapper .suggestions {
          padding: 10px;
          border: 0; } }
  @media screen and (max-width: 360px) {
    .filters .product-search {
      width: 100%; } }

.filters .search-products .search-product {
  display: flex; }
  .filters .search-products .search-product img {
    margin-right: 10px; }

.filters .search-products span {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 28px; }

.filters .search-field {
  position: absolute;
  left: auto;
  border: none;
  padding: 0;
  top: 13px;
  text-transform: uppercase;
  transform: translate(0px, -36%);
  line-height: 43px;
  font-weight: 600;
  font-size: 13px;
  padding: 0px 20px 0 0;
  margin-left: 27px;
  width: 200px; }
  @media (max-width: 1639.98px) {
    .filters .search-field {
      top: 18px; } }
  @media (max-width: 1199.98px) {
    .filters .search-field {
      top: 14px; } }
  @media (max-width: 991.98px) {
    .filters .search-field {
      width: auto; } }
  @media (max-width: 575.98px) {
    .filters .search-field {
      top: auto;
      margin-top: -20px;
      width: auto; } }
  .filters .search-field:focus {
    box-shadow: none; }

.filters .search-material-lib {
  width: 250px; }
  @media (max-width: 991.98px) {
    .filters .search-material-lib {
      width: 250px; } }
  @media only screen and (max-width: 312px) {
    .filters .search-material-lib {
      margin-top: -35px;
      height: 50px; } }

.filters .search-field-1 {
  position: absolute;
  z-index: -1;
  display: none;
  border: 0;
  width: auto; }
  .filters .search-field-1:focus {
    box-shadow: none; }

.filters .site-search button {
  display: none; }

.load-more-div .load-more {
  margin-bottom: 20px; }
  @media (min-width: 576px) {
    .load-more-div .load-more {
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .load-more-div .load-more {
      margin-bottom: 29px; } }
  @media (min-width: 992px) {
    .load-more-div .load-more {
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .load-more-div .load-more {
      margin-bottom: 40px; } }
  @media (min-width: 1640px) {
    .load-more-div .load-more {
      margin-bottom: 50px; } }
  .load-more-div .load-more .plp-result-count-text {
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0px;
    font-weight: normal; }
  .load-more-div .load-more .more {
    margin-top: 20px; }

.material-grid-header .applied-filters .applied-filter-list {
  margin-left: 0; }

.menu-toggleable-left.navbar-toggleable-xs {
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  display: block;
  max-width: 100%; }
  .menu-toggleable-left.navbar-toggleable-xs.in {
    min-width: 50%;
    left: 0; }

@media (max-width: 575.98px) {
  .menu-toggleable-left.navbar-toggleable-sm {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-sm.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 767.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-md.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 991.98px) {
  .menu-toggleable-left.navbar-toggleable-lg {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-lg.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 1199.98px) {
  .menu-toggleable-left.navbar-toggleable-xl {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-xl.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 1639.98px) {
  .menu-toggleable-left.navbar-toggleable-xxl {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-xxl.in {
      min-width: 50%;
      left: 0; } }

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0; }
  @media (min-width: 768px) {
    .navbar.bg-inverse .navbar-nav .nav-item + .nav-item {
      margin-left: 0; }
    .navbar.bg-inverse .navbar-nav .nav-link {
      padding: 0.8rem;
      white-space: nowrap; } }

.navbar-expand-md .navbar-nav.nav-center {
  justify-content: center; }

.navbar-expand-md .navbar-nav.nav-spaced {
  justify-content: space-evenly; }

.navbar-expand-md .navbar-nav.nav-right {
  justify-content: end; }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: #ffffff;
  color: #444; }

@media (min-width: 768px) {
  .nav-item > .nav-link {
    color: #ffffff; } }

@media (max-width: 575.98px) {
  .main-menu.menu-toggleable-left {
    background-color: #ffffff;
    z-index: 4; } }

.menu-toggleable-left .close-menu {
  padding: 15px;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  flex: 0 0 100%; }
  @media (min-width: 768px) {
    .menu-toggleable-left .close-menu {
      display: none; } }

.menu-toggleable-left .menu-group {
  flex: 0 0 100%; }

.menu-toggleable-left li > .close-menu {
  margin-right: 0;
  margin-top: -0.6rem;
  margin-left: 0; }

@media (max-width: 575.98px) {
  .menu-toggleable-left .bg-inverse {
    background-color: white !important;
    color: #222; } }

.menu-toggleable-left.in {
  right: 0;
  margin-right: 1.25em;
  /* 20/16 */ }
  @media (max-width: 575.98px) {
    .menu-toggleable-left.in .nav-item + .nav-item {
      border-top: 1px solid #eee; }
    .menu-toggleable-left.in .dropdown {
      display: block;
      position: static; }
    .menu-toggleable-left.in .dropdown-toggle {
      padding-left: 1rem; }
      .menu-toggleable-left.in .dropdown-toggle::after {
        border-top: 0.3em solid transparent;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
        position: absolute;
        right: 0.3em;
        margin-top: 0.55em; }
    .menu-toggleable-left.in .nav-item .nav-link {
      padding-left: 1rem; }
    .menu-toggleable-left.in .show > .dropdown-menu {
      left: 0; }
    .menu-toggleable-left.in .dropdown-menu {
      position: absolute;
      left: -100%;
      top: 0;
      width: 100%;
      height: 100%;
      border: 0 none;
      transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
      display: block; } }

.multilevel-dropdown .dropdown-menu {
  top: 90%;
  border: 0;
  border-radius: 0; }
  @media (min-width: 768px) {
    .multilevel-dropdown .dropdown-menu {
      box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4); } }

.multilevel-dropdown .dropdown-item.dropdown > .dropdown-toggle::after {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  right: 0.3em;
  margin-top: 0.55em; }

@media (min-width: 768px) {
  .multilevel-dropdown .dropdown-menu > .dropdown > .dropdown-menu {
    top: -0.65em;
    left: 99%; } }

.multilevel-dropdown .navbar > .close-menu > .back {
  display: none; }

.multilevel-dropdown .close-menu .back .caret-left {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-left: 0.3 solid transparent;
  width: 0;
  height: 0;
  display: inline-block;
  margin-bottom: 0.125em; }

.multilevel-dropdown .dropdown-item {
  padding: 0 0 0 1em; }
  .multilevel-dropdown .dropdown-item .dropdown-link {
    display: block;
    padding: 0.425em 5em 0.425em 0; }
  .multilevel-dropdown .dropdown-item + .dropdown-item {
    border-top: 1px solid #eee; }
  .multilevel-dropdown .dropdown-item.top-category {
    font-weight: bold; }
    .multilevel-dropdown .dropdown-item.top-category > .nav-link {
      padding-left: 0; }

.header {
  position: relative; }

.navbar-header {
  height: 4.375em;
  /* 70/16 */ }
  .navbar-header .user,
  .navbar-header .country-selector,
  .navbar-header .search,
  .navbar-header .minicart {
    display: inline-block;
    margin: 1.125em 0 0 0.5em; }
  .navbar-header .user,
  .navbar-header .country-selector,
  .navbar-header .minicart,
  .navbar-header .navbar-toggler {
    line-height: 2.25em;
    /* 36/16 */
    height: auto; }
  .navbar-header .navbar-toggler {
    font-size: 1.6em;
    width: auto; }
  .navbar-header .user {
    position: relative; }
    .navbar-header .user .popover {
      position: absolute;
      display: none;
      padding: 1em;
      top: 85%;
      left: 0; }
      .navbar-header .user .popover a {
        white-space: nowrap;
        margin-bottom: 0.5em; }
      .navbar-header .user .popover::before {
        left: 1.5rem; }
      .navbar-header .user .popover::after {
        left: 1.5rem; }
      .navbar-header .user .popover.show {
        display: block; }

.brand {
  position: absolute;
  left: 50%;
  display: block;
  text-align: center; }
  .brand img {
    width: 100%; }
  @media (min-width: 992px) {
    .brand {
      width: 14.125em;
      /* 226/16 */
      margin-left: -7.0625em;
      /* 113/16 */
      padding-top: 0.5em; } }
  @media (max-width: 767.98px) {
    .brand {
      width: 4em;
      /* 64/16 */
      margin-left: -2em;
      padding-top: 0.8em; } }

.main-menu {
  background-color: #444; }
  .main-menu .navbar .close-menu button,
  .main-menu .navbar .close-button button {
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }

.header-banner {
  background-color: #222;
  text-align: center;
  color: #ffffff; }
  .header-banner .close-button {
    width: 2.125em; }
    .header-banner .close-button .close {
      opacity: 1;
      color: #ffffff;
      width: 100%;
      height: 100%;
      background-color: var(--skin-primary-color-1); }
  .header-banner .content {
    margin-right: 1.5em;
    padding-top: 0.3125em;
    padding-bottom: 0.3125em; }

.minicart {
  margin-top: 0.1875em;
  /* 3/16 */
  vertical-align: top; }
  .minicart .minicart-icon {
    font-size: 1.5em; }
  .minicart a.minicart-link:hover {
    text-decoration: none; }
  .minicart .minicart-quantity {
    background-color: var(--skin-primary-color-1);
    border-radius: 50%;
    width: 1.25em;
    /* 20/16 */
    height: 1.25em;
    /* 20/16 */
    line-height: normal;
    display: inline-block;
    text-align: center;
    font-size: 0.8125em;
    /* 13/16 */
    position: relative;
    top: -0.9375em;
    /* 15/16 */
    left: -0.9375em;
    /* 15/16 */
    color: #ffffff; }

a.normal {
  color: var(--skin-primary-color-1);
  text-decoration: underline; }

.slide-up {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden; }
  .slide-up.hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.dropdown-country-selector {
  margin-top: -0.0625em; }

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none; }

.valid-cookie-warning {
  background-color: var(--skin-link-color-1);
  color: #ffffff;
  white-space: nowrap; }
  .valid-cookie-warning p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em; }

header {
  position: relative; }
  @media (max-width: 991.98px) {
    header {
      padding: 15px 0; } }
  @media (min-width: 992px) {
    header {
      z-index: 10; } }
  header:not(.stickyHeader) .navbar-header .pull-left > .container, header:not(.stickyHeader) .navbar-header .sticky-filter.applied-filter-sticky .pull-left > .applied-filters, .sticky-filter.applied-filter-sticky header:not(.stickyHeader) .navbar-header .pull-left > .applied-filters {
    padding: 0; }
  header .top-header {
    padding: 15px 0;
    position: relative; }
    @media (max-width: 991.98px) {
      header .top-header {
        padding: 10px 0; } }
    header .top-header .navbar-header {
      position: relative;
      overflow: hidden; }
      header .top-header .navbar-header .pull-left .search {
        display: none;
        position: fixed;
        top: 0;
        transform: none;
        left: auto;
        z-index: 10;
        margin-left: 20px;
        width: 100%;
        padding: 6px 0;
        margin-top: 0; }
        header .top-header .navbar-header .pull-left .search .site-search .form-control {
          border-radius: 0;
          border: none;
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 0.65px;
          font-weight: 600;
          padding: 20px 16px 16px 16px;
          color: #000000; }
          @media (min-width: 992px) {
            header .top-header .navbar-header .pull-left .search .site-search .form-control {
              background-color: transparent;
              line-height: 13px;
              padding: 10px 16px; } }
          header .top-header .navbar-header .pull-left .search .site-search .form-control.search-field-1 {
            position: absolute;
            top: 14px;
            left: 0;
            color: rgba(0, 0, 0, 0.45);
            z-index: -1;
            text-align: left; }
            @media (max-width: 991.98px) {
              header .top-header .navbar-header .pull-left .search .site-search .form-control.search-field-1 {
                top: 0; } }
      header .top-header .navbar-header .pull-right .user a {
        text-decoration: none; }
      header .top-header .navbar-header .pull-right .minicart .hide-no-link {
        display: none; }
      header .top-header .navbar-header .pull-right .minicart .minicart-quantity {
        background-color: #956839;
        color: #ffffff; }
      header .top-header .navbar-header .pull-right .minicart .popover {
        border-color: transparent; }
  header .top-header-icons {
    margin: 0; }
    header .top-header-icons li:not(:last-child) {
      margin-right: 20px; }
      @media (max-width: 991.98px) {
        header .top-header-icons li:not(:last-child) {
          margin-right: 30px; } }
    header .top-header-icons li a {
      display: flex;
      cursor: pointer; }
    @media (max-width: 991.98px) {
      header .top-header-icons li .search-icon img {
        width: 23px; } }
    @media (max-width: 991.98px) {
      header .top-header-icons li .profile-icon img {
        width: 21px; } }
    @media (max-width: 991.98px) {
      header .top-header-icons li .chat-icon img {
        width: 24px; } }
    header .top-header-icons li.active {
      z-index: 4; }
  header .mobile-category-bar {
    width: 100%; }
  header .top-header-nav-right a {
    font-size: 13px;
    text-transform: uppercase;
    color: #000000; }
    @media (max-width: 991.98px) {
      header .top-header-nav-right a {
        font-size: 20px;
        text-transform: capitalize;
        font-family: "Plus Jakarta Sans";
        font-weight: 300; } }
    header .top-header-nav-right a img {
      margin: 4px 0 0 14px; }
  header .acount-bar {
    margin: 0;
    background-color: #ffffff;
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 250px);
    margin: 0;
    z-index: 1;
    padding: 8px 7px;
    display: none; }
    @media (max-width: 991.98px) {
      header .acount-bar {
        left: 0;
        top: 100%;
        transform: inherit;
        width: 100%;
        padding: 0; } }
    header .acount-bar .user {
      margin: 0;
      display: flex;
      line-height: initial; }
      header .acount-bar .user:not(:last-child) {
        margin-right: 15px; }
      header .acount-bar .user a {
        display: flex;
        color: #000000;
        font-size: 13px;
        font-family: "Plus Jakarta Sans";
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.65px; }
        @media (max-width: 991.98px) {
          header .acount-bar .user a {
            width: 100%;
            font-size: 23px;
            padding: 16px 0;
            border-top: 1px solid #000000;
            border-bottom: 1px solid #000000;
            text-transform: capitalize;
            font-weight: 300; } }
        header .acount-bar .user a i {
          display: none; }
  header .search {
    position: absolute;
    left: 20px;
    top: -3px;
    width: calc(100% - 250px);
    background-color: #ffffff;
    z-index: 2; }
    @media (max-width: 991.98px) {
      header .search {
        width: 100%;
        left: 25px;
        top: 16px; } }
    header .search .form-control {
      border: none;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.65px;
      font-weight: 600;
      padding: 20px 16px 16px 16px;
      color: #000000; }
      @media (max-width: 991.98px) {
        header .search .form-control {
          padding: 5px 16px 5px 16px; } }
      header .search .form-control::placeholder {
        /* Most modern browsers support this now. */
        color: #959595; }
      header .search .form-control:focus {
        box-shadow: none; }
    header .search button[type="submit"] {
      visibility: hidden;
      display: none; }
  header .header-spacer {
    margin: 1px 0 0;
    opacity: 1;
    background-color: #000000;
    height: 1px; }
  header .main-header .navbar-toggler {
    margin-right: auto; }
  header .main-header .brand {
    position: initial;
    padding: 0;
    margin: 0; }
    @media (max-width: 991.98px) {
      header .main-header .brand {
        width: auto;
        margin-right: auto; } }
    header .main-header .brand img {
      width: auto; }
      header .main-header .brand img.d-lg-none {
        max-height: 30px; }
  header .main-header .main-menu {
    background-color: #ffffff;
    z-index: 1; }
    @media (min-width: 992px) {
      header .main-header .main-menu {
        background-color: transparent; } }
    header .main-header .main-menu.menu-toggleable-left .close-menu {
      position: fixed;
      top: 0;
      left: -100%;
      width: 60px;
      height: 60px;
      background-color: #E0E7E7;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px; }
    header .main-header .main-menu.menu-toggleable-left.in .close-menu {
      transition: 0.4s; }
    header .main-header .main-menu.menu-toggleable-left.in .scroll-arrow {
      opacity: 1; }
    header .main-header .main-menu .navbar {
      position: static; }
      @media (max-width: 991.98px) {
        header .main-header .main-menu .navbar {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 20px;
          height: 100%; } }
      header .main-header .main-menu .navbar .mobile-top-header {
        padding: 16px 0;
        border-bottom: 1px solid #000000;
        width: 100%;
        position: relative; }
        header .main-header .main-menu .navbar .mobile-top-header .search {
          display: none; }
        header .main-header .main-menu .navbar .mobile-top-header .acount-bar {
          display: none; }
      header .main-header .main-menu .navbar .menu-group {
        position: static; }
        @media (max-width: 991.98px) {
          header .main-header .main-menu .navbar .menu-group {
            width: 100%;
            padding: 0px;
            flex: auto;
            position: relative;
            overflow-y: auto;
            height: calc(100vh - 150px); } }
        header .main-header .main-menu .navbar .menu-group .nav .nav-item {
          position: static; }
          @media (max-width: 991.98px) {
            header .main-header .main-menu .navbar .menu-group .nav .nav-item {
              border-bottom: 1px solid #000000; } }
          header .main-header .main-menu .navbar .menu-group .nav .nav-item:focus {
            outline: none;
            background-color: transparent; }
          header .main-header .main-menu .navbar .menu-group .nav .nav-item .nav-link {
            color: #000000;
            background-color: transparent;
            padding: 30px 15px;
            font-size: 20px;
            line-height: 20px; }
            @media (max-width: 991.98px) {
              header .main-header .main-menu .navbar .menu-group .nav .nav-item .nav-link {
                padding: 16px 0; } }
          header .main-header .main-menu .navbar .menu-group .nav .nav-item:last-child .nav-link {
            padding-right: 0; }
          @media (max-width: 991.98px) {
            header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown .dropdown-menu {
              left: inherit;
              right: -100%; } }
          header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown .dropdown-menu .nav-menu {
            display: flex;
            width: 18px;
            margin-right: 11px; }
            header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown .dropdown-menu .nav-menu .close-menu {
              position: initial;
              background-color: transparent;
              border: none;
              width: auto;
              height: auto; }
          header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu {
            display: flex;
            flex-direction: column;
            /*--new change--*/ }
            @media (max-width: 991.98px) {
              header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu {
                position: absolute;
                flex-direction: row;
                flex-wrap: wrap;
                opacity: 1;
                transition: 0.4s;
                right: 0;
                z-index: 1;
                top: 0;
                height: 100%; } }
            @media (max-width: 991.98px) {
              header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu {
                left: 0;
                flex-direction: column; } }
            @media (max-width: 991.98px) {
              header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu .top-category {
                padding: 0;
                background: transparent; } }
            @media (max-width: 991.98px) {
              header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu .cat-nav {
                display: none; } }
            @media (max-width: 991.98px) {
              header .main-header .main-menu .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu .cat-nav-items {
                flex-direction: column; } }
    header .main-header .main-menu > .container, header .main-header .sticky-filter.applied-filter-sticky .main-menu > .applied-filters, .sticky-filter.applied-filter-sticky header .main-header .main-menu > .applied-filters {
      padding: 0; }
  header .main-header .menu-toggleable-left.navbar-toggleable-lg.in {
    left: 110px;
    margin-right: 0;
    left: 60px; }
    @media (max-width: 575.98px) {
      header .main-header .menu-toggleable-left.navbar-toggleable-lg.in {
        left: 74px; } }

.dropdown-item:hover {
  background-color: transparent; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

header {
  background-color: #e0e7e7;
  position: relative;
  z-index: 13; }
  @media (min-width: 992px) {
    header {
      z-index: 10;
      margin-top: 60px; } }
  @media (max-width: 991.98px) {
    header {
      position: fixed;
      width: 100%;
      top: 0; } }
  header:not(.stickyHeader) .navbar-header .pull-left > .container, header:not(.stickyHeader) .navbar-header .sticky-filter.applied-filter-sticky .pull-left > .applied-filters, .sticky-filter.applied-filter-sticky header:not(.stickyHeader) .navbar-header .pull-left > .applied-filters {
    padding: 0; }
  @media (min-width: 992px) {
    header .header-spacer {
      position: fixed;
      top: 59px;
      z-index: 1;
      width: 100%; } }
  header .top-header {
    padding: 30px 0; }
    @media (min-width: 992px) {
      header .top-header {
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #e0e7e7;
        height: 60px;
        padding: 20px 0 0; } }
    @media (min-width: 992px) {
      header .top-header::after {
        position: fixed;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        z-index: 1;
        background-color: #e0e7e7;
        display: none; } }
    header .top-header .navbar-header {
      overflow: visible; }
      @media (min-width: 992px) {
        header .top-header .navbar-header .vertical-categories {
          margin: 0 auto;
          position: relative;
          z-index: 2; } }
      @media (min-width: 992px) {
        header .top-header .navbar-header .pull-right {
          position: relative;
          z-index: 2; } }
      header .top-header .navbar-header .header-icon-block .search {
        padding: 12px 0 11px;
        display: none; }
        @media (min-width: 992px) {
          header .top-header .navbar-header .header-icon-block .search {
            display: none;
            position: absolute;
            left: 30px;
            background-color: #e0e7e7;
            padding: 0;
            width: 320px;
            right: 0; }
            header .top-header .navbar-header .header-icon-block .search .search-field {
              padding: 18px 20px 10px 5px; } }
      @media (min-width: 768px) {
        header .top-header .navbar-header .header-icon-block .top-header-nav-left {
          max-width: 75%; } }
      @media (min-width: 1200px) {
        header .top-header .navbar-header .header-icon-block .top-header-nav-left {
          max-width: 80%; } }
      @media (min-width: 1640px) {
        header .top-header .navbar-header .header-icon-block .top-header-nav-left {
          max-width: 87%; } }
      header .top-header .navbar-header .header-icon-block .top-header-nav-left .searchBar {
        position: relative; }
    header .top-header .top-header-nav-right a {
      font-family: "Plus Jakarta Sans";
      font-weight: 600;
      letter-spacing: 0.65px;
      line-height: 16px; }
      @media (max-width: 991.98px) {
        header .top-header .top-header-nav-right a {
          font-size: 22px; } }
  @media (min-width: 992px) {
    header .top-header-icons {
      top: 21px;
      top: 18px;
      z-index: 2;
      position: static;
      justify-content: flex-end; } }
  header .top-header-icons li.product-configurator {
    margin-right: 50px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px; }
    @media (max-width: 991.98px) {
      header .top-header-icons li.product-configurator {
        display: none; } }
  @media (max-width: 991.98px) {
    header .top-header-icons li.searchBar {
      order: 1; } }
  header .top-header-icons li.profile-section {
    position: relative; }
    @media (max-width: 991.98px) {
      header .top-header-icons li.profile-section {
        order: 2; } }
    @media (max-width: 991.98px) {
      header .top-header-icons li.profile-section .acount-bar {
        display: none !important; } }
  @media (max-width: 991.98px) {
    header .vertical-categories {
      padding: 15px 0;
      overflow-x: auto;
      flex-wrap: nowrap;
      margin-left: 0; }
      header .vertical-categories .scroll-arrow {
        cursor: pointer; } }
  @media (max-width: 991.98px) {
    header .vertical-categories .scroll-arrow {
      display: none !important; } }
  @media (max-width: 500px) {
    header .vertical-categories .scroll-arrow {
      display: block !important; } }
  header .vertical-categories::-webkit-scrollbar {
    width: 0;
    height: 0; }
  header .vertical-categories li {
    display: flex;
    align-items: center;
    white-space: nowrap; }
    header .vertical-categories li a {
      font-size: 13px;
      text-transform: uppercase;
      font-family: "Plus Jakarta Sans";
      font-weight: 600;
      color: #000000;
      letter-spacing: 0.65px;
      padding: 6.5px 16px 7px;
      line-height: 16px; }
      @media (max-width: 991.98px) {
        header .vertical-categories li a {
          padding: 7px 15px; } }
      header .vertical-categories li a:hover, header .vertical-categories li a:focus {
        color: #000000; }
    @media (max-width: 991.98px) {
      header .vertical-categories li:first-child a {
        padding-left: 0; } }
    @media (max-width: 991.98px) {
      header .vertical-categories li:last-child a {
        padding-right: 40px; } }
  header .vertical-categories .scrollLeft {
    right: 21px; }
  header .scroll-arrow {
    position: fixed;
    top: 61px;
    right: 0;
    opacity: 0;
    max-width: 80px;
    width: 100%; }
  @media (max-width: 991.98px) {
    header .slide {
      margin-left: -80px; } }
  @media (max-width: 440px) {
    header .slide {
      margin-left: -120px; } }
  @media (max-width: 399px) {
    header .slide {
      margin-left: -170px; } }
  @media (max-width: 991.98px) {
    header .navScroll {
      margin-left: -130px; } }
  @media (min-width: 992px) {
    header .search {
      background-color: #e0e7e7;
      position: absolute;
      width: calc(100% - 142px);
      z-index: 3;
      top: -16px;
      left: 0;
      margin: 0 !important; } }
  @media (max-width: 991.98px) {
    header .search {
      width: calc(100% - 25px); } }
  header .search .site-search .form-control {
    border-radius: 0;
    background-color: transparent; }
    header .search .site-search .form-control.search-field-1 {
      position: absolute;
      top: 10px;
      left: 5px;
      color: rgba(0, 0, 0, 0.45);
      z-index: -1;
      text-align: left; }
      @media (max-width: 991.98px) {
        header .search .site-search .form-control.search-field-1 {
          top: 0;
          left: 0; } }
    @media (min-width: 992px) {
      header .search .site-search .form-control {
        background-color: transparent;
        line-height: 13px;
        padding: 10px 16px 10px 0; } }
  @media (min-width: 992px) {
    header .acount-bar {
      background-color: #e0e7e7;
      position: absolute;
      z-index: 0;
      top: 10px;
      left: 30px;
      padding-left: 0;
      width: 100px; } }
  @media (max-width: 767.98px) {
    header .acount-bar .user {
      overflow: auto; } }
  @media (min-width: 992px) {
    header .acount-bar .user {
      height: 43px;
      align-items: center; }
      header .acount-bar .user a:first-child {
        padding-left: 0 !important; } }
  @media (max-width: 767.98px) {
    header .acount-bar .user a {
      font-size: 18px; } }
  @media (min-width: 992px) {
    header .main-header {
      min-height: 80px; } }
  @media (max-width: 991.98px) {
    header .main-header {
      position: relative; } }
  header .main-header .brand {
    margin: 20px 0 0 0; }
  header .main-header .navbar-toggler {
    margin-right: 0; }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left.navbar-toggleable-lg {
      right: -100%;
      left: inherit;
      transition: 0.5s; } }
  header .main-header .menu-toggleable-left.navbar-toggleable-lg.in {
    left: inherit;
    transition: 0.5s; }
    @media (max-width: 991.98px) {
      header .main-header .menu-toggleable-left.navbar-toggleable-lg.in {
        left: inherit;
        width: calc(100% - 60px);
        right: 0;
        margin: 0; } }
    @media (max-width: 991.98px) {
      header .main-header .menu-toggleable-left.navbar-toggleable-lg.in .close-menu {
        width: 60px; } }
    header .main-header .menu-toggleable-left.navbar-toggleable-lg.in .close-menu .close-button {
      margin: auto; }
  @media (min-width: 992px) {
    header .main-header .main-menu {
      background-color: #e0e7e7;
      z-index: 0; } }
  header .main-header .toogle-button {
    position: absolute;
    left: 2%;
    width: 32px;
    height: 20px;
    top: 50%;
    transform: translate(0, -50%); }
    @media (max-width: 575.98px) {
      header .main-header .toogle-button {
        left: 4%; } }
    header .main-header .toogle-button .icon-bar-1, header .main-header .toogle-button .icon-bar-2, header .main-header .toogle-button .icon-bar-3 {
      border-top: 1px solid #000000;
      width: 100%;
      position: absolute;
      left: 0;
      transition: 0.5s; }
    header .main-header .toogle-button .icon-bar-1 {
      top: 0; }
    header .main-header .toogle-button .icon-bar-2 {
      top: 50%; }
    header .main-header .toogle-button .icon-bar-3 {
      bottom: 0; }
  header.hover-menu {
    background-color: #ffffff; }
    @media (max-width: 991.98px) {
      header.hover-menu {
        padding: 10px 0; } }
    header.hover-menu .top-header {
      background-color: #ffffff; }
      header.hover-menu .top-header::after {
        background-color: #ffffff; }
    header.hover-menu .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link {
      color: #767676; }
      @media (max-width: 991.98px) {
        header.hover-menu .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link {
          color: #000000; } }
    header.hover-menu .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.show .nav-link {
      color: #000000; }
    header.hover-menu .main-header .brand {
      margin: 10px 0 0 0; }
    header.hover-menu .main-header .main-menu {
      background-color: #ffffff; }
    @media (min-width: 992px) {
      header.hover-menu .search {
        background-color: #ffffff; } }
    @media (min-width: 992px) {
      header.hover-menu .search .site-search .form-control {
        background-color: #ffffff; } }
    @media (min-width: 992px) {
      header.hover-menu .acount-bar {
        background-color: #ffffff; } }
  @media (min-width: 992px) {
    header.stickyHeader .top-header::after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      z-index: 1;
      background-color: #e0e7e7; } }
  @media (min-width: 992px) {
    header.stickyHeader .top-header .navbar-header .pull-left {
      position: fixed;
      left: 0;
      z-index: 3;
      top: 18px;
      width: 100%; } }
  @media (min-width: 992px) {
    header.stickyHeader .top-header .navbar-header .acount-bar {
      position: absolute;
      width: auto;
      top: 0;
      left: 30px;
      transform: none;
      z-index: 1;
      margin-left: 0; }
      header.stickyHeader .top-header .navbar-header .acount-bar .user {
        height: 5px; } }
  @media (min-width: 992px) {
    header.stickyHeader .menu-toggleable-left.navbar-toggleable-lg {
      position: fixed;
      top: 60px;
      right: 0;
      transition: .3s ease-in-out;
      z-index: 1;
      width: 100%; } }
  header.stickyHeader .menu-toggleable-left.navbar-toggleable-lg .navbar .menu-group .nav {
    justify-content: end; }
    @media (min-width: 992px) {
      header.stickyHeader .menu-toggleable-left.navbar-toggleable-lg .navbar .menu-group .nav .nav-item .nav-link {
        padding: 18px 15px; } }
    header.stickyHeader .menu-toggleable-left.navbar-toggleable-lg .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show {
      position: fixed;
      top: 120px; }
    @media (min-width: 992px) {
      header.stickyHeader .menu-toggleable-left.navbar-toggleable-lg .navbar .menu-group .nav .nav-item.show .nav-link {
        padding: 18px 15px; } }
    header.stickyHeader .menu-toggleable-left.navbar-toggleable-lg .navbar .menu-group .nav .nav-item.show:last-child .nav-link {
      padding-right: 0; }
  header.stickyHeader .main-menu .container, header.stickyHeader .main-menu .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky header.stickyHeader .main-menu .applied-filters {
    padding: 0; }
    @media (min-width: 992px) {
      header.stickyHeader .main-menu .container, header.stickyHeader .main-menu .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky header.stickyHeader .main-menu .applied-filters {
        padding: 0 35px; } }
    @media (min-width: 1200px) {
      header.stickyHeader .main-menu .container, header.stickyHeader .main-menu .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky header.stickyHeader .main-menu .applied-filters {
        padding: 0 40px; } }
    @media (min-width: 1640px) {
      header.stickyHeader .main-menu .container, header.stickyHeader .main-menu .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky header.stickyHeader .main-menu .applied-filters {
        padding: 0 50px; } }
  header.stickyHeader.hover-menu {
    z-index: 15; }
    header.stickyHeader.hover-menu .navbar-toggleable-lg {
      background-color: #ffffff; }
  @media (min-width: 992px) {
    header.stickyHeader .header-spacer {
      z-index: 2; } }
  header nav .main-header .navbar-header .logo-home img {
    max-width: 100%; }

.fixed-position header .main-header .toogle-button .icon-bar-1 {
  top: 50%;
  transform: rotate(45deg); }

.fixed-position header .main-header .toogle-button .icon-bar-2 {
  display: none; }

.fixed-position header .main-header .toogle-button .icon-bar-3 {
  top: 50%;
  transform: rotate(-45deg);
  bottom: auto; }

@media (min-width: 992px) {
  header.headerWhite .main-header {
    background: #ffffff; }
    header.headerWhite .main-header .menu-toggleable-left .navbar .menu-group .nav > .nav-item > .nav-link {
      color: #949494; }
      header.headerWhite .main-header .menu-toggleable-left .navbar .menu-group .nav > .nav-item > .nav-link:hover {
        color: #000000; } }

.collapsible-xs .title {
  line-height: 2.5rem;
  /* 40/16 */ }
  .collapsible-xs .title::after {
    display: block;
    clear: both;
    content: ""; }
  .collapsible-xs .title::after {
    float: right;
    content: "\f078";
    font-family: "FontAwesome"; }

.collapsible-xs .content,
.collapsible-xs .card-body {
  display: none; }

.collapsible-xs.active .title::after {
  content: "\f077";
  margin-top: -0.125em;
  /* 2/16 */ }

.collapsible-xs.active .content,
.collapsible-xs.active .card-body {
  display: block; }

.container div.collapsible-xs button.title, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-xs button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-xs button.title:hover, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-xs button.title:hover {
    text-decoration: none; }

@media (max-width: 575.98px) {
  .collapsible-sm .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-sm .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-sm .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-sm .content,
  .collapsible-sm .card-body {
    display: none; }
  .collapsible-sm.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-sm.active .content,
  .collapsible-sm.active .card-body {
    display: block; } }

.container div.collapsible-sm button.title, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-sm button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-sm button.title:hover, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-sm button.title:hover {
    text-decoration: none; }

@media (max-width: 767.98px) {
  .collapsible-md .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-md .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-md .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-md .content,
  .collapsible-md .card-body {
    display: none; }
  .collapsible-md.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-md.active .content,
  .collapsible-md.active .card-body {
    display: block; } }

.container div.collapsible-md button.title, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-md button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-md button.title:hover, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-md button.title:hover {
    text-decoration: none; }

@media (max-width: 991.98px) {
  .collapsible-lg .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-lg .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-lg .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-lg .content,
  .collapsible-lg .card-body {
    display: none; }
  .collapsible-lg.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-lg.active .content,
  .collapsible-lg.active .card-body {
    display: block; } }

.container div.collapsible-lg button.title, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-lg button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-lg button.title:hover, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-lg button.title:hover {
    text-decoration: none; }

@media (max-width: 1199.98px) {
  .collapsible-xl .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-xl .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-xl .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-xl .content,
  .collapsible-xl .card-body {
    display: none; }
  .collapsible-xl.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-xl.active .content,
  .collapsible-xl.active .card-body {
    display: block; } }

.container div.collapsible-xl button.title, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-xl button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-xl button.title:hover, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-xl button.title:hover {
    text-decoration: none; }

@media (max-width: 1639.98px) {
  .collapsible-xxl .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-xxl .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-xxl .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-xxl .content,
  .collapsible-xxl .card-body {
    display: none; }
  .collapsible-xxl.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-xxl.active .content,
  .collapsible-xxl.active .card-body {
    display: block; } }

.container div.collapsible-xxl button.title, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-xxl button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-xxl button.title:hover, .sticky-filter.applied-filter-sticky .applied-filters div.collapsible-xxl button.title:hover {
    text-decoration: none; }

footer {
  background-color: #e9ecef;
  padding-top: 1.25em;
  /* 20/16 */
  padding-bottom: 1.25em; }
  footer h2, footer .h2 {
    font-size: 1rem;
    margin-bottom: 0;
    line-height: 2.5em;
    /* 40/16 */ }
  footer ul {
    list-style: none;
    padding-left: 0; }
  footer .social h2, footer .social .h2 {
    margin-top: 0; }
  footer .social::after {
    display: block;
    clear: both;
    content: ""; }
  footer .copyright,
  footer .social {
    margin-top: 1.25em; }
  footer .footer-container .footer-item.collapsible-xs button {
    font-family: 'Dosis', sans-serif;
    padding: 0; }
  footer .social-links {
    width: 80%;
    float: left; }
    footer .social-links::after {
      display: block;
      clear: both;
      content: ""; }
    footer .social-links li {
      float: left;
      margin: 0.313em;
      width: 20%;
      text-align: center; }
    footer .social-links a {
      font-size: 2.25em; }
      footer .social-links a:hover {
        text-decoration: none; }
  footer .store {
    border-bottom: 1px solid #444; }
    footer .store .content {
      display: none; }
    footer .store h2::after, footer .store .h2::after {
      display: block;
      clear: both;
      content: ""; }
    footer .store h2::after, footer .store .h2::after {
      font-family: "FontAwesome";
      float: right;
      content: "\f041"; }
  footer .content {
    font-size: 0.875em; }
    footer .content li {
      height: 1.875rem; }
  footer .copyright,
  footer .postscript {
    font-size: 0.8125em; }
  footer .copyright {
    margin-bottom: 0.625em; }
  footer .back-to-top {
    margin: 0.1em 0.313em;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
    footer .back-to-top i.fa-arrow-up {
      color: rgba(0, 0, 0, 0.7); }
    footer .back-to-top i.fa-circle {
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }

.footer-item {
  border-bottom: 1px solid #444; }

#consent-tracking .button-wrapper button {
  margin: 0.5em; }

.email-signup-message {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.email-signup-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .email-signup-alert.show {
    display: block; }

footer {
  padding: 0;
  background-color: transparent; }
  footer .footer-upper {
    padding: 50px 0 0; }
    @media (max-width: 1639.98px) {
      footer .footer-upper {
        padding: 40px 0 0; } }
    @media (max-width: 1199.98px) {
      footer .footer-upper {
        padding: 35px 0 0; } }
    @media (max-width: 991.98px) {
      footer .footer-upper {
        padding: 29px 0 0; } }
    @media (max-width: 767.98px) {
      footer .footer-upper {
        padding: 25px 0 0; } }
    @media (max-width: 575.98px) {
      footer .footer-upper {
        padding: 20px 0 0; } }
    footer .footer-upper .footer-item {
      padding-bottom: 50px;
      padding-top: 11px; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        footer .footer-upper .footer-item:first-child {
          margin-bottom: 0; } }
      @media (max-width: 991.98px) {
        footer .footer-upper .footer-item:first-child {
          padding-bottom: 0; } }
      @media (max-width: 575.98px) {
        footer .footer-upper .footer-item:first-child {
          align-items: center; } }
      @media (min-width: 768px) and (max-width: 991.98px) {
        footer .footer-upper .footer-item:nth-child(2) {
          margin-top: -23px; } }
      @media (min-width: 768px) and (max-width: 991.98px) {
        footer .footer-upper .footer-item:nth-child(3) {
          margin-top: -23px; } }
      @media (max-width: 1199.98px) {
        footer .footer-upper .footer-item {
          padding-bottom: 30px;
          margin-bottom: 30px; } }
      @media (max-width: 575.98px) {
        footer .footer-upper .footer-item {
          padding-bottom: 0; } }
      @media (max-width: 991.98px) {
        footer .footer-upper .footer-item:last-child {
          margin-bottom: 0; } }
      footer .footer-upper .footer-item h4, footer .footer-upper .footer-item .h4 {
        font-size: 13px;
        text-transform: uppercase;
        line-height: 26px;
        font-weight: 600;
        letter-spacing: 0.65px; }
      footer .footer-upper .footer-item p {
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 0;
        font-weight: 400; }
      footer .footer-upper .footer-item address {
        font-size: 13px;
        line-height: 25px;
        margin-top: 25px; }
      footer .footer-upper .footer-item ul {
        padding: 0;
        margin: 0;
        list-style: none; }
        footer .footer-upper .footer-item ul li {
          display: flex; }
          footer .footer-upper .footer-item ul li a {
            font-size: 13px;
            line-height: 25px;
            text-decoration: none;
            color: #000000;
            display: flex; }
            footer .footer-upper .footer-item ul li a.cookies-setting-btn {
              font-size: 13px !important;
              line-height: 25px !important;
              color: #000000 !important;
              background-color: transparent !important; }
      footer .footer-upper .footer-item .footer-social-media {
        margin-top: 6px; }
      footer .footer-upper .footer-item .footer-item-content a {
        color: #000000; }
      @media (max-width: 767.98px) {
        footer .footer-upper .footer-item .footer-logo img {
          width: 100%; } }
  footer .footer-bottom {
    padding: 15px 0; }

footer {
  background-color: #f6f8f8;
  padding: 0; }
  footer .footer-upper {
    padding: 30px 0 0; }
    footer .footer-upper .footer-item {
      border: none;
      padding-bottom: 30px; }
      footer .footer-upper .footer-item:first-child {
        padding-top: 0; }
      footer .footer-upper .footer-item:nth-child(2) {
        padding-top: 3px; }
      footer .footer-upper .footer-item:nth-child(3) {
        padding-top: 3px; }
      footer .footer-upper .footer-item .sign-up-desc {
        font-size: 16px;
        font-weight: 400; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        footer .footer-upper .footer-item .footer-logo {
          width: 100%;
          max-width: 215px; } }
      @media (min-width: 576px) {
        footer .footer-upper .footer-item .footer-logo {
          max-width: 244px; } }
      @media (max-width: 575.98px) {
        footer .footer-upper .footer-item .footer-logo {
          max-width: 100%; } }
      footer .footer-upper .footer-item .footer-logo a {
        display: block; }
        footer .footer-upper .footer-item .footer-logo a img {
          max-width: 100%; }
      footer .footer-upper .footer-item .mobile-copyright {
        margin-top: 20px; }
        @media (min-width: 576px) and (max-width: 991.98px) {
          footer .footer-upper .footer-item .mobile-copyright {
            display: flex; } }
        @media (max-width: 575.98px) {
          footer .footer-upper .footer-item .mobile-copyright .getFullYear, footer .footer-upper .footer-item .mobile-copyright .content-asset, footer .footer-upper .footer-item .mobile-copyright .siteName, footer .footer-upper .footer-item .mobile-copyright .kimball {
            display: contents; } }
        @media (max-width: 575.98px) {
          footer .footer-upper .footer-item .mobile-copyright .International {
            display: block; } }
      footer .footer-upper .footer-item .footer-item-content span {
        margin-right: 8px; }
      footer .footer-upper .footer-item .footer-item-content address {
        line-height: 25px;
        font-weight: 400;
        color: #000000; }
      footer .footer-upper .footer-item .sub-btn {
        margin-bottom: 30px;
        padding: 7.5px 0;
        font-size: 13px;
        letter-spacing: 0.65px; }
        footer .footer-upper .footer-item .sub-btn:hover {
          color: #ffffff; }
      @media (max-width: 991.98px) {
        footer .footer-upper .footer-item h4 span, footer .footer-upper .footer-item .h4 span {
          display: block; } }
      @media (max-width: 575.98px) {
        footer .footer-upper .footer-item h4 span, footer .footer-upper .footer-item .h4 span {
          display: inline; } }
    footer .footer-upper .surcharge-banner {
      text-align: center;
      margin-bottom: 10px;
      padding: 0; }
  footer .footer-bottom {
    background-color: #e0e7e7;
    padding: 21.5px 0 18px; }
    @media (max-width: 1639.98px) {
      footer .footer-bottom {
        padding: 15px 0; } }
    @media (max-width: 991.98px) {
      footer .footer-bottom {
        padding: 16.5px 0; } }
    @media (max-width: 767.98px) {
      footer .footer-bottom {
        padding: 24px 0; } }
    footer .footer-bottom .copyright-text ul {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (max-width: 767.98px) {
        footer .footer-bottom .copyright-text ul {
          flex-wrap: wrap;
          gap: 10px 0; } }
      @media (max-width: 575.98px) {
        footer .footer-bottom .copyright-text ul {
          grid-row-gap: 3px; } }
      footer .footer-bottom .copyright-text ul li:first-child {
        margin-bottom: 4px; }
        @media (min-width: 768px) and (max-width: 991.98px) {
          footer .footer-bottom .copyright-text ul li:first-child {
            margin-bottom: 0;
            margin-top: 0; } }
      footer .footer-bottom .copyright-text ul li:nth-child(2) {
        margin-bottom: 2px; }
        @media (min-width: 768px) and (max-width: 991.98px) {
          footer .footer-bottom .copyright-text ul li:nth-child(2) {
            margin-bottom: 0;
            margin-top: 2px; } }
      footer .footer-bottom .copyright-text ul li:nth-child(5) {
        margin-top: 2px; }
      footer .footer-bottom .copyright-text ul li:last-child {
        margin-top: 2px; }
      footer .footer-bottom .copyright-text ul li:nth-child(3) {
        margin-bottom: 10px; }
        @media (max-width: 991.98px) {
          footer .footer-bottom .copyright-text ul li:nth-child(3) {
            margin-bottom: 4px; } }
      @media (min-width: 992px) {
        footer .footer-bottom .copyright-text ul li:nth-child(4) {
          margin-bottom: 5px; } }
      @media (max-width: 575.98px) {
        footer .footer-bottom .copyright-text ul li:nth-child(6) {
          max-width: 70px; } }
      @media (max-width: 575.98px) {
        footer .footer-bottom .copyright-text ul li:nth-child(8) {
          max-width: 93px; } }
      footer .footer-bottom .copyright-text ul li:not(:last-child) {
        margin-right: 17px; }
        @media (max-width: 767.98px) {
          footer .footer-bottom .copyright-text ul li:not(:last-child) {
            margin-right: 12px; } }
        @media (max-width: 575.98px) {
          footer .footer-bottom .copyright-text ul li:not(:last-child) {
            margin-right: 6px;
            max-width: 76px; } }
      footer .footer-bottom .copyright-text ul li a img {
        width: 100%;
        max-width: 100%; }
        @media (max-width: 767.98px) {
          footer .footer-bottom .copyright-text ul li a img {
            max-height: 15px; } }
        @media (max-width: 575.98px) {
          footer .footer-bottom .copyright-text ul li a img {
            max-height: 10px; } }

.menu-toggleable-left.navbar-toggleable-xs {
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  display: block;
  max-width: 100%; }
  .menu-toggleable-left.navbar-toggleable-xs.in {
    min-width: 50%;
    left: 0; }

@media (max-width: 575.98px) {
  .menu-toggleable-left.navbar-toggleable-sm {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-sm.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 767.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-md.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 991.98px) {
  .menu-toggleable-left.navbar-toggleable-lg {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-lg.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 1199.98px) {
  .menu-toggleable-left.navbar-toggleable-xl {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-xl.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 1639.98px) {
  .menu-toggleable-left.navbar-toggleable-xxl {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-xxl.in {
      min-width: 50%;
      left: 0; } }

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0; }
  @media (min-width: 768px) {
    .navbar.bg-inverse .navbar-nav .nav-item + .nav-item {
      margin-left: 0; }
    .navbar.bg-inverse .navbar-nav .nav-link {
      padding: 0.8rem;
      white-space: nowrap; } }

.navbar-expand-md .navbar-nav.nav-center {
  justify-content: center; }

.navbar-expand-md .navbar-nav.nav-spaced {
  justify-content: space-evenly; }

.navbar-expand-md .navbar-nav.nav-right {
  justify-content: end; }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: #ffffff;
  color: #444; }

@media (min-width: 768px) {
  .nav-item > .nav-link {
    color: #ffffff; } }

@media (max-width: 575.98px) {
  .main-menu.menu-toggleable-left {
    background-color: #ffffff;
    z-index: 4; } }

.menu-toggleable-left .close-menu {
  padding: 15px;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  flex: 0 0 100%; }
  @media (min-width: 768px) {
    .menu-toggleable-left .close-menu {
      display: none; } }

.menu-toggleable-left .menu-group {
  flex: 0 0 100%; }

.menu-toggleable-left li > .close-menu {
  margin-right: 0;
  margin-top: -0.6rem;
  margin-left: 0; }

@media (max-width: 575.98px) {
  .menu-toggleable-left .bg-inverse {
    background-color: white !important;
    color: #222; } }

.menu-toggleable-left.in {
  right: 0;
  margin-right: 1.25em;
  /* 20/16 */ }
  @media (max-width: 575.98px) {
    .menu-toggleable-left.in .nav-item + .nav-item {
      border-top: 1px solid #eee; }
    .menu-toggleable-left.in .dropdown {
      display: block;
      position: static; }
    .menu-toggleable-left.in .dropdown-toggle {
      padding-left: 1rem; }
      .menu-toggleable-left.in .dropdown-toggle::after {
        border-top: 0.3em solid transparent;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
        position: absolute;
        right: 0.3em;
        margin-top: 0.55em; }
    .menu-toggleable-left.in .nav-item .nav-link {
      padding-left: 1rem; }
    .menu-toggleable-left.in .show > .dropdown-menu {
      left: 0; }
    .menu-toggleable-left.in .dropdown-menu {
      position: absolute;
      left: -100%;
      top: 0;
      width: 100%;
      height: 100%;
      border: 0 none;
      transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
      display: block; } }

.multilevel-dropdown .dropdown-menu {
  top: 90%;
  border: 0;
  border-radius: 0; }
  @media (min-width: 768px) {
    .multilevel-dropdown .dropdown-menu {
      box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4); } }

.multilevel-dropdown .dropdown-item.dropdown > .dropdown-toggle::after {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  right: 0.3em;
  margin-top: 0.55em; }

@media (min-width: 768px) {
  .multilevel-dropdown .dropdown-menu > .dropdown > .dropdown-menu {
    top: -0.65em;
    left: 99%; } }

.multilevel-dropdown .navbar > .close-menu > .back {
  display: none; }

.multilevel-dropdown .close-menu .back .caret-left {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-left: 0.3 solid transparent;
  width: 0;
  height: 0;
  display: inline-block;
  margin-bottom: 0.125em; }

.multilevel-dropdown .dropdown-item {
  padding: 0 0 0 1em; }
  .multilevel-dropdown .dropdown-item .dropdown-link {
    display: block;
    padding: 0.425em 5em 0.425em 0; }
  .multilevel-dropdown .dropdown-item + .dropdown-item {
    border-top: 1px solid #eee; }
  .multilevel-dropdown .dropdown-item.top-category {
    font-weight: bold; }
    .multilevel-dropdown .dropdown-item.top-category > .nav-link {
      padding-left: 0; }

header .main-header .brand {
  width: auto; }

header .main-header .main-menu .dropdown-menu {
  padding: 30px; }
  @media (max-width: 991.98px) {
    header .main-header .main-menu .dropdown-menu {
      box-shadow: none; } }
  header .main-header .main-menu .dropdown-menu.show {
    display: flex; }
  @media (min-width: 992px) {
    header .main-header .main-menu .dropdown-menu {
      width: 100%;
      position: absolute;
      left: 0;
      top: 141px;
      box-shadow: none;
      padding: 40px;
      z-index: 4; } }
  @media (max-width: 1199.98px) {
    header .main-header .main-menu .dropdown-menu .cat-nav ul {
      display: flex; } }
  header .main-header .main-menu .dropdown-menu .cat-nav ul .dropdown-item {
    padding: 0;
    border: none; }
    @media (max-width: 1199.98px) {
      header .main-header .main-menu .dropdown-menu .cat-nav ul .dropdown-item {
        width: auto; }
        header .main-header .main-menu .dropdown-menu .cat-nav ul .dropdown-item:not(:last-child) {
          margin-right: 28px; } }
    header .main-header .main-menu .dropdown-menu .cat-nav ul .dropdown-item:hover {
      background-color: transparent; }
    header .main-header .main-menu .dropdown-menu .cat-nav ul .dropdown-item .dropdown-link {
      color: #767676;
      font-size: 20px;
      padding: 0 0 20px 0; }
    header .main-header .main-menu .dropdown-menu .cat-nav ul .dropdown-item.active {
      background-color: transparent; }
      header .main-header .main-menu .dropdown-menu .cat-nav ul .dropdown-item.active .dropdown-link {
        color: #000000; }
  header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title, header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title-brand, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title-brand {
    text-transform: uppercase;
    color: #000000;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    margin-bottom: 3px;
    display: block; }
    header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title .brand-image, header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title-brand .brand-image, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title .brand-image, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title-brand .brand-image {
      margin-bottom: 5px; }
      @media (max-width: 991.98px) {
        header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title .brand-image, header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title-brand .brand-image, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title .brand-image, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title-brand .brand-image {
          display: none; } }
    header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title.view-insight, header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title-brand.view-insight, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title.view-insight, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title-brand.view-insight {
      cursor: pointer;
      display: grid;
      gap: 10px 0; }
      header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title.view-insight::after, header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-title-brand.view-insight::after, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title.view-insight::after, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-title-brand.view-insight::after {
        display: none; }
  @media (max-width: 991.98px) {
    header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-list, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-list {
      display: none; } }
  header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-list li, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-list li {
    padding: 0;
    border: none; }
    header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-list li:hover, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-list li:hover {
      background-color: transparent; }
    header .main-header .main-menu .dropdown-menu .cat-nav-items .col .cat-nav-items-list li a, header .main-header .main-menu .dropdown-menu .cat-nav-items .nav-group .cat-nav-items-list li a {
      color: #000000;
      font-size: 13px;
      font-family: "Plus Jakarta Sans";
      font-weight: 400;
      line-height: 26px; }

@media (max-width: 991.98px) {
  header .main-header .brand {
    margin: 0 auto; } }

header .main-header .menu-toggleable-left .navbar > .close-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  background-color: #e0e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  opacity: 0;
  visibility: hidden; }

@media (min-width: 992px) {
  header .main-header .menu-toggleable-left .navbar {
    position: static; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left .navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 20px 30px;
    height: 100%; } }

@media (max-width: 767.98px) {
  header .main-header .menu-toggleable-left .navbar {
    padding: 0 0 20px 25px; } }

@media (max-width: 575.98px) {
  header .main-header .menu-toggleable-left .navbar {
    padding: 0 0 20px 20px; } }

header .main-header .menu-toggleable-left .navbar.in .close-menu {
  transition: 0.4s; }

header .main-header .menu-toggleable-left .navbar.in .scroll-arrow {
  opacity: 1; }

header .main-header .menu-toggleable-left .navbar .mobile-top-header {
  padding: 16px 0;
  border-bottom: 1px solid #000000;
  width: 100%;
  position: relative; }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left .navbar .mobile-top-header {
      padding: 18px 0; } }
  header .main-header .menu-toggleable-left .navbar .mobile-top-header .search {
    display: none; }
  header .main-header .menu-toggleable-left .navbar .mobile-top-header .acount-bar {
    display: none; }

header .main-header .menu-toggleable-left .navbar .mobile-category-bar {
  width: 100%; }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left .navbar .mobile-category-bar {
      overflow: hidden;
      border-bottom: 1px solid #000000; } }

@media (min-width: 992px) {
  header .main-header .menu-toggleable-left .navbar .menu-group {
    position: static; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left .navbar .menu-group {
    width: 100%;
    padding: 0px;
    flex: auto;
    position: relative; } }

@media (min-width: 992px) {
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item {
    position: static; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item {
    border-bottom: 1px solid #000000; } }

header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link {
  color: #000000;
  background-color: transparent;
  padding: 28px 15px 0;
  font-size: 20px;
  line-height: 25px;
  font-family: "Plus Jakarta Sans";
  font-weight: 400; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link {
      position: relative;
      font-size: 18px;
      padding: 28px 12px 0; } }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link {
      padding: 17.5px 0;
      position: relative;
      font-size: 22px;
      font-family: "Plus Jakarta Sans";
      font-weight: 300; } }
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link:focus {
    outline: 0;
    box-shadow: none; }
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link::after {
    margin: 0;
    right: 20px;
    position: absolute;
    content: "";
    top: 45%;
    background-repeat: no-repeat;
    width: 7px;
    height: 7px;
    border: 1px solid #000000;
    border-width: 1px 0px 0px 1px;
    background-size: cover;
    transform: rotate(136deg); }
    @media (min-width: 992px) {
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item .nav-link::after {
        display: none; } }

header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.show .nav-link {
  padding: 28px 15px 17px; }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.show .nav-link {
      padding: 18px 15px 17px 0; } }

header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item:last-child .nav-link {
  padding-right: 0; }

header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop {
  width: 100%;
  left: 0;
  padding: 50px 0 50px; }
  @media (min-width: 992px) {
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop {
      box-shadow: 0px 3px 7px -6px #000000;
      top: 70px;
      max-height: 90vh;
      overflow-y: auto; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop {
      flex-direction: column;
      padding: 20px 0; } }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop {
      top: 0;
      padding: 0;
      left: inherit;
      display: flex;
      opacity: 0;
      display: none; } }
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters {
    display: flex; }
    @media (min-width: 992px) {
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters {
        flex-direction: column; } }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .col-xl-8, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .col-xl-8, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .col-xl-8 {
      justify-content: space-between; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .col-xl-8 .col, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .col-xl-8 .col, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .col-xl-8 .col {
        flex: inherit;
        width: calc(25% - 20px); }
        @media (max-width: 1199.98px) {
          header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .col-xl-8 .col, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .col-xl-8 .col, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .col-xl-8 .col {
            width: calc(25% - 18px); } }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .col-xl-10, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .col-xl-10, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .col-xl-10 {
      justify-content: space-between; }
      @media (max-width: 1199.98px) {
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .col-xl-10, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .col-xl-10, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .col-xl-10 {
          justify-content: start; } }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .col-xl-10 .col, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .col-xl-10 .col, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .col-xl-10 .col {
        flex: inherit;
        width: calc(20% - 20px); }
        @media (max-width: 1199.98px) {
          header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .col-xl-10 .col, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .col-xl-10 .col, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .col-xl-10 .col {
            width: calc(20% - 27px); } }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .dropdown-item, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .dropdown-item, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .dropdown-item {
      padding: 0; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .dropdown-item.active, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .dropdown-item.active, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .dropdown-item.active {
        background-color: #ffffff; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .dropdown-item .dropdown-link, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .dropdown-item .dropdown-link, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .dropdown-item .dropdown-link {
        color: #000000;
        text-decoration: none;
        padding: 0.425em 3em 0.425em 1em;
        text-decoration: none; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .dropdown-item .dropdown-link:focus, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .dropdown-item .dropdown-link:focus, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .dropdown-item .dropdown-link:focus {
          background-color: #cdcdc1; }
    @media (max-width: 1199.98px) {
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul {
        display: flex; } }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item {
      padding: 0;
      border: none; }
      @media (max-width: 1199.98px) {
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item {
          width: auto; }
          header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item:not(:last-child), header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item:not(:last-child), .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item:not(:last-child) {
            margin-right: 28px; } }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item:hover, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item:hover, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item:hover {
        background-color: transparent; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item .dropdown-link, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item .dropdown-link, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item .dropdown-link {
        color: #000000;
        font-size: 20px;
        padding: 0 0 20px 0;
        line-height: 25px; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item .dropdown-link:focus, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item .dropdown-link:focus, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item .dropdown-link:focus {
          background-color: transparent; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item .dropdown-link:hover, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item .dropdown-link:hover, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item .dropdown-link:hover {
          color: #956839; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item.active, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item.active, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item.active {
        background-color: transparent; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item.active .dropdown-link, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item.active .dropdown-link, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item.active .dropdown-link {
          color: #000000; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav ul .dropdown-item:active, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav ul .dropdown-item:active, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav ul .dropdown-item:active {
        background-color: #ffffff; }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-title, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-title-brand, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-title-brand, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-title-brand {
      text-transform: uppercase;
      color: #000000;
      font-size: 13px;
      font-family: "Plus Jakarta Sans";
      font-weight: 600;
      margin-bottom: 0px;
      text-decoration: none;
      letter-spacing: 0.65px;
      line-height: 26px; }
      @media (max-width: 991.98px) {
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-title, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-title-brand, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-title-brand, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-title-brand {
          font-size: 20px; } }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-list, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-list, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-list {
      margin-bottom: 38px; }
      @media (max-width: 991.98px) {
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-list, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-list, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-list {
          display: none; } }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-list li, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-list li, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-list li {
        padding: 1px 0 0;
        border: none;
        white-space: inherit; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-list li:hover, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-list li:hover, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-list li:hover {
          background-color: transparent; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-list li a, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-list li a, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-list li a {
          color: #000000;
          font-size: 13px;
          font-family: "Plus Jakarta Sans";
          font-weight: 400;
          padding: 0;
          text-decoration: none; }
          header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .col .cat-nav-items-list li a:hover, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .col .cat-nav-items-list li a:hover, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .col .cat-nav-items-list li a:hover {
            color: #956839; }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-title, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-title-brand, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-title-brand, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-title-brand {
      text-transform: uppercase;
      color: #000000;
      font-size: 13px;
      font-family: "Plus Jakarta Sans";
      font-weight: 600;
      margin: 0;
      text-decoration: none;
      letter-spacing: 0.65px;
      line-height: 20px; }
      @media (max-width: 991.98px) {
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-title, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-title, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-title-brand, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-title-brand, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-title-brand {
          font-size: 20px; } }
    @media (max-width: 991.98px) {
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-list, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-list, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-list {
        display: none; } }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-list li, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li {
      padding: 2px 0 0;
      border: none; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-list li:hover, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li:hover, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li:hover {
        background-color: transparent; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-list li a, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li a, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li a {
        color: #000000;
        font-size: 13px;
        font-family: "Plus Jakarta Sans";
        font-weight: 400;
        padding: 6px 0;
        text-decoration: none;
        line-height: 26px; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items .nav-group .cat-nav-items-list li a:hover, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li a:hover, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items .nav-group .cat-nav-items-list li a:hover {
          color: #956839; }
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items.kimball-brands, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items.kimball-brands, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items.kimball-brands {
      flex-wrap: wrap; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items.kimball-brands .col, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items.kimball-brands .col, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items.kimball-brands .col {
        margin-bottom: 35px; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items.kimball-brands .col:not(:last-child), header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items.kimball-brands .col:not(:last-child), .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items.kimball-brands .col:not(:last-child) {
          margin-right: 0; }
      header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items.kimball-brands a, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items.kimball-brands a, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items.kimball-brands a {
        cursor: pointer; }
        header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .container .cat-nav-items.kimball-brands a:hover span, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .sticky-filter.applied-filter-sticky .applied-filters .cat-nav-items.kimball-brands a:hover span, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown .custom-drop.show .applied-filters .cat-nav-items.kimball-brands a:hover span {
          color: #956839; }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu.custom-drop {
    position: absolute;
    flex-direction: row;
    flex-wrap: wrap;
    opacity: 1;
    transition: 0.4s;
    right: 0;
    z-index: 1; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu.custom-drop .container, header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu.custom-drop .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu.custom-drop .applied-filters {
    padding: 0; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu.custom-drop .row {
    margin: 0; } }

header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu a:hover {
  color: #956839; }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left .navbar .menu-group .nav .nav-item.dropdown.show .dropdown-menu a:hover span {
      color: #956839; } }

header .main-header .menu-toggleable-left .navbar .mobile-bottom-footer {
  position: absolute;
  bottom: 30px; }
  @media (max-width: 575.98px) {
    header .main-header .menu-toggleable-left .navbar .mobile-bottom-footer {
      bottom: 50px;
      padding-bottom: 20px;
      background-color: #ffffff;
      width: 100%;
      z-index: 9999; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown.show {
    position: relative; } }

header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .nav-menu .close-menu {
  margin: 0; }
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .nav-menu .close-menu .back button {
    padding-bottom: 5px; }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .nav-menu .close-menu .close-button {
      display: none; } }

header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .top-category:focus, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop:active:focus {
  background-color: transparent;
  outline: none;
  border: none; }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .top-category, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop:active {
    width: auto;
    font-weight: 300;
    font-size: 18px; } }

header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .top-category .nav-link::after, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop:active .nav-link::after {
  display: none; }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav {
    position: static;
    height: auto;
    display: block; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav.cat-nav-parent-li {
    padding: 0; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav ul {
    display: block; }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav ul li {
      border-top: 1px solid #000000;
      margin: 0; }
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav ul li a {
        padding: 15px 0;
        color: #000000;
        position: relative; } }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .product-cat-nav-items.cat-nav-items {
    border-bottom: 0;
    position: static;
    height: auto;
    display: block !important; }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .product-cat-nav-items.cat-nav-items .custom-back-button {
      display: none; } }

header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .product-cat-nav-items.cat-nav-items .nav-back-button {
  padding: 14px 15px 13px 0; }
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .product-cat-nav-items.cat-nav-items .nav-back-button button span {
    padding-left: 11px;
    font-size: 22px;
    color: #000000;
    font-weight: 300; }

@media (max-width: 991.98px) {
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-child-li {
    display: none; }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-child-li .brand-category li {
      padding-left: 0;
      border-color: #000000; }
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-child-li .brand-category li:hover, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-child-li .brand-category li:focus, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-child-li .brand-category li:active {
        background-color: transparent; }
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-child-li .brand-category li a {
        font-size: 20px;
        line-height: 30px;
        padding: 13.5px 0;
        font-weight: 300; } }

header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items {
  overflow-y: auto;
  max-height: 100vh;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #000000; }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items {
      padding: 0; } }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col,
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group {
      border-top: 1px solid #000000; }
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col:last-child,
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group:last-child {
        border-bottom: 1px solid #000000; } }
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col.custom-active,
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group.custom-active {
    border: none; }
    @media (max-width: 575.98px) {
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col.custom-active .top-category,
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group.custom-active .top-category {
        max-width: 85%;
        white-space: normal; } }
    @media (max-width: 991.98px) {
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col.custom-active .cat-nav-items-list,
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group.custom-active .cat-nav-items-list {
        border: none; } }
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title-brand,
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title,
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title-brand {
    margin: 0;
    padding: 13.5px 0;
    font-size: 18px;
    text-transform: capitalize;
    font-family: "Plus Jakarta Sans";
    font-weight: 300;
    position: relative; }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title:focus, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title-brand:focus,
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title:focus,
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title-brand:focus {
      outline: 0; }
    @media (max-width: 991.98px) {
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title-brand,
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title,
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title-brand {
        font-size: 20px;
        padding-right: 30px; } }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title::after, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title-brand::after,
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title::after,
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title-brand::after {
      margin: 0;
      right: 20px;
      position: absolute;
      content: "";
      top: 45%;
      background-repeat: no-repeat;
      width: 7px;
      height: 7px;
      border: 1px solid #000000;
      border-width: 1px 0px 0px 1px;
      background-size: cover;
      transform: rotate(136deg); }
      @media (min-width: 992px) {
        header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title::after, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col .cat-nav-items-title-brand::after,
        header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title::after,
        header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .nav-group .cat-nav-items-title-brand::after {
          display: none; } }
  @media (max-width: 991.98px) {
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .col:last-child {
      border-bottom: none; } }
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active .top-category .cat-nav-items-title, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active .top-category .cat-nav-items-title-brand {
      border: none; }
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active .top-category .cat-nav-items-title::after, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active .top-category .cat-nav-items-title-brand::after {
        display: none; }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active .cat-nav-items-list {
      width: 100%;
      border-top: 1px solid #000000; }
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active .cat-nav-items-list .dropdown-item .text-decoration-none {
        font-size: 22px;
        display: block;
        font-family: "Plus Jakarta Sans";
        font-weight: 300;
        padding: 17px 0;
        white-space: normal; }
        @media (max-width: 991.98px) {
          header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items .custom-active .cat-nav-items-list .dropdown-item .text-decoration-none {
            border-top: 1px solid #000000; } }
  header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items.kimball-brands .cat-nav-items-title, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items.kimball-brands .cat-nav-items-title-brand {
    border: none; }
    @media (min-width: 992px) {
      header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items.kimball-brands .cat-nav-items-title:last-child, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items.kimball-brands .cat-nav-items-title-brand:last-child {
        border-bottom: none; } }
    header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items.kimball-brands .cat-nav-items-title::after, header .main-header .menu-toggleable-left.in .navbar .menu-group .nav .nav-item.dropdown .custom-drop .cat-nav-items.kimball-brands .cat-nav-items-title-brand::after {
      display: none; }

header .main-header .cat-nav-items .cat-nav-items-title, header .main-header .cat-nav-items .cat-nav-items-title-brand {
  cursor: default; }
  header .main-header .cat-nav-items .cat-nav-items-title.product-menu, header .main-header .cat-nav-items .cat-nav-items-title-brand.product-menu {
    cursor: pointer; }
    header .main-header .cat-nav-items .cat-nav-items-title.product-menu:hover, header .main-header .cat-nav-items .cat-nav-items-title-brand.product-menu:hover {
      color: #956839 !important; }

@media (min-width: 992px) {
  .product-cat-nav-items {
    column-count: 4;
    grid-column: 3/-1;
    column-gap: 20px; } }

@media (min-width: 1200px) {
  .product-cat-nav-items {
    column-count: 5;
    grid-column: 3/-1;
    column-gap: 20px; } }

@media (min-width: 992px) {
  .product-cat-nav-items .nav-group {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%; } }

.slick-list {
  padding-bottom: 10px; }

.slick-dots {
  display: flex;
  bottom: 0; }
  .slick-dots li {
    margin: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e3d6ce; }
    .slick-dots li.slick-active {
      background-color: #956839; }
    .slick-dots li:not(:last-child) {
      margin-right: 20px; }

.form-group.required .form-control-label::before {
  content: "*";
  color: #dc3545; }

.form-group.required .form-control-label::before {
  content: "*";
  color: #dc3545; }

.form .form-label.required:after {
  content: "*";
  color: red; }

.form .form-control {
  color: #000000;
  border: 1px solid #949494;
  border-radius: 40px;
  background-color: #f6f8f8;
  font-size: 18px;
  padding: 10px 25px; }
  .form .form-control:focus {
    box-shadow: none;
    border-color: #949494; }

.form.needs-validation .select-field {
  position: relative; }
  .form.needs-validation .select-field .is-invalid {
    margin-top: 35px; }
  .form.needs-validation .select-field .invalid-feedback {
    position: absolute;
    top: -30px;
    font-size: 13px;
    color: #C71717; }

.kii-table th, .kii-table td {
  box-shadow: none;
  border-bottom: 1px solid #000;
  padding: 20px 5px;
  background-color: transparent; }

.kii-table tbody tr:hover {
  background-color: #f6f8f8; }

.product-listing {
  position: relative;
  margin-top: 80px; }
  @media (min-width: 576px) {
    .product-listing {
      margin-top: 85px; } }
  @media (min-width: 768px) {
    .product-listing {
      margin-top: 90px; } }
  @media (min-width: 992px) {
    .product-listing {
      margin-top: 35px; } }
  @media (min-width: 1200px) {
    .product-listing {
      margin-top: 40px; } }
  @media (min-width: 1640px) {
    .product-listing {
      margin-top: 50px; } }
  @media screen and (max-width: 360px) {
    .product-listing {
      width: 100%; } }
  .product-listing .product-grid .filter-bar .filters .button-filter {
    margin-bottom: 10px; }
  @media (max-width: 575.98px) {
    .product-listing .product-grid .product-grid-header {
      gap: 20px 0;
      padding-bottom: 0px; } }
  @media (max-width: 767.98px) {
    .product-listing .product-grid .product-grid-header .pl-heading {
      margin-top: -8px; } }
  .product-listing .product-grid .product-grid-header .pl-heading.idea-starter-cat {
    margin-bottom: 0px; }
  .product-listing .product-grid .product-grid-header h1, .product-listing .product-grid .product-grid-header .h1, .product-listing .product-grid .product-grid-header .material-details-wrap .md-title, .material-details-wrap .product-listing .product-grid .product-grid-header .md-title {
    margin-bottom: 20px; }
    @media (max-width: 575.98px) {
      .product-listing .product-grid .product-grid-header h1, .product-listing .product-grid .product-grid-header .h1, .product-listing .product-grid .product-grid-header .material-details-wrap .md-title, .material-details-wrap .product-listing .product-grid .product-grid-header .md-title {
        word-break: break-all; } }
  .product-listing .product-grid .col-6 {
    padding: 0 10px;
    gap: 10px 0; }
  @media (min-width: 992px) {
    .product-listing .product-grid .small-plp-image-tile {
      width: 16.66667%; } }
  .product-listing .product-grid .small-plp-image-tile .image-container {
    min-height: 200px; }
    @media (min-width: 576px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 190px; } }
    @media (min-width: 768px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 188px; } }
    @media (min-width: 992px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 228px; } }
    @media (min-width: 1200px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 282px; } }
    @media (min-width: 1640px) {
      .product-listing .product-grid .small-plp-image-tile .image-container {
        min-height: 320px; } }
    .product-listing .product-grid .small-plp-image-tile .image-container img {
      object-fit: cover; }
      @media (min-width: 576px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 190px; } }
      @media (min-width: 768px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 188px; } }
      @media (min-width: 992px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 228px; } }
      @media (min-width: 1200px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 282px; } }
      @media (min-width: 1640px) {
        .product-listing .product-grid .small-plp-image-tile .image-container img {
          height: 320px; } }
  @media (min-width: 992px) {
    .product-listing .product-grid .large-plp-image-tile {
      width: 33.33333%; } }
  .product-listing .product-grid .large-plp-image-tile .image-container {
    min-height: 200px; }
    @media (min-width: 576px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 190px; } }
    @media (min-width: 768px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 188px; } }
    @media (min-width: 992px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 228px; } }
    @media (min-width: 1200px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 282px; } }
    @media (min-width: 1640px) {
      .product-listing .product-grid .large-plp-image-tile .image-container {
        min-height: 320px; } }
    .product-listing .product-grid .large-plp-image-tile .image-container img {
      object-fit: cover; }
      @media (min-width: 576px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 190px; } }
      @media (min-width: 768px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 188px; } }
      @media (min-width: 992px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 228px; } }
      @media (min-width: 1200px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 282px; } }
      @media (min-width: 1640px) {
        .product-listing .product-grid .large-plp-image-tile .image-container img {
          height: 320px; } }
  .product-listing .product-grid .sticky-filter.applied-filter-sticky .applied-filters .applied-filter-list {
    margin-bottom: 5px; }
  .product-listing .product-grid .sticky-filter.applied-filter-sticky .applied-filters .iconBar {
    margin-bottom: 10px; }
    @media (max-width: 575.98px) {
      .product-listing .product-grid .sticky-filter.applied-filter-sticky .applied-filters .iconBar {
        margin-bottom: 15px; } }
  .product-listing .sticky-prod-coparison-bar {
    background-color: #f0f0ec;
    padding: 17px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .product-listing .sticky-prod-coparison-bar {
        flex-direction: column;
        padding: 17px 12px; } }
    .product-listing .sticky-prod-coparison-bar .left-area-bar p {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: #000000; }
    .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products {
      list-style: none;
      display: flex;
      align-items: center; }
      @media (max-width: 575.98px) {
        .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products {
          flex-wrap: wrap;
          gap: 10px; } }
      .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products li span {
        margin-left: 7px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px; }
      .product-listing .sticky-prod-coparison-bar .left-area-bar .added-products li:not(:last-child) {
        margin-right: 30px; }
    .product-listing .sticky-prod-coparison-bar .right-area-bar .button {
      border: 1px solid #000000;
      border-radius: 50px; }
      .product-listing .sticky-prod-coparison-bar .right-area-bar .button:hover {
        background-color: #000000;
        color: #ffffff; }
  .product-listing .idea-started-product-list, .product-listing .download-product-list {
    margin-top: 20px;
    align-items: end; }
    @media (max-width: 1199.98px) {
      .product-listing .idea-started-product-list, .product-listing .download-product-list {
        align-items: start; } }
    @media (max-width: 991.98px) {
      .product-listing .idea-started-product-list, .product-listing .download-product-list {
        align-items: end; } }
    .product-listing .idea-started-product-list li, .product-listing .download-product-list li {
      padding-right: 0; }
  .product-listing .download-product-list li {
    padding-left: 15px; }

.plp-no-result-found {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-style: normal;
  font-weight: 300; }
  @media (min-width: 576px) {
    .plp-no-result-found {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .plp-no-result-found {
      padding-top: 29px;
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .plp-no-result-found {
      padding-top: 35px;
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .plp-no-result-found {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .plp-no-result-found {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media (min-width: 768px) {
    .plp-no-result-found {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.2px; } }
  @media (min-width: 992px) {
    .plp-no-result-found {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: normal; } }
  @media (min-width: 1640px) {
    .plp-no-result-found {
      font-size: 40px;
      line-height: 50px;
      letter-spacing: 0.4px; } }

.iconBar {
  display: inline-flex;
  width: 30%;
  justify-content: flex-end;
  margin-left: auto;
  margin-bottom: 30px;
  align-self: flex-start; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .iconBar {
      width: 26%; } }
  @media screen and (max-width: 374px) {
    .iconBar {
      margin-left: 0;
      width: auto; } }
  @media (max-width: 575.98px) {
    .iconBar {
      align-items: center; } }
  @media (min-width: 768px) {
    .iconBar {
      width: 20%; } }
  @media (min-width: 1200px) {
    .iconBar {
      width: 15%; } }
  .iconBar button {
    background: none;
    box-shadow: none;
    border: none;
    margin-right: 10px; }
    .iconBar button:last-child {
      margin-right: 0; }
    .iconBar button.active img, .iconBar button:hover img {
      filter: invert(46%) sepia(24%) saturate(1009%) hue-rotate(350deg) brightness(88%) contrast(87%); }
  .iconBar [data-title]:hover:after {
    opacity: 1; }
  .iconBar [data-title]:after {
    content: attr(data-title);
    color: #956839;
    font-size: 9px;
    position: absolute;
    top: 30px;
    left: -50%;
    white-space: nowrap;
    opacity: 0; }
  .iconBar [data-title] {
    position: relative; }
  .iconBar .sort-by {
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.65px;
    margin-right: 21px;
    text-transform: uppercase;
    font-weight: 600; }
    @media (max-width: 575.98px) {
      .iconBar .sort-by {
        font-size: 12px;
        line-height: 19px;
        margin-right: 8px; } }

.downloadsList .table .gridCintainer {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 575.98px) {
    .downloadsList .table .gridCintainer {
      display: flex;
      flex-wrap: wrap; } }
  .downloadsList .table .gridCintainer .gridCintainer-inner-block {
    display: grid;
    grid-template-columns: 7% 43% 30% 20%;
    width: calc(100% - 145px);
    padding: 0;
    background-color: transparent; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .downloadsList .table .gridCintainer .gridCintainer-inner-block {
        grid-template-columns: 11% 35% 29% 25%; } }
    @media (max-width: 575.98px) {
      .downloadsList .table .gridCintainer .gridCintainer-inner-block {
        display: flex;
        width: 100%;
        flex-wrap: wrap; } }
  .downloadsList .table .gridCintainer .download-file {
    width: 145px;
    display: flex; }
  .downloadsList .table .gridCintainer .productImageContent {
    background-color: transparent;
    padding: 0; }
    @media (max-width: 575.98px) {
      .downloadsList .table .gridCintainer .productImageContent {
        width: 100%; } }
    .downloadsList .table .gridCintainer .productImageContent img {
      width: 100%;
      max-width: 45px; }
      @media (max-width: 575.98px) {
        .downloadsList .table .gridCintainer .productImageContent img {
          max-width: 60px; } }
    .downloadsList .table .gridCintainer .productImageContent .productImageLink {
      padding: 10px 0px 10px 20px;
      display: block; }
      @media (max-width: 1199.98px) {
        .downloadsList .table .gridCintainer .productImageContent .productImageLink {
          padding: 21px 0px 20px 10px; } }
      @media (max-width: 575.98px) {
        .downloadsList .table .gridCintainer .productImageContent .productImageLink {
          padding: 0 0 10px 10px; } }
  .downloadsList .table .gridCintainer .gridList {
    font-size: 15px;
    font-family: "Plus Jakarta Sans" !important;
    color: #000000;
    border: none;
    padding: 0; }
    @media (max-width: 767.98px) {
      .downloadsList .table .gridCintainer .gridList {
        font-size: 13px; } }
    .downloadsList .table .gridCintainer .gridList .link-icon-btn {
      margin-top: 0; }
    @media (max-width: 575.98px) {
      .downloadsList .table .gridCintainer .gridList.item1 {
        width: 100%; } }
    @media (min-width: 576px) {
      .downloadsList .table .gridCintainer .gridList.item4 {
        padding: 21px 10px; } }
    @media (max-width: 575.98px) {
      .downloadsList .table .gridCintainer .gridList.item4 .downloadFile {
        display: flex; } }
    .downloadsList .table .gridCintainer .gridList.item4 .add-to-fav img {
      max-width: 17px; }
    @media (min-width: 576px) {
      .downloadsList .table .gridCintainer .gridList .fileName {
        padding: 21px 10px; } }
    @media (max-width: 767.98px) {
      .downloadsList .table .gridCintainer .gridList .fileName {
        white-space: nowrap;
        width: 100%;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media (max-width: 575.98px) {
      .downloadsList .table .gridCintainer .gridList .fileName {
        max-width: 100%;
        white-space: normal; } }
    .downloadsList .table .gridCintainer .gridList .fileName:hover {
      color: #000000; }

.downloadsList .table .headingContainer .gridList {
  font-weight: 600;
  background: none;
  padding: 21px 10px; }
  @media (max-width: 575.98px) {
    .downloadsList .table .headingContainer .gridList {
      display: none; }
      .downloadsList .table .headingContainer .gridList:nth-child(2) {
        display: block;
        width: 100%; } }
  .downloadsList .table .headingContainer .gridList button {
    background: none;
    box-shadow: none;
    border: none;
    margin-left: 5px;
    display: inline-block;
    padding: 0; }

@media (max-width: 767.98px) {
  .downloadsList .table .detailContainer .gridList a {
    font-size: 13px; } }

.downloadsList .table .detailContainer:hover {
  background-color: #f6f8f8; }
  .downloadsList .table .detailContainer:hover a {
    color: #956839; }
  .downloadsList .table .detailContainer:hover .gridList:hover a {
    color: #956839; }

@media (max-width: 575.98px) {
  .downloadsList .table .detailContainer {
    padding-block: 21px; } }

.downloadsList .table .detailContainer .gridList {
  line-height: 26px;
  font-weight: 400;
  background-color: transparent; }
  @media (max-width: 575.98px) {
    .downloadsList .table .detailContainer .gridList {
      width: auto;
      padding: 0 10px; } }
  .downloadsList .table .detailContainer .gridList:first-child {
    padding-left: 10px; }
    @media (max-width: 767.98px) {
      .downloadsList .table .detailContainer .gridList:first-child {
        width: 100%; } }
    @media (max-width: 575.98px) {
      .downloadsList .table .detailContainer .gridList:first-child {
        white-space: inherit;
        overflow: inherit;
        width: 100%;
        margin-bottom: 5px; } }
  .downloadsList .table .detailContainer .gridList:last-child {
    text-align: left; }
    @media (max-width: 575.98px) {
      .downloadsList .table .detailContainer .gridList:last-child {
        text-align: left;
        justify-content: start;
        width: auto;
        margin-top: 0; } }
    .downloadsList .table .detailContainer .gridList:last-child .add-to-fav {
      margin-left: 10px; }
  .downloadsList .table .detailContainer .gridList a {
    display: block;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    width: auto; }
    @media (max-width: 991.98px) {
      .downloadsList .table .detailContainer .gridList a {
        font-size: 13px; } }
    .downloadsList .table .detailContainer .gridList a::before {
      display: none; }
    .downloadsList .table .detailContainer .gridList a .download-icon {
      margin-left: 10px; }
    .downloadsList .table .detailContainer .gridList a.downloadFile {
      position: relative;
      padding-right: 20px; }
      .downloadsList .table .detailContainer .gridList a.downloadFile:after {
        content: '';
        background-image: url(../images/download-icon.svg);
        position: absolute;
        right: 0;
        top: 7px;
        width: 11px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: 50%; }

@media (max-width: 575.98px) {
  .downloadsList .table .detailContainer .gridList.item2 {
    position: relative; }
    .downloadsList .table .detailContainer .gridList.item2::after {
      content: "/";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }

.downloadsList .table .detailContainer:nth-child(odd) {
  background-color: transparent; }
  .downloadsList .table .detailContainer:nth-child(odd):hover {
    background-color: #f6f8f8; }

.count-filter-seperation {
  margin: 0 5px;
  word-break: break-word;
  /* overflow-wrap: break-word; */ }
  @media (max-width: 575.98px) {
    .count-filter-seperation {
      white-space: pre-line; } }

.product-PLP .product .product-tile .tile-body {
  padding: 0; }
  @media (min-width: 1200px) and (max-width: 1639.98px) {
    .product-PLP .product .product-tile .tile-body {
      padding-top: 19px;
      padding-bottom: 10px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .product-PLP .product .product-tile .tile-body {
      padding: 13px 0 10px; } }
  .product-PLP .product .product-tile .tile-body .pdp-link .link {
    font-size: 16px;
    margin-bottom: 0; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .product-PLP .product .product-tile .tile-body .pdp-link .link {
        margin-bottom: 10px; } }
    .product-PLP .product .product-tile .tile-body .pdp-link .link sup {
      font-size: .45em;
      top: -8px; }
    .product-PLP .product .product-tile .tile-body .pdp-link .link span {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: .65px;
      text-transform: uppercase;
      margin-left: 5px; }

.product-PLP .product .product-tile .tile-footer {
  margin-bottom: 0;
  display: none; }
  .product-PLP .product .product-tile .tile-footer .pdp-link-btn {
    width: 32px;
    height: 32px; }
    .product-PLP .product .product-tile .tile-footer .pdp-link-btn img {
      width: 17px; }

.product-listing .product-grid .product-PLP .large-plp-image-tile .product, .product-listing .product-grid .product-PLP .small-plp-image-tile .product {
  padding-bottom: 20px; }
  .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text {
    padding-top: 0;
    padding-bottom: 0; }
    .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text.contentTile-block a:hover *, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text.contentTile-block a:hover * {
      color: #000000; }
    .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text.contentTile-block a.contentTile-block-link, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text.contentTile-block a.contentTile-block-link {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      font-size: 0; }
    .product-listing .product-grid .product-PLP .large-plp-image-tile .product .article-block.image-with-text.contentTile-block .image-description a, .product-listing .product-grid .product-PLP .small-plp-image-tile .product .article-block.image-with-text.contentTile-block .image-description a {
      position: relative;
      z-index: 2; }

.product-listing .product-grid .product-PLP .applied-filters .applied-filter-list {
  margin-bottom: 71px; }
  @media (max-width: 991.98px) {
    .product-listing .product-grid .product-PLP .applied-filters .applied-filter-list {
      margin-bottom: 49px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .product-listing .product-grid .product-PLP .pl-heading .h1 {
    font-size: 60px;
    line-height: 82px;
    letter-spacing: 0.6px; } }

.product-listing .plp-product-list .plp-list-view .pdp-link span,
.product-listing .plp-product-list .plp-grid-view .pdp-link span {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: .65px;
  text-transform: uppercase; }

.product-listing .plp-product-list .plp-list-view .tile-footer,
.product-listing .plp-product-list .plp-grid-view .tile-footer {
  display: none; }

.product-listing .product-grid .product-PLP .product.plp-list-view {
  padding-bottom: 20px; }

.product-plp-wishListButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center; }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0d6efd;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0d6efd;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #0d6efd;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #73630D;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #73630D;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #73630D;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffffff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffffff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffffff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.attribute {
  margin-top: 0.938em; }
  .attribute label {
    display: block; }

.swatch a {
  text-decoration: none; }

.primary-images {
  margin: 0;
  padding: 0; }

.prices-add-to-cart-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 50;
  padding-right: 0;
  padding-left: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
  @media (min-width: 576px) {
    .prices-add-to-cart-actions {
      position: static;
      padding: 0 0.9375em; } }
  .prices-add-to-cart-actions .price {
    text-align: center; }

.prices {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center; }

.cart-and-ipay {
  text-align: center; }
  @media (max-width: 575.98px) {
    .cart-and-ipay {
      padding-bottom: 26px; }
      .cart-and-ipay .btn {
        width: 98%;
        margin: 1%;
        display: block; } }

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .add-to-basket-alert.show {
    display: block; }

.simple-quantity {
  margin-top: 1em; }

.main-attributes {
  margin-top: 1em; }

.size-chart {
  margin-top: 1.071em; }

div.availability {
  margin-top: 1.071em; }

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc; }
  .bundle-item:last-child {
    border-bottom: none; }

.container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
  margin-top: 2em;
  margin-bottom: 2em; }
  @media (max-width: 575.98px) {
    .container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
      margin-top: 0;
      margin-bottom: 0; } }

.product-option:not(:first-child) {
  margin-top: 1.071em; }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0d6efd;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0d6efd;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #0d6efd;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #73630D;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #73630D;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #73630D;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffffff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffffff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffffff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.attribute {
  margin-top: 0.938em; }
  .attribute label {
    display: block; }

.swatch a {
  text-decoration: none; }

.primary-images {
  margin: 0;
  padding: 0; }

.prices-add-to-cart-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 50;
  padding-right: 0;
  padding-left: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
  @media (min-width: 576px) {
    .prices-add-to-cart-actions {
      position: static;
      padding: 0 0.9375em; } }
  .prices-add-to-cart-actions .price {
    text-align: center; }

.prices {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center; }

.cart-and-ipay {
  text-align: center; }
  @media (max-width: 575.98px) {
    .cart-and-ipay {
      padding-bottom: 26px; }
      .cart-and-ipay .btn {
        width: 98%;
        margin: 1%;
        display: block; } }

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .add-to-basket-alert.show {
    display: block; }

.simple-quantity {
  margin-top: 1em; }

.main-attributes {
  margin-top: 1em; }

.size-chart {
  margin-top: 1.071em; }

div.availability {
  margin-top: 1.071em; }

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc; }
  .bundle-item:last-child {
    border-bottom: none; }

.container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
  margin-top: 2em;
  margin-bottom: 2em; }
  @media (max-width: 575.98px) {
    .container.product-detail, .sticky-filter.applied-filter-sticky .product-detail.applied-filters {
      margin-top: 0;
      margin-bottom: 0; } }

.product-option:not(:first-child) {
  margin-top: 1.071em; }

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: 56.25em; }
  .quick-view-dialog .selectable-bonus-product-line-item,
  .choose-bonus-product-dialog .selectable-bonus-product-line-item {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    padding-top: 0.3em;
    padding-bottom: 0.3em; }
  .quick-view-dialog .beenSelected,
  .choose-bonus-product-dialog .beenSelected {
    background-color: #eee; }
  .quick-view-dialog .modal-header,
  .choose-bonus-product-dialog .modal-header {
    background-color: #eee;
    border-bottom: 2px solid #ccc;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem; }
    .quick-view-dialog .modal-header .full-pdp-link,
    .choose-bonus-product-dialog .modal-header .full-pdp-link {
      color: var(--skin-primary-color-1); }
    .quick-view-dialog .modal-header .close,
    .choose-bonus-product-dialog .modal-header .close {
      font-size: 2rem;
      line-height: 1.5rem; }
  .quick-view-dialog .modal-title,
  .choose-bonus-product-dialog .modal-title {
    font-size: 1em; }
  .quick-view-dialog .product-name,
  .choose-bonus-product-dialog .product-name {
    font-size: 1.875em; }
  .quick-view-dialog .swatch-circle,
  .choose-bonus-product-dialog .swatch-circle {
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: 2.5em;
    height: 2.5em;
    background: #ffffff;
    -moz-border-radius: 1.25em;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative; }
    .quick-view-dialog .swatch-circle.color-value[data-selected=true]::after,
    .choose-bonus-product-dialog .swatch-circle.color-value[data-selected=true]::after {
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      left: 0.295em;
      position: absolute; }
    .quick-view-dialog .swatch-circle.color-value.selected::after,
    .choose-bonus-product-dialog .swatch-circle.color-value.selected::after {
      background: #ffffff;
      border-radius: 50%;
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      height: 0.75em;
      left: 0.31em;
      line-height: 0.8em;
      position: absolute;
      top: 0.35em;
      width: 0.8em; }
    .quick-view-dialog .swatch-circle i.fa-times-circle,
    .choose-bonus-product-dialog .swatch-circle i.fa-times-circle {
      background: #ffffff;
      border-radius: 50%;
      height: 0.75em;
      line-height: 0.8em;
      width: 0.8em; }
  .quick-view-dialog a[disabled] .swatch-circle,
  .choose-bonus-product-dialog a[disabled] .swatch-circle {
    cursor: not-allowed; }
    .quick-view-dialog a[disabled] .swatch-circle.color-value.selected::after,
    .choose-bonus-product-dialog a[disabled] .swatch-circle.color-value.selected::after {
      background-color: #495057; }
  .quick-view-dialog .availablity-container,
  .choose-bonus-product-dialog .availablity-container {
    text-align: right; }
  .quick-view-dialog .availablity-container,
  .quick-view-dialog .size-chart,
  .choose-bonus-product-dialog .availablity-container,
  .choose-bonus-product-dialog .size-chart {
    margin-top: 0.938em; }
  .quick-view-dialog .modal-content,
  .choose-bonus-product-dialog .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .quick-view-dialog .modal-body,
  .choose-bonus-product-dialog .modal-body {
    max-height: 28.125em;
    /* 450/16 */
    overflow-y: auto; }
  .quick-view-dialog button.close,
  .choose-bonus-product-dialog button.close {
    font-size: 1.25em; }
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    background-color: #ffffff;
    border: none;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
    .quick-view-dialog .modal-footer .prices .price,
    .choose-bonus-product-dialog .modal-footer .prices .price {
      font-size: 1.6em; }
  .quick-view-dialog .prices .sales,
  .choose-bonus-product-dialog .prices .sales {
    font-size: 1.5rem; }
  .quick-view-dialog .promotions,
  .choose-bonus-product-dialog .promotions {
    text-align: left;
    color: #dc3545; }
  @media (max-width: 575.98px) {
    .quick-view-dialog .bonus-summary,
    .choose-bonus-product-dialog .bonus-summary {
      font-size: 0.625em; } }
  @media (max-width: 575.98px) {
    .quick-view-dialog .bonus-summary .bonus-product-name,
    .choose-bonus-product-dialog .bonus-summary .bonus-product-name {
      padding: 0; } }
  .quick-view-dialog .pre-cart-products,
  .choose-bonus-product-dialog .pre-cart-products {
    margin-right: 0.125em; }
  .quick-view-dialog .color-attribute,
  .choose-bonus-product-dialog .color-attribute {
    border: none;
    padding: 0;
    background: none; }
  .quick-view-dialog .non-input-label,
  .choose-bonus-product-dialog .non-input-label {
    display: block;
    margin-bottom: 0.5rem; }

.product-tile {
  min-height: 13.4375em;
  border: 0;
  margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .product-tile {
      min-height: 23.4375em; } }
  @media (max-width: 575.98px) {
    .product-tile {
      min-height: 19.6875em; } }
  .product-tile .tile-body {
    padding: 0.625em 0 1.875em; }
    .product-tile .tile-body .color-swatches {
      min-height: 2.25em; }
      .product-tile .tile-body .color-swatches .product-tile-color-label {
        cursor: pointer;
        font-size: 1em; }
        @media (max-width: 767.98px) {
          .product-tile .tile-body .color-swatches .product-tile-color-label {
            font-size: 0.9375em; } }
        @media (max-width: 575.98px) {
          .product-tile .tile-body .color-swatches .product-tile-color-label {
            font-size: 0.8125em; } }
    .product-tile .tile-body .price {
      font-size: 1.125em;
      margin-bottom: 0; }
      @media (max-width: 767.98px) {
        .product-tile .tile-body .price {
          font-size: 1.0625em; } }
      @media (max-width: 575.98px) {
        .product-tile .tile-body .price {
          font-size: 1em; } }
      .product-tile .tile-body .price .tiered {
        font-size: 0.875em; }
        .product-tile .tile-body .price .tiered .value {
          font-weight: bold; }
    .product-tile .tile-body .coming-soon-tile {
      text-align: center; }
    .product-tile .tile-body .pdp-link {
      line-height: 1.2; }
      .product-tile .tile-body .pdp-link a {
        font-size: 1em;
        text-decoration: none; }
        @media (max-width: 767.98px) {
          .product-tile .tile-body .pdp-link a {
            font-size: 0.9375em; } }
        @media (max-width: 575.98px) {
          .product-tile .tile-body .pdp-link a {
            font-size: 0.8125em; } }
    .product-tile .tile-body .ratings {
      font-size: 0.9em; }
  .product-tile .image-container {
    position: relative;
    overflow: auto; }
    .product-tile .image-container .quickview {
      position: absolute;
      bottom: 1rem;
      right: 1rem; }
      .product-tile .image-container .quickview i.fa-expand {
        color: rgba(0, 0, 0, 0.7); }
      .product-tile .image-container .quickview i.fa-circle {
        color: rgba(255, 255, 255, 0.7);
        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
    .product-tile .image-container a {
      display: block; }
      .product-tile .image-container a .tile-image {
        width: 100%; }
  .product-tile .swatches a {
    text-decoration: none; }
  .product-tile .swatch-circle {
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: 1.8em;
    height: 1.8em;
    background: #ffffff;
    -moz-border-radius: 0.9em;
    border-radius: 0.9em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative; }
    .product-tile .swatch-circle.color-value[data-selected=true]::after {
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      left: 0.295em;
      position: absolute; }
    .product-tile .swatch-circle.color-value.selected::after {
      background: #ffffff;
      border-radius: 50%;
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      height: 0.75em;
      left: 0.31em;
      line-height: 0.8em;
      position: absolute;
      top: 0.35em;
      width: 0.8em; }
    .product-tile .swatch-circle i.fa-times-circle {
      background: #ffffff;
      border-radius: 50%;
      height: 0.75em;
      line-height: 0.8em;
      width: 0.8em; }

#chooseBonusProductModal .modal-footer .container, #chooseBonusProductModal .modal-footer .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky #chooseBonusProductModal .modal-footer .applied-filters {
  margin-left: 0;
  width: 100%;
  margin-right: 0; }

#chooseBonusProductModal .select-cbp-container {
  margin-top: auto;
  margin-bottom: auto; }

#chooseBonusProductModal .product-name-wrapper {
  width: 100%; }

#chooseBonusProductModal .bonus-quantity,
#chooseBonusProductModal .bonus-option {
  margin-top: 0.938em; }

#chooseBonusProductModal .bonus-quantity-select {
  min-width: 5em; }

#chooseBonusProductModal .select-bonus-product {
  margin-top: 1em; }

#chooseBonusProductModal .selected-pid {
  border: 1px solid #ccc; }
  #chooseBonusProductModal .selected-pid .bonus-product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

#chooseBonusProductModal .bonus-product-price {
  text-align: center;
  margin-top: 1em; }

.product .product-tile {
  position: relative;
  min-height: auto; }
  .product .product-tile .compare-chcek {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    cursor: pointer; }
    .product .product-tile .compare-chcek button {
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none; }
  .product .product-tile .image-container {
    position: relative;
    overflow: visible; }
    .product .product-tile .image-container .tile-image {
      width: 100%; }
    .product .product-tile .image-container .hover-tile-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.5s; }
    .product .product-tile .image-container:hover .hover-tile-image {
      opacity: 1;
      transition: 0.5s; }
  .product .product-tile .tile-body {
    padding: 0; }
    @media (max-width: 575.98px) {
      .product .product-tile .tile-body {
        padding: 10px 0 0; } }
    .product .product-tile .tile-body .pdp-link .link {
      font-family: "Plus Jakarta Sans";
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 0;
      color: #000000;
      display: block;
      align-items: center;
      word-break: break-word;
      padding: 11px 0 20px; }
      .product .product-tile .tile-body .pdp-link .link:hover {
        color: #000000; }
    .product .product-tile .tile-body .prod-cat p {
      font-family: "Plus Jakarta Sans";
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.65px;
      text-transform: uppercase; }
  .product .product-tile .tile-footer {
    opacity: 0;
    transition: 0.5s; }
    .product .product-tile .tile-footer .pdp-link-btn {
      border: 1px solid #8C602F;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .product .product-tile .tile-footer .pdp-link-btn {
          display: none; } }
      @media (max-width: 767.98px) {
        .product .product-tile .tile-footer .pdp-link-btn {
          display: none; } }
      .product .product-tile .tile-footer .pdp-link-btn img {
        max-width: 20px; }
  .product .product-tile:hover .tile-footer {
    opacity: 1;
    transition: 0.5s; }

.related-product-tile .image-container img {
  width: 100%; }

.kii-content-slider .product .product-tile .tile-body .pdp-link .link {
  display: block; }

.visualiztionIcon {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50px;
  transition: 0.5s ease-in-out;
  display: flex;
  align-items: center;
  background: #fff url(../images/hero-banner/config-icon.svg) no-repeat;
  background-size: 18px;
  background-position: 6px; }
  .visualiztionIcon img {
    max-width: 100%;
    padding: 6px;
    height: auto !important;
    width: auto !important;
    object-fit: inherit !important;
    display: none; }
  .visualiztionIcon span {
    font-size: 13px;
    text-transform: uppercase;
    position: relative;
    font-family: "Plus Jakarta Sans" !important;
    color: #000000 !important;
    font-weight: 600 !important;
    margin: 0 0 0 32px !important;
    opacity: 0;
    transition: .3s ease-in-out;
    top: 1px; }
  .visualiztionIcon:hover {
    width: 122px; }
    .visualiztionIcon:hover span {
      opacity: 1; }

.pagination, .image-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px; }
  .pagination ul, .image-pagination ul {
    align-items: center;
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px; }
    .pagination ul li, .image-pagination ul li {
      font-size: 18px; }
      .pagination ul li:not(.current-page), .image-pagination ul li:not(.current-page) {
        opacity: 0.8; }
        .pagination ul li:not(.current-page) a, .image-pagination ul li:not(.current-page) a {
          font-weight: 400; }
      .pagination ul li.disabled, .image-pagination ul li.disabled {
        cursor: not-allowed; }
        .pagination ul li.disabled a, .image-pagination ul li.disabled a {
          pointer-events: none; }
      .pagination ul li.current-page, .image-pagination ul li.current-page {
        font-weight: 600; }
      .pagination ul li a, .image-pagination ul li a {
        font-weight: 600; }
        .pagination ul li a:hover, .image-pagination ul li a:hover {
          color: #000000; }
      .pagination ul li .go-to-first img, .pagination ul li .go-to-previous img, .pagination ul li .go-to-last img, .pagination ul li .go-to-next img, .image-pagination ul li .go-to-first img, .image-pagination ul li .go-to-previous img, .image-pagination ul li .go-to-last img, .image-pagination ul li .go-to-next img {
        vertical-align: baseline; }
      .pagination ul li .go-to-next img, .pagination ul li .go-to-last img, .image-pagination ul li .go-to-next img, .image-pagination ul li .go-to-last img {
        transform: rotate(180deg); }

.products-comparison {
  padding: 50px 0 0; }
  .products-comparison .compare-product-added .product-tile .image-container img {
    width: 100%; }
  .products-comparison .compare-product-added .product-tile .product-tile-body h4, .products-comparison .compare-product-added .product-tile .product-tile-body .h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 0; }
  .products-comparison .compare-product-added .product-tile .product-tile-body span {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600; }
  .products-comparison .commpared-info {
    padding: 50px 0 0; }
    .products-comparison .commpared-info .compared-info-group h3, .products-comparison .commpared-info .compared-info-group .h3 {
      font-weight: 600;
      margin-bottom: 20px; }
    .products-comparison .commpared-info .compared-info-group table {
      margin-bottom: 0; }
      .products-comparison .commpared-info .compared-info-group table tr td {
        padding: 20px; }
      .products-comparison .commpared-info .compared-info-group table tr:nth-of-type(odd) > * {
        background-color: #f0f7f7; }

.sticky-back-to-products {
  position: sticky;
  bottom: 0;
  padding: 20px 0;
  background-color: #f0f0ec;
  margin: 50px 0; }
  .sticky-back-to-products span {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase; }

.search-results.container, .sticky-filter.applied-filter-sticky .search-results.applied-filters {
  padding-bottom: 50px; }

.search-results .search-custom-tabs {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border: none;
  flex-wrap: wrap; }
  .search-results .search-custom-tabs .nav-item .button {
    margin-top: 0; }
    .search-results .search-custom-tabs .nav-item .button.active {
      background-color: #000000;
      color: #ffffff;
      padding: 7px 12px;
      border-radius: 25px; }
    .search-results .search-custom-tabs .nav-item .button.disabled {
      color: #767676; }

.search-results .no-result-found .carousel-title * {
  color: #000000; }

.search-results .no-result-found .carousel-title {
  border-bottom: 1px solid #000000;
  padding-bottom: 20px; }

.search-results .no-result-found .kii-content-slider.product-slider-wrapper {
  padding-top: 20px; }

.search-results .no-result-found .container, .search-results .no-result-found .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky .search-results .no-result-found .applied-filters {
  padding: 0; }

.search-results .search-title {
  text-align: center;
  padding: 40px 0 0; }
  @media (max-width: 767.98px) {
    .search-results .search-title {
      padding: 40px 0 20px; } }

.search-results .html-slot-container {
  margin-top: 20px; }
  @media (min-width: 768px) {
    .search-results .html-slot-container {
      margin-top: 40px; } }

.search-results .search-subtitle p {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.65px;
  font-weight: 600; }

.search-results .tab-content .tab-pane .image-listing {
  margin-bottom: 78px; }
  .search-results .tab-content .tab-pane .image-listing.images-search-list {
    margin-bottom: 0; }
    .search-results .tab-content .tab-pane .image-listing.images-search-list .searchImagesResult {
      margin: 0;
      gap: 0 20px; }
      .search-results .tab-content .tab-pane .image-listing.images-search-list .searchImagesResult li {
        max-height: 180px;
        margin-top: 20px; }
        .search-results .tab-content .tab-pane .image-listing.images-search-list .searchImagesResult li:last-child {
          margin-bottom: 78px; }

.search-results .tab-content .tab-pane .searchProductsResultTitle {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #000000;
  padding: 15px 0; }
  .search-results .tab-content .tab-pane .searchProductsResultTitle h4, .search-results .tab-content .tab-pane .searchProductsResultTitle .h4 {
    font-weight: 600; }

.search-results .tab-content .tab-pane .searchProductsResult {
  list-style: none;
  display: flex;
  justify-content: flex-start; }
  .search-results .tab-content .tab-pane .searchProductsResult .product-tile {
    margin-bottom: 78px;
    gap: 50px 0; }
    @media (max-width: 767.98px) {
      .search-results .tab-content .tab-pane .searchProductsResult .product-tile {
        min-height: auto;
        margin-bottom: 30px; } }
    .search-results .tab-content .tab-pane .searchProductsResult .product-tile .image-container .product-tile-image {
      width: 100%; }
    .search-results .tab-content .tab-pane .searchProductsResult .product-tile .product-tile-body h4, .search-results .tab-content .tab-pane .searchProductsResult .product-tile .product-tile-body .h4 {
      font-weight: 600;
      font-size: 16px;
      margin: 16px 0 0; }
      .search-results .tab-content .tab-pane .searchProductsResult .product-tile .product-tile-body h4 span, .search-results .tab-content .tab-pane .searchProductsResult .product-tile .product-tile-body .h4 span {
        font-weight: 400;
        margin-left: 5px; }
    .search-results .tab-content .tab-pane .searchProductsResult .product-tile .product-tile-body .link-icon-btn {
      font-size: 18px;
      padding: 3px 6px 3px 24px;
      position: relative;
      margin-top: 30px;
      display: inline-block;
      color: #000000;
      font-size: 13px;
      padding: 3px 6px 0 17px;
      margin-top: 5px; }
      .search-results .tab-content .tab-pane .searchProductsResult .product-tile .product-tile-body .link-icon-btn::before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 11px;
        height: 14px;
        background-image: url("../images/download-icon.svg");
        background-repeat: no-repeat;
        background-position: center; }
  .search-results .tab-content .tab-pane .searchProductsResult.literature {
    column-count: 2;
    margin-top: 20px;
    gap: 0;
    flex-wrap: wrap;
    align-items: end; }
    @media (max-width: 1199.98px) {
      .search-results .tab-content .tab-pane .searchProductsResult.literature {
        align-items: start; } }
    @media (max-width: 991.98px) {
      .search-results .tab-content .tab-pane .searchProductsResult.literature {
        align-items: end; } }
    .search-results .tab-content .tab-pane .searchProductsResult.literature li {
      padding: 0 0 0 15px;
      margin-top: 0; }
      .search-results .tab-content .tab-pane .searchProductsResult.literature li .product-tile {
        display: flex;
        align-items: flex-end;
        gap: 20px; }
        @media (max-width: 1199.98px) {
          .search-results .tab-content .tab-pane .searchProductsResult.literature li .product-tile {
            display: grid; } }
        @media (max-width: 991.98px) {
          .search-results .tab-content .tab-pane .searchProductsResult.literature li .product-tile {
            display: flex; } }
        @media screen and (max-width: 374px) {
          .search-results .tab-content .tab-pane .searchProductsResult.literature li .product-tile .image-container {
            width: 40%; } }
        .search-results .tab-content .tab-pane .searchProductsResult.literature li .product-tile.product-download {
          gap: 0;
          margin: 0 0 35px; }
          .search-results .tab-content .tab-pane .searchProductsResult.literature li .product-tile.product-download .product-tile-body {
            padding: 0 0 0 10px; }
            @media (min-width: 992px) and (max-width: 1199.98px) {
              .search-results .tab-content .tab-pane .searchProductsResult.literature li .product-tile.product-download .product-tile-body {
                padding: 0; } }
  .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result li {
    margin-top: 20px; }
  .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result.literature .product-tile-body {
    padding: 0 15px 0 0; }
  .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile .product-tile, .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile .product-tile {
    margin-bottom: 19px; }
  @media (min-width: 992px) {
    .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile {
      width: 33.33333%; } }
  .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile .image-container img {
    object-fit: cover; }
    @media (min-width: 576px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile .image-container img {
        height: 190px; } }
    @media (min-width: 768px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile .image-container img {
        height: 188px; } }
    @media (min-width: 992px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile .image-container img {
        height: 228px; } }
    @media (min-width: 1200px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile .image-container img {
        height: 282px; } }
    @media (min-width: 1640px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .large-plp-image-tile .image-container img {
        height: 320px; } }
  @media (min-width: 992px) {
    .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile {
      width: 16.66667%; } }
  .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile .image-container img {
    object-fit: cover; }
    @media (min-width: 576px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile .image-container img {
        height: 190px; } }
    @media (min-width: 768px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile .image-container img {
        height: 188px; } }
    @media (min-width: 992px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile .image-container img {
        height: 228px; } }
    @media (min-width: 1200px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile .image-container img {
        height: 282px; } }
    @media (min-width: 1640px) {
      .search-results .tab-content .tab-pane .searchProductsResult.plp-search-result .small-plp-image-tile .image-container img {
        height: 320px; } }
  .search-results .tab-content .tab-pane .searchProductsResult.ideastarter-search-list .product-tile {
    min-height: auto;
    margin-bottom: 0; }
    .search-results .tab-content .tab-pane .searchProductsResult.ideastarter-search-list .product-tile li:last-child {
      margin-bottom: 78px; }

@media (min-width: 992px) {
  .search-results .tab-content .tab-pane .searchPagesResult {
    padding: 0 0 50px; } }

.search-results .tab-content .tab-pane .searchPagesResult .searchPagesResultList {
  margin-top: 20px; }
  .search-results .tab-content .tab-pane .searchPagesResult .searchPagesResultList a {
    display: block; }
  .search-results .tab-content .tab-pane .searchPagesResult .searchPagesResultList h4, .search-results .tab-content .tab-pane .searchPagesResult .searchPagesResultList .h4 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600; }
  .search-results .tab-content .tab-pane .searchPagesResult .searchPagesResultList span {
    font-size: 13px;
    margin: 10px 0;
    display: block; }
  .search-results .tab-content .tab-pane .searchPagesResult .searchPagesResultList .searchPagesResultListContent {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    color: #000000; }
    .search-results .tab-content .tab-pane .searchPagesResult .searchPagesResultList .searchPagesResultListContent p {
      font-size: 18px; }

.search-results .download-resource {
  margin-top: 20px;
  margin-bottom: 50px; }

.search-results .product-download {
  display: flex;
  align-items: flex-end;
  gap: 0;
  margin: 0 0 35px;
  flex-wrap: wrap; }
  @media (max-width: 1639.98px) {
    .search-results .product-download {
      min-height: auto; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .search-results .product-download {
      display: grid; } }
  @media (max-width: 991.98px) {
    .search-results .product-download {
      min-height: auto; } }
  @media screen and (max-width: 374px) {
    .search-results .product-download .image-container {
      width: 40%; } }
  .search-results .product-download .image-container img {
    width: 100%; }
  .search-results .product-download h5, .search-results .product-download .h5 {
    font-size: 14px;
    line-height: 25px;
    word-break: break-word; }
  .search-results .product-download .s-cat-title {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    font-size: 10px; }
  .search-results .product-download .link-icon-btn {
    font-size: 18px;
    padding: 3px 6px 3px 24px;
    position: relative;
    margin-top: 30px;
    display: inline-block;
    color: #000000;
    margin-top: 5px;
    padding: 3px 6px 0 17px;
    font-size: 13px; }
    .search-results .product-download .link-icon-btn::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 11px;
      height: 14px;
      background-image: url("../images/download-icon.svg");
      background-repeat: no-repeat;
      background-position: center; }
  .search-results .product-download .product-tile-body {
    padding-left: 10px; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .search-results .product-download .product-tile-body {
        margin-top: 10px;
        padding-left: 0; } }
    .search-results .product-download .product-tile-body .add-to-wishlist,
    .search-results .product-download .product-tile-body .remove-from-wishlist {
      margin-bottom: 10px; }

.search-results .image-grid .applied-filters {
  display: block; }
  .search-results .image-grid .applied-filters .applied-filter-list {
    width: auto; }
    @media (min-width: 576px) {
      .search-results .image-grid .applied-filters .applied-filter-list {
        float: left; }
        .search-results .image-grid .applied-filters .applied-filter-list:first-child {
          margin-right: 20px; } }

.searchImagesResult {
  list-style: none;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px 0 40px; }
  .searchImagesResult img {
    cursor: pointer;
    max-height: 180px;
    max-width: 100%; }
  .searchImagesResult .video-wrapper {
    cursor: pointer; }

.com-search {
  margin: 50px 0; }
  @media (max-width: 991.98px) {
    .com-search {
      margin-top: 100px; } }
  .com-search .com-search-heading {
    letter-spacing: 0.4px;
    margin-bottom: 50px;
    font-weight: 300; }
    @media (max-width: 991.98px) {
      .com-search .com-search-heading {
        margin-bottom: 20px; } }
  .com-search .com-search-form .search-upper-text {
    margin-bottom: 20px; }
  .com-search .com-search-form .form-heading {
    font-size: 13px;
    margin: 27px 0 20px;
    letter-spacing: 0.65px;
    text-transform: uppercase; }
  .com-search .com-search-form .form .form-select {
    margin-bottom: 20px; }
  .com-search .com-search-form .form .submit-button {
    text-align: end; }
    .com-search .com-search-form .form .submit-button .button {
      margin-top: 4px; }
    .com-search .com-search-form .form .submit-button .button-filter {
      padding: 17px 30px;
      letter-spacing: 0.65px; }
  .com-search .form-bottom-text {
    margin: 50px 0; }
  .com-search .search-results {
    border-top: 1px solid #000000;
    padding-top: 20px;
    margin: 0px 0 50px 0; }
    @media (max-width: 767.98px) {
      .com-search .search-results {
        margin: 30px 0; } }
    .com-search .search-results .paragraph-spacing {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 13px;
      letter-spacing: 0.65px;
      font-weight: 600; }
    .com-search .search-results .button {
      margin-top: 30px; }
      .com-search .search-results .button .button-outline {
        padding: 7px 12px; }
    .com-search .search-results .left-heading h3, .com-search .search-results .left-heading .h3 {
      letter-spacing: 0.25px; }
      @media (max-width: 767.98px) {
        .com-search .search-results .left-heading h3, .com-search .search-results .left-heading .h3 {
          font-size: 25px; } }
    .com-search .search-results .mobile-content-spacing {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 10px; }
      @media (max-width: 767.98px) {
        .com-search .search-results .mobile-content-spacing {
          margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .com-search .show-more-details {
      display: none; } }
  .com-search .guidelines {
    border-top: 1px solid #000000;
    padding-top: 50px; }
    .com-search .guidelines .appearance-guidelines .text-uppercase, .com-search .guidelines .restriction-guidelines .text-uppercase {
      font-size: 13px;
      letter-spacing: 0.65px;
      font-weight: 600; }
  .com-search ol {
    counter-reset: list; }
    .com-search ol li {
      padding-left: 30px;
      position: relative; }
      .com-search ol li:before {
        counter-increment: list;
        content: counter(list) " ";
        position: absolute;
        left: 0;
        top: 0; }

.needs-validation .form-select.is-invalid {
  background-image: url("../../images/product-details/down-arrow.svg") !important;
  background-size: 12px !important; }

.com-middle-content .container, .com-middle-content .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky .com-middle-content .applied-filters {
  padding: 0; }

.lead-times {
  margin-bottom: 50px;
  margin-top: 40px;
  /* Rounded sliders */ }
  .lead-times .container .container, .lead-times .sticky-filter.applied-filter-sticky .applied-filters .container, .sticky-filter.applied-filter-sticky .lead-times .applied-filters .container, .lead-times .container .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky .lead-times .container .applied-filters, .lead-times .sticky-filter.applied-filter-sticky .applied-filters .applied-filters, .sticky-filter.applied-filter-sticky .lead-times .applied-filters .applied-filters {
    padding: 0; }
  @media (max-width: 767.98px) {
    .lead-times {
      margin-top: 80px; } }
  .lead-times .kimball .dropdown {
    display: inline-block; }
    .lead-times .kimball .dropdown button {
      position: relative;
      border: none;
      background-color: transparent; }
      .lead-times .kimball .dropdown button::after {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        font-size: 25px;
        color: #324048; }
      .lead-times .kimball .dropdown button h1, .lead-times .kimball .dropdown button .h1, .lead-times .kimball .dropdown button .material-details-wrap .md-title, .material-details-wrap .lead-times .kimball .dropdown button .md-title {
        color: #324048;
        font-size: 40px;
        font-weight: 500;
        line-height: inherit;
        padding-right: 20px; }
        @media (max-width: 767.98px) {
          .lead-times .kimball .dropdown button h1, .lead-times .kimball .dropdown button .h1, .lead-times .kimball .dropdown button .material-details-wrap .md-title, .material-details-wrap .lead-times .kimball .dropdown button .md-title {
            font-size: 20px; } }
    .lead-times .kimball .dropdown .kimball-drop {
      position: absolute;
      width: max-content;
      border-radius: 5px;
      background-color: #324048;
      padding: 10px;
      z-index: 1; }
      .lead-times .kimball .dropdown .kimball-drop li a {
        color: #ffffff;
        display: block; }
        .lead-times .kimball .dropdown .kimball-drop li a:hover {
          color: #000000;
          background-color: #e9ecef; }
    .lead-times .kimball .dropdown .kimball-drop {
      display: none; }
  @media (max-width: 767.98px) {
    .lead-times .reports-filter {
      margin: 20px 0; } }
  .lead-times .reports-filter .report-block {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: fit-content;
    margin: 0 auto; }
  @media (max-width: 767.98px) {
    .lead-times .search-input {
      display: flex; } }
  .lead-times .search-input input {
    padding: 0 5px; }
    @media (max-width: 767.98px) {
      .lead-times .search-input input {
        width: 100%; } }
  .lead-times .casegood-table .casegoods {
    margin-top: 20px; }
  .lead-times .casegood-table .hr-dbl {
    border-top: solid 5px #000000;
    border-bottom: solid 2px #000000;
    opacity: 1;
    padding-top: 3px; }
  .lead-times .casegood-table p {
    font-size: 16px; }
  .lead-times .casegood-table table {
    margin-top: 10px; }
    .lead-times .casegood-table table th {
      font-size: 16px;
      font-weight: 600; }
    .lead-times .casegood-table table tr td {
      font-size: 16px; }
      .lead-times .casegood-table table tr td.child-text {
        font-size: 13px;
        padding-left: 20px; }
  .lead-times .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px; }
    @media (max-width: 767.98px) {
      .lead-times .switch {
        justify-self: center; } }
  .lead-times .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .lead-times .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: .4s;
    border: 1px solid #000000; }
  .lead-times .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 2px;
    background-color: #ffffff;
    transition: .4s;
    border: 1px solid #000000; }
  .lead-times input:checked + .slider {
    background-color: #000000; }
  .lead-times input:focus + .slider {
    box-shadow: 0 0 1px #000000; }
  .lead-times input:checked + .slider:before {
    transform: translateX(13px); }
  .lead-times .slider.round {
    border-radius: 34px; }
  .lead-times .slider.round:before {
    border-radius: 50%; }

.leadtimes-tab ul {
  display: flex;
  justify-content: center;
  margin: 30px 0 50px; }
  @media (max-width: 575.98px) {
    .leadtimes-tab ul {
      display: block;
      margin: 30px 0 20px; } }
  .leadtimes-tab ul .nav-item {
    margin: 0 0 10px; }
    @media (min-width: 768px) {
      .leadtimes-tab ul .nav-item {
        margin: 0 10px 0 0; } }
    .leadtimes-tab ul .nav-item .nav-link {
      color: #000000; }
      @media (max-width: 767.98px) {
        .leadtimes-tab ul .nav-item .nav-link {
          justify-content: flex-start; } }
      @media (max-width: 575.98px) {
        .leadtimes-tab ul .nav-item .nav-link {
          width: 70%; } }
      @media (min-width: 576px) and (max-width: 767.98px) {
        .leadtimes-tab ul .nav-item .nav-link {
          font-size: 11px;
          padding: 7px 5px 5px;
          margin: 0 2px;
          justify-content: flex-start; } }
      .leadtimes-tab ul .nav-item .nav-link.active, .leadtimes-tab ul .nav-item .nav-link:hover {
        background-color: #000000;
        color: #ffffff; }

.leadtimes-tab .tablist-content .card-header {
  background-color: transparent;
  padding: 0; }
  .leadtimes-tab .tablist-content .card-header h5, .leadtimes-tab .tablist-content .card-header .h5 {
    padding: 10px 0;
    border-bottom: 1px solid #000000; }
  @media (min-width: 768px) {
    .leadtimes-tab .tablist-content .card-header {
      display: none; } }

.leadtimes-tab .tablist-content .card-body {
  padding: 20px 0; }

@media (min-width: 768px) {
  .leadtimes-tab .tablist-content .collapse:not(.show) {
    display: block; } }

@media (max-width: 767.98px) {
  .leadtimes-tab .tablist-content .fade:not(.show) {
    opacity: 1; } }

@media (max-width: 767.98px) {
  .leadtimes-tab .tablist-content .tab-content > .tab-pane {
    display: block; } }

html {
  border: transparent !important; }

p {
  margin-bottom: 20px; }

#ot-sdk-btn-floating.ot-floating-button {
  display: none; }

.back-and-like-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0; }
  .back-and-like-btn a:first-child {
    margin-right: 30px; }

.img-info-overlay-parent {
  position: relative; }
  .img-info-overlay-parent .img-info-overlay {
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 500ms; }
    @media (min-width: 768px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 100%; } }
    @media (min-width: 992px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 294px; } }
    @media (min-width: 1200px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 360px; } }
    @media (min-width: 1640px) {
      .img-info-overlay-parent .img-info-overlay {
        width: 370px; } }
    .img-info-overlay-parent .img-info-overlay .info-overlay-container img {
      width: 11px;
      height: 14px;
      display: none; }
    .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      transition: all 500ms;
      opacity: 0;
      width: 100%;
      z-index: 9; }
      @media (max-width: 767.98px) {
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block {
          height: 100%; } }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block.active {
        opacity: 1;
        transform: translateX(0);
        transition: all 500ms;
        z-index: 8; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block * {
        color: #ffffff;
        font-size: 13px;
        line-height: 17px; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .global-download-icon {
        display: inline-block; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons {
        border-top: 0;
        padding: 15px 20px 15px 60px;
        background-color: #000000; }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons a {
          color: #ffffff;
          margin-right: 15px;
          font-size: 13px;
          line-height: 20px;
          cursor: pointer;
          background: rgba(0, 0, 0, 0.25) url("../images/hero-banner/download-arrow.svg");
          background-repeat: no-repeat;
          background-size: 17px 13px;
          background-position: 0px center;
          padding-left: 10px; }
          @media (min-width: 768px) and (max-width: 991.98px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons a {
              margin-right: 0; } }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons a span {
            margin-left: 10px;
            color: #ffffff; }
        @media (max-width: 767.98px) {
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .social-icons {
            position: absolute;
            bottom: 0;
            width: 100%; } }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block p:empty {
        height: 10px; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content {
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.7); }
        @media (max-width: 767.98px) {
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content {
            height: calc(100% - 50px);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 0;
            background-color: transparent; } }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content h6, .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .h6,
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .infooverlay-title {
          font-weight: 600;
          letter-spacing: 0.65px;
          text-transform: uppercase;
          line-height: 23px;
          font-size: 13px;
          color: #ffffff;
          margin-bottom: 10px;
          display: block; }
          @media (max-width: 767.98px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content h6, .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .h6,
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .infooverlay-title {
              background: rgba(0, 0, 0, 0.7);
              margin: 0;
              margin-top: auto;
              padding: 20px 20px 0; } }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
          overflow-y: auto;
          /* width */
          /* Track */
          /* Handle */
          /* Handle on hover */ }
          @media (max-width: 767.98px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              background-color: rgba(0, 0, 0, 0.7);
              padding: 10px 20px 20px; } }
          @media (min-width: 768px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              max-height: 119px; } }
          @media (min-width: 992px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              max-height: 219px; } }
          @media (min-width: 1200px) {
            .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block {
              max-height: 288px; } }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar {
            width: 2px; }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar-track {
            background: #707070; }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar-thumb {
            background: #ffffff; }
          .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-text-block .info-overlay-content .info-overlay-content-block::-webkit-scrollbar-thumb:hover {
            background: #ffffff; }
    .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon {
      position: absolute;
      left: 10px;
      bottom: 10px;
      width: 30px;
      height: 30px;
      background: rgba(0, 0, 0, 0.25) url("../images/hero-banner/info-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      border-radius: 50%;
      line-height: 10px;
      border: 0;
      z-index: 9;
      color: #ffffff;
      opacity: 0;
      transition: .5s all; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon:focus {
        opacity: 1; }
      @media (max-width: 767.98px) {
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon {
          opacity: 1; } }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon .cross {
        display: none;
        width: 11px;
        height: 11px; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon:hover {
        background-color: #000000; }
      .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon.active {
        background: #000000 url("../images/hero-banner/info-cross.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 11px;
        z-index: 9;
        opacity: 1;
        border: 1px solid #ffffff; }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon.active img {
          display: none;
          margin: auto; }
        .img-info-overlay-parent .img-info-overlay .info-overlay-container .info-overlay-icon.active .cross {
          display: none; }
  .img-info-overlay-parent:hover .img-info-overlay {
    transition: all 500ms; }
  .img-info-overlay-parent:hover .img-info-overlay .info-overlay-container .info-overlay-icon {
    opacity: 1; }

a:not(.btn-check) + .btn:hover,
a .btn:first-child:hover {
  background: inherit;
  color: inherit;
  border-color: inherit; }

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  background: inherit;
  color: inherit;
  border-color: inherit; }

.skip {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #ffffff;
  transition: all .2s ease-in-out; }
  .skip:focus {
    top: 0;
    z-index: 10000000;
    transition: all .2s ease-in-out; }

.modal-background {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  transition: right 0.4s; }

.fixed-position {
  overflow: hidden; }
  .fixed-position .modal-background {
    z-index: 10;
    right: 0;
    left: 0;
    transition: right 0.4s; }

.mxt-product-config-container .mxt-spins-fullscreen {
  z-index: 111 !important; }

.custom-visualizer-container #kmb-config-overrides .kmb-wrapper .labeled-image-option .product-info {
  z-index: 1 !important; }

.cloudnary-video {
  max-height: 100%;
  max-width: 100%; }

@media (max-width: 991.98px) {
  video::-webkit-media-controls-overlay-play-button {
    opacity: 0; } }

.video-wrapper {
  position: relative; }
  .video-wrapper .video-title {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 0; }

/*-- CLOUDINARY VIDEO --*/
.video-js .vjs-tech {
  position: static !important; }

.pageProtected .modal-content {
  padding: 15px 20px 5px;
  box-shadow: 7px 8px 4px -10px; }

.pageProtected .modal-body input {
  width: 100%;
  height: 38px; }
  .pageProtected .modal-body input:focus {
    outline: none; }
  .pageProtected .modal-body input:invalid {
    border: 1px solid #C71717; }

.pageProtected .modal-body .passwordLabel {
  margin-bottom: 8px; }

.pageProtected .modal-body .errorMsg {
  margin-top: 5px;
  font-size: 12px;
  color: #C71717; }

.pageProtected .modal-footer .button {
  border: 1px solid #000000;
  background: #ffffff; }
  .pageProtected .modal-footer .button:hover {
    background-color: black; }

.article-block.contentTile-block {
  padding: 0 0 20px; }
  .article-block.contentTile-block .image-description a {
    position: relative;
    z-index: 2; }
  .article-block.contentTile-block .contentTile-block-link {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    font-size: 0; }

.surcharge-banner {
  padding-block: 10px; }
  .surcharge-banner p {
    color: #ff0000;
    font-size: 18px;
    font-weight: 500; }

.product-information .surcharge-banner {
  margin-top: 5px; }

/*-- Gallery slider  --*/
p {
  margin: 0; }

@media (max-width: 575.98px) {
  .modal-dialog {
    padding: 0 !important; } }

.modal-dialog .modal-content {
  contain: paint; }
  .modal-dialog .modal-content button {
    border: none;
    background: transparent; }
  .modal-dialog .modal-content .download-image, .modal-dialog .modal-content .download-video {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 67px 0 81px; }
    @media (max-width: 1639.98px) {
      .modal-dialog .modal-content .download-image, .modal-dialog .modal-content .download-video {
        padding: 67px 0 77px; } }
    @media (max-width: 1199.98px) {
      .modal-dialog .modal-content .download-image, .modal-dialog .modal-content .download-video {
        padding: 46px 0 98px; } }
    @media (max-width: 991.98px) {
      .modal-dialog .modal-content .download-image, .modal-dialog .modal-content .download-video {
        padding: 46px 0 59px; } }
    @media (max-width: 767.98px) {
      .modal-dialog .modal-content .download-image, .modal-dialog .modal-content .download-video {
        padding: 46px 0 82px; } }
    @media (max-width: 575.98px) {
      .modal-dialog .modal-content .download-image, .modal-dialog .modal-content .download-video {
        padding: 36px 0 116px;
        justify-content: start; } }
    .modal-dialog .modal-content .download-image .download-icon, .modal-dialog .modal-content .download-video .download-icon {
      background-image: url(../images/download-ico.png);
      height: 20px;
      min-width: 170px;
      background-repeat: no-repeat;
      background-size: contain;
      color: #000000;
      font-size: 13px;
      font-weight: 600; }
  .modal-dialog .modal-content .close-button {
    padding: 50px 0 0 60px; }
    @media (max-width: 1639.98px) {
      .modal-dialog .modal-content .close-button {
        padding: 50px 0 0 50px; } }
    @media (max-width: 1199.98px) {
      .modal-dialog .modal-content .close-button {
        padding: 30px 0 0 45px; } }
    @media (max-width: 991.98px) {
      .modal-dialog .modal-content .close-button {
        padding: 30px 0 0 40px; } }
    @media (max-width: 767.98px) {
      .modal-dialog .modal-content .close-button {
        padding: 29px 0 0 35px; } }
    @media (max-width: 575.98px) {
      .modal-dialog .modal-content .close-button {
        padding: 20px 0 0 20px; } }
    .modal-dialog .modal-content .close-button .close-btn {
      background-image: url(../images/close-icon.svg);
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-position: center; }
  .modal-dialog .modal-content .product-image-carousel {
    padding-bottom: 50px; }
    @media (max-width: 575.98px) {
      .modal-dialog .modal-content .product-image-carousel {
        padding-bottom: 74px; } }
    .modal-dialog .modal-content .product-image-carousel .image-carousel {
      position: relative; }
  .modal-dialog .modal-content .kii-gallery-carousel-controls {
    padding: 0 10px; }
    @media (min-width: 576px) {
      .modal-dialog .modal-content .kii-gallery-carousel-controls {
        padding: 0 25px; } }
    @media (min-width: 768px) {
      .modal-dialog .modal-content .kii-gallery-carousel-controls {
        padding: 0 30px; } }
    @media (min-width: 992px) {
      .modal-dialog .modal-content .kii-gallery-carousel-controls {
        padding: 0 35px; } }
    @media (min-width: 1200px) {
      .modal-dialog .modal-content .kii-gallery-carousel-controls {
        padding: 0 40px; } }
    @media (min-width: 1640px) {
      .modal-dialog .modal-content .kii-gallery-carousel-controls {
        padding: 0 50px; } }
    .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-prev,
    .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-next {
      border: 1px solid #000000;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 575.98px) {
        .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-prev,
        .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-next {
          width: 45px;
          height: 45px; } }
    .modal-dialog .modal-content .kii-gallery-carousel-controls .carousel-control-prev-icon {
      position: relative;
      left: 0;
      height: 29px;
      width: 29px;
      background-image: url(../images/carousel/left-slide-show-arrow.svg); }
      @media (max-width: 575.98px) {
        .modal-dialog .modal-content .kii-gallery-carousel-controls .carousel-control-prev-icon {
          width: 20px;
          height: 20px; } }
    .modal-dialog .modal-content .kii-gallery-carousel-controls .carousel-control-next-icon {
      position: relative;
      left: 0;
      height: 29px;
      width: 29px;
      background-image: url(../images/carousel/right-slide-show-arrow.svg); }
      @media (max-width: 575.98px) {
        .modal-dialog .modal-content .kii-gallery-carousel-controls .carousel-control-next-icon {
          width: 20px;
          height: 20px; } }
    .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-next {
      margin-left: auto; }
      @media (max-width: 575.98px) {
        .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-next {
          margin-left: 0; } }
      .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-next:hover .carousel-control-next-icon {
        left: 4px;
        transition: 0.3s; }
    .modal-dialog .modal-content .kii-gallery-carousel-controls .kii-carousel-prev:hover .carousel-control-prev-icon {
      left: -4px;
      transition: 0.3s; }
    .modal-dialog .modal-content .kii-gallery-carousel-controls.previous-control {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%); }
      @media (max-width: 575.98px) {
        .modal-dialog .modal-content .kii-gallery-carousel-controls.previous-control {
          position: absolute;
          top: 100%;
          left: 36%;
          bottom: 0;
          transform: translate(-50%, 0); } }
    .modal-dialog .modal-content .kii-gallery-carousel-controls.next-control {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%); }
      @media (max-width: 575.98px) {
        .modal-dialog .modal-content .kii-gallery-carousel-controls.next-control {
          bottom: 0;
          position: absolute;
          right: 40%;
          top: 100%;
          transform: translate(50%, 0); } }
  .modal-dialog .modal-content .image-below-text {
    margin-top: 20px; }
    @media (max-width: 575.98px) {
      .modal-dialog .modal-content .image-below-text {
        margin-top: 70px; } }
    .modal-dialog .modal-content .image-below-text .product-description {
      margin-top: 10px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400; }
      .modal-dialog .modal-content .image-below-text .product-description br {
        display: none; }
      .modal-dialog .modal-content .image-below-text .product-description p {
        font-size: 13px;
        white-space: normal; }
        .modal-dialog .modal-content .image-below-text .product-description p span {
          margin: 0 10px; }
    .modal-dialog .modal-content .image-below-text h5, .modal-dialog .modal-content .image-below-text .h5 {
      font-size: 13px;
      letter-spacing: 0.65px; }
  .modal-dialog .modal-content .product-image {
    text-align: center;
    position: relative;
    overflow: hidden; }
    @media (max-width: 575.98px) {
      .modal-dialog .modal-content .product-image {
        margin-bottom: 15px; } }
    .modal-dialog .modal-content .product-image img {
      max-width: 1020px;
      width: auto;
      max-height: 720px; }
      @media (max-width: 1639.98px) {
        .modal-dialog .modal-content .product-image img {
          max-width: 100%; } }
  .modal-dialog .modal-content .product-image-carousel .video-carousel video, .modal-dialog .modal-content .product-image-carousel .vimeo-frame video {
    max-width: 1020px;
    max-height: 600px; }
  .modal-dialog .modal-content .product-image-carousel .video-carousel .vimeo-video-iframe, .modal-dialog .modal-content .product-image-carousel .vimeo-frame .vimeo-video-iframe {
    height: 100vh;
    max-height: 750px; }
    @media (max-width: 767.98px) {
      .modal-dialog .modal-content .product-image-carousel .video-carousel .vimeo-video-iframe, .modal-dialog .modal-content .product-image-carousel .vimeo-frame .vimeo-video-iframe {
        height: 50vh; } }
    @media (max-width: 575.98px) {
      .modal-dialog .modal-content .product-image-carousel .video-carousel .vimeo-video-iframe, .modal-dialog .modal-content .product-image-carousel .vimeo-frame .vimeo-video-iframe {
        height: 40vh; } }

.download-popup-overlay {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 360px;
  inline-size: 100%;
  text-align: left;
  padding-top: 20px;
  z-index: 999;
  transition: all;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 0; }
  @media (max-width: 575.98px) {
    .download-popup-overlay {
      max-width: 312px; } }
  .download-popup-overlay.active {
    display: block;
    transition: 0.5s; }
  .download-popup-overlay .download-overlay .progress-append, .download-popup-overlay .download-overlay .overlay-title {
    padding-inline: 20px; }
  .download-popup-overlay .download-overlay .progress-append:last-of-type {
    margin-bottom: 50px; }
  .download-popup-overlay p {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.65px;
    line-height: 23px;
    margin-bottom: 7px; }
  .download-popup-overlay span {
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 17px;
    margin-bottom: 20px;
    word-break: break-all; }
  .download-popup-overlay .progress {
    height: 4px;
    margin-top: 15px;
    background: #a0a0a0; }
    @media (max-width: 767.98px) {
      .download-popup-overlay .progress {
        margin-top: 18px; } }
    .download-popup-overlay .progress .progress-bar {
      background-color: #ffffff; }
  .download-popup-overlay .download-info {
    margin-bottom: 20px; }
    .download-popup-overlay .download-info p {
      font-weight: 200; }
  .download-popup-overlay .download-picture {
    padding: 13px 20px;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #000000; }
    .download-popup-overlay .download-picture .imageDownload {
      color: #ffffff;
      font-size: 13px;
      line-height: 20px;
      position: relative;
      padding-left: 44px;
      background: transparent;
      border: 0; }
      .download-popup-overlay .download-picture .imageDownload::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("../images/hero-banner/info-cross.svg");
        height: 30px;
        width: 30px;
        background-size: 11px;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid;
        border-radius: 50%;
        padding: 10px; }

.wishlist-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #e0e7e7;
  padding: 5px 20px 3.5px 21px;
  border-radius: 10px;
  z-index: 8; }
  @media (max-width: 575.98px) {
    .wishlist-modal {
      width: 80%; } }
  .wishlist-modal .modal-dialog .modal-content {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .wishlist-modal .modal-dialog .modal-content .modal-text {
      font-size: 13px;
      font-weight: 600; }
    .wishlist-modal .modal-dialog .modal-content .close-button {
      padding: 0px 8.5px 16px 19px; }
      .wishlist-modal .modal-dialog .modal-content .close-button .close-btn {
        height: 10px;
        width: 10px;
        position: relative; }
        .wishlist-modal .modal-dialog .modal-content .close-button .close-btn::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          height: 30px;
          width: 30px;
          background: #f6f8f8;
          z-index: -1;
          transform: translate(-50%, -50%);
          border-radius: 50px; }

.experience-kii_component-IFrame {
  margin: 0; }

/*-- --*/
@media (max-width: 767.98px) {
  .document-wrapper .region.col-sm-6 {
    width: 100%; } }

.document-wrapper .image-container {
  width: 180px; }
  @media (max-width: 1639.98px) {
    .document-wrapper .image-container {
      width: 130px; } }
  @media (max-width: 1199.98px) {
    .document-wrapper .image-container {
      width: 200px; } }
  @media (max-width: 991.98px) {
    .document-wrapper .image-container {
      width: 102px; } }
  @media (max-width: 767.98px) {
    .document-wrapper .image-container {
      width: 162px; } }
  @media (max-width: 575.98px) {
    .document-wrapper .image-container {
      width: 155px; } }
  .document-wrapper .image-container img {
    width: 100%; }

.document-wrapper h5, .document-wrapper .h5 {
  margin-top: 10px;
  font-size: 14px;
  line-height: 25px;
  word-break: break-all; }

.document-wrapper .product-download {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 47px;
  min-height: 100px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .document-wrapper .product-download {
      display: grid;
      margin-bottom: 40px; } }
  .document-wrapper .product-download .image-container {
    overflow: inherit; }
    .document-wrapper .product-download .image-container img {
      width: 100%; }
  .document-wrapper .product-download .product-tile-body {
    padding: 0 10px; }

.document-wrapper .link-icon-btn {
  font-size: 18px;
  padding: 3px 6px 3px 24px;
  position: relative;
  margin-top: 30px;
  display: inline-block;
  color: #000000;
  margin-top: 15px;
  font-size: 13px;
  padding: 3px 6px 3px 17px; }
  .document-wrapper .link-icon-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 11px;
    height: 14px;
    background-image: url("../images/download-icon.svg");
    background-repeat: no-repeat;
    background-position: center; }

.document-wrapper .s-cat-title {
  display: block;
  margin-top: 10px;
  font-size: 12px; }

.document-wrapper .view-now {
  margin-top: 17px; }

.image-download-wrapper {
  position: relative; }
  .image-download-wrapper .image-download-icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
    text-align: center;
    z-index: 8;
    opacity: 0;
    transition: all 500ms;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%; }
    @media (max-width: 767.98px) {
      .image-download-wrapper .image-download-icon {
        opacity: 1;
        z-index: 1; } }
    .image-download-wrapper .image-download-icon a {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../images/hero-banner/download-arrow.svg");
      background-repeat: no-repeat;
      background-position: center; }
  .image-download-wrapper:hover .image-download-icon {
    opacity: 1; }

.cld-video-player .vjs-big-play-button {
  background-color: #ffffff !important;
  width: 50px !important;
  height: 50px !important; }
  .cld-video-player .vjs-big-play-button .vjs-icon-placeholder {
    display: none !important; }
  .cld-video-player .vjs-big-play-button:focus {
    background-color: #ffffff !important; }
  .cld-video-player .vjs-big-play-button:before {
    content: 'PLAY' !important;
    color: #000;
    font-size: 13px;
    font-weight: 600;
    padding: 0 !important;
    height: 100%;
    width: 100% !important;
    position: absolute !important;
    top: 100%;
    line-height: 16px;
    left: 0;
    transform: translate(0, -63%);
    letter-spacing: 0.65px; }

video {
  cursor: pointer; }

.plp-video video {
  max-height: 180px; }

.video-wrapper {
  position: relative; }
  .video-wrapper .video-play-pause-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -56%);
    border: 0;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    font-size: 13px;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    line-height: 16px;
    color: #000000 !important;
    font-weight: 600;
    pointer-events: none; }
  .video-wrapper .plp-video-payer.active + .video-play-pause-btn {
    display: none; }
  .video-wrapper .video-title {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 0; }

.modal-dialog .video-carousel {
  position: relative; }
  .modal-dialog .video-carousel .video-play-pause-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -56%);
    border: 0;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    font-size: 13px;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    line-height: 16px;
    color: #000000 !important;
    font-weight: 600;
    pointer-events: none;
    padding: 0; }
  .modal-dialog .video-carousel .plp-video-payer.active + .video-play-pause-btn {
    display: none; }

.searchImagesResult {
  padding: 0; }
  .searchImagesResult .video-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    background: #000;
    width: 48%; }
    @media (min-width: 768px) {
      .searchImagesResult .video-wrapper {
        width: 31%; } }
    @media (min-width: 992px) {
      .searchImagesResult .video-wrapper {
        width: 23%; } }
    @media (min-width: 1200px) {
      .searchImagesResult .video-wrapper {
        width: 23.5%; } }
    @media (min-width: 1640px) {
      .searchImagesResult .video-wrapper {
        width: 24%; } }

.image-with-text {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (min-width: 576px) {
    .image-with-text {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .image-with-text {
      padding-top: 29px;
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .image-with-text {
      padding-top: 35px;
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .image-with-text {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .image-with-text {
      padding-top: 50px;
      padding-bottom: 50px; } }

.image-with-text.article-block .image-description {
  margin-top: 20px; }
  .image-with-text.article-block .image-description .image-description-text {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300; }

@font-face {
  font-family: 'VideoJS';
  src: url("https://vjs.zencdn.net/f/1/vjs.eot");
  src: url("https://vjs.zencdn.net/f/1/vjs.eot?#iefix") format("embedded-opentype"), url("https://vjs.zencdn.net/f/1/vjs.woff") format("woff"), url("https://vjs.zencdn.net/f/1/vjs.ttf") format("truetype"); }

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
  content: "\f103";
  font-family: 'VideoJS';
  color: #fff; }

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
  content: "\f107";
  font-family: 'VideoJS';
  color: #fff; }

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: "\f101";
  font-family: 'VideoJS';
  color: #fff; }

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
  content: "\f121";
  font-family: 'VideoJS';
  color: #fff; }

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
  content: "\f108";
  font-family: 'VideoJS';
  color: #fff; }

.grid-content .product-option:not(:first-child),
.grid-content .product-option:first-child {
  margin-top: 0; }

.mxt-product-config-container .mxt-spins-fullscreen {
  z-index: 111 !important; }

.custom-visualizer-container #kmb-config-overrides .kmb-wrapper .labeled-image-option .product-info {
  z-index: 1 !important; }

.vjs-control-bar [class*="time"] span {
  color: #fff; }

.back-to-top-wrapper {
  position: fixed;
  bottom: 115px;
  right: -3%;
  width: 100%;
  height: 0;
  z-index: 99; }
  .back-to-top-wrapper .container, .back-to-top-wrapper .sticky-filter.applied-filter-sticky .applied-filters, .sticky-filter.applied-filter-sticky .back-to-top-wrapper .applied-filters {
    height: 0; }
  @media (max-width: 575.98px) {
    .back-to-top-wrapper {
      bottom: 120px; } }
  @media (min-width: 1200px) {
    .back-to-top-wrapper {
      right: -25px; } }
  .back-to-top-wrapper .btn-back-to-top {
    height: 35px;
    width: 35px;
    background-color: #000000;
    border-radius: 50%;
    border: 0;
    display: none;
    padding: 0; }
    .back-to-top-wrapper .btn-back-to-top:focus {
      box-shadow: none; }
    @media (min-width: 1200px) {
      .back-to-top-wrapper .btn-back-to-top {
        height: 50px;
        width: 50px; } }
    .back-to-top-wrapper .btn-back-to-top img {
      height: 19px;
      width: 19px;
      position: relative;
      top: -2px; }
      @media (min-width: 1200px) {
        .back-to-top-wrapper .btn-back-to-top img {
          height: 29px;
          width: 29px;
          top: 0; } }

.psmtc_2Vvg {
  z-index: 2147483647 !important;
  position: fixed !important;
  bottom: 55px !important;
  right: 210px !important;
  left: auto !important;
  display: none !important;
  /*  @include media-breakpoint-down(xxl) {
    bottom: 47px !important;
    right: 95px !important;
  }
  @include media-breakpoint-down(xl) {
    bottom: 47px !important;
    right: 60px !important;
  }
  @include media-breakpoint-down(lg) {
    bottom: 47px !important;
    right: 65px !important;
  }
  @include media-breakpoint-down(md) {
    bottom: 92px !important;
    right: 55px !important;
  }
  @include media-breakpoint-down(sm) {
    bottom: 76px !important;
    right: 63px !important
  } */ }

.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat !important; }

@font-face {
  font-family: 'slick';
  src: url("./fonts/slick.eot") !important;
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg") !important;
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'slick';
  src: url("../fonts/slick.eot") !important;
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg") !important;
  font-weight: normal;
  font-style: normal;
  display: none; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .request-quote .col-sm-6 {
    width: 55%; }
    .request-quote .col-sm-6 + .col-sm-6 {
      width: 45%; } }

@media (max-width: 767.98px) {
  .request-quote .col-sm-6 {
    order: 2;
    width: 100%; }
    .request-quote .col-sm-6 + .col-sm-6 {
      order: 1;
      width: 100%; } }

#zones .text-component-container h1, #zones .text-component-container .h1, #zones .text-component-container .material-details-wrap .md-title, .material-details-wrap #zones .text-component-container .md-title {
  color: black; }

.cld-video-player .vjs-control-bar {
  display: flex !important; }
  .cld-video-player .vjs-control-bar .vjs-play-progress:before {
    font-family: VideoJS;
    color: #fff; }
  .cld-video-player .vjs-control-bar .vjs-progress-holder {
    margin: 0 10px; }

/*-- CLOUDINARY VIDEO --*/
.video-js {
  width: 100%;
  height: auto; }
  .video-js .vjs-tech {
    position: static; }
  .video-js .vjs-big-play-button {
    z-index: 9 !important; }
  .video-js .vjs-play-progress {
    background: #fff !important; }
  .video-js .vjs-slider {
    background: rgba(115, 133, 159, 0.5) !important; }
  .video-js .vjs-load-progress div {
    background: rgba(115, 133, 159, 0.75) !important; }

#ContainingElementId .mxt-desktop-side-screen-content .multiscreen .sb-header,
#ContainingElementId .mxt-desktop-side-screen-content .multiscreen header {
  z-index: 9; }

.discontinue {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  padding: 3px 10px;
  color: #fff !important;
  display: block !important;
  margin: 0 !important;
  text-transform: uppercase;
  letter-spacing: 0.65px;
  font-size: 13px;
  font-weight: 600 !important; }
  .discontinue .md-subtitle span {
    color: #ffffff; }

.related-products .content-small-image-tile-block .image-container img,
.related-products .content-large-image-tile-block .image-container img {
  object-fit: cover;
  height: 193px; }
  @media (min-width: 576px) {
    .related-products .content-small-image-tile-block .image-container img,
    .related-products .content-large-image-tile-block .image-container img {
      height: 221px; } }
  @media (min-width: 768px) {
    .related-products .content-small-image-tile-block .image-container img,
    .related-products .content-large-image-tile-block .image-container img {
      height: 171px; } }
  @media (min-width: 992px) {
    .related-products .content-small-image-tile-block .image-container img,
    .related-products .content-large-image-tile-block .image-container img {
      height: 225px; } }
  @media (min-width: 1640px) {
    .related-products .content-small-image-tile-block .image-container img,
    .related-products .content-large-image-tile-block .image-container img {
      height: 230px; } }

.related-products .content-small-image-tile-block {
  flex: 0 0 auto;
  width: 197px; }
  @media (min-width: 576px) {
    .related-products .content-small-image-tile-block {
      width: 182px; } }
  @media (min-width: 768px) {
    .related-products .content-small-image-tile-block {
      width: 122px; } }
  @media (min-width: 992px) {
    .related-products .content-small-image-tile-block {
      width: 157px; } }
  @media (min-width: 1200px) {
    .related-products .content-small-image-tile-block {
      width: 170px; } }
  @media (min-width: 1640px) {
    .related-products .content-small-image-tile-block {
      width: 200px; } }

.related-products .content-large-image-tile-block {
  flex: 0 0 auto;
  width: 80%; }
  @media (min-width: 576px) {
    .related-products .content-large-image-tile-block {
      width: 364px; } }
  @media (min-width: 768px) {
    .related-products .content-large-image-tile-block {
      width: 244px; } }
  @media (min-width: 992px) {
    .related-products .content-large-image-tile-block {
      width: 314px; } }
  @media (min-width: 1200px) {
    .related-products .content-large-image-tile-block {
      width: 340px; } }
  @media (min-width: 1640px) {
    .related-products .content-large-image-tile-block {
      width: 404px; } }

.chat-button {
  background-color: #f0f7f7;
  color: #000000;
  border-color: transparent;
  width: 192px;
  height: 46px;
  line-height: 40px;
  font-size: 14px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 99; }
  .chat-button .chat-icon {
    width: 16px;
    margin-right: 12px; }

.suggestions-wrapper {
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 8px 6px -6px #9b9191; }
  @media (max-width: 991.98px) {
    .suggestions-wrapper {
      width: calc(100% - 90px);
      left: auto;
      right: 0;
      height: 100%; }
      .suggestions-wrapper:empty {
        height: auto; } }
  @media (max-width: 767.98px) {
    .suggestions-wrapper {
      width: calc(100% - 85px); } }
  @media (max-width: 575.98px) {
    .suggestions-wrapper {
      width: calc(100% - 80px); } }
  .suggestions-wrapper .suggestions {
    padding-top: 50px;
    padding-bottom: 50px; }
    @media (max-width: 991.98px) {
      .suggestions-wrapper .suggestions {
        padding-top: 30px;
        border-top: 1px solid #000000; } }
    @media (max-width: 767.98px) {
      .suggestions-wrapper .suggestions {
        padding-top: 25px; } }
    .suggestions-wrapper .suggestions .suggestion-title {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.65px;
      line-height: 26px; }
    .suggestions-wrapper .suggestions .sugessted-items {
      overflow-x: auto;
      scrollbar-width: 0;
      /* Firefox */ }
      .suggestions-wrapper .suggestions .sugessted-items::-webkit-scrollbar {
        width: 0;
        height: 0; }
      .suggestions-wrapper .suggestions .sugessted-items .back-btn button {
        background: transparent;
        border: none;
        margin-bottom: 15px; }
    .suggestions-wrapper .suggestions ul.list {
      white-space: nowrap; }
    @media (max-width: 991.98px) {
      .suggestions-wrapper .suggestions ul.container, .suggestions-wrapper .suggestions .sticky-filter.applied-filter-sticky ul.applied-filters, .sticky-filter.applied-filter-sticky .suggestions-wrapper .suggestions ul.applied-filters {
        padding: 0; } }
    .suggestions-wrapper .suggestions ul li.item {
      margin-right: 33px; }
      @media (min-width: 992px) {
        .suggestions-wrapper .suggestions ul li.item {
          display: inline-block; } }
      .suggestions-wrapper .suggestions ul li.item a {
        font-size: 25px;
        line-height: 40px;
        font-weight: 300; }
        @media (max-width: 991.98px) {
          .suggestions-wrapper .suggestions ul li.item a {
            font-size: 22px; } }
      .suggestions-wrapper .suggestions ul li.item:last-child {
        margin-right: 0; }
    .suggestions-wrapper .suggestions .more-below {
      display: none; }

.dealersLocationPart {
  padding: 57px 0 100px; }
  .dealersLocationPart form {
    margin-bottom: 27px; }
    .dealersLocationPart form .formControl {
      width: 100%;
      display: flex;
      align-items: center;
      height: 16px; }
      .dealersLocationPart form .formControl input {
        width: 100%;
        height: 100%;
        border: none;
        letter-spacing: 0.65px;
        font-weight: 600;
        color: #000000;
        font-size: 13px;
        font-family: "Plus Jakarta Sans";
        text-transform: uppercase; }
        .dealersLocationPart form .formControl input:focus-visible {
          outline: 0; }
        .dealersLocationPart form .formControl input::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #000000;
          opacity: 1;
          /* Firefox */
          text-transform: uppercase; }
        .dealersLocationPart form .formControl input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #000000;
          text-transform: uppercase; }
        .dealersLocationPart form .formControl input::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #000000;
          text-transform: uppercase; }
      .dealersLocationPart form .formControl .searchBtn {
        margin-right: 16px;
        width: 100%;
        max-width: 13px;
        height: 13px;
        background: none;
        border: none; }
        .dealersLocationPart form .formControl .searchBtn img {
          vertical-align: top; }
  .dealersLocationPart .addressBoxMain {
    margin: 50px 0; }
    .dealersLocationPart .addressBoxMain .addressDetails {
      display: flex;
      flex-wrap: wrap;
      padding: 50px 0;
      border-bottom: 1px solid #000000; }
      .dealersLocationPart .addressBoxMain .addressDetails:first-child {
        border-top: 1px solid #000000; }
      .dealersLocationPart .addressBoxMain .addressDetails .locationName {
        width: 100%;
        max-width: 239px;
        margin-right: 22px; }
        @media (max-width: 767.98px) {
          .dealersLocationPart .addressBoxMain .addressDetails .locationName {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 20px; } }
      .dealersLocationPart .addressBoxMain .addressDetails h5, .dealersLocationPart .addressBoxMain .addressDetails .h5, .dealersLocationPart .addressBoxMain .addressDetails h6, .dealersLocationPart .addressBoxMain .addressDetails .h6 {
        font-weight: 600;
        color: #000000;
        font-size: 13px;
        font-family: "Plus Jakarta Sans";
        line-height: 20px; }
      .dealersLocationPart .addressBoxMain .addressDetails .locationBox {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
        max-width: calc(100% - 261px);
        gap: 50px 22px; }
        @media (max-width: 1199.98px) {
          .dealersLocationPart .addressBoxMain .addressDetails .locationBox {
            gap: 20 22px;
            grid-template-columns: repeat(4, 1fr); } }
        @media (max-width: 767.98px) {
          .dealersLocationPart .addressBoxMain .addressDetails .locationBox {
            max-width: 100%;
            grid-template-columns: repeat(3, 1fr); } }
        @media (max-width: 575.98px) {
          .dealersLocationPart .addressBoxMain .addressDetails .locationBox {
            grid-template-columns: repeat(2, 1fr); } }
        .dealersLocationPart .addressBoxMain .addressDetails .locationBox .fullAddress p {
          font-weight: 400;
          color: #000000;
          font-size: 13px;
          font-family: "Plus Jakarta Sans";
          margin-top: 10px;
          line-height: normal; }
  .dealersLocationPart #continuesBtn {
    background: none;
    border: none;
    color: #FF00CE;
    font-size: 18px;
    line-height: 28px;
    font-family: "Plus Jakarta Sans";
    font-weight: 400; }

.filter-bar {
  padding: 5px 0px;
  background-color: #ffffff;
  width: 100%; }
  @media (max-width: 1639.98px) {
    .filter-bar {
      top: -47px;
      padding: 10px 0; } }
  @media (max-width: 1199.98px) {
    .filter-bar {
      padding: 6px 0; } }
  .filter-bar.filter-shadow {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07); }
  .filter-bar.filter-sticky {
    position: fixed;
    padding: 15px 0px 5px;
    width: 100%;
    background: #ffffff;
    top: 60px;
    z-index: 10;
    right: 0; }
    @media (max-width: 1639.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 40px 5px; } }
    @media (min-width: 992px) {
      .filter-bar.filter-sticky {
        top: 120px; } }
    @media (max-width: 991.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 30px 5px; } }
    @media (max-width: 767.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 25px 5px; } }
    @media (max-width: 575.98px) {
      .filter-bar.filter-sticky {
        padding: 15px 20px 5px; } }
    .filter-bar.filter-sticky .search-field {
      top: 23px; }
      @media (max-width: 575.98px) {
        .filter-bar.filter-sticky .search-field {
          margin-top: 0; } }
      @media (max-width: 767.98px) {
        .filter-bar.filter-sticky .search-field {
          top: 23px; } }
  .filter-bar .filters {
    flex-wrap: nowrap;
    max-width: 1540px;
    margin: auto; }
    @media (max-width: 767.98px) {
      .filter-bar .filters {
        flex-wrap: wrap; } }
    .filter-bar .filters .button-filter {
      position: relative;
      z-index: 3;
      margin-bottom: 10px;
      margin-right: 10px; }
      .filter-bar .filters .button-filter:hover {
        background-color: #956839;
        color: #ffffff; }
    .filter-bar .filters .show-filter-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #ffffff;
      padding: 76px 0px 50px;
      z-index: 2;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07); }
      @media (max-width: 991.98px) {
        .filter-bar .filters .show-filter-bar {
          padding: 60px 0 40px; } }
      @media (max-width: 767.98px) {
        .filter-bar .filters .show-filter-bar {
          padding: 50px 0 20px; } }
      .filter-bar .filters .show-filter-bar .nav-pills .nav-link {
        color: #000000;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        padding: 0;
        text-align: left; }
        @media (min-width: 768px) {
          .filter-bar .filters .show-filter-bar .nav-pills .nav-link {
            margin-inline-end: auto; } }
        .filter-bar .filters .show-filter-bar .nav-pills .nav-link:hover {
          color: #956839; }
        .filter-bar .filters .show-filter-bar .nav-pills .nav-link.active {
          background-color: #ffffff;
          color: #956839; }
      .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
        display: inline-grid;
        grid-auto-flow: column;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
        overflow: auto; }
        @media (max-width: 767.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            display: block;
            column-count: 2; } }
        @media (max-width: 991.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            column-gap: 20px; } }
        @media (max-width: 767.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            column-gap: 10px; } }
        @media (min-width: 992px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane {
            column-gap: 40px; } }
        .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item,
        .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item {
          width: 100%; }
          @media (min-width: 992px) {
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item,
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item {
              width: 50%; } }
          @media (min-width: 992px) {
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item,
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item {
              width: 20%; } }
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link,
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link {
            font-size: 13px;
            line-height: 26px;
            color: #000000;
            border: none;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            white-space: nowrap;
            margin-left: 15px; }
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link span,
            .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link span {
              white-space: normal;
              text-align: left; }
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link span:hover,
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link span:hover {
                color: #956839; }
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item .link span.selected,
              .filter-bar .filters .show-filter-bar .tab-content .tab-pane .custom-filter-item .link span.selected {
                color: #956839; }
        @media (min-width: 992px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane .filter-item {
            width: auto; } }
        .filter-bar .filters .show-filter-bar .tab-content .tab-pane:not(.active) {
          height: 0;
          display: none; }
        @media (max-width: 767.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane.active {
            padding-bottom: 40px; } }
        @media (max-width: 575.98px) {
          .filter-bar .filters .show-filter-bar .tab-content .tab-pane.active {
            margin-top: 25px; } }
      @media (max-width: 991.98px) {
        .filter-bar .filters .show-filter-bar .tab-content #refinement-brand {
          column-count: unset; } }
      .filter-bar .filters .show-filter-bar .tab-content .custom-filter-item,
      .filter-bar .filters .show-filter-bar .tab-content .filter-item {
        position: relative; }
        .filter-bar .filters .show-filter-bar .tab-content .custom-filter-item img,
        .filter-bar .filters .show-filter-bar .tab-content .filter-item img {
          position: absolute;
          left: 0;
          height: 8px;
          top: 8px; }
      .filter-bar .filters .show-filter-bar .nav button {
        position: relative; }
        .filter-bar .filters .show-filter-bar .nav button.unchecked {
          color: #000000; }
          .filter-bar .filters .show-filter-bar .nav button.unchecked::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            height: 19px;
            width: 19px;
            border: 1px solid #000000;
            border-radius: 50px;
            transform: translate(0, -50%); }
          .filter-bar .filters .show-filter-bar .nav button.unchecked.nav-link {
            padding-left: 31px; }
        .filter-bar .filters .show-filter-bar .nav button.checked {
          color: #956839; }
          .filter-bar .filters .show-filter-bar .nav button.checked::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            height: 19px;
            width: 19px;
            border: none;
            background-image: url(../images/products/checked-icon.svg);
            transform: translate(0, -50%); }
          .filter-bar .filters .show-filter-bar .nav button.checked.nav-link {
            padding-left: 31px; }
      .filter-bar .filters .show-filter-bar .nav .custom-filters {
        margin-top: 10px; }
        .filter-bar .filters .show-filter-bar .nav .custom-filters .filter-value {
          margin-top: 8px; }
          .filter-bar .filters .show-filter-bar .nav .custom-filters .filter-value:first-child {
            margin-top: 0; }
      .filter-bar .filters .show-filter-bar .col-6,
      .filter-bar .filters .show-filter-bar .filter-left-block,
      .filter-bar .filters .show-filter-bar .filter-right-block {
        padding: 0;
        gap: 10px 0; }
  @media (max-width: 575.98px) {
    .filter-bar {
      order: 1; } }

.sticky-filter.applied-filter-sticky {
  position: fixed;
  right: 0;
  top: 118px;
  background: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
  padding: 5px 0px 5px;
  width: 100%;
  z-index: 9; }
  @media (min-width: 992px) {
    .sticky-filter.applied-filter-sticky {
      top: 177px; } }
  .sticky-filter.applied-filter-sticky .applied-filters .applied-filter-list {
    margin-bottom: 5px; }

.applied-filters {
  width: 100%;
  display: flex;
  margin: auto;
  position: relative;
  z-index: 1;
  gap: 0 30px; }
  @media (max-width: 575.98px) {
    .applied-filters {
      gap: 0 10px; } }
  @media screen and (max-width: 360px) {
    .applied-filters {
      flex-wrap: wrap;
      justify-content: space-between; } }
  .applied-filters p {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    align-self: flex-start;
    letter-spacing: 0.65px; }
    @media (max-width: 575.98px) {
      .applied-filters p {
        font-size: 12px;
        word-break: break-word;
        max-width: 220px; } }
  .applied-filters .applied-filter-list {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 11px 30px;
    flex-wrap: wrap;
    max-width: calc(100% - 46px);
    overflow-x: auto;
    cursor: auto;
    margin-bottom: 30px;
    margin-left: 4px;
    width: 80%; }
    @media (min-width: 1640px) {
      .applied-filters .applied-filter-list {
        margin-left: 8px; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .applied-filters .applied-filter-list {
        width: 74%; } }
    @media (max-width: 575.98px) {
      .applied-filters .applied-filter-list {
        width: 99%;
        gap: 10px 20px;
        max-width: 100%; } }
    @media screen and (max-width: 360px) {
      .applied-filters .applied-filter-list {
        width: 100%; } }
    @media (min-width: 768px) {
      .applied-filters .applied-filter-list {
        width: 80%; } }
    @media (min-width: 1200px) {
      .applied-filters .applied-filter-list {
        width: 85%; } }
    @media (max-width: 767.98px) {
      .applied-filters .applied-filter-list {
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .applied-filters .applied-filter-list {
        gap: 10px 20px;
        max-width: 100%; } }
    .applied-filters .applied-filter-list::-webkit-scrollbar {
      width: 0;
      height: 0; }
    .applied-filters .applied-filter-list .filter-value,
    .applied-filters .applied-filter-list .image-video {
      float: inherit;
      display: flex;
      gap: 0 7px;
      white-space: pre-wrap; }
      .applied-filters .applied-filter-list .filter-value button,
      .applied-filters .applied-filter-list .image-video button {
        margin: 0;
        display: flex; }
      .applied-filters .applied-filter-list .filter-value span,
      .applied-filters .applied-filter-list .image-video span {
        color: #956839;
        font-size: 13px; }
    .applied-filters .applied-filter-list .custom-filter-reset,
    .applied-filters .applied-filter-list .custom-filter-clearfilter {
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 0 5px;
      white-space: nowrap; }
      .applied-filters .applied-filter-list .custom-filter-reset span,
      .applied-filters .applied-filter-list .custom-filter-clearfilter span {
        color: #ce470d;
        text-transform: capitalize; }
  @media (max-width: 575.98px) {
    .applied-filters {
      order: 3; } }

.filter-library {
  margin: 30px 0 40px 0; }
  .filter-library .filter-image-list {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 20px; }
    @media (max-width: 1199.98px) {
      .filter-library .filter-image-list {
        margin-bottom: 40px;
        gap: 67px; } }
    @media (max-width: 991.98px) {
      .filter-library .filter-image-list {
        gap: 20px; } }
    @media (max-width: 767.98px) {
      .filter-library .filter-image-list {
        display: block;
        margin-bottom: 20px; } }
    .filter-library .filter-image-list ul {
      display: grid;
      gap: 20px;
      grid-template-columns: auto auto auto auto auto auto auto auto auto auto; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list ul {
          display: flex;
          flex-wrap: wrap; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list ul {
          gap: 16px 20px; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list ul {
          overflow-x: auto;
          flex-wrap: nowrap;
          -ms-overflow-style: none;
          scrollbar-width: none;
          gap: 10px; } }
      .filter-library .filter-image-list ul::-webkit-scrollbar {
        display: none; }
    .filter-library .filter-image-list .filter-text {
      flex: 0 0 7.1333%; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list .filter-text {
          flex: 0 0 6.7111%; } }
      @media (max-width: 1199.98px) {
        .filter-library .filter-image-list .filter-text {
          flex: 0 0 9.66%; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list .filter-text {
          flex: 0 0 14.4%; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list .filter-text {
          margin-bottom: 10px; } }
    .filter-library .filter-image-list .product-details {
      display: block;
      width: 110px;
      overflow-wrap: break-word;
      background: none;
      border: none;
      text-align: left;
      line-height: 18px; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list .product-details {
          width: 84px; } }
      @media (max-width: 1199.98px) {
        .filter-library .filter-image-list .product-details {
          width: 137px; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list .product-details {
          width: 101px; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list .product-details {
          width: 162px; } }
      @media (max-width: 575.98px) {
        .filter-library .filter-image-list .product-details {
          width: 98px; } }
      .filter-library .filter-image-list .product-details:hover span {
        color: #956839; }
      .filter-library .filter-image-list .product-details.active-filter .product-filter-name {
        color: #956839; }
    .filter-library .filter-image-list .product-filter-name {
      font-size: 13px;
      line-height: 18px; }
    .filter-library .filter-image-list .narrow-image {
      height: 100%;
      width: 100%;
      max-height: 38px;
      margin-bottom: 5px; }
      @media (max-width: 1639.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 30px; } }
      @media (max-width: 1199.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 47px; } }
      @media (max-width: 991.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 35px; } }
      @media (max-width: 767.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 56px; } }
      @media (max-width: 575.98px) {
        .filter-library .filter-image-list .narrow-image {
          max-height: 34px; } }
    .filter-library .filter-image-list p {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.65px;
      line-height: 18px;
      text-transform: uppercase;
      white-space: break-spaces; }
      @media (min-width: 1200px) and (max-width: 1639.98px) {
        .filter-library .filter-image-list p {
          letter-spacing: 0px;
          font-size: 12px; } }

.common-listing-header .search-products .search-product {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600; }

.common-listing-header .pl-heading {
  margin: 20px 0; }

.spacer-line {
  background-color: #000000;
  margin: 0;
  border: 0;
  height: 1px;
  position: relative;
  z-index: -1; }

.square-product-grid .product .image-container {
  margin: 0 0 10px; }
  .square-product-grid .product .image-container img {
    width: 100%; }

.square-product-grid .product .title-container {
  padding: 0;
  margin-bottom: 40px; }
  .square-product-grid .product .title-container .is-Title {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    color: #000000; }
  .square-product-grid .product .title-container .price-config {
    margin: 10px 0 0;
    display: flex;
    flex-direction: column; }
    .square-product-grid .product .title-container .price-config span {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.65px;
      font-weight: 600;
      color: #000000; }

.square-product-grid .idea-started-product-list .image-container {
  min-height: 118px; }
  @media (min-width: 576px) {
    .square-product-grid .idea-started-product-list .image-container {
      min-height: 190px; } }
  @media (min-width: 768px) {
    .square-product-grid .idea-started-product-list .image-container {
      min-height: 167px; } }
  @media (min-width: 992px) {
    .square-product-grid .idea-started-product-list .image-container {
      min-height: 161px; } }
  @media (min-width: 1200px) {
    .square-product-grid .idea-started-product-list .image-container {
      min-height: 198px; } }
  @media (min-width: 1640px) {
    .square-product-grid .idea-started-product-list .image-container {
      min-height: 277px; } }
  .square-product-grid .idea-started-product-list .image-container img {
    width: 100%; }

.material-list .material-list-item .material-list-item-title {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000; }
  .material-list .material-list-item .material-list-item-title h4, .material-list .material-list-item .material-list-item-title .h4 {
    font-size: 25px;
    padding: 20px 0;
    line-height: 22px;
    font-weight: 600; }

.material-list .material-list-item .material-list-item-subtitle h6, .material-list .material-list-item .material-list-item-subtitle .h6 {
  font-size: 14px;
  padding: 20px 0 13px;
  font-weight: 600;
  line-height: 22px; }

.material-list .material-list-item .material-list-items .mli-title {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.65px; }

.material-list .material-list-item .material-list-items .product .product-tile .tile-body {
  display: flex;
  flex-direction: column; }
  .material-list .material-list-item .material-list-items .product .product-tile .tile-body span {
    font-size: 18px;
    line-height: 22px; }

.material-list .material-list-item:last-child .spacer-line {
  display: none; }

.filters .product-search {
  margin-left: 10px;
  z-index: 2; }
  .filters .product-search .suggestions-wrapper {
    position: absolute;
    top: 65px;
    z-index: 1;
    display: none !important; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .filters .product-search .suggestions-wrapper {
        left: 100px; } }
    .filters .product-search .suggestions-wrapper .suggestions {
      padding-top: 10px; }
      @media (max-width: 991.98px) {
        .filters .product-search .suggestions-wrapper .suggestions {
          padding: 10px;
          border: 0; } }
  @media screen and (max-width: 360px) {
    .filters .product-search {
      width: 100%; } }

.filters .search-products .search-product {
  display: flex; }
  .filters .search-products .search-product img {
    margin-right: 10px; }

.filters .search-products span {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 28px; }

.filters .search-field {
  position: absolute;
  left: auto;
  border: none;
  padding: 0;
  top: 13px;
  text-transform: uppercase;
  transform: translate(0px, -36%);
  line-height: 43px;
  font-weight: 600;
  font-size: 13px;
  padding: 0px 20px 0 0;
  margin-left: 27px;
  width: 200px; }
  @media (max-width: 1639.98px) {
    .filters .search-field {
      top: 18px; } }
  @media (max-width: 1199.98px) {
    .filters .search-field {
      top: 14px; } }
  @media (max-width: 991.98px) {
    .filters .search-field {
      width: auto; } }
  @media (max-width: 575.98px) {
    .filters .search-field {
      top: auto;
      margin-top: -20px;
      width: auto; } }
  .filters .search-field:focus {
    box-shadow: none; }

.filters .search-material-lib {
  width: 250px; }
  @media (max-width: 991.98px) {
    .filters .search-material-lib {
      width: 250px; } }
  @media only screen and (max-width: 312px) {
    .filters .search-material-lib {
      margin-top: -35px;
      height: 50px; } }

.filters .search-field-1 {
  position: absolute;
  z-index: -1;
  display: none;
  border: 0;
  width: auto; }
  .filters .search-field-1:focus {
    box-shadow: none; }

.filters .site-search button {
  display: none; }

.load-more-div .load-more {
  margin-bottom: 20px; }
  @media (min-width: 576px) {
    .load-more-div .load-more {
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .load-more-div .load-more {
      margin-bottom: 29px; } }
  @media (min-width: 992px) {
    .load-more-div .load-more {
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .load-more-div .load-more {
      margin-bottom: 40px; } }
  @media (min-width: 1640px) {
    .load-more-div .load-more {
      margin-bottom: 50px; } }
  .load-more-div .load-more .plp-result-count-text {
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0px;
    font-weight: normal; }
  .load-more-div .load-more .more {
    margin-top: 20px; }

.material-grid-header .applied-filters .applied-filter-list {
  margin-left: 0; }

.material-details-wrap {
  padding: 50px 0; }
  .material-details-wrap .add-to-fav {
    margin: 14px 0 17px; }
    .material-details-wrap .add-to-fav a {
      justify-content: flex-start; }
      .material-details-wrap .add-to-fav a span {
        margin-left: 7px;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.65px;
        font-weight: 600;
        color: #000000; }
  .material-details-wrap .download-btns {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; }
  .material-details-wrap .typical-thumbnails {
    padding: 20px 0; }
    .material-details-wrap .typical-thumbnails ul {
      display: flex;
      gap: 20px; }
  @media (min-width: 992px) {
    .material-details-wrap .material-details-content {
      padding-left: 126px; } }
  .material-details-wrap .material-details-content .md-subtitle {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column; }
    .material-details-wrap .material-details-content .md-subtitle h6, .material-details-wrap .material-details-content .md-subtitle .h6 {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.65px;
      font-weight: 600;
      color: #000000;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .material-details-wrap .material-details-content .md-subtitle.table span {
      width: 100%;
      display: block;
      padding: 0; }
  .material-details-wrap .material-full-image {
    min-height: 335px; }
    @media (min-width: 576px) {
      .material-details-wrap .material-full-image {
        min-height: 526px; } }
    @media (min-width: 768px) {
      .material-details-wrap .material-full-image {
        min-height: 344px; } }
    .material-details-wrap .material-full-image .primary-image-elm {
      width: 100%; }
  .material-details-wrap.ideal-starter-detail .material-details-content .md-subtitle.table {
    display: none; }
  .material-details-wrap.ideal-starter-detail .typical-thumbnails li img {
    border: 1px solid transparent; }
    .material-details-wrap.ideal-starter-detail .typical-thumbnails li img.active {
      border-color: #000; }
  .material-details-wrap .discontinue {
    padding: 20px; }

.download-items li a {
  position: relative;
  padding-left: 20px;
  display: block; }
  .download-items li a::before {
    content: "";
    position: absolute;
    background-image: url("../images/products/download-black.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    left: 0;
    top: 4px;
    width: 11px;
    height: 14px; }

.download-items li.is-download-doc a::before {
  background-image: url("../images/products/download-doc.svg"); }

.download-items li.is-download-pdf a::before {
  background-image: url("../images/products/download-pdf.svg"); }

.related-products h3, .related-products .h3 {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #956839; }
  @media (min-width: 576px) {
    .related-products h3, .related-products .h3 {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .related-products h3, .related-products .h3 {
      padding-top: 29px;
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .related-products h3, .related-products .h3 {
      padding-top: 35px;
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .related-products h3, .related-products .h3 {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .related-products h3, .related-products .h3 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.related-products .product-slider-wrapper {
  padding-bottom: 20px;
  margin-bottom: 13px; }
  @media (min-width: 576px) {
    .related-products .product-slider-wrapper {
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .related-products .product-slider-wrapper {
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .related-products .product-slider-wrapper {
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .related-products .product-slider-wrapper {
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .related-products .product-slider-wrapper {
      padding-bottom: 50px; } }
  .related-products .product-slider-wrapper .product-tile-body {
    margin-top: 20px; }
    .related-products .product-slider-wrapper .product-tile-body h4, .related-products .product-slider-wrapper .product-tile-body .h4 {
      font-size: 20px;
      line-height: 25px;
      font-weight: 600; }

.recomended-products-container {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (min-width: 576px) {
    .recomended-products-container {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .recomended-products-container {
      padding-top: 29px;
      padding-bottom: 29px; } }
  @media (min-width: 992px) {
    .recomended-products-container {
      padding-top: 35px;
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .recomended-products-container {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (min-width: 1640px) {
    .recomended-products-container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .recomended-products-container .recomended-products-title {
    margin-top: auto;
    margin-bottom: 50px; }
    @media (max-width: 991.98px) {
      .recomended-products-container .recomended-products-title {
        margin-bottom: 26px; } }
    .recomended-products-container .recomended-products-title h3, .recomended-products-container .recomended-products-title .h3 {
      color: #956839; }
  .recomended-products-container .recomended-products .product-tile-body,
  .recomended-products-container .recomended-products .title-container {
    margin-top: 10px; }
  .recomended-products-container .recomended-products h5, .recomended-products-container .recomended-products .h5 {
    font-weight: 300;
    line-height: 25px; }
  .recomended-products-container .recomended-products .price-config {
    font-size: 13px;
    letter-spacing: 0.65px;
    line-height: 20px;
    font-weight: 400; }

.ideal-starter-detail .typical-thumbnails {
  padding-bottom: 0; }
  @media (max-width: 767.98px) {
    .ideal-starter-detail .typical-thumbnails {
      padding-bottom: 40px; } }

.ideal-starter-detail .recomended-products-wrapper .recomended-products-title {
  padding-bottom: 20px; }

.ideal-starter-detail .recomended-products-wrapper .recomended-products .image-container {
  height: auto; }
  @media (min-width: 576px) {
    .ideal-starter-detail .recomended-products-wrapper .recomended-products .image-container {
      height: auto; } }

.work-able {
  margin: 50px 0; }
  @media (max-width: 991.98px) {
    .work-able {
      margin: 90px 0; } }
  .work-able .overview h5, .work-able .overview .h5 {
    margin: 20px 0; }
  .work-able .overview .overview-details thead tr td h6, .work-able .overview .overview-details thead tr td .h6 {
    margin-bottom: 10px; }
  .work-able .workable {
    width: 100%; }
  .work-able .itme-specification h5, .work-able .itme-specification .h5 {
    margin: 20px 0; }
  .work-able .itme-specification table tbody tr {
    background-color: #E9E9E9; }
    .work-able .itme-specification table tbody tr:nth-child(even) {
      background-color: transparent; }
  .work-able td, .work-able th, .work-able h5, .work-able .h5, .work-able h6, .work-able .h6 {
    color: #707070;
    font-size: 14px; }

.material-item {
  display: flex;
  flex-direction: column; }
  .material-item .image-container {
    background-color: #f7f7f7;
    position: relative;
    min-height: 118px; }
    @media (min-width: 576px) {
      .material-item .image-container {
        min-height: 190px; } }
    @media (min-width: 768px) {
      .material-item .image-container {
        min-height: 167px; } }
    @media (min-width: 992px) {
      .material-item .image-container {
        min-height: 103px; } }
    @media (min-width: 1200px) {
      .material-item .image-container {
        min-height: 128px; } }
    @media (min-width: 1640px) {
      .material-item .image-container {
        min-height: 180px; } }
    .material-item .image-container img {
      width: 100%; }
    .material-item .image-container span.material-banner {
      background: #000;
      color: #fff;
      font-size: 13px;
      line-height: 15px;
      text-transform: capitalize;
      text-align: center;
      padding: 2px 4px;
      position: absolute;
      left: 0;
      top: 20px;
      box-shadow: 0 0 5px #979696; }
  .material-item .title-container {
    padding: 10px 0 40px; }
    .material-item .title-container h6, .material-item .title-container .h6 {
      display: flex;
      flex-direction: column; }
      .material-item .title-container h6 span, .material-item .title-container .h6 span {
        font-size: 14px;
        line-height: 22px;
        font-weight: 600; }

.add-to-fav img {
  height: auto !important; }

.add-to-fav a {
  display: inline-block;
  align-items: center;
  justify-content: center; }
  .add-to-fav a .fav-checked {
    display: block; }
