.com-search {
    margin: 50px 0;
    @include media-breakpoint-down(lg) {
        margin-top: 100px;
    }
    .com-search-heading {
        letter-spacing: 0.4px;
        margin-bottom: 50px;
        font-weight: $font-weight-light;
        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }
    }

    .com-search-form {
        .search-upper-text {
            margin-bottom: 20px;
        }

        .form-heading {
            font-size: 13px;
            margin: 27px 0 20px;
            letter-spacing: 0.65px;
            text-transform: uppercase;
        }
        .form {
            .form-select {
                margin-bottom: 20px;
            }
            .submit-button {
                text-align: end;
                .button {
                    margin-top: 4px;
                }
                .button-filter {
                    padding: 17px 30px;
                    letter-spacing: 0.65px;
                }
            }
        }
    }

    .form-bottom-text {
        margin: 50px 0;
    }

    .search-results {
        border-top: 1px solid $brand-primary;
        padding-top: 20px;
        margin: 0px 0 50px 0;
        @include media-breakpoint-down(md) {
            margin: 30px 0;
        }
        .paragraph-spacing {
            margin-top: 30px;
            margin-bottom: 10px;
            font-size: 13px;
            letter-spacing: 0.65px;
            font-weight: $font-weight-semibold;
        }
        .button {
            margin-top: 30px;
            .button-outline {
                padding: 7px 12px;
            }
        }
        .left-heading {
           
            h3 {
                letter-spacing: 0.25px;
                @include media-breakpoint-down(md) {
                    font-size: 25px;
                 }
            }

        }
        .mobile-content-spacing {
            font-size: 13px;
            font-weight: $font-weight-semibold;
              margin-bottom: 10px;
                @include media-breakpoint-down(md) {
                    margin-top: 30px;
                }
        }
    }
    .show-more-details {
        @include media-breakpoint-down(md) {
            display: none;
         }
       
    }
    .guidelines  {
        border-top: 1px solid $brand-primary;
        padding-top: 50px;
        .appearance-guidelines, .restriction-guidelines {
            .text-uppercase {
                font-size: 13px;
                letter-spacing: 0.65px;
                font-weight: $font-weight-semibold;
            }
        }
    }
    ol {
        counter-reset: list;
        li {
            padding-left: 30px;
            position: relative;
            &:before {
                counter-increment: list;
                content: counter(list) " ";
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}
.needs-validation{
    .form-select {
        &.is-invalid {
            background-image: url('../../images/product-details/down-arrow.svg') !important;
            background-size: 12px !important;
        }
    }
}
.com-middle-content {
    .container {
        padding: 0;
    }
}