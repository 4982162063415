@import "~base/components/menu";
$breakpoint-name: 'lg';
$breakpoint-name: 'lg' !default;

header {
    .main-header {
        .brand {
            width: auto;
        }
        .main-menu {
            .dropdown-menu {
                padding: 30px;
                @include media-breakpoint-down(lg) {
                    box-shadow: none;
                }
                &.show {
                    display: flex;
                }
                @include media-breakpoint-up(lg) {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 141px;
                    box-shadow: none;
                    padding: 40px;
                    z-index: 4;
                }
                .cat-nav {
                    ul {
                        @include media-breakpoint-down(xl) {
                            display: flex;
                        }
                        .dropdown-item {
                            padding: 0;
                            border: none;
                            @include media-breakpoint-down(xl) {
                                width: auto;
                                &:not(:last-child) {
                                    margin-right: 28px;
                                }
                            }

                            &:hover {
                                background-color: transparent;
                            }

                            .dropdown-link {
                                color: $gray;
                                font-size: 20px;
                                padding: 0 0 20px 0;
                            }
                            &.active {
                                background-color: transparent;
                                .dropdown-link {
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                }

                .cat-nav-items {
                    .col, .nav-group {
                        .cat-nav-items-title, .cat-nav-items-title-brand {
                            text-transform: uppercase;
                            color: $brand-primary;
                            font-size: $sub-nav-link-font-size;
                            font-family: $PlusJakartaSans;
                            font-weight: 600;
                            margin-bottom: 3px;             
                            display: block; 
                            .brand-image {
                                margin-bottom: 5px;
                                @include media-breakpoint-down(lg) {
                                    display: none;
                                }
                            }
                            &.view-insight {
                                cursor: pointer;
                                display: grid;
                                gap: 10px 0;
                                &::after {
                                    display: none;
                                }
                            }
                        }

                        .cat-nav-items-list {
                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                            li {
                                padding: 0;
                                border: none;                             

                                &:hover {
                                    background-color: transparent;
                                }

                                a {
                                    color: $brand-primary;
                                    font-size: $sub-nav-link-font-size;
                                    font-family: $PlusJakartaSans;
                                    font-weight: 400;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}