@import "~core/components/common";

/*-- Gallery slider  --*/

p {
  margin: 0;
}
.modal-dialog{
     @include media-breakpoint-down(sm) {
       padding: 0 !important;
        }
.modal-content {
    contain: paint;
    button {
      border: none;
      background: transparent;
    }
    .download-image, .download-video {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 67px 0 81px;
      @include media-breakpoint-down(xxl) {
        padding: 67px 0 77px;
      }
      @include media-breakpoint-down(xl) {
        padding: 46px 0 98px;
      }
      @include media-breakpoint-down(lg) {
        padding: 46px 0 59px;
      }
      @include media-breakpoint-down(md) {
        padding: 46px 0 82px;
      }
      @include media-breakpoint-down(sm) {
        padding: 36px 0 116px;
        justify-content: start;
      }
      .download-icon {
        background-image: url(../images/download-ico.png);
        height: 20px;
        min-width: 170px;
        background-repeat: no-repeat;
        background-size: contain;
        color: $brand-primary;
        font-size: 13px;
        font-weight: $font-weight-semibold;
      }
    }
    .close-button {
        padding: 50px 0 0 60px;
        @include media-breakpoint-down(xxl) {
          padding: 50px 0 0 50px;
        }
        @include media-breakpoint-down(xl) {
          padding: 30px 0 0 45px;
        }
        @include media-breakpoint-down(lg) {
          padding: 30px 0 0 40px;
        }
        @include media-breakpoint-down(md) {
          padding: 29px 0 0 35px;
        }
        @include media-breakpoint-down(sm) {
          padding: 20px 0 0 20px;
        }
      .close-btn {
        background-image: url(../images/close-icon.svg);
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .product-image-carousel {
        padding-bottom: 50px;
        @include media-breakpoint-only(xs) {
          padding-bottom: 74px;
        }
      .image-carousel {
        position: relative;
      }
    }
    .kii-gallery-carousel-controls {
        padding: 0 10px;
        @include media-breakpoint-up(sm) {
            padding: 0 25px;
        }
        @include media-breakpoint-up(md) {
            padding: 0 30px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 35px;
        }
        @include media-breakpoint-up(xl) {
            padding: 0 40px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 0 50px;
        }
    // @include media-breakpoint-down(sm) {
    // margin: 0;
    // }
    .kii-carousel-prev,
    .kii-carousel-next {
      border: 1px solid $brand-primary;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
     @include media-breakpoint-down(sm) {
      width: 45px;
      height: 45px;
      }
    }
    .carousel-control-prev-icon {
      position: relative;
      left: 0;
      height: 29px;
      width: 29px;
      background-image: url(../images/carousel/left-slide-show-arrow.svg);
      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
        }
    }
    .carousel-control-next-icon {
      position: relative;
      left: 0;
      height: 29px;
      width: 29px;
      background-image: url(../images/carousel/right-slide-show-arrow.svg);
      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
        }
    }
    .kii-carousel-next {
      margin-left: auto;
      @include media-breakpoint-down(sm) {
       margin-left: 0;
        }
        &:hover {
          .carousel-control-next-icon {
            left: 4px;
            transition: 0.3s;
          }
        }
    }
    .kii-carousel-prev {
        &:hover {
          .carousel-control-prev-icon {
            left: -4px;
            transition: 0.3s;
          }
        }
    }
    &.previous-control {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
         @include media-breakpoint-only(xs) {
            position: absolute;
            top: 100%;
            left: 36%;
            bottom: 0;
            transform: translate(-50%, 0);
        }
    }
    &.next-control {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      @include media-breakpoint-only(xs) {
        bottom: 0;
        position: absolute;
        right: 40%;
        top: 100%;
        transform: translate(50%, 0);
      }
    }
    }
    .image-below-text {
      margin-top: 20px;
      @include media-breakpoint-only(xs) {
        margin-top: 70px;
    }
      .product-description {
        margin-top: 10px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        br {
          display: none;
        }
        p {
          font-size: 13px;
          white-space: normal;
          span {
            margin: 0 10px;
          }
        }
      }
      h5 {
        font-size: 13px;
        letter-spacing: 0.65px;
      }
    }
    .product-image {
        text-align: center;
        position: relative;
        overflow: hidden;
        @include media-breakpoint-only(xs) {
          margin-bottom: 15px;
       }
        img {
            max-width: 1020px;
            width: auto;  
            max-height: 720px;
           @include media-breakpoint-down(xxl) {
             max-width: 100%;
            }
        }
    }
    .product-image-carousel {
      .video-carousel, .vimeo-frame {
        video {
          max-width: 1020px;
          max-height: 600px;
        }
        .vimeo-video-iframe {
          height: 100vh;
          max-height: 750px;
          @include media-breakpoint-down(md) {
            height: 50vh;
          }
          @include media-breakpoint-down(sm) {
            height: 40vh;
          }
        }
      }
    }
  }
}

// Download Popup Overlay
.download-popup-overlay {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 360px;
  inline-size: 100%;
  text-align: left;
  padding-top: 20px;
  z-index: 999;
  transition: all;
  background-color: rgba(0, 0, 0, .7);
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 0;
  @include media-breakpoint-down(sm) {
    max-width: 312px;
  }

  &.active {
    display: block;
    transition: 0.5s;
  }
  .download-overlay {
     .progress-append, .overlay-title {
        padding-inline: 20px;
     }
     .progress-append {
        &:last-of-type {
          margin-bottom: 50px;
        }
     }
  }
  p {
    color: $white;
    font-size: $sub-nav-link-font-size;
    font-weight: $font-weight-semibold;
    letter-spacing: 0.65px;
    line-height: 23px;
    margin-bottom: 7px;
  }

  span {
    color: $white;
    font-size: $sub-nav-link-font-size;
    letter-spacing: 0px;
    line-height: 17px;
    margin-bottom: 20px;
    word-break: break-all;
  }

  .progress {
    height: 4px;
    margin-top: 15px;
    background: rgb(160, 160, 160);
    @include media-breakpoint-down(md) {
      margin-top: 18px;
    }
    .progress-bar {
      background-color: $white;
    }
  }
  .download-info {
    margin-bottom: 20px;
      p {
        font-weight: $font-weight-extralight;
      }
  }
  .download-picture {
    padding: 13px 20px;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $brand-primary;
    .imageDownload {
       color: $white; 
       font-size: $sub-nav-link-font-size;
       line-height: 20px;
       position: relative;
       padding-left: 44px;
       background: transparent;
       border: 0;
       &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("../images/hero-banner/info-cross.svg");
        height: 30px;
        width: 30px;
        background-size: 11px;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid;
        border-radius: 50%;
        padding: 10px;
       }
    }
  }
}

// Wishlist modal

.wishlist-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $default-bg;
  padding: 5px 20px 3.5px 21px;
  border-radius: 10px;
  z-index: 8;
    @include media-breakpoint-down(sm) {
      width: 80%;
  }

  .modal-dialog { 
      .modal-content {
          display: flex;
          flex-direction: row;
          align-items: center;

          .modal-text {
              font-size: 13px;
              font-weight: $font-weight-semibold;
          }

          .close-button {
              padding: 0px 8.5px 16px 19px;

              .close-btn {
                  height: 10px;
                  width: 10px;
                  position: relative;
                    &:focus {

                    }
                  &::before {
                      content: "";
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      height: 30px;
                      width: 30px;
                      background: $cultured;
                      z-index: -1;
                      transform: translate(-50%, -50%);
                      border-radius: 50px;
                  }
              }
          }
      }
  }
}

.experience-kii_component-IFrame{
  margin: 0;
}


/*-- --*/

.document-wrapper {
  .region.col-sm-6 {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .image-container {
    width: 180px;
    @include media-breakpoint-down(xxl) {
        width: 130px;
    }
    @include media-breakpoint-down(xl) {
        width: 200px;
    }   
    @include media-breakpoint-down(lg) {
        width: 102px;
    }
    @include media-breakpoint-down(md) {
        width: 162px;
    }
    @include media-breakpoint-down(sm) {
        width: 155px;
    }
    img {
        width: 100%;
    }
}
  h5 {
    margin-top: 10px;
    font-size: 14px;
    line-height: 25px;
    word-break: break-all;
  }
  .product-download {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      margin-bottom: 47px;
      min-height: 100px;
      @include media-breakpoint-only(lg) {
        display: grid;
        margin-bottom: 40px;
      }
      .image-container {
          overflow: inherit;
          img {
              width: 100%;
          }
      }
      .product-tile-body {
        padding: 0 10px;
      }
  }
  .link-icon-btn {
      @include link-icon-btn;
      margin-top: 15px;
      font-size: 13px;
      padding: 3px 6px 3px 17px;
  }
  .s-cat-title {
      display: block;
      margin-top: 10px;
      font-size: 12px;
  }
  .view-now {
      margin-top: 17px;
  }
}

.image-download-wrapper {
  position: relative;
  .image-download-icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
    text-align: center;
    z-index: 8;
    opacity: 0;
    transition: all 500ms;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    @include media-breakpoint-down(md) {
      opacity: 1;
      z-index: 1;
    }
    a {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('../images/hero-banner/download-arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &:hover {
    .image-download-icon {
      opacity: 1;
    }
  }
}




.cld-video-player {
  .vjs-big-play-button {
      background-color: $white !important;
      width: 50px !important;
      height: 50px !important;

      .vjs-icon-placeholder {
          display: none !important;
      }

      &:focus {
          background-color: $white !important;
      }

      &:before {
          content: 'PLAY' !important;
          color: $black;
          font-size: $font-size-13;
          font-weight: $font-weight-semibold;
          padding: 0 !important;
          height: 100%;
          width: 100% !important;
          position: absolute !important;
          top: 100%;
          line-height: 16px;
          left: 0;
          transform: translate(0, -63%);
          letter-spacing: 0.65px;
      }
  }
}

video {
  cursor: pointer;
}
.plp-video {
  video {
      max-height: 180px;
  }
}

.video-wrapper {
  position: relative;
  .video-play-pause-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -56%);
    border: 0;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    font-size: 13px;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    line-height: 16px;
    color: $brand-primary !important;
    font-weight: 600;
    pointer-events: none;
  }
  .plp-video-payer.active + .video-play-pause-btn {
    display: none;
  }
  .video-title {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    background-color: rgba(0,0,0,.7);
    padding: 5px 0;
  }
}
.modal-dialog {
  .video-carousel {
    position: relative;
    .video-play-pause-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -56%);
      border: 0;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: #fff;
      font-size: 13px;
      letter-spacing: 0.65px;
      text-transform: uppercase;
      line-height: 16px;
      color: $brand-primary !important;
      font-weight: 600;
      pointer-events: none;
      padding: 0;
    }
    .plp-video-payer.active + .video-play-pause-btn {
      display: none;
    }
  }
}
.searchImagesResult {
  padding: 0;
      .video-wrapper {
          justify-content: center;
          align-items: center;
          display: flex;
          background: #000;
          width: 48%;
          @include media-breakpoint-up(md) {
              width: 31%;
          }
          @include media-breakpoint-up(lg) {
            width: 23%;
          }
          @include media-breakpoint-up(xl) {
            width: 23.5%;
          }
          @include media-breakpoint-up(xxl) {
              width: 24%;
          }
          img {
            // width: 100%;
            // object-fit: cover;
            // object-position: center;
            // height: 100%;
          }
      }
}

.image-with-text {
  @include top-bottom-padding;
}
.image-with-text.article-block .image-description {
  margin-top: 20px;
  .image-description-text {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
  }
}



@font-face {
  font-family: 'VideoJS';
  src: url('https://vjs.zencdn.net/f/1/vjs.eot');
  src: url('https://vjs.zencdn.net/f/1/vjs.eot?#iefix') format('embedded-opentype'),
    url('https://vjs.zencdn.net/f/1/vjs.woff') format('woff'),
    url('https://vjs.zencdn.net/f/1/vjs.ttf') format('truetype');
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
  content: "\f103";
  font-family: 'VideoJS';
  color: #fff;
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
  content: "\f107";
  font-family: 'VideoJS';
  color: #fff;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: "\f101";
  font-family: 'VideoJS';
  color: #fff;
}

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
  content: "\f121";
  font-family: 'VideoJS';
  color: #fff;
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
  content: "\f108";
  font-family: 'VideoJS';
  color: #fff;
}

.grid-content .product-option:not(:first-child),
.grid-content .product-option:first-child {
  margin-top: 0;
}
.mxt-product-config-container {
  .mxt-spins-fullscreen{
      z-index: 111 !important;
  }
}
.custom-visualizer-container{
  #kmb-config-overrides{
      .kmb-wrapper {
          .labeled-image-option{
              .product-info{
                  z-index: 1 !important;
              }
          } 
      }
  } 
}
.vjs-control-bar {
  [class*="time"]{
    span{
      color: #fff;
    }
  }
}

.back-to-top-wrapper {
  position: fixed;
  bottom: 115px;
  right: -3%;
  width: 100%;
  height: 0;
  z-index: 99;
  .container {
      height: 0;
  }
  @include media-breakpoint-down(sm) { 
      bottom: 120px;
  }
  @include media-breakpoint-up(xl) { 
      right: -25px;
  }
  .btn-back-to-top {
      height: 35px;
      width: 35px;
      background-color: $brand-primary;
      border-radius: 50%;
      border: 0;
      display: none;
      padding: 0;
      &:focus {
          box-shadow: none;
      }
      @include media-breakpoint-up(xl) { 
          height: 50px;
          width: 50px;
      }
      img {
          height: 19px;
          width: 19px;
          position: relative;
          top: -2px;
          @include media-breakpoint-up(xl) { 
              height: 29px;
              width: 29px;
              top: 0;
          }
      }
  }
}

.psmtc_2Vvg {
  z-index: 2147483647 !important;
  position: fixed !important;
  bottom: 55px !important;
  right: 210px !important;
  left: auto !important;
  display: none !important;
 /*  @include media-breakpoint-down(xxl) {
    bottom: 47px !important;
    right: 95px !important;
  }
  @include media-breakpoint-down(xl) {
    bottom: 47px !important;
    right: 60px !important;
  }
  @include media-breakpoint-down(lg) {
    bottom: 47px !important;
    right: 65px !important;
  }
  @include media-breakpoint-down(md) {
    bottom: 92px !important;
    right: 55px !important;
  }
  @include media-breakpoint-down(sm) {
    bottom: 76px !important;
    right: 63px !important
  } */
}


.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat !important;
 }

 @font-face {
  font-family: 'slick';
  src: url('./fonts/slick.eot') !important;
  src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), 
        url('./fonts/slick.woff') format('woff'), 
        url('./fonts/slick.ttf') format('truetype'), 
        url('./fonts/slick.svg#slick') format('svg') !important;
  font-weight: normal;
  font-style: normal;
    
}

 @font-face {
  font-family: 'slick';
  src: url('../fonts/slick.eot') !important;
  src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), 
        url('../fonts/slick.woff') format('woff'), 
        url('../fonts/slick.ttf') format('truetype'), 
        url('../fonts/slick.svg#slick') format('svg') !important;
  font-weight: normal;
  font-style: normal;
  display: none;
}

.request-quote {
  @include media-breakpoint-only(md) { 
    .col-sm-6 {
      width: 55%;
      + .col-sm-6 {
        width: 45%;
      }
    }
  }
  @include media-breakpoint-down(md) { 
    .col-sm-6 {
      order: 2;
      width: 100%;
      + .col-sm-6 {
        order: 1;
        width: 100%;
      }
    }
  }
}

#zones {
  .text-component-container {
    h1 {
      color: black;
    }
  }
}
.cld-video-player {
  .vjs-control-bar {
      display: flex !important;
      .vjs-play-progress:before {
        font-family: VideoJS;
        color: #fff;
      }
      .vjs-progress-holder {
        margin: 0 10px;
      }
  }
}

/*-- CLOUDINARY VIDEO --*/
.video-js {
  width: 100%;
  height: auto;
  .vjs-tech {
    position: static;
  }
  .vjs-big-play-button {
    z-index: 9 !important;
    // visibility: visible !important;
    // opacity: 1 !important;
  }
  .vjs-play-progress {
    background: #fff !important;
  }
  .vjs-slider {
    background: rgba(115,133,159,.5) !important;
  }
  .vjs-load-progress {
    div {
      background: rgba(115,133,159,.75) !important;
    }
  }
}

#ContainingElementId {
  .mxt-desktop-side-screen-content {
    .multiscreen {
        .sb-header,
        header {
          z-index: 9;
        }
    }
  }
}

.discontinue {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.8);
    width: 100%;
    padding: 3px 10px;
    color: #fff !important;
    display: block !important;
    margin: 0 !important;
    text-transform: uppercase;
    letter-spacing: 0.65px;
    font-size: 13px;
    font-weight: 600 !important;
    .md-subtitle {
      span {
        color: $white;
      }
    }
}


.related-products {
  .content-small-image-tile-block,
  .content-large-image-tile-block {
    .image-container {
      img {
        object-fit: cover;
        height: 193px;
        @include media-breakpoint-up(sm) {
            height: 221px;
        }
        @include media-breakpoint-up(md) {
            height: 171px;
        }
        @include media-breakpoint-up(lg) {
            height: 225px;
        }
        @include media-breakpoint-up(xxl) {
            height: 230px;
        }
      }
    }
  }

  .content-small-image-tile-block {
    flex: 0 0 auto;
    width: 197px;
    @include media-breakpoint-up(sm) {
        width: 182px;
    }
    @include media-breakpoint-up(md) {
        width: 122px;
    }
    @include media-breakpoint-up(lg) {
        width: 157px;
    }
    @include media-breakpoint-up(xl) {
        width: 170px;
    }
    @include media-breakpoint-up(xxl) {
        width: 200px;
    }
  }

  .content-large-image-tile-block {
    flex: 0 0 auto;
    width: 80%;
    @include media-breakpoint-up(sm) {
        width: 364px;
    }
    @include media-breakpoint-up(md) {
        width: 244px;
    }
    @include media-breakpoint-up(lg) {
        width: 314px;
    }
    @include media-breakpoint-up(xl) {
        width: 340px;
    }
    @include media-breakpoint-up(xxl) {
        width: 404px;
    }
  }
}
//Custom Chat Button
.chat-button {
  background-color: #f0f7f7;
  color: $brand-primary;
  border-color: transparent;
  width: 192px;
  height: 46px;
  line-height: 40px;
  font-size: 14px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 99;
  .chat-icon {
    width: 16px;
    margin-right: 12px;
  }
}