.search-results {
    &.container {
        padding-bottom: 50px;
    }
    .search-custom-tabs {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: center;
        border: none;
        flex-wrap: wrap;

        .nav-item{
           .button{
            margin-top: 0;
            &.active{
                background-color: #000000;
                color: #ffffff;
                padding: 7px 12px;
                border-radius: 25px;
            }
            &.disabled{
                // color: #D3D3D3
                color: $gray;
            }
         }
        }
    }

    .no-result-found {
        .carousel-title * {
            color: $brand-primary;
        }
        .carousel-title {
            border-bottom: 1px solid $brand-primary;
            padding-bottom: 20px;
        }
        .kii-content-slider.product-slider-wrapper {
            padding-top: 20px;
        }
        .container {
            padding: 0;
        }
    }
    .search-title {
        text-align: center;
        padding: 40px 0 0;
        @include media-breakpoint-down(md) {
            padding: 40px 0 20px;
         }
    }
    .html-slot-container {
        margin-top: 20px;
        @include media-breakpoint-up(md) {
          margin-top: 40px;
        }
    }
    .search-subtitle p {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.65px;
        font-weight: 600;
    }

    .tab-content {
        .tab-pane {
            .image-listing {
                margin-bottom: 78px;
                &.images-search-list {
                    margin-bottom: 0;
                    .searchImagesResult {
                        // margin: 20px 0 0;
                        margin: 0;
                        gap: 0 20px;
                        li {
                            max-height: 180px;
                            margin-top: 20px;
                            &:last-child {
                                margin-bottom: 78px;
                            }
                        }
                    }
                }
            }
            .searchProductsResultTitle {
                display: flex;
                align-items: center;
                gap: 15px;
                border-bottom: 1px solid $brand-primary;
                // padding: 20px 0;
                padding: 15px 0;
                @include media-breakpoint-down(md) { 
                    // padding-top: 0px;
                 }
                h4 {
                    font-weight: $font-weight-semibold;
                }
            }

            .searchProductsResult {
                list-style: none;
                display: flex;
                justify-content: flex-start;
                // gap: 20px;
                // margin-top: 20px;
                // @include media-breakpoint-down(md) {
                //     margin-bottom: 50px;
                //  }
                .product-tile {
                    margin-bottom: 78px;
                    gap: 50px 0;
                        @include media-breakpoint-down(md) {
                            min-height: auto;
                            // margin-bottom: 48px;
                            margin-bottom: 30px;
                        }
                    .image-container {
                        .product-tile-image {
                            width: 100%;
                        }
                    }

                    .product-tile-body {
                        h4 {
                            font-weight: $font-weight-semibold;
                            // font-size: $search-title-size;
                            font-size: 16px;
                            margin: 16px 0 0;
                            span {
                                font-weight: 400;
                                margin-left: 5px;
                            }
                        }

                        .link-icon-btn {
                            @include link-icon-btn;
                            font-size: 13px;
                            padding: 3px 6px 0 17px;
                            margin-top: 5px;
                        }
                    }
                }

                &.literature {
                    column-count: 2;
                    margin-top: 20px;
                    gap: 0;
                    flex-wrap: wrap;
                    align-items: end;
                    @include media-breakpoint-down(xl) { 
                    align-items: start;
                    }
                    @include media-breakpoint-down(lg) { 
                    align-items: end;
                    }
                    @include media-breakpoint-down(md) {
                        // gap: 20px 50px;
                    }
                    li {
                        padding: 0 0 0 15px;
                        margin-top: 0;
                        .product-tile {
                            display: flex;
                            align-items: flex-end;
                            gap: 20px;
                            @include media-breakpoint-down(xl) {
                                display: grid;
                            }
                            @include media-breakpoint-down(lg) {
                                display: flex;
                            }
                            .image-container {
                                //  width: 180px;
                                // @include media-breakpoint-down(xxl) {
                                //     width: 130px;
                                // }
                                // @include media-breakpoint-down(xl) {
                                //     width: 200px;
                                // }
                                // @include media-breakpoint-down(lg) {
                                //     width: 102px;
                                // }
                                // @include media-breakpoint-down(md) {
                                //     width: 162px;
                                // }
                                // @include media-breakpoint-down(sm) {
                                //     width: 155px;
                                // }
                                @media screen and (max-width: 374px) {
                                    width: 40%;
                                }
                            }
                            &.product-download {
                                // gap: 5px;
                                gap: 0;
                                margin: 0 0 35px;
                                .product-tile-body {
                                    padding: 0 0 0 10px;
                                    @include media-breakpoint-only(lg) {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                &.plp-search-result {
                    // margin-top: 20px;
                    // @include media-breakpoint-down(xxl) {
                    //     display: grid;
                    //     grid-template-columns: auto auto auto;
                    // }
                    // @include media-breakpoint-down(xl) {
                    //     display: flex;
                    // }
                    // @include media-breakpoint-down(lg) {
                    //     display: grid;
                    //     grid-template-columns: auto auto;
                    //     // gap: 0 20px;
                    // }
                    // @include media-breakpoint-down(md) {
                    //     grid-template-columns: auto;
                    // }
                    // .image-container {
                    //     @include media-breakpoint-down(xxl) { 
                    //         width: 170px;
                    //     }
                    //     @include media-breakpoint-down(xl) { 
                    //         width: auto;
                    //     }
                    // }
                    li {
                        margin-top: 20px;
                    }
                    &.literature {
                        li {
                            // @include media-breakpoint-down(xxl) { 
                            //     width: 50%;
                            // }
                            // @include media-breakpoint-down(md) { 
                            //     width: auto;
                            // }
                        }
                        .product-tile-body {
                            padding: 0 15px 0 0;                           
                        }
                    }
                    // .small-tile-image, .medium-tile-image {
                    //     img {
                    //         object-fit: cover;
                    //         @include media-breakpoint-up(md) { 
                    //             height: 210px;
                    //         }
                    //         @include media-breakpoint-up(xl) { 
                    //             height: 250px;
                    //         }
                    //         @include media-breakpoint-up(xxl) { 
                    //             height: 370px;
                    //         }
                    //     }
                    //     .product-tile {
                    //         @include media-breakpoint-up(md) { 
                    //             margin-bottom: 65px;
                    //         }
                    //     }
                    // }
                    .large-plp-image-tile, .small-plp-image-tile  {
                        .product-tile {
                            // @include media-breakpoint-up(md) {
                            //     margin-bottom: 65px;
                            // }
                            margin-bottom: 19px;
                        }
                    }
                    .large-plp-image-tile {
                        // @include media-breakpoint-up(xxl) {
                        //     width: 40%;
                        // }
                        @include media-breakpoint-up(lg) {
                            width: 33.33333%;
                        }
                        .image-container {
                            // img {
                            //     object-fit: cover;    
                            //     @include media-breakpoint-up(md) {
                            //         height: 238px;
                            //     }
                            //     @include media-breakpoint-up(lg) {
                            //         height: 278px;
                            //     }
                            //     @include media-breakpoint-up(xl) {
                            //         height: 332px;
                            //     }
                            //     @include media-breakpoint-up(xxl) {
                            //         height: 370px;
                            //     }
                            // }
                            img {
                                object-fit: cover;
                                @include media-breakpoint-up(sm) {
                                    height: 190px;
                                }
                                @include media-breakpoint-up(md) {
                                    height: 188px;
                                }
                                @include media-breakpoint-up(lg) {
                                    height: 228px;
                                }
                                @include media-breakpoint-up(xl) {
                                    height: 282px;
                                }
                                @include media-breakpoint-up(xxl) {
                                    height: 320px;
                                }
                            }
                        }
                    }
                    .small-plp-image-tile {
                        // @include media-breakpoint-up(xxl) {
                        //     width: 20%;
                        // }
                        @include media-breakpoint-up(lg) {
                            width: 16.66667%;
                        }
                        .image-container {
                            // img {
                            //     object-fit: cover;   
                            //     @include media-breakpoint-up(md) {
                            //         height: 238px;
                            //     }
                            //     @include media-breakpoint-up(lg) {
                            //         height: 278px;
                            //     }
                            //     @include media-breakpoint-up(xl) {
                            //         height: 332px;
                            //     }
                            //     @include media-breakpoint-up(xxl) {
                            //         height: 370px;
                            //     }
                            // }
                            img {
                                object-fit: cover;
                                @include media-breakpoint-up(sm) {
                                    height: 190px;
                                }
                                @include media-breakpoint-up(md) {
                                    height: 188px;
                                }
                                @include media-breakpoint-up(lg) {
                                    height: 228px;
                                }
                                @include media-breakpoint-up(xl) {
                                    height: 282px;
                                }
                                @include media-breakpoint-up(xxl) {
                                    height: 320px;
                                }
                            }
                        }
                    }
                }
                &.ideastarter-search-list {
                    .product-tile {
                        min-height: auto; 
                        margin-bottom: 0;    
                        li {
                            &:last-child {
                                margin-bottom: 78px;
                            }
                        }  
                    }
                }
            }

            .searchPagesResult {
                @include media-breakpoint-up(lg) {
                    // padding: 0 10% 50px;
                    padding: 0 0 50px;
                }

                .searchPagesResultList {
                    // margin-top: 40px;
                    margin-top: 20px;

                    a {
                        display: block;
                    }
                    h4 {
                        font-size: $default-text-font-size;
                        line-height: 28px;
                        font-weight: 600;
                    }

                    span {
                        font-size: $search-cat-size;
                        margin: 10px 0;
                        display: block;
                    }

                    .searchPagesResultListContent {
                        display: flex;
                        align-items: flex-start;
                        gap: 20px;
                        color: $brand-primary;
                        p {
                            font-size: $default-text-font-size;
                        }
                    }
                }
            }
        }
    }
    .download-resource {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .product-download {
        display: flex;
        align-items: flex-end;
        // gap: 5px;
        gap: 0;
        // margin: 0 0 80px;
        margin: 0 0 35px;
        flex-wrap: wrap;
        @include media-breakpoint-down(xxl) {
            min-height: auto;
         }
        // @include media-breakpoint-down(xl) {
        //    display: grid;
        // }
        @include media-breakpoint-between(lg, xl) {
            display: grid;
        }
        @include media-breakpoint-down(lg) {
           min-height: auto;
         }
        .image-container {
            // width: 240px;
            // width: 180px;
            @include media-breakpoint-down(xxl) {
                // width: 170px;
                // width: 130px;
            }
            @include media-breakpoint-down(xl) {
                // width: 240px;
                // width: 200px;
            }   
            // @include media-breakpoint-down(lg) {
            //     width: 102px;
            // }
            // @include media-breakpoint-down(md) {
            //     width: 162px;
            // }
            // @include media-breakpoint-down(sm) {
            //     width: 155px;
            // }
            @media screen and (max-width: 374px) {
                width: 40%;
            }
            img {
                width: 100%;
            }
        }
        h5 {
            font-size: 14px;
            line-height: 25px;
            word-break: break-word;
        }
        .s-cat-title {
            display: block;
            margin-top: 5px;
            font-size: 12px;
            font-size: 10px;
        }
        .link-icon-btn {
            @include link-icon-btn;
            margin-top: 5px;
            padding: 3px 6px 0 17px;
            font-size: 13px;
        }
        .product-tile-body {
            padding-left: 10px;
            @include media-breakpoint-only(lg) {
                margin-top: 10px;
                padding-left: 0;
            }
            .add-to-wishlist,
            .remove-from-wishlist  {
                margin-bottom: 10px;
            }
        }
    }
    .image-grid {
        .applied-filters {
            display: block;
            .applied-filter-list {
                width: auto;
                @include media-breakpoint-up(sm) {
                    float: left;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

// Global Images Gallery
.searchImagesResult {
    list-style: none;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin: 20px 0 40px;
    img {
        cursor: pointer;
        max-height: 180px;
        max-width: 100%;
    }
    .video-wrapper{
        cursor: pointer;
    }
}
