.lead-times {
    margin-bottom: 50px;
    margin-top: 40px;
    .container {
        .container {
            padding: 0;
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 80px;
    }

    .kimball {
        .dropdown {
            display: inline-block;

            button {
                position: relative;
                border: none;
                background-color: transparent;

                &::after {  
                    display: none;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    font-size: 25px;
                    color: $charcoal;
                }

                h1 {
                    color: $charcoal;
                    font-size: 40px;
                    font-weight: 500;
                    line-height: inherit;
                    padding-right: 20px;
                    @include media-breakpoint-down(md) {
                        font-size: 20px;
                    }
                }
            }

            .kimball-drop {
                position: absolute;
                width: max-content;
                border-radius: 5px;
                background-color: $charcoal;
                padding: 10px;
                z-index: 1;

                li {
                    a {
                        color: $white;
                        display: block;

                        &:hover {
                            color: $brand-primary;
                            background-color: $light-grayish-blue;
                        }
                    }

                }
            }

            .kimball-drop {
                display: none;
            }
        }
    }

    .reports-filter {
        @include media-breakpoint-down(md) {
            margin: 20px 0;
          }
        // a {
        //     display: flex;
        //     align-items: center;
        //     gap: 10px;
        //     justify-content: center;
        //     width: fit-content;
        //     margin: 0 auto;
        // }
        .report-block {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;
        }
      }
    .search-input {
            @include media-breakpoint-down(md) {
                display: flex;
            }
            input {
                padding: 0 5px;
                @include media-breakpoint-down(md) {
                   width: 100%;
                }
            }
          }

    .casegood-table {
        @include media-breakpoint-down(sm) {
            // overflow: auto;
            // padding: 0 10px !important;
        }

        .casegoods {
            margin-top: 20px;
        }

        .hr-dbl {
            border-top: solid 5px $brand-primary;
            border-bottom: solid 2px $brand-primary;
            opacity: 1;
            padding-top: 3px;
        }

        p {
            font-size: 16px;
        }

        table {
            margin-top: 10px;

            th {
                font-size: 16px;
                font-weight: $font-weight-semibold;
            }

            tr {

                td {
                    font-size: 16px;

                    &.child-text {
                        font-size: 13px;
                        padding-left: 20px;
                    }
                }
            }
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 15px;
        @include media-breakpoint-down(md) {
            justify-self: center;
         }
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        transition: .4s;
        border: 1px solid $brand-primary;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 4px;
        bottom: 2px;
        background-color: $white;
        transition: .4s;
        border: 1px solid $brand-primary;
      }
      
      input:checked + .slider {
        background-color: $brand-primary;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px $brand-primary;
      }
      
      input:checked + .slider:before {
        transform: translateX(13px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }     
}

.leadtimes-tab {
    ul {
        display: flex;
        justify-content: center;
        margin: 30px 0 50px;
        @include media-breakpoint-down(sm) {
            // display: none;
            display: block;
            margin: 30px 0 20px;
        }
        .nav-item {
            margin: 0 0 10px;
            @include media-breakpoint-up(md) {
                margin: 0 10px 0 0;
            }
            .nav-link {
                color: $brand-primary;
                @include media-breakpoint-up(lg) {
                    // font-size: 16px;
                    // padding: 10px 15px;
                }
                @include media-breakpoint-down(md) {
                    justify-content: flex-start;
                    // font-size: 14px;
                }
                @include media-breakpoint-down(sm) {
                    width: 70%;
                }
                @include media-breakpoint-only(sm) {
                    font-size: 11px;
                    padding: 7px 5px 5px;
                    margin: 0 2px;
                    justify-content: flex-start;
                }
                &.active,
                &:hover {
                    background-color: $brand-primary;
                    color: $white;
                }
            }
        }
    }
    .tablist-content {
        .card-header {
            background-color: transparent;
            padding: 0;
            h5 {
                padding: 10px 0;
                border-bottom: 1px solid $brand-primary;
            }
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
        .card-body {
            padding: 20px 0;
        }
        .collapse:not(.show) {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
        .fade:not(.show) {
            @include media-breakpoint-down(md) {
                opacity: 1;
            }
        }
        .tab-content {
            >.tab-pane {
                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
        }
    }
}