
.suggestions-wrapper {
    position: fixed;
    left: 0;
    top: 60px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 8px 6px -6px #9b9191;
    @include media-breakpoint-down(lg) {
        width: calc(100% - 90px);
        left: auto;
        right: 0;
        height: 100%;
        &:empty {
            height: auto;
        }
    }
   @include media-breakpoint-down(md) {
         width: calc(100% - 85px);
   }

     @include media-breakpoint-down(sm) {
        width: calc(100% - 80px);
     }
    .suggestions {
         padding-top: 50px;
         padding-bottom: 50px;
         @include media-breakpoint-down(lg) {
            padding-top: 30px;
            border-top: 1px solid $brand-primary;
         }
         @include media-breakpoint-down(md) {
            padding-top: 25px;
         }
        .suggestion-title { 
            font-size: 13px;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            letter-spacing: 0.65px;
            line-height: 26px;
        }
        .sugessted-items {
            overflow-x: auto;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            scrollbar-width: 0;  /* Firefox */
            .back-btn {
                button {
                    background: transparent;
                    border: none;
                    margin-bottom: 15px;
                }
            }
        }
        ul {
            &.list {
                white-space: nowrap;
            }
            &.container {
                @include media-breakpoint-down(lg) {
                    padding: 0;
                }
            }
            li.item {
                margin-right: 33px;
                @include media-breakpoint-up(lg) {
                    display: inline-block;
                }
                a {
                    font-size: 25px;
                    line-height: 40px;
                    font-weight: $font-weight-light;
                    @include media-breakpoint-down(lg) {
                        font-size: 22px;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .more-below {
            display: none;
        }
    }
}