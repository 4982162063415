.add-to-fav {
    img {
        height: auto !important;
    }
    a {
        display: inline-block;
        align-items: center;
        justify-content: center;

        .fav-checked {
            display: block;
        }

        // &:hover {
        //     .fav-uncheck {
        //         display: none;
        //     }

        //     .fav-checked {
        //         display: block;
        //     }
        // }
    }
}