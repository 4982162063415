.material-details-wrap {
    padding: 50px 0;

    .md-title {
        @extend h1;
    }
    .add-to-fav {
       margin: 14px 0 17px;

        a {
            justify-content: flex-start;

            span {
                margin-left: 7px;
                @include plp-subHeading;
            }
        }
    }

    .download-btns {
        margin: 20px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .typical-thumbnails {
        padding: 20px 0;

        ul {
            display: flex;
            gap: 20px;
        }
    }

    .material-details-content {
        @include media-breakpoint-up(lg) {
            padding-left: 126px;
        }

        .md-subtitle {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;

            h6 {
                @include plp-subHeading;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            &.table {
                span {
                    width: 100%;
                    display: block;
                    padding: 0;
                }
            }

        }
    }
    .material-full-image {
        min-height: 335px;
        @include media-breakpoint-up(sm) {
            min-height: 526px;
        }   
        @include media-breakpoint-up(md) {
            min-height: 344px;
        }
        .primary-image-elm {
            width: 100%;
        }
    }
    &.ideal-starter-detail {
        .material-details-content {
            .md-subtitle.table {
                display: none;
            }
        }
        .typical-thumbnails {
            li {
                img {
                    border: 1px solid transparent;
                    &.active {
                        border-color: #000;
                    }
                }
            }
        }
    }
    .discontinue {
        padding: 20px;
    }
}

.download-items {
    li {
        a {
            position: relative;
            padding-left: 20px;
            display: block;

            &::before {
                content: "";
                position: absolute;
                background-image: url('../images/products/download-black.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                left: 0;
                top: 4px;
                // top: 50%;
                // transform: translateY(-50%);
                width: 11px;
                height: 14px;
            }
        }

        &.is-download-doc {
            a {
                &::before {
                    background-image: url('../images/products/download-doc.svg');
                }
            }
        }

        &.is-download-pdf {
            a {
                &::before {
                    background-image: url('../images/products/download-pdf.svg');
                }
            }
        }
    }
}

.related-products {
    h3 {
        @include top-bottom-padding;
        color: $brand-secondary;
    }
    .product-slider-wrapper {
        @include bottom-padding;
        // @include bottom-margin;
        margin-bottom: 13px;
        .product-tile-body {
            margin-top: 20px;
            h4 {
                font-size: 20px;
                line-height: 25px;
                font-weight: 600;
            }
        }
    }
}


.recomended-products-container {
    @include top-bottom-padding;
    .recomended-products-title {
        margin-top: auto;
        margin-bottom: 50px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 26px;
        }
        h3 {
            color: $brand-secondary;
        }
    }
    .recomended-products {
        .product-tile-body, 
        .title-container {
            margin-top: 10px;
        }
        h5 {
            font-weight: $font-weight-light;
            line-height: 25px;
        }
        .price-config {
            font-size: 13px;
            letter-spacing: 0.65px;
            line-height: 20px;
            font-weight: $default-font-weight;
        }
    }
}

.ideal-starter-detail {
    .typical-thumbnails {
        padding-bottom: 0;
        @include media-breakpoint-down(md) { 
            padding-bottom: 40px;
        }
    }
    .recomended-products-wrapper {
        .recomended-products-title {
            padding-bottom: 20px;
        }
        .recomended-products {
            .image-container {
                height: auto;
                @include media-breakpoint-up(sm) { 
                    height: auto;
                }
            }
        }
    }
    
}


.work-able {
    margin: 50px 0;
    @include media-breakpoint-down(lg) {
        margin: 90px 0;
    }
    .overview {
       h5 {
        margin: 20px 0;
       }
       .overview-details {
         thead {
          tr {
            td {
              h6 {
                margin-bottom: 10px;
              }
            }
          }
         }
       }
    }
    .workable {
      width: 100%;
    }
    .itme-specification {
      h5 {
        margin: 20px 0;
      }
      table {
        tbody {
            tr {
                background-color: #E9E9E9;
                &:nth-child(even) {
                    background-color: transparent;
                }
            }
        }
      }
    }
    td, th, h5, h6 {
    color: $Gray44;
    font-size: 14px;
    }
  }