.dealersLocationPart{
    padding: 57px 0 100px;
    form{
        margin-bottom: 27px;
        .formControl {
            width: 100%;
            display: flex;
            align-items: center;
            height: 16px;
            input{
                width: 100%;
                height: 100%;
                //max-width: 202px;
                border: none;
                letter-spacing: 0.65px;
                font-weight:$font-weight-semibold;
                color:$brand-primary;
                font-size: $font-size-13;
                font-family: $default-text-font;
                text-transform: uppercase;
                &:focus-visible {
                    outline: 0;
                }  
                 
                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color:$brand-primary;
                    opacity: 1; /* Firefox */
                    text-transform: uppercase;
                  }
                  
                  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color:$brand-primary;
                    text-transform: uppercase;
                  }
                  
                  &::-ms-input-placeholder { /* Microsoft Edge */
                    color:$brand-primary;
                    text-transform: uppercase;
                  }
            }
            
            .searchBtn {
                margin-right: 16px;
                width: 100%;
                max-width: 13px;
                height: 13px;
                background: none;
                border: none;
                img{
                    vertical-align: top;
                }
            }
        }
    }
    .addressBoxMain{
        margin:50px 0;
        .addressDetails {
            display: flex;
            flex-wrap: wrap;
            padding: 50px 0;
            border-bottom: 1px solid $brand-primary;
            &:first-child{
              border-top: 1px solid $brand-primary;
            }
            .locationName {
                width: 100%;
                max-width: 239px;
                margin-right: 22px;
                @include media-breakpoint-down(md) {
                    max-width:100%;
                    margin-right:0;
                    margin-bottom: 20px;
                }
                
            }
            h5, h6{
                font-weight:$font-weight-semibold;
                color:$brand-primary;
                font-size: $font-size-13;
                font-family: $default-text-font;
                line-height: 20px;
            }
            .locationBox {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                width: 100%;
                max-width: calc(100% - 261px);
                gap:50px 22px;
                @include media-breakpoint-down(xl) {
                    gap:20 22px;
                    grid-template-columns: repeat(4, 1fr);
                }
                @include media-breakpoint-down(md) {
                    max-width:100%;
                    grid-template-columns: repeat(3, 1fr);
                }
                @include media-breakpoint-down(sm) {
                    grid-template-columns: repeat(2, 1fr);
                }
                .fullAddress {
                  
                   

                    p{
                        font-weight:$default-text-font-weight;
                        color:$brand-primary;
                        font-size: $font-size-13;
                        font-family: $default-text-font;
                        margin-top: 10px;
                        line-height: normal;
                    }
                    
                }
            }
        }
    }
    #continuesBtn{
        background: none;
        border: none;
        color:$pink;
        font-size: $default-text-font-size;
        line-height:$default-text-line-height;
        font-family: $default-text-font;
        font-weight:$default-text-font-weight;
    }
}