@import "~core/components/footer";
footer {
    background-color: $cultured;
    padding: 0;
    .footer-upper {
        padding: 30px 0 0;
        .footer-item {
            border: none;
            padding-bottom: 30px;
            &:first-child{
                padding-top: 0;
            }
            &:nth-child(2){
                padding-top: 3px;
            }
            &:nth-child(3){
                padding-top: 3px;
            }
            .sign-up-desc {
                font-size: 16px;
                font-weight: 400;
            }
            .footer-logo{
                @include media-breakpoint-between(md, lg) {
                    width: 100%;
                    max-width:215px;
                }
                @include media-breakpoint-up(sm) {
                    max-width:244px;
                }
                @include media-breakpoint-down(sm) {
                    max-width:100%;
                }
                a{
                    display: block;
                    img{
                        max-width: 100%;
                    }
                }
            }
            .mobile-copyright {
                margin-top: 20px;
                @include media-breakpoint-between(sm, lg){
                   display: flex;
                }
                .getFullYear, .content-asset, .siteName, .kimball{
                    @include media-breakpoint-down(sm) {
                       display:contents;
                    }
                   
                }
                .International{
                    @include media-breakpoint-down(sm) {
                        display:block;
                     }
                }
              
            }
            .getFullYear {
                // line-height: 30px;
                // font-weight: $default-font-weight;
               
            }
            .footer-item-content {
                span {
                    margin-right: 8px;
                }

                address {
                    line-height: 25px;
                    font-weight: $default-font-weight;
                    color: $brand-primary;
                }
            }
            .sub-btn {
                margin-bottom: 30px;
                padding: 7.5px 0;
                font-size: 13px;
                letter-spacing: 0.65px;
                
                &:hover {
                    color: $white;
                }
            }
            h4{
                span{
                    @include media-breakpoint-down(lg) {
                        display: block;
                    } 
                    @include media-breakpoint-down(sm) {
                        display:inline;
                    } 
                }
            }

        }
        .surcharge-banner{
            text-align: center;
            margin-bottom: 10px;
            padding: 0;
        }
    }

    .footer-bottom {
        background-color: $default-bg;
        padding: 21.5px 0 18px;

        @include media-breakpoint-down(xxl) {
          padding: 15px 0;
        }
        @include media-breakpoint-down(lg) {
            padding: 16.5px 0;
        }
        @include media-breakpoint-down(md) {
            padding: 24px 0;
        }
        .copyright-text {
            ul {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                    gap: 10px 0;
                }
                @include media-breakpoint-down(sm) {
                    // grid-column-gap: 10px;
                    grid-row-gap: 3px;
                }
                li {
                    &:first-child {
                        margin-bottom: 4px;
                        @include media-breakpoint-only(md) {
                            margin-bottom: 0;
                            margin-top: 0;
                        }
                    }
                    &:nth-child(2) {
                        margin-bottom: 2px;
                        @include media-breakpoint-only(md) {
                            margin-bottom: 0;
                            margin-top: 2px;
                        }
                    }
                    &:nth-child(5) {
                        margin-top: 2px;
                    }
                    &:last-child {
                        margin-top: 2px;
                    }
                    &:nth-child(3) {
                        margin-bottom: 10px;
                        @include media-breakpoint-down(lg) {
                            margin-bottom: 4px;
                        }
                    }
                    &:nth-child(4) {
                        @include media-breakpoint-up(lg) {
                            margin-bottom: 5px;
                        }
                    }
                    &:nth-child(6) {
                        @include media-breakpoint-down(sm) {
                            max-width: 70px;
                        }
                    }
                    &:nth-child(8) {
                        @include media-breakpoint-down(sm) {
                            max-width: 93px;
                        }
                    }
                    &:not(:last-child) {
                        margin-right: 17px;
                        @include media-breakpoint-down(md) {
                            margin-right: 12px;
                        }
                        @include media-breakpoint-down(sm) {
                            margin-right: 6px;
                            max-width: 76px;
                        }
                    }
                    a {
                        img {
                            width: 100%;
                            max-width: 100%;
                            @include media-breakpoint-down(md) {
                                max-height: 15px;
                            }
                            @include media-breakpoint-only(xs) {
                                max-height: 10px;
                            }
                        }
                    }
                }
            }
        }
        
    }
}