
// Generate header classes, e.g. h1, .h1
@each $value in $headers {
    $headerIndex: index($headers, $value);

    #{nth($value, 1)},
    .#{nth($value, 1)} {
        @include header($headerIndex);
    }
}

// Generate color classes for setting text color, background color, and border color
// Usage: .text--red, .text--md--red, background--light-gray, background--lg--info, border--dark-gray, border--md--brand-tertiary
.text {
    @include color-classes(color);
}

.background {
    @include color-classes(background-color);
}

.border {
    @include color-classes(border-color);
}


// Generate font classes, e.g. font-size--15, font-size--md--50
.font-size {
    @include text-size-classes(font-size, 0, 83);
}

// Generate line-height classes, e.g. line-height--15, line-height--md-50
.line-height {
    @include text-size-classes(line-height, 0, 82);
}

.button {
    @include button;

    &-primary {
        @include button(primary);
    }

    &-secondary {
        @include button(secondary);
    }

    &-outline {
        @include button(outline);
    }

    &-tertiary {
        @include button(tertiary);
    }

    &-filter {
        @include button(filter);
    }

    &-arrow {
        @include button(arrow);
    }

    &-document {
        @include button(document);
    }

    &-configure {
        @include button(configure);
    }
}

@each $value in $spacers {
    $spacerIndex: index($spacers, $value);

    .#{nth($value, 1)} {
        @include spacer($spacerIndex);
    }
}

// Create paragraph classes, e.g. p, .p
p,
.p {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    font-family: $paragraph-font-family;
    font-weight: $font-weight-light;
}

a {
    &:hover {
        color: $brand-secondary;
    }
}

body {
    font-family: $body-font;
}

.font-weight-extra-light {
    font-weight: $font-weight-extralight;
}

.font-weight-light {
    font-weight: $font-weight-light;
}

.font-weight-regular {
    font-weight: $default-font-weight;
}

.font-weight-semibold {
    font-weight: $font-weight-semibold;
}

.link-icon-btn {
    @include link-icon-btn;
}

.s-cat-title {
    font-size: $search-cat-size;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    letter-spacing: 0.65px;
    line-height: 16px;
}
// .progressbar-slider-wrapper, .sliderWrapper  {
//     overflow-x: hidden;
// } 
.product-slider-wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 20px;
    margin-top: 0;
    margin-bottom: 0;
    // scrollbar-color: $brand-secondary $brand-secondary;
    // scrollbar-width: thin;
    width: 100%;

    // &:hover,
    // &:focus {
    //     scrollbar-color: $brand-secondary transparent;
    // }

    .product-slide {
        &:first-child {
            margin-left: 0;
        }
    }

    &::-webkit-scrollbar {
        height: 10px;
    }

    /*-- track --*/
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: linear-gradient(360deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), $brand-secondary, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    }

    /*-- handle --*/
    &::-webkit-scrollbar-thumb {
        background: $brand-secondary;
        border-radius: 10px;
    }

    /*-- handle on hover --*/
    &::-webkit-scrollbar-thumb:hover {
        background: $brand-secondary;
    }
}

.kii-carousel.scrollbarbgcolor {
    .product-slider-wrapper {
        &::-webkit-scrollbar-thumb {
            background: var(--scrollbar-color);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: linear-gradient(360deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), var(--scrollbar-color), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
        }
    }
}

@-moz-document url-prefix() {
    .progressbar-slider-wrapper {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $brand-secondary;
            width: block;
        }
        .product-slider-wrapper {
            scrollbar-color: $brand-secondary transparent;
            scrollbar-width: thin;
            &:hover,
            &:focus {
                scrollbar-color: $brand-secondary transparent;
            }
        }
        &.scrollbarbgcolor {
            &:after {
                background-color: var(--scrollbar-color);
            }
            .product-slider-wrapper {
                scrollbar-color: var(--scrollbar-color) transparent;
                &:hover,
                &:focus {
                    scrollbar-color: var(--scrollbar-color) transparent;
                }
            }
        }
    }
}

.kii-gallery-slider {
    padding-bottom: 40px;
    .kii-gallery-slide {
        padding: 0;
        margin-left: 15px;
        overflow: hidden;
        min-height: 179px;
        &:first-child {
            margin-left: 0;
        }
        .img-info-overlay-parent {
            .img-info-overlay {
                .social-icons {
                    @include media-breakpoint-down(md) {
                        padding: 4px 5px 6px 34px;
                        height: 43px;
                    }
                    .download-image {
                        @include media-breakpoint-down(md) {
                            height: 100%;
                            a {
                                display: flex;
                                align-items: center;
                                height: 100%;
                                span {
                                    font-size: 12px;
                                    margin-left: 6px;
                                    text-align: center;
                                }
                                img {
                                    height: 15px;
                                }
                            }
                        }
                    }
                }
                .info-overlay-icon {
                    @include media-breakpoint-down(md) {
                        width: 20px;
                        height: 20px;
                        background-size: 8px;
                        bottom: 12px;
                        left: 5px;
                        &.active {
                            background-size: 8px;
                        }
                    }
                }
                .info-overlay-content {
                    @include media-breakpoint-down(md) {
                        height: calc(100% - 43px);
                    }
                }
            }
        }
        img {
            @include media-breakpoint-down(xxl) {
                width: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-only(xl) {
                height: 544px;
            }

            @include media-breakpoint-only(lg) {
                height: 447px;
            }

            @include media-breakpoint-only(md) {
                height: 343px;
            }

            @include media-breakpoint-only(sm) {
                height: 223px;
            }

            @include media-breakpoint-down(sm) {
                height: 179px;
            }
        }

        img.download-icon {
            height: auto;
            width: auto;
            object-fit: none;
        }
    }
}

.common-product-link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    color: transparent;
    opacity: 0;
    font-size: 0;
}

.back-button {
    @include back-button;
}

.fav-button {
    @include fav-button;
}

.region {
    margin-bottom: 0;
}

.padding-top-bottom-50 {
    @include top-bottom-padding;
}

.margin-top-bottom-50 {
    @include top-bottom-margin;
}

.padding-top-50 {
    @include top-padding;
}

.padding-bottom-50 {
    @include bottom-padding;
}

.margin-top-50 {
    @include top-margin;
}

.margin-bottom-50 {
    @include bottom-margin;
}

.disable-image-download {
    &:hover {
        .img-info-overlay,
        .image-download-icon {
            display: none;
        }
    }
}

.smalltitle {
    font-size: 13px;
    letter-spacing: 0.65px;
    font-weight: $font-weight-semibold;
}