
@import "~base/components/productTiles";

.product {
    .product-tile {
        .tile-body {
            .pdp-link {
                .link {
                    font-family: "Plus Jakarta Sans";
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 10px;
                    color: #000000;
                    display: block;
                    align-items: center;
                    word-break: break-word;
                }
            }
        }
    }
}